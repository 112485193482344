import { IsOptional, ValidateNested } from "class-validator";
import { CreateProjetParams } from "./create-projet.params";
import { Projet } from "../../models/projet.model";
import { Params } from "./_params.type";
import { Type, Transform } from "class-transformer";
import { Model } from "../../models/base/model";

export class UpdateProjetParams extends CreateProjetParams implements Params<Projet>{

    @IsOptional()
    @ValidateNested({ each: true })
    @Type(() => Model)
    @Transform(({ value }) => (!value || value[0]?.id) ? value : value.map((v: number) => ({ id: v } as Model)))
    sites: Array<Model> | undefined
}