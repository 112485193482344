import { IsDate, IsOptional, IsString } from "class-validator"
import { Transform } from "class-transformer"
import { Model } from "./model"
import { FormConfig } from "@class-former/decorators"
import { ModelWithParent } from "./model-with-parent"

export abstract class ModelWithMeta extends ModelWithParent {

    @FormConfig({ skip: true })
    @IsOptional()
    @IsString()
    meta_auteurcreation!: string

    @FormConfig({ skip: true })
    @IsOptional()
    @IsString()
    meta_auteurmaj?: string

    @FormConfig({ skip: true })
    @IsDate()
    @Transform(({ value }) => new Date(value), { toClassOnly: true })
    meta_datecreation!: Date

    @FormConfig({ skip: true })
    @IsOptional()
    @IsDate()
    @Transform(({ value }) => { value ? new Date(value) : undefined }, { toClassOnly: true })
    meta_datemaj?: Date
}