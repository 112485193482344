import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Card, HStack, Stack, VStack } from "@chakra-ui/react";
import { Form } from "formik";
import { memo, useCallback, useMemo, useRef } from "react";
import { Model, SearchableModel } from "soverdi-api-models";
import { Stepper } from "react-shared";
import { FormButtons } from "./components/form-buttons.component";
import { IFormProps } from "./props/form.props";
import { ClassFormer, ClassFormerRef, FieldWrapperProps } from "@class-former/react";
import { CustomFormConfig } from "./configuration/custom-form.config";
import { useSubmit } from "./hooks/submit";
import { FieldWrapper } from "./components/field-wrapper.component";
import "./form.component.css"

interface IProps<T extends Model & SearchableModel, U extends Object> extends IFormProps<T, U> {
    createMode?: boolean
}
function CustomFormComponent<T extends Model & SearchableModel, U extends Object>({ model, FormSubmitType, tabIdentifier, service, onChange, onClose, createMode }: IProps<T, U>) {

    const { submit, loading } = useSubmit({ FormSubmitType, tabIdentifier, service, onChange, model })
    const fieldWrapper = useCallback((p: FieldWrapperProps<any, T>) => (<FieldWrapper {...p} tabIdentifier={tabIdentifier} />), [tabIdentifier])

    const data = useMemo(() => model || new Model(), [model])
    const formRef = useRef<ClassFormerRef<T>>(null)
    const _submit = useCallback(async (values: T) => {
        const result = await submit(values)
        if (result && createMode)
            formRef.current?.resetForm({ values: data, touched: undefined, errors: undefined })
    }, [submit, data, createMode])

    return (
        <ClassFormer<T>
            data={data}
            onSubmit={_submit}
            fieldWrapper={fieldWrapper}
            config={CustomFormConfig}
            ref={formRef}
        >
            {({ fields, formConfig, errors, resetForm }) => {
                const errorArray = Object.keys(errors)
                const invalid = errorArray.length > 0

                const taggedFields = fields.filter(f => f.meta.tags)
                const untaggedFields = fields.filter(f => !f.meta.tags)
                const optionalIndices = untaggedFields.map(f => !!f.meta.validations?.find(v => v.name === "isOptional" || v.each) || f.meta.specialType === "parent")
                const requiredFields = untaggedFields.filter((f, i) => !optionalIndices[i])
                const optionalFields = untaggedFields.filter((f, i) => optionalIndices[i])

                const tags = Array.from(new Set(taggedFields.map(f => f.meta.tags as Array<string>).flat()))
                const fieldsByTag = tags.map(tag => ({ tag, fields: taggedFields.filter(f => f.meta.tags?.includes(tag)) }))
                return (
                    <Form>
                        <HStack alignItems={"stretch"}>
                            <VStack flexGrow={1} spacing={4}>
                                {requiredFields.map(f => f.input)}
                            </VStack>
                            <Stack ml={4} marginTop="35px">
                                <Stepper formControlStates={requiredFields.map(c => errorArray.includes(c.meta.propertyName))} />
                            </Stack>
                        </HStack>
                        <VStack mt={6} flexGrow={1} spacing={4} alignItems={"stretch"}>
                            {fieldsByTag.length > 0 && <Card boxShadow={"none"}>
                                <Accordion allowMultiple>
                                    {fieldsByTag.map(tf => (
                                        <AccordionItem key={tf.tag}>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        {tf.tag}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <VStack spacing={4}>
                                                    {tf.fields.map(f => f.input)}
                                                </VStack>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            </Card>
                            }
                            {optionalFields.map(f => f.input)}
                        </VStack>
                        <FormButtons createMode={!model} formInvalid={invalid} loading={loading} onCancel={() => {
                            onClose();
                            resetForm({ values: data, touched: undefined, errors: undefined });
                        }} />
                    </Form>
                )
            }}
        </ClassFormer>
    )
}

export const CustomForm = memo(CustomFormComponent) as typeof CustomFormComponent