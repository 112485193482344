import { memo, useCallback, useContext, useState } from "react";
import { AssignParams, Model, User, assignationDefaultRoles } from "soverdi-api-models";
import { useTranslation } from "react-i18next";
import { useStatusToast } from "react-shared";
import { IAssignationService } from "soverdi-api-client";
import { AiOutlineStar } from "react-icons/ai";
import { BulkActionProps } from "./bulk-action.props";
import { AssignableModel } from "soverdi-api-models/src/models/composition/assignable.model";
import { AppContext } from "../../../app.context";
import { Button, Icon } from "@chakra-ui/react";

function AssignMeBulkActionComponent<T extends Model & AssignableModel>({ service, data, onChange }: BulkActionProps<T, IAssignationService<T>>) {

    const { t } = useTranslation()
    const salert = useStatusToast()
    const { token } = useContext(AppContext)
    const [loading, setLoading] = useState(false)

    const onFavorite = useCallback(async () => {
        setLoading(true)
        const params = new AssignParams()
        params.ids = data
        try {
            await service.assignation.assignMe(params)
            salert.onSuccess(t("DefaultBulkActions.assignations"), "save")
            if (!onChange) return
            const me = new User()
            me.id = token!.token.iduser
            me.username = token!.token.username
            onChange(prev => [...prev])
        }
        catch (e) {
            console.error(e)
            salert.onError(t("DefaultBulkActions.assignations"), "save")
        }
        setLoading(false)
    }, [service, data, salert, t, onChange, token, setLoading])

    return (
        <>
            {assignationDefaultRoles.assignMe.includes(token!.role) &&
                <Button isLoading={loading} loadingText={t("ListItem.favorite")} colorScheme="yellow" data-testid="assign-me-button" onClick={onFavorite} rightIcon={<Icon as={AiOutlineStar} />}>{t("ListItem.favorite")}</Button >
            }
        </>
    )
}

export const AssignMeBulkAction = memo(AssignMeBulkActionComponent) as typeof AssignMeBulkActionComponent
