import { Heading, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface IProps {
    sm?: boolean
}
export function EmptyList({ sm }: IProps) {

    const { t } = useTranslation()

    return (
        <Stack width={"full"} my={sm ? 0 : 12} alignItems={"center"} justifyContent={"center"}>
            <Heading size={sm ? "sm" : "lg"}>{t("EmptyList.title")}</Heading>
        </Stack>
    )
}