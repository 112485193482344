import { memo, useCallback } from "react";
import { CustomInputProps } from "./input.props";
import {
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput as ChakraNumberInput,
    NumberInputField,
    NumberInputStepper,
} from "@chakra-ui/react";

interface IProps extends CustomInputProps<number, never> {
    min?: number
    max?: number
}
function NumberInputComponent({ value, onChange: _onChange, "data-testid": dataTestId, max, min }: IProps) {

    const onChange = useCallback((valueAsString: string, valueAsNumber: number) => _onChange(isNaN(valueAsNumber) ? undefined : valueAsNumber), [_onChange])

    return (
        <ChakraNumberInput step={1} value={value} onChange={onChange} max={max} min={min}>
            <NumberInputField data-testid={dataTestId} />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </ChakraNumberInput>
    )
}

export const NumberInput = memo(NumberInputComponent) as typeof NumberInputComponent