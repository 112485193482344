import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator';
import * as gjv from 'geojson-validation';

@ValidatorConstraint({ async: false })
class IsValidGeoJSONConstraint implements ValidatorConstraintInterface {
    validate(geojson: any) {
        return !!gjv.valid(geojson);
    }

    defaultMessage() {
        return 'GeoJSON is invalid';
    }
}

export function IsGeoJSON(validationOptions?: ValidationOptions) {
    return (object: any, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsValidGeoJSONConstraint,
        });
    };
}