import i18next from "i18next";
import { useInit } from "../../hooks/init.hook";

export function addTranslations() {
    useInit(() => {
        i18next.addResourceBundle("en", "translation", {
            DeleteProvider: {
                title: "Delete ",
                body: "Are you sure? You can't undo this action afterwards.",
                cancel: "Cancel",
                delete: "Delete"
            }
        })

        i18next.addResourceBundle("fr", "translation", {
            DeleteProvider: {
                title: "Supprimer ",
                body: "Êtes-vous sûr? Cette action est irréversible.",
                cancel: "Annuler",
                delete: "Supprimer"
            }
        })
    })
}