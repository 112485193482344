import { HStack } from "@chakra-ui/layout";
import { Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody } from "@chakra-ui/popover";
import { IconButton, Icon, Stack } from "@chakra-ui/react";
import { memo, useCallback, useMemo } from "react";
import { FakeInput, useRoutingDisclosure } from "react-shared";
import { DOMAINE_TYPE, Domaine } from "soverdi-api-models";
import { DomaineBadge } from "../domaine-badge.component";
import { MdModeEdit } from "react-icons/md";
import { DomaineSelector } from "./components/domaine-selector.component";
import { RestService } from "api-client-shared";
import { IAutocompleteService } from "soverdi-api-client";
import { CustomInputProps } from "./input.props";
import { useLocation } from "react-router-dom";
import { FixIosPopupScroll } from "../../hooks/ios-popup-scroll.hook";

export interface DomaineInputIProps<T extends Domaine | Domaine[]> extends CustomInputProps<T, RestService<Domaine, any, any> & IAutocompleteService<Domaine>> {
    domaineType: DOMAINE_TYPE,
    multiple?: boolean
}
function DomaineInputComponent<T extends Domaine | Domaine[]>({ value, service, onChange, 'data-testid': dataTestId, domaineType, multiple }: DomaineInputIProps<T>) {

    const values = useMemo(() => value as Domaine[] || [], [value])
    const onValuesChange = useMemo(() => onChange as (value: Domaine[]) => void, [onChange])
    const { pathname } = useLocation()

    const { isOpen, onOpen, onClose } = useRoutingDisclosure(service.endpoint + "/domaines")
    FixIosPopupScroll(isOpen, () => document.getElementsByClassName("domaine-selector")[0])
    
    const onRemoveBadgeFromInput = useCallback((d: Domaine) => {
        if (multiple) {
            onValuesChange(values.filter(v => d.id !== v.id))
        }
        else onChange(undefined)
    }, [multiple, onChange, onValuesChange, values])

    const onChangeFromDomaineSelector = useCallback((d: T | undefined) => {
        onChange(d)
        onClose()
    }, [onChange, onClose])

    const freezePopover = useMemo(() => {
        return pathname.includes("delete")
    }, [pathname])

    return (
        <>
            <HStack alignItems={"start"} data-testid={dataTestId}>
                <Stack flexGrow={1}>
                    <FakeInput>
                        <HStack data-testid="domaine-input" spacing={1} flexWrap={"wrap"} overflowX={"hidden"}>
                            {value && (multiple ? values : [value as Domaine]).map(d => (<DomaineBadge key={d.id} domaine={d} size="sm" onClose={() => onRemoveBadgeFromInput(d)} />))}
                        </HStack>
                    </FakeInput>
                </Stack>
                <Popover closeOnBlur={!freezePopover} isLazy isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
                    <PopoverTrigger>
                        <IconButton data-testid="open-domaine-selector" aria-label="add-domaine" icon={<Icon as={MdModeEdit} />} />
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody p={4} data-testid="domaine-input-popover">
                            <DomaineSelector value={value} onChange={onChangeFromDomaineSelector} domaineType={domaineType} service={service} multiple={multiple} />
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </HStack>
        </>
    )
}

export const DomaineInput = memo(DomaineInputComponent) as typeof DomaineInputComponent