import axios from "axios"
import { plainToInstance } from "class-transformer";
import { BaseService } from "./base.service"
import { environment } from "../config/_environment";

export abstract class RestService<T, J, U> extends BaseService<T> {

    async get(id: number): Promise<T> {
        const res = await axios.get<T>(this.url.join([this.endpoint, "rest", id.toString()]))
        const clazzed = plainToInstance(this.modelClazz, res.data)
        return clazzed
    }

    async some(ids: Array<number>): Promise<Array<T>> {
        const res = await axios.get<Array<T>>(this.url.join([this.endpoint, "rest", environment.API.ENDPOINTS.some], { ids }))
        const clazzed = plainToInstance(this.modelClazz, res.data)
        return clazzed
    }

    async getAll(): Promise<Array<T>> {
        const res = await axios.get<Array<T>>(this.url.join([this.endpoint, "rest"]))
        const clazzed = res.data.map(d => plainToInstance(this.modelClazz, d))
        return clazzed
    }

    async getAllIds(): Promise<Array<number>> {
        const res = await axios.get<Array<number>>(this.url.join([this.endpoint, "rest", environment.API.ENDPOINTS.allIds]))
        return res.data
    }

    async create(body: J): Promise<T> {
        const res = await axios.post<T>(this.url.join([this.endpoint, "rest"]), body)
        const clazzed = plainToInstance(this.modelClazz, res.data)
        return clazzed
    }

    async update(id: number, body: U): Promise<T> {
        const res = await axios.put<T>(this.url.join([this.endpoint, "rest", id?.toString()]), body)
        const clazzed = plainToInstance(this.modelClazz, res.data)
        return clazzed
    }

    async delete(id: number): Promise<void> {
        const res = await axios.delete<T>(this.url.join([this.endpoint, "rest", id.toString()]))
        return
    }
}
