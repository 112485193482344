import axios from "axios"
import { CompositionService } from "api-client-shared"
import { IdsParams, InterventionExportParams, Vegetal, environment, VegetalExportParams } from "soverdi-api-models"
import { Token } from "typedi"

export class VegetauxService extends CompositionService<Vegetal> {

    constructor(protected endpoint: string, urlInjectionToken: Token<string>) {
        super(Vegetal, endpoint, urlInjectionToken)
    }

    async vegetauxForEntityIds(ids: Array<number>): Promise<number[]> {
        const params = new IdsParams()
        params.ids = ids
        const res = await axios.post<number[]>(this.url.join([this.endpoint, environment.endpoints.generics.vegetaux.base, environment.endpoints.generics.vegetaux.in]), params)
        return res.data
    }

    async exportVegetauxForEntityIds(params: VegetalExportParams): Promise<string> {
        const res = await axios.post<string>(this.url.join([this.endpoint, environment.endpoints.generics.vegetaux.base, environment.endpoints.generics.vegetaux.exportvegetaux]), params)
        return res.data
    }

    async exportInterventionsForEntityIds(params: InterventionExportParams): Promise<string> {
        const res = await axios.post<string>(this.url.join([this.endpoint, environment.endpoints.generics.vegetaux.base, environment.endpoints.generics.vegetaux.exportvegetauxinterventions]), params)
        return res.data
    }
}

export interface IVegetauxService {
    readonly vegetaux: VegetauxService
}
