import { Transform, Type } from "class-transformer"
import { IsOptional, IsDate } from "class-validator"

export class BetweenDates {
    @IsOptional()
    @IsDate()
    @Transform(({ value }) => new Date(value), { toClassOnly: true })
    after!: Date

    @IsOptional()
    @IsDate()
    @Transform(({ value }) => new Date(value), { toClassOnly: true })
    before!: Date
}