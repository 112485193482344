import { Input } from "@chakra-ui/react";
import { useState, useCallback, useRef, useMemo, useEffect } from "react";
import { IFilterFormControlProps } from "./filter-form-control.props";
import { DomaineParams, Domaine } from "soverdi-api-models";
import { FilterFormControl } from "./filter-form-control.component";
import { DomaineSelector } from "../../../selectors/domaine.selector";
import { RestService } from "api-client-shared";

interface IProps extends IFilterFormControlProps<Domaine[]> {
    domaineServiceType: RestService<Domaine, DomaineParams, DomaineParams>
    'data-testid'?: string;
}
export function DomainesFilter({ domaineServiceType, text, onChange, onRemove, "data-testid": dataTestid }: IProps) {

    const [selected, setSelected] = useState<Domaine[]>([])
    const nom = useMemo(() => selected.map(x => x.nom).join(", "), [selected])
    const ref = useRef<HTMLInputElement>(null)

    const onDomaineSelect = useCallback((d: Domaine) => {
        setSelected(prev => {
            const updated = [...prev, d]
            onChange(updated)
            return updated
        })
    }, [onChange, setSelected])

    useEffect(() => {
        if (ref.current)
            ref.current.style.width = (5 + nom.length) + "ch";
    }, [nom, ref])

    return (
        <FilterFormControl onClose={onRemove} text={text}>
            <DomaineSelector
                domaineService={domaineServiceType}
                onSelect={onDomaineSelect}
                customTrigger={<Input data-testid={dataTestid} ref={ref} type="text" readOnly textAlign={"center"} px={2} htmlSize={1} value={selected.map(x => x.nom).join(", ")}
                    height="25px" width='5ch' variant={"filled"}></Input>} />

        </FilterFormControl>
    )
}