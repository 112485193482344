import { Card, CardBody, HStack, Stack, SkeletonText } from "@chakra-ui/react";
import { memo } from "react";

export const ListItemPlaceholder = memo(() => {

    return (
        <Card mb={4} width={"full"}>
            <CardBody p={3}>
                <HStack justifyContent={"space-between"}>
                    <Stack ml={6} flexGrow={1}>
                        <SkeletonText mb={2} noOfLines={1} width={"180px"} skeletonHeight="6"></SkeletonText>
                        <SkeletonText noOfLines={1} width={"100px"} skeletonHeight="4"></SkeletonText>
                    </Stack>
                </HStack>
            </CardBody>
        </Card>
    )
})