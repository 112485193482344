import { ModalSheetBody, ModalSheetContext, useRoutingDisclosureForTabs } from "react-shared";
import { useState, useCallback, useMemo, memo, useEffect, SetStateAction, Dispatch, useContext } from "react";
import { Collapse } from "@chakra-ui/transition";
import { Model, ModelWithParent, SearchableModel } from "soverdi-api-models";
import { ModalHeader } from "../modal/modal-header.component";
import { ChildResources, ModelResources } from "../../model-resources/model.resources";
import { ModelList } from "../model-list/model-list.component";
import { useTranslation } from "react-i18next";
import { IoMdCreate } from "react-icons/io"
import { Hierarchy } from "../navigation/hierarchy.component";
import { Box, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { useTabStack } from "../../hooks/tab-stack/tab-stack.hook";
import { AppContext } from "../../app.context";
import { useNavigate } from "react-router-dom";
import { plainToInstance } from "class-transformer"

export interface IModelViewProps<T extends ModelWithParent & SearchableModel> {
    modelid: number
    modelResources: ModelResources<T>
    focused: boolean,
    onChange?: (value: Model) => void
}
const ModelViewComponent = <T extends ModelWithParent & SearchableModel>({ onChange, modelid, modelResources, focused }: IModelViewProps<T>) => {

    const { t } = useTranslation()
    const [model, _setModel] = useState<T | undefined>()
    const { clear } = useTabStack()
    const setModel = useCallback((value: T) => {
        _setModel(value)
        onChange && onChange(value)
    }, [onChange, _setModel])
    const [virtuosoScrollParent, setVirtuosoScrollParent] = useState<HTMLElement>()
    const FormComponent = modelResources.form
    const { token } = useContext(AppContext)
    const { setHeader } = useContext(ModalSheetContext)
    const editAllowed = useMemo(() => !!FormComponent && modelResources.roles.rest.update.includes(token!.role), [FormComponent, token, modelResources.roles.rest.update])
    const tabPaths = useMemo(() => [...modelResources.lists.map((x, i) => i === 0 ? "" : (x.resources.tabIdentifier + "list")), ...(editAllowed ? ["edit"] : [])], [modelResources.lists, editAllowed])
    const { tabIndex, onTabChange } = useRoutingDisclosureForTabs({ animationDuration: 0, tabPaths })
    const navigate = useNavigate()

    const refreshContainer = useCallback(async () => {
        const result = await modelResources.service.get(modelid)
        setModel(result)
    }, [setModel, modelid, modelResources.service])

    const onModelChangeFromAction: Dispatch<SetStateAction<any>> = useCallback((action: (prev: Array<T>) => Array<T>) => {
        const updatedList = action([model as T])
        setModel(plainToInstance(modelResources.Model, updatedList[0]))
        if (updatedList.length === 0)
            clear()
    }, [clear, model, setModel, modelResources.Model])

    const renderedActions = useMemo(() => model ? modelResources.actions.map((Action, i) => (
        <Action key={"action_" + i} model={modelResources.Model} service={modelResources.service} data={model.id} onChange={onModelChangeFromAction}></Action>
    )) : [], [model, onModelChangeFromAction, modelResources])
    const renderedBulkActions = useMemo(() => model ? modelResources.bulkActions.map((Action, i) => (
        <Action key={"bulkaction_" + i} model={modelResources.Model} service={modelResources.service} data={[model.id]} onChange={onModelChangeFromAction}></Action>
    )) : [], [model, onModelChangeFromAction, modelResources])

    const onModelListChange = useCallback((child: ChildResources<T, any>) => {
        const setState: Dispatch<SetStateAction<any>> = (action: (prev: Array<any>) => Array<any>) => {
            const updatedList = action(child.get(model as T) as Array<any>)
            const updatedData = child.set(model as T, updatedList)
            setModel(updatedData)
        }
        return setState
    }, [model, setModel])

    const lists = useMemo(() => modelResources.lists.map((child, i) => {
        const childData = child.get(model)
        const subtitle = child.subtitle ? child.subtitle(model!, t) : undefined
        const _onChange = onModelListChange(child)
        return (
            <ModelList
                marginbottom="lg"
                subtitle={subtitle}
                parentidToCreateItem={model?.id}
                onChange={_onChange}
                ids={childData}
                modelResources={child.resources}
                virtuosoScrollParent={virtuosoScrollParent}
            />
        )
    }), [modelResources.lists, model, virtuosoScrollParent, onModelListChange, t])

    const onFormClose = useCallback(() => {
        navigate(-1)
    }, [navigate])

    useEffect(() => {
        if (!focused) return
        refreshContainer()
    }, [refreshContainer, focused])

    useEffect(() => {
        setHeader(<ModalHeader title={model?.searchBy} icon={modelResources.icon} actions={[...renderedActions, ...renderedBulkActions]} />)
    }, [model?.searchBy, modelResources, renderedActions, renderedBulkActions, setHeader])

    return (
        <>
            <ModalSheetBody data-testid="model-view" ref={r => { r && setVirtuosoScrollParent(r) }}>
                {model?.subtext && <Box>
                    <Text fontWeight={"bold"}>{model.subtext.map(subtext => (subtext.i18nprefix ? t(subtext.i18nprefix) + " " : "") + subtext.value).join(", ")}</Text>
                </Box>
                }
                <Collapse in={!!model?.parent}>
                    <Box mb={6}>
                        {model && <Hierarchy model={model} />}
                    </Box>
                </Collapse>
                <Tabs index={tabIndex} onChange={onTabChange} isManual>
                    <TabList overflowX={"visible"} >
                        {modelResources.lists.map(x => (<Tab px={[2, 4]} key={x.resources.tabIdentifier} data-testid="tab-button"><Icon mr={2} as={x.resources.icon} />{t("models.asList." + x.resources.tabIdentifier)}</Tab>))}
                        {editAllowed && <Tab px={[2, 4]} data-testid="tab-button"><Icon mr={2} as={IoMdCreate} /> {t("common.edit")}</Tab>}
                    </TabList>
                    <TabPanels>
                        {lists.map((x, i) => (<TabPanel px={0} key={i}>{x}</TabPanel>))}
                        {editAllowed && model && FormComponent &&
                            <TabPanel px={0}>
                                <FormComponent
                                    service={modelResources.service}
                                    tabIdentifier={modelResources.tabIdentifier}
                                    onClose={onFormClose}
                                    model={model}
                                    FormSubmitType={modelResources.UpdateParams}
                                    onChange={setModel}
                                /></TabPanel>
                        }
                    </TabPanels>

                </Tabs>

            </ModalSheetBody>
        </>
    )
}

export const ModelView = memo(ModelViewComponent) as typeof ModelViewComponent