import axios from 'axios';
import { Service, Inject } from 'typedi';
import { environment } from '../config/_environment';
import { Token } from '../interfaces/token.interface';
import { IGOazimutApp, IGOazimutUser } from '../interfaces/credentials.interface';
import { APIError } from "api-client-shared"
import { PortailAPIUrl } from '../utils/portail-api-url';
import { set, get } from "idb-keyval"

@Service()
export class AuthService {

  private authToken?: Token
  @Inject()
  private url!: PortailAPIUrl

  /** Unintercepted clone of axios to prevent infinite loop */
  private axios = axios.create()

  constructor() {
    axios.interceptors.request.use(async (config) => {
      const token = await this.beforeRequest()
      config.headers.set("Authorization", "Bearer " + token.access_token)
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });
  }

  getGDE() {
    return this.authToken?.gde
  }

  logout() {
    this.authToken = undefined
  }

  private validateToken(): boolean {
    if (!this.authToken) return false
    const expiration = new Date(this.authToken.access_exp)
    const now = new Date()
    if (expiration < now) return false
    return true
  }

  private validateRefreshToken(): boolean {
    if (!this.authToken) return false
    const expiration = new Date(this.authToken.refresh_exp)
    const now = new Date()
    if (expiration < now) return false
    return true
  }

  public async beforeRequest() {
    const token = await this.authentificateWithRefreshToken();
    return token
  }

  public async authentificateWithRefreshToken(_token?: Token, app?: IGOazimutApp): Promise<Token> {
    const token = _token || this.authToken
    this.authToken = token
    if (this.validateToken()) return this.authToken as Token
    if (!this.validateRefreshToken()) throw new APIError(400, "refresh token invalid or expired")
    const res = await this.axios.post<Token>(this.url.join([environment.API.ENDPOINTS.refreshToken]), { "refresh_token": this.authToken?.refresh_token })
    if (app)
      this.matchApp(app, res.data)
    this.authToken = res.data
    set("auth_token", this.authToken)
    return this.authToken
  }

  matchApp(app: IGOazimutApp, token: Token) {
    const match = token.roles.find(r => r.app.description === app.description && r.app.version === app.version)
    if (!match) throw new APIError(403, "User forbideen access for this app or version")
  }

  async authentificateWithUser(_user: IGOazimutUser): Promise<Token> {

    if (!_user) {
      this.logout()
      throw new APIError(400, "User not set")
    }

    if (this.validateToken()) return this.authToken as Token

    const res = await this.axios.post<Token>(this.url.join([environment.API.ENDPOINTS.token]), { username: _user.username, password: _user.password, gd: true /*gd retourne un token à accès limité qui a une longue durée d'expiration*/ })
    this.matchApp(_user.app, res.data)
    this.authToken = res.data
    if (!this.validateRefreshToken()) throw new APIError(400, "refresh token invalid or expired")
    return this.authToken
  }

}
