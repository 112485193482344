import { ChildResources, ModelResources } from "../model.resources";
import { GrCatalog } from "react-icons/gr";
import { ProduitResources } from "../produit.resources";
import { EspeceResources } from "../espece.resources";
import { Catalog } from "./catalog.model";
import { Produit, Espece, restDefaultRoles } from "soverdi-api-models";
import { CatalogService } from "./catalog.service";
import { instanceToInstance } from "class-transformer"
import { TabIdentifier } from "../../hooks/tab-stack/tab-identifier.enum";

class CatalogResourcesClass extends ModelResources<Catalog> {
    form = undefined
    icon = GrCatalog

    bulkActions = []
    actions = []
    service = new CatalogService() as any
    get parentService() { return undefined }
    tabIdentifier = TabIdentifier.catalog
    lists = [
        {
            resources: ProduitResources,
            get: (me) => me?.produits,
            set: (me, values) => {
                me.produits = values
                return instanceToInstance(me)
            }
        } as ChildResources<Catalog, Produit>,
        {
            resources: EspeceResources,
            get: (me) => me?.especes,
            set: (me, values) => {
                me.especes = values
                return instanceToInstance(me)
            }
        } as ChildResources<Catalog, Espece>
    ];
    roles = { rest: restDefaultRoles };
    Model = Catalog
    CreateParams = class { }
    UpdateParams = class { }
}

export const CatalogResources = new CatalogResourcesClass()