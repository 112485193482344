import { HStack, CloseButton, Box, Heading, Skeleton, Icon, IconButton } from "@chakra-ui/react";
import { PopButton } from "../navigation/pop-button.component";
import { FC, ReactNode, memo, useCallback, useContext, useRef } from "react";
import { BrickMenu, ModalSheetContext, MenuRef } from "react-shared";
import { isMobile } from 'react-device-detect';
import { IconType } from "react-icons";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";

interface IProps {
    title?: string
    icon?: IconType
    actions: Array<ReactNode>
}
export const ModalHeader: FC<IProps> = memo(({ title, icon, actions }: IProps) => {

    const navigate = useNavigate()
    const { onToggle, onClose } = useContext(ModalSheetContext)
    const ref = useRef<MenuRef>(null)

    const _onToggle = useCallback(() => {
        ref.current?.onClose()
        onToggle()
    }, [ref, onToggle])

    const onBack = useCallback(() => {
        navigate(-1)
    }, [navigate])

    return (
        <HStack justifyContent={"space-between"} alignItems={"center"}>
            <HStack>
                <Box cursor={"pointer"}>
                    <IconButton variant={"ghost"} aria-label="back" onClick={onBack} icon={<Icon as={MdArrowBackIosNew} />} />
                </Box>
                <Box cursor={"pointer"}>
                    <PopButton />
                </Box>
            </HStack>
            <HStack minWidth={0} height="40px" flexGrow="1" justifyContent="end" data-testid="expand-modal-sheet-button" cursor={"pointer"} onClick={_onToggle}>
                {title ?
                    <>
                        {icon && <Icon as={icon} />}
                        <Heading data-testid="modal-sheet-header" size={"sm"} mt={"2px"} className="ellipsis">{title}</Heading>
                    </>
                    : <Skeleton data-testid="modal-sheet-loading" width={"100px"} height="20px" noOfLines={1} />}
            </HStack>
            <HStack gap={0}>
                {actions.length > 0 &&
                    <Box ml={1}>
                        <BrickMenu data-testid="modal-header-actions-button" ref={ref} usePortal isDisabled={!title} buttons={actions} />
                    </Box>
                }
                {!isMobile && <CloseButton data-testid="close-modal-button" zIndex={2} onClick={onClose} size={"lg"} fontSize={13} />}
            </HStack>
        </HStack>
    )
})