import { Service, Inject } from "typedi";
import { RestService } from "api-client-shared";
import { VegetalParams, SearchVegetalParams, Vegetal } from "soverdi-api-models";
import { environment } from "soverdi-api-models";
import { SoverdiAPIUrl } from "../utils/soverdi-api-url";
import { SOVERDI_API_HOSTNAME_TOKEN } from "../tokens/soverdi-api-hostname.token";
import { ISearchService, SearchService } from "./composition/search-service";
import { BulkService, IBulkService } from "./composition/bulk.service";
import { IVegetauxService, VegetauxService } from "./composition/vegetaux.service";

@Service()
export class VegetalService extends RestService<Vegetal, VegetalParams, Partial<VegetalParams>> implements ISearchService<Vegetal, SearchVegetalParams>, IBulkService<Vegetal, never>, IVegetauxService {
    modelClazz = Vegetal
    @Inject()
    protected url!: SoverdiAPIUrl
    endpoint = environment.endpoints.vegetal.base

    readonly search = new SearchService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly bulk = new BulkService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly vegetaux = new VegetauxService(this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
}
