/** Clean will removed any undefined value from an object */
export function Clean(obj: any) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                // Recursively remove undefined values in nested objects
                obj[key] = Clean(obj[key]);

                // Remove the entire key if it becomes an empty object after removing undefined values
                if (Object.keys(obj[key]).length === 0 && obj[key].constructor === Object) {
                    delete obj[key];
                }
            } else {
                // Remove the key if its value is undefined
                if (obj[key] === undefined) {
                    delete obj[key];
                }
            }
        }
    }

    return obj;
}