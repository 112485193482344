import { IsArray, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator"
import { SearchParams } from "./search.params"
import { Type } from "class-transformer"
import { Model } from "../../models/base/model"
import { ISearchParams } from "./search-params.type"
import { Vegetal } from "../../models/vegetal.model"

export class SearchVegetalParams extends SearchParams implements ISearchParams<Vegetal, "commentaires" | "idcommande" | "produit"> {

    @IsOptional()
    @IsNumber()
    id?: number

    @IsOptional()
    @IsString()
    specimenid?: string

    @IsOptional()
    @IsNumber()
    remplacementid?: number

    @IsOptional()
    @ValidateNested()
    @Type(() => Model)
    etatsante?: Model

    @IsOptional()
    @ValidateNested()
    @Type(() => Model)
    raisonmortalite?: Model

    @IsOptional()
    @IsString()
    genre?: string

    @IsOptional()
    @IsString()
    nomespece?: string

    @IsOptional()
    @IsString()
    cultivar?: string

    @IsOptional()
    @IsString()
    code?: string

    @IsOptional()
    @ValidateNested()
    @Type(() => Model)
    typeespece?: Model

    @IsOptional()
    @ValidateNested()
    @Type(() => Model)
    formatpot?: Model

}