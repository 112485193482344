import { Popover, PopoverTrigger, IconButton, Icon, PopoverContent, PopoverArrow, PopoverBody, VStack, Button, Text, useDisclosure } from "@chakra-ui/react";
import { getService } from "api-client-shared";
import { AuthService } from "portail-api-client";
import { useCallback, useContext } from "react";
import { BsLightbulb } from "react-icons/bs";
import { Contact, LanguageSwitcher, useRoutingDisclosure } from "react-shared";
import { AppContext } from "../../app.context";
import { CiLogout, CiSettings } from "react-icons/ci";
import { BiBug } from "react-icons/bi"
import { environment } from "../../config/environment";
import { useTranslation } from "react-i18next";

export function Options() {

    const { t } = useTranslation()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: requestFeatureIsOpen, onOpen: requestFeatureOnOpen, onClose: requestFeatureOnClose } = useRoutingDisclosure("/request-feature")
    const { isOpen: reportBugIsOpen, onOpen: reportBugOnOpen, onClose: reportBugOnClose } = useRoutingDisclosure("/report-bug")
    const auth = getService(AuthService)
    const { setToken, setLanguage } = useContext(AppContext)

    const logout = useCallback(async () => {
        auth.logout();
        setToken(undefined)
    }, [auth, setToken])

    return (
        <>
            <Popover isLazy isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
                <PopoverTrigger>
                    <IconButton boxShadow={"lg"} fontSize="20px" icon={< Icon as={CiSettings} />} aria-label='more'></IconButton >
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody p={4}>
                        <VStack alignItems={"stretch"} spacing={"4"}>
                            <LanguageSwitcher setLanguage={setLanguage} />
                            <Button id="logout-button" data-testid="logout-button" onClick={logout} leftIcon={<Icon as={CiLogout} />} colorScheme='teal' variant='solid' justifyContent={"start"}>
                                {t("options.logout")}
                            </Button>
                            <Button data-testid="request-feature-button" marginTop={"6"} colorScheme="yellow" leftIcon={<Icon as={BsLightbulb} />} onClick={requestFeatureOnOpen} justifyContent={"start"} >{t("contact.feature.title")}</Button>
                            <Button data-testid="report-bug-button" colorScheme="yellow" leftIcon={<Icon as={BiBug} />} onClick={reportBugOnOpen} justifyContent={"start"} >{t("contact.bug.title")}</Button>
                            <Text textAlign={"right"}>v{environment.VERSION}</Text>
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
            <Contact appName={environment.USER_REPORT_APPNAME} isOpen={requestFeatureIsOpen} onClose={requestFeatureOnClose} requestType="feature" />
            <Contact appName={environment.USER_REPORT_APPNAME} isOpen={reportBugIsOpen} onClose={reportBugOnClose} requestType="bug" />
        </>
    )
}