import { ModelResources } from "./model.resources";
import { CommandeService, VegetalService } from "soverdi-api-client";
import { TabIdentifier } from "../hooks/tab-stack/tab-identifier.enum";
import { Container } from "typedi"
import { Vegetal, VegetalParams, environment } from "soverdi-api-models";
import { PiPlant } from "react-icons/pi";
import { DeleteBulkAction } from "../components/menu-actions/bulk-actions/delete.bulk-action";
import { ShowBulkAction } from "../components/menu-actions/bulk-actions/show.bulk-action";
import { CustomForm } from "../components/model-forms/form.component";
import { ExportVegetauxBulkAction } from "../components/menu-actions/bulk-actions/export-vegetaux.bulk-action";
import { ExportInterventionsBulkAction } from "../components/menu-actions/bulk-actions/export-interventions.bulk-action";
import { ChangeParentAction } from "../components/menu-actions/actions/change-parent.action";
import { SetTagsAction } from "../components/menu-actions/bulk-actions/set-tags.bulk-action";

class VegetalResourcesClass extends ModelResources<Vegetal> {
    form = CustomForm
    get service() { return Container.get(VegetalService) }
    get parentService() { return Container.get(CommandeService) }

    icon = PiPlant
    bulkActions = [
        SetTagsAction,
        ShowBulkAction,
        ExportVegetauxBulkAction,
        ExportInterventionsBulkAction,
        DeleteBulkAction]
    actions = [ChangeParentAction]
    tabIdentifier = TabIdentifier.vegetal
    lists = []
    roles = { rest: environment.endpoints.vegetal.rest };
    Model = Vegetal
    CreateParams = VegetalParams
    UpdateParams = VegetalParams
}

export const VegetalResources = new VegetalResourcesClass()