import { Badge, HStack, IconButton, Icon, Text, Skeleton } from "@chakra-ui/react";
import { useMemo } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { Domaine } from "soverdi-api-models";
import { ChakraColor } from "../types/chakra-color";
import { getBadgeColor } from "../utils/badge-color";


interface IProps {
    domaine?: Domaine
    onClick?: () => void
    onDeleteClick?: () => void
    onClose?: () => void
    color?: ChakraColor
    size?: "sm" | "md"
}
export function DomaineBadge({ domaine, onClick, onDeleteClick, onClose, color, size }: IProps) {
    const sm = useMemo(() => size === "sm", [size])
    const hasIcon = useMemo(() => onDeleteClick || onClose, [onDeleteClick, onClose])
    const badgeColor = useMemo(() => color ? color : domaine ? getBadgeColor(domaine.id) : "blue", [domaine, color])
    return (
        <Badge maxWidth={"250px"} borderRadius={6} pl={4} colorScheme={badgeColor} pr={hasIcon ? 0 : 4}>
            <HStack spacing={0} alignItems={"center"} justifyContent={"center"} height={size ? sm ? "30px" : "40px" : undefined}>
                {!domaine ? <Skeleton noOfLines={1} width={"60px"} height={"18px"} /> :
                    <Text data-testid="domaine-badge" className="ellipsis" cursor={onClick ? "pointer" : "default"} onClick={onClick}>{domaine.nom}</Text>
                }
                {onDeleteClick &&
                    <IconButton
                        data-testid="domaine-badge-delete-button"
                        isDisabled={!domaine}
                        _hover={{}}
                        variant={"ghost"}
                        onClick={onDeleteClick}
                        aria-label="delete"
                        height={sm ? "30px" : "40px"}
                        icon={<Icon as={BsFillTrashFill} />} />
                }
                {onClose &&
                    <IconButton
                        data-testid="domaine-badge-close-button"
                        isDisabled={!domaine}
                        _hover={{}}
                        variant={"ghost"}
                        onClick={onClose}
                        aria-label="close"
                        height={sm ? "30px" : "40px"}
                        icon={<Icon as={AiOutlineClose} />} />}
            </HStack>
        </Badge>
    )
}