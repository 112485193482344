import { IsOptional, IsString } from "class-validator"

export class FindAddressCandidatesParams {
    @IsString()
    address!: string
    @IsString()
    city!: string
    @IsOptional()
    @IsString()
    postal?: string
}