import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input"
import { Box } from "@chakra-ui/layout"
import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/popover"
import { Portal } from "@chakra-ui/react"
import { InputProps } from "@class-former/react"
import { memo } from "react"
import { ColorPicker } from "react-pick-color"

interface IProps extends InputProps<string> {
    "data-testid"?: string
}
function ColorInputComponent({ value, onChange, "data-testid": datatestid }: IProps) {
    return (
        <Popover isLazy>
            <PopoverTrigger>
                <InputGroup>
                    <InputLeftElement>
                        <Box height={"15px"} width="15px" backgroundColor={value}></Box>
                    </InputLeftElement>
                    <Input data-testid={datatestid} readOnly value={value} onChange={() => null} />
                </InputGroup>
            </PopoverTrigger>
            <Portal>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                        <ColorPicker color={value} onChange={color => onChange(color.hex)} />
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    )
}

export const ColorInput = memo(ColorInputComponent) as typeof ColorInputComponent