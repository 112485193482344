import { Container, Fade, HStack, Icon, IconButton, VStack } from "@chakra-ui/react";
import { SearchBar } from "../components/search-bar/search-bar.component";
import { UpdateApp, useRoutingDisclosure } from "react-shared";
import { MapModal } from "../components/modal/modal.component";
import { useCallback, useEffect, useState } from "react";
import { TabStackContext } from "../contexts/tab-stack.context";
import { ViewInfo } from "../hooks/tab-stack/tab-stack-item.interface";
import { GOnetWrapper } from "../components/map-wrapper/gonet-wrapper.component";
import { MapHandle } from "../components/map-wrapper/map-handle.interface";
import { MapContext } from "../contexts/map.context";
import { BiCurrentLocation } from "react-icons/bi";
import { StartMenu } from "../components/dashboard-actions/start-menu.component";
import { Layers } from "../components/dashboard-actions/layers.component";
import { Options } from "../components/dashboard-actions/options.component";
import { ModalLockContext } from "../contexts/modal-lock.context";
import { environment } from "../config/environment";
import { ImportationActions } from "../components/importation/importation.component";

export function DashboardPage() {

    const [tabStack, setTabStack] = useState<Array<ViewInfo>>([])
    const { isOpen, onClose } = useRoutingDisclosure("/selection", { onCloseNavigateTo: "/dashboard", useInitialLocation: true })
    const [mapHandle, setMapHandle] = useState<MapHandle | undefined>()
    const [lock, setLock] = useState(false)
    const [positionWatch, setPositionWatch] = useState(false)

    const onPosition = useCallback(async () => {
        setPositionWatch(prev => {
            const updated = !prev
            if (updated)
                mapHandle?.watchPosition()
            else
                mapHandle?.stopWatchPosition()
            return updated
        })
    }, [mapHandle, setPositionWatch])

    useEffect(() => {
        if (tabStack.length === 0)
            onClose()
    }, [tabStack.length, onClose])

    return (
        <ModalLockContext.Provider value={{ lock, setLock }}>
            <TabStackContext.Provider value={{ tabStack: tabStack, setTabStack }}>
                <MapContext.Provider value={{ mapHandle, setMapHandle }}>
                    <Container maxW='full' height={"full"} p="0">
                        <UpdateApp version={environment.VERSION!} />
                        <Fade in={!!mapHandle || environment.OFFLINE}>
                            <HStack zIndex={1} spacing={2} px={2} mt={2} position={"absolute"} top="0" right="0" width={["calc(100vw)", "calc(100vw)", "auto"]}>
                                <SearchBar />
                                <StartMenu />
                            </HStack>
                            <VStack spacing={2} alignItems={"end"} p={2} position={"absolute"} top="calc(40px + 0.5rem)" right="0">
                                <Layers />
                                <IconButton colorScheme={positionWatch ? "blue" : "gray"} onClick={onPosition} boxShadow={"lg"} icon={<Icon fontSize={"20px"} as={BiCurrentLocation} />} aria-label="location"></IconButton>
                                <ImportationActions />
                                <Options />
                            </VStack>
                        </Fade>
                        <GOnetWrapper onMapReady={setMapHandle} />
                        <MapModal isOpen={isOpen} onClose={onClose} />
                    </Container>
                </MapContext.Provider>
            </TabStackContext.Provider>
        </ModalLockContext.Provider>
    )
}