import i18next from "i18next";
import { useInit } from "../../hooks/init.hook";

export function addTranslations() {
    useInit(() => {
        i18next.addResourceBundle("en", "translation", {
            modalsheet: {
                cancel: "Cancel",
                confirm: "Confirm"
            },
        })

        i18next.addResourceBundle("fr", "translation", {
            modalsheet: {
                cancel: "Annuler",
                confirm: "Confirmer"
            },
        })
    })
}