import { Box } from "@chakra-ui/layout";
import { useAsyncEffect, useDebounce, useRoutingDisclosure } from "react-shared";
import { useState, useMemo, useCallback, useEffect, Dispatch, SetStateAction, memo, useContext } from "react";
import { Virtuoso } from "react-virtuoso";
import { useTranslation } from "react-i18next";
import { ModalSubheader } from "../modal/modal-subheader.component";
import { EmptyList } from "../empty-list.component";
import { FormableModel, MetadataHelper, Model, SearchableModel } from "soverdi-api-models";
import { Collapse, HStack } from "@chakra-ui/react";
import { useTabStack } from "../../hooks/tab-stack/tab-stack.hook";
import { ListControls } from "./components/list-controls.component";
import { ListItemPlaceholder } from "../model-list-item/list-item.placeholder";
import { ListItem } from "../model-list-item/list-item.component";
import { IModelListProps } from "./props/model-list.props";
import { TypedDomainesState } from "../selectors/domaines.selector";
import { ModelViewInfo } from "../../hooks/tab-stack/tab-stack-item.interface";
import { ModelListLoading } from "./model-list-loading.component";
import { ListFilters } from "./components/list-filters.component";
import { useFilterData } from "./hooks/filter-data";
import { CustomForm } from "../model-forms/form.component"
import { LoadMore } from "./components/load-more.component";
import { environment } from "../../config/environment";
import { ISearchService } from "soverdi-api-client";
import { AppContext } from "../../app.context";

interface IProps<T extends Model & SearchableModel> extends IModelListProps<T> {
    virtuosoScrollParent?: HTMLElement
    onChange: Dispatch<SetStateAction<Array<number>>>
    onCreateClick?: () => void
    parentidToCreateItem?: number
    marginbottom: "sm" | "lg"
    onItemClick?: (value: T) => void
    subtitle?: string
}
export const ModelList = memo(<T extends Model & SearchableModel & FormableModel>({ subtitle, marginbottom, parentidToCreateItem: parentid, onChange, virtuosoScrollParent, ids, modelResources, onItemClick }: IProps<T>) => {

    const { t } = useTranslation()
    const { token } = useContext(AppContext)
    const [loading, setLoading] = useState(false)
    const [loadedModels, setLoadedModels] = useState(new Array<T>())
    const [filteredIds, setFilteredIds] = useState(ids)
    const [filter, setFilter] = useState("")
    const debouncedFilter = useDebounce(filter, environment.debounceDelay)
    const domaineTypes = useMemo(() => MetadataHelper.getDomainesTypesFromModel(modelResources.Model), [modelResources.Model])
    const [domaineFilters, setDomaineFilters] = useState<TypedDomainesState[]>(domaineTypes.map(d => ({ type: d, filters: [] })))
    const [checked, setChecked] = useState<Array<{ id: number, checked: boolean }>>(ids?.map(id => ({ id, checked: false })) || [])
    const selection = useMemo(() => checked.filter(c => c.checked).map(c => c.id), [checked])
    const numberChecked = useMemo(() => checked.filter(x => x.checked).length, [checked])
    const { push } = useTabStack()
    const { isOpen: isCreateOpen, onClose, onOpen } = useRoutingDisclosure("/create" + modelResources.tabIdentifier)
    const [initialized, setInitialized] = useState(false)
    const uncheckAll = useCallback(() => setChecked(prev => prev.map(x => ({ ...x, checked: false }))), [setChecked])
    const filterIds = useFilterData(modelResources.service as unknown as ISearchService<any, any>)

    //reset selection on ids update
    useEffect(() => setChecked(ids?.map(id => ({ id, checked: false })) || []), [ids])
    //on filter change
    useAsyncEffect(async () => {
        if (!ids) return
        setLoading(true)
        try {
            const result = await filterIds(ids, debouncedFilter, domaineFilters)
            setFilteredIds(result)
            if (!result || result.length === 0) {
                setLoadedModels([])
                return
            }
            const loaded = await modelResources.service.some(result.slice(0, Math.min(result.length, environment.loadChunk)))
            setLoadedModels(loaded)
            setInitialized(true)
        }
        catch (e) {
            console.error(e)
        }
        finally {
            setLoading(false)
        }
    }, [ids, debouncedFilter, domaineFilters, setLoadedModels, setLoading, modelResources.service, setInitialized])

    const checkAll = useCallback(() => {
        setChecked(prev => prev.map((p) => ({ id: p.id, checked: filteredIds!.includes(p.id) })))
    }, [setChecked, filteredIds])

    const onListItemCheckedChange = useCallback((id: number) => setChecked(prev => prev.map(x => x.id === id ? { ...x, checked: !x.checked } : x)), [setChecked])

    const onClick = useCallback(async (value: T) => {
        if (onItemClick) return onItemClick(value)
        const onChildChange = (x: T) => onChange(prev => [...prev])
        push(new ModelViewInfo(modelResources, value.id, false, onChildChange))
    }, [push, modelResources, onChange, onItemClick])

    const onCancelCreate = useCallback(() => onClose(), [onClose])
    const onCreateClick = useCallback(() => onOpen(), [onOpen])

    const onItemCreated = useCallback((value: T) => {
        onChange(prev => [...prev, value.id])
        onClose()
    }, [onChange, onClose])

    const loadMore = useCallback(async () => {
        if (!initialized) return
        if (!filteredIds) return
        const startIndex = loadedModels.length
        if (startIndex >= filteredIds.length) return
        setLoading(true)
        const loaded = await modelResources.service.some(filteredIds.slice(startIndex, Math.min(filteredIds.length, startIndex + environment.loadChunk)))
        setLoadedModels([...loadedModels, ...loaded])
        setLoading(false)
    }, [setLoadedModels, modelResources.service, setLoading, loadedModels, filteredIds, initialized])

    const renderedBulkActions = useMemo(() => modelResources.bulkActions.map((Action, i) => (
        <Action key={`${i}+${selection.length}`} service={modelResources.service} data={selection} model={modelResources.Model} onChange={onChange}></Action>
    )), [selection, onChange, modelResources])

    const virtuosoStyle = useMemo(() => ({ width: "100%", marginBottom: marginbottom === "sm" ? "60px" : "300px" }), [marginbottom])
    const prerenderedItems = useMemo(() => {
        const sorted = (loadedModels.length > 0 && loadedModels[0].sortBy !== undefined) ? loadedModels.sort((a, b) => b.sortBy! - a.sortBy!) : loadedModels
        return sorted?.map((x) => (
            <Box mb={4} key={x.id}>
                <ListItem
                    model={modelResources.Model}
                    service={modelResources.service}
                    onChange={onChange}
                    checked={!!checked.find(c => c.id === x.id)?.checked}
                    onCheckedChange={() => onListItemCheckedChange(x.id)}
                    onClick={onClick}
                    data={x}
                    actions={modelResources.actions}
                    bulkActions={modelResources.bulkActions} />
            </Box>
        ))
    }, [loadedModels, checked, onListItemCheckedChange, onClick, modelResources, onChange])

    const getPrerenderedListItem = useCallback((i: number) => (prerenderedItems[i]), [prerenderedItems])

    const FormCreateComponent = modelResources.form || CustomForm

    const createTemplate = useMemo(() => {
        const m = new modelResources.Model();
        if (parentid)
            (m as unknown as FormableModel).parentid = parentid
        return m
    }, [modelResources.Model, parentid])



    return (
        <>
            <Collapse data-testid="model-list-collapse" className="model-list-collapse" in={!isCreateOpen} animateOpacity>
                {subtitle &&
                    <Box mb={2}>
                        <ModalSubheader item={{
                            icon: modelResources.icon,
                            title: subtitle
                        }} />
                    </Box>
                }
                <ListControls
                    actions={renderedBulkActions}
                    numberOfSelected={numberChecked}
                    numberOfFiltered={filteredIds?.length || 0}
                    numberTotal={ids?.length || 0}
                    onSelectAll={checkAll}
                    onDeselectAll={uncheckAll}
                    onCreateClick={(parentid && token?.role && modelResources.roles.rest.create.includes(token.role)) ? onCreateClick : undefined}
                />
                <HStack my={4}>
                    <ListFilters
                        loading={loading}
                        domaineTypes={domaineTypes}
                        stringFilter={filter}
                        onStringFilterChange={setFilter}
                        domainefilters={domaineFilters}
                        setDomaineFilters={setDomaineFilters}
                    />
                </HStack>
                {!ids ?
                    <ModelListLoading style={virtuosoScrollParent} scrollParent={virtuosoScrollParent} />
                    : ids?.length === 0 ?
                        <Box mt={12}><EmptyList /></Box>
                        : <Virtuoso
                            context={{ loadMore, loading }}
                            style={virtuosoStyle}
                            customScrollParent={virtuosoScrollParent}
                            data={prerenderedItems}
                            itemContent={getPrerenderedListItem}
                            components={{ ScrollSeekPlaceholder: ListItemPlaceholder, Footer: LoadMore }}
                            scrollSeekConfiguration={{
                                enter: (velocity) => Math.abs(velocity) > 500,
                                exit: (velocity) => Math.abs(velocity) < 20
                            }}
                        />
                }
            </Collapse>
            {parentid &&
                <Collapse data-testid="model-create-collapse" in={isCreateOpen} animateOpacity>
                    <Box mb={4}>
                        <ModalSubheader item={{
                            icon: modelResources.icon,
                            title: `${t("common.create")} ${t("models." + modelResources.tabIdentifier).toLowerCase()}`
                        }} />
                    </Box>
                    <FormCreateComponent
                        model={createTemplate}
                        FormSubmitType={modelResources.CreateParams}
                        service={modelResources.service}
                        tabIdentifier={modelResources.tabIdentifier}
                        onClose={onCancelCreate}
                        onChange={onItemCreated}
                        createMode
                    />
                </Collapse>
            }
        </>
    )
}) as <T extends Model & SearchableModel>(props: IProps<T>) => JSX.Element