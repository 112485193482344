import { addTranslations } from "./_modal-sheet.i18n"
import { Box, Button, HStack, Heading, Text, VStack } from "@chakra-ui/react";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import { ModalSheetContext, PromptOptions } from "./modal-sheet.context";
import { useTranslation } from "react-i18next";
import 'react-spring-bottom-sheet/dist/style.css'
import "./modal-sheet.component.css"

const fullHeightPercent = 0.95
//chrome seems to have a bug where innerheight is incorrect and inconsistent 
const innerHeight = window.innerHeight

interface IProps {
    isOpen: boolean
    onClose: () => void
    full?: boolean
    header?: ReactNode,
    headerHeight?: number
    children: ReactNode
}
export function ModalSheet({ isOpen, onClose, children, full, header: headerProp, headerHeight = 75 }: IProps) {
    addTranslations()
    const { t } = useTranslation()
    const ref = useRef<BottomSheetRef>(null);
    const [prompt, setPrompt] = useState<{ title: string, options?: PromptOptions }>()
    const [promise, setPromise] = useState<{ resolve: (value: boolean) => void } | undefined>()
    const [header, setHeader] = useState<ReactNode | undefined>()

    const onMinimize = useCallback(() => {
        ref.current?.snapTo(() => headerHeight)
    }, [ref])

    const onMaximize = useCallback(() => {
        ref.current?.snapTo(({ maxHeight }) => maxHeight)
    }, [ref])

    const onToggle = useCallback(() => {
        ref.current?.snapTo((({ maxHeight }) => ref.current?.height === headerHeight ? maxHeight : headerHeight))
    }, [ref])

    const onPrompt = useCallback((title: string, options?: PromptOptions) => {
        setPrompt({ title, options })
        const _promise = new Promise<boolean>((resolve) => setPromise({ resolve }))
        onMinimize()
        return _promise
    }, [setPrompt, onMinimize, setPromise])

    const onClosePrompt = useCallback(() => {
        setPrompt(undefined)
        onMaximize()
    }, [setPrompt, onMaximize])

    const onPromptResolve = useCallback((value: boolean) => {
        onClosePrompt()
        if (!promise) return
        promise.resolve(value)
        setPromise(undefined)
    }, [onClosePrompt, promise, setPromise])

    const onConfirm = useCallback(() => {
        onPromptResolve(true)
    }, [onPromptResolve])

    const onCancel = useCallback(() => {
        onPromptResolve(false)
    }, [onPromptResolve])

    useEffect(() => { full && onMaximize() }, [full, onMaximize])

    return (
        <ModalSheetContext.Provider value={{ onMaximize, onClose, onMinimize, onPrompt, onToggle, onClosePrompt, setHeader }}>
            <BottomSheet
                className="bottom-sheet"
                
                open={isOpen}
                ref={ref}
                defaultSnap={({ maxHeight }) => {
                    return full ? innerHeight * fullHeightPercent : headerHeight
                }}
                snapPoints={({ maxHeight }) => [
                    headerHeight,
                    innerHeight * fullHeightPercent
                ]}
                onDismiss={onClose}
                blocking={false}
                header={prompt ?
                    <HStack justifyContent={"space-between"} px={6}>
                        <VStack alignItems={"start"}>
                            <Heading size={"sm"}>{prompt.title}</Heading>
                            {prompt.options?.subtext && <Text>{prompt.options.subtext}</Text>}
                        </VStack>
                        <HStack gap={2}>
                            <Button data-testid="cancel-prompt" variant={"outline"} onClick={onCancel}>{t("modalsheet.cancel")}</Button>
                            {prompt.options?.confirmButton && <Button data-testid="confirm-prompt" onClick={onConfirm}>{t("modalsheet.confirm")}</Button>}
                        </HStack>
                    </HStack>
                    : (headerProp || header)}
            >
                <Box className="modal-sheet-content" height="full" overflow={(isOpen && ref.current?.height === headerHeight) ? "hidden" : "auto"} minHeight={"60vh"} >
                    {children}
                </Box>
            </BottomSheet>
        </ModalSheetContext.Provider>
    )
}