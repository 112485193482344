import { FieldWrapperProps } from "@class-former/react";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { TabIdentifier } from "../../../hooks/tab-stack/tab-identifier.enum";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";

interface IProps extends FieldWrapperProps {
    tabIdentifier: TabIdentifier
}
function FieldWrapperComponent({ meta, tabIdentifier, input, name }: IProps) {

    const { t } = useTranslation()

    return (
        <FormControl isInvalid={!!meta.error && meta.touched}>
            <FormLabel>{t(`${tabIdentifier}.${name}`)}</FormLabel>
            {input}
            <FormErrorMessage>{t("CustomFormErrors." + meta.error)}</FormErrorMessage>
        </FormControl>
    )
}

export const FieldWrapper = memo(FieldWrapperComponent) as typeof FieldWrapperComponent