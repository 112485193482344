import { DOMAINE_TYPE } from '../enums/domaine.enum';
import { METADATA_TAG } from '../enums/metadata-tag.enum';

import { MetadataStorage } from '../utils/metadata-storage';

/** Add domaines that are either in children properties, or simply dont have the @IsDomaine decorator */
export function AddDomaines(...types: DOMAINE_TYPE[]): ClassDecorator {
    return (target: Object): void => {
        //target.constructor is not used because class decorators target already passes the constructor, compared to property decorators which passes Object
        const _additionalDomaines = MetadataStorage.getByClass(METADATA_TAG.DOMAINES_TAG, target as any) ?? []
        MetadataStorage.setOnClass(METADATA_TAG.DOMAINES_TAG, target as any, [..._additionalDomaines, ...types])
    }
}