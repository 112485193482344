import { memo, useCallback, useContext, useState } from "react";
import { AssignParams, AssignableModel, Model, assignationDefaultRoles } from "soverdi-api-models";
import { useTranslation } from "react-i18next";
import { useStatusToast } from "react-shared";
import { IAssignationService } from "soverdi-api-client";
import { LuStarOff } from "react-icons/lu";
import { BulkActionProps } from "./bulk-action.props";
import { AppContext } from "../../../app.context";
import { Button, Icon } from "@chakra-ui/react";

function UnassignmeBulkActionComponent<T extends Model & AssignableModel>({ service, data, onChange }: BulkActionProps<T, IAssignationService<T>>) {

    const { t } = useTranslation()
    const salert = useStatusToast()
    const { token } = useContext(AppContext)
    const [loading, setLoading] = useState(false)

    const onUnassign = useCallback(async () => {
        setLoading(true)
        const params = new AssignParams()
        const ids = data
        params.ids = ids
        try {
            await service.assignation.unassignMe(params)
            salert.onSuccess(t("DefaultBulkActions.assignations"), "save")
            if (!onChange) return
            onChange(prev => [...prev])
        }
        catch (e) {
            console.error(e)
            salert.onError(t("DefaultBulkActions.assignations"), "save")
        }
        setLoading(false)
    }, [service, data, salert, t, onChange, setLoading])

    return (
        <>
            {assignationDefaultRoles.unassignMe.includes(token!.role) &&
                <Button isLoading={loading} loadingText={t("ListItem.unassign")} colorScheme="yellow" data-testid="unassign-me-button" onClick={onUnassign} rightIcon={<Icon as={LuStarOff} />}>{t("ListItem.unassign")}</Button >
            }
        </>
    )
}

export const UnassignMeBulkAction = memo(UnassignmeBulkActionComponent) as typeof UnassignmeBulkActionComponent
