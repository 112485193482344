import { Box, FormControl, HStack } from "@chakra-ui/react";
import { QueryBuilder } from "./query-builder/query-builder.component";
import { memo, useCallback, useContext } from "react";
import { Projet, environment } from "soverdi-api-models";
import { useTabStack } from "../../hooks/tab-stack/tab-stack.hook";
import { ProjetResources } from "../../model-resources/projet.resources";
import { AppContext } from "../../app.context";
import { ModelViewInfo } from "../../hooks/tab-stack/tab-stack-item.interface";
import { SearchBarAutocomplete } from "./search-bar-autocomplete";

export const SearchBar = memo(() => {

    const { set } = useTabStack()
    const { token } = useContext(AppContext)

    const onSubmit = useCallback((p?: Projet) => {
        if (!p) return
        set(new ModelViewInfo(ProjetResources, p.id as number, true))
    }, [set])

    return (
        <HStack flexGrow={1}>
            {token && environment.endpoints.projet.autocomplete.autocomplete.includes(token.role) &&
                <form autoComplete="off" className="full-width">
                    <FormControl borderRadius={6} boxShadow={"lg"} backgroundColor={"white"}>
                        <Box position={"absolute"} left={0} zIndex={2}>
                            <QueryBuilder />
                        </Box>
                        <SearchBarAutocomplete result={undefined} onChange={onSubmit} />
                    </FormControl>
                </form>
            }
        </HStack>
    )
})