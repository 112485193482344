import { IsNumber, ValidateNested } from "class-validator";
import { ImportResponse } from "./import-response";

export class InventoryImportResult {
    @IsNumber()
    produit!: number
    @IsNumber()
    espece!: number
}

export class InventoryImportResponse extends ImportResponse {
    @ValidateNested()
    added: InventoryImportResult = new InventoryImportResult()
    @ValidateNested()
    skipped: InventoryImportResult = new InventoryImportResult()
}