import { METADATA_TAGS, FormConfigParameters } from '@class-former/decorators';
import { IFieldConfig } from '../types/_field-config.interface';
import { ValidationMetadata } from 'class-validator/types/metadata/ValidationMetadata';
import { getMetadataStorage } from 'class-validator';
import { FieldMetadata } from '../types/field-metadata.type';
import { getClassTransformerStorage } from '../utils/get-class-transformer-storage';
import { useMemo } from 'react';
import { FormConfig } from '../classes/form-config';

function providePrimitiveTypeFunction(meta: ValidationMetadata | undefined) {
  switch (meta?.name) {
    case 'isString':
      return () => String;
    case 'isNumber':
      return () => Number;
    case 'isBoolean':
      return () => Boolean;
    case 'isDate':
      return () => Date;
    default:
      return undefined;
  }
}

export function useFormConfig<FormDataType extends Object>(initialValues: FormDataType, data: FormDataType, formConfig: FormConfig<FormDataType>) {
  return useMemo(() => {
    const formconfigs = new Array<IFieldConfig<FormDataType, any, any, any>>();
    const stringTypeBeforeFunctionTypeComponents = formConfig.components.sort((a) => typeof a.type === "string" ? -1 : 1)
    const prototype = Object.getPrototypeOf(data);
    const target = prototype.constructor;
    const storage = getMetadataStorage();
    const classTransformerMetadataStorage = getClassTransformerStorage();
    const validatorsMetadata = storage.getTargetValidationMetadatas(target, '', true, false);
    for (const propertyName of Object.keys(initialValues)) {
      const config = Reflect.getMetadata(METADATA_TAGS.FORM_CONFIG_TAG, prototype, propertyName) as
        | FormConfigParameters<FormDataType>
        | undefined;
      const validations = validatorsMetadata.filter(v => v.propertyName === propertyName);
      const propertyTypeMetadata = classTransformerMetadataStorage.findTypeMetadata(target, propertyName);
      const typeFunction = propertyTypeMetadata?.typeFunction || validations.map(v => providePrimitiveTypeFunction(v)).find(x => x);
      if (!typeFunction) {
        console.warn(`The property ${propertyName}'s type could not be extracted. Skipping.`);
        continue
      }
      const propertyType = typeFunction();
      const isArray = validations?.find(v => v.each)
      const componentInfo = stringTypeBeforeFunctionTypeComponents.find(x => {
        if (typeof x.type === 'string') return x.type === config?.specialType;

        const _type = x.type();
        if (isArray) {
          if (Array.isArray(_type)) {
            if (_type.length != 1)
              throw new Error('Invalid array type. Type definition of arrays should be defined like so: [Class]');
            return (_type as any)[0] === propertyType && validations?.find(v => v.each);
          }
        }
        else {
          return _type === propertyType;
        }
      });
      if (!componentInfo) {
        console.warn(`The type ${typeFunction().name} could not be associated with an input component in your configuration. Skipping.`);
        continue
      }
      const meta = {
        prototype,
        propertyName,
        propertyType,
        tags: config?.tags,
        validations,
        typeFunction,
        specialType: config?.specialType
      } as FieldMetadata<FormDataType>;
      const component = componentInfo.component(meta);
      const fieldConfig = { name: propertyName, component } as IFieldConfig<FormDataType, any, any, any>;
      if (componentInfo.attachProps) {
        const props = componentInfo.attachProps({ initialValues, meta, data });
        fieldConfig.props = props;
      }
      fieldConfig.meta = meta
      fieldConfig.pipein = componentInfo.pipein
      fieldConfig.pipeout = componentInfo.pipeout
      formconfigs.push(fieldConfig);
    }

    return formconfigs;
  }, [initialValues, data, formConfig])
}
