import { set } from "idb-keyval"
import { IGOazimutApp, IGOazimutUser, Token } from 'portail-api-client';
import axios from "axios"
import { ROLES } from "soverdi-api-models";

export class AuthServiceMock {

    readonly stub = { iduser: 2703, gde: "dsa", roles: [{ app: { description: "Soverdi", version: "soverdi_beta" } as IGOazimutApp, name: ROLES.admin }] } as any as Token

    constructor() {
        axios.interceptors.request.use(async (config) => {
            config.headers.set("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImZjdHJlbWJsYXkiLCJpZHVzZXIiOjI3MDMsInJvbGVzIjpbeyJhcHAiOnsiZGVzY3JpcHRpb24iOiJTb3ZlcmRpIn0sIm5hbWUiOiJST0xFX1NPVkVSRElfQURNSU5JU1RSQVRFVVIifV0sImlhdCI6MTcxNzE3MDgxOH0.95xTTFj5tzz5iRykUpvivtoNLt0C-wAbQOBw7_yZLMw")
            return config
        })
    }
    getGDE() {
        return "dsadsa"
    }

    logout() {
    }

    private validateToken(): boolean {
        return true
    }

    private validateRefreshToken(): boolean {
        return true
    }

    public async beforeRequest() {
        const token = await this.authentificateWithRefreshToken();
        return token
    }

    public async authentificateWithRefreshToken(_token?: Token): Promise<Token> {

        set("auth_token", this.stub)
        return this.stub
    }

    async authentificateWithUser(_user: IGOazimutUser): Promise<Token> {
        return this.stub
    }

}
