import { IsNumber } from "class-validator";
import { User } from "./user.model";

export class UserWithRelations extends User {

    @IsNumber(undefined, { each: true })
    projets: Array<number> = []

    @IsNumber(undefined, { each: true })
    sites: Array<number> = []

    @IsNumber(undefined, { each: true })
    evenements: Array<number> = []

    @IsNumber(undefined, { each: true })
    interventions: Array<number> = []
}