import { HStack, Heading } from "@chakra-ui/layout"
import { Skeleton } from "@chakra-ui/react";
import { ModalTabInfo } from "../../types/modal-tab-info"
import { FC, memo } from "react"

interface IProps {
    item: ModalTabInfo
    loading?: boolean
}

export const ModalSubheader: FC<IProps> = memo(({ item, loading }: IProps) => {

    return (
        <HStack>
            {loading ?
                <Skeleton width={"160px"} height="22px" noOfLines={1} /> :
                <Heading mt={"2px"} size={"small"}>{item.title + (item.subtitle ? ` ${item.subtitle}` : "")}</Heading>
            }
        </HStack>
    )
})