interface SimplifiedDBError {
    property: string
    value: string
}
export class DBError {

    errors: SimplifiedDBError[]

    constructor(data: any) {
        const _errors = [...data.message?.detail?.matchAll(/\([^()]*\)/g)].map(x => x[0].replace("(", "").replace(")", ""))
        const simplified = new Array<SimplifiedDBError>()
        for (let i = 0; i + 1 < _errors.length; i = i + 2) {
            simplified.push({ property: _errors[i], value: _errors[i + 1] })
        }
        this.errors = simplified
    }


}