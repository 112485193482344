import { Service, Inject } from "typedi";
import { Intervention, Photo } from "soverdi-api-models";
import { environment } from "soverdi-api-models";
import { SoverdiAPIUrl } from "../utils/soverdi-api-url";
import axios from "axios"

@Service()
export class PhotoService {
    modelClazz = Intervention
    @Inject()
    protected url!: SoverdiAPIUrl
    endpoint = environment.endpoints.files.post

    src(filename: string) {
        return this.url.join([environment.endpoints.files.get, filename])
    }

    async upload(files: File[]): Promise<Photo[]> {
        const formdata = new FormData()
        for (let i = 0; i < files.length; i++) {
            const item = files[i]
            formdata.append("files", item!)
        }
        const res = await axios.post<Photo[]>(this.url.join([this.endpoint]), formdata, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        })
        return res.data
    }

    async delete(id: string): Promise<void> {
        await axios.delete(this.url.join([this.endpoint, id]))
    }
}