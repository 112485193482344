import { ReactNode, useCallback, useRef, useState } from "react";
import { DeleteContext } from "./_delete.context"
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "react-i18next";
import { addTranslations } from "./_delete.i18n"

interface PromiseContent {
    resolve: (value: boolean | PromiseLike<boolean>) => void
    reject: (reason?: any) => void
}
interface IProps {
    children: ReactNode
}
export function DeleteProvider({ children }: IProps) {

    addTranslations()

    const { t } = useTranslation()
    const { isOpen, onOpen, onClose: _onClose } = useDisclosure()
    const [identifier, setIdentifier] = useState("")
    const [request, setRequest] = useState<PromiseContent | undefined>()
    const cancelRef = useRef(null)

    const onDelete = useCallback(async (identifier: string) => {
        setIdentifier(identifier)
        onOpen()
        const promise = new Promise<boolean>((resolve, reject) => setRequest({ resolve, reject }))
        return promise
    }, [setIdentifier, setRequest])

    const onConfirm = useCallback(() => {
        if (request) request.resolve(true)
        setRequest(undefined)
        _onClose()
    }, [request, _onClose])

    const onCancel = useCallback(() => {
        if (request) request.resolve(false)
        setRequest(undefined)
        _onClose()
    }, [request, _onClose])

    return (
        <DeleteContext.Provider value={{ onDelete }}>
            {children}
            <AlertDialog
                isOpen={isOpen}
                onClose={onCancel}
                leastDestructiveRef={cancelRef}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("DeleteProvider.title") + identifier}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("DeleteProvider.body")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button data-testid="cancel delete button" ref={cancelRef} onClick={onCancel}>
                                {t("DeleteProvider.cancel")}
                            </Button>
                            <Button data-testid="confirm delete button" colorScheme='red' onClick={onConfirm} ml={3}>
                                {t("DeleteProvider.delete")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </DeleteContext.Provider>
    )
}