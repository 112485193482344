import { useTranslation } from "react-i18next";
import { QueryForm } from "./query-form.component";
import { memo, useCallback, useMemo, useState } from "react";
import { SearchCommandeParams } from "soverdi-api-models";
import { BaseForm } from "../base-form";
import { getService } from "api-client-shared";
import { CommandeService, TypeEspeceService } from "soverdi-api-client";
import { useTabStack } from "../../../../hooks/tab-stack/tab-stack.hook";
import { ModelListViewInfo } from "../../../../hooks/tab-stack/tab-stack-item.interface";
import { TextFilter } from "../form-controls/text-filter.component";
import { DomaineFilter } from "../form-controls/domaine-filter.component";
import { Container } from "typedi"
import { CommandeResources } from "../../../../model-resources/commande.resources";
import { SearchParameter } from "./search-parameters.interface";

interface IProps {
    onClose: () => void
}
export const CommandeQueryForm = memo(({ onClose }: IProps) => {
    const { t } = useTranslation()
    const [params, setParams] = useState(new SearchCommandeParams())
    const service = getService(CommandeService)
    const parameters = useMemo(() => [
        {
            key: "genre",
            i18n: t("espece.genre"),
            component: (key, i18n, setValue, onRemove) => <TextFilter data-testid="genre-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, espece: { ...prev.espece, genre: x } }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && value.espece?.genre === "") return t("espece.genre") + t("QueryForm.empty") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, espece: { ...prev.espece, genre: undefined } }))
        },
        {
            key: "nomespece",
            i18n: t("espece.nomespece"),
            component: (key, i18n, setValue, onRemove) => <TextFilter data-testid="nomespece-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, espece: { ...prev.espece, nomespece: x } }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && value.espece?.nomespece === "") return t("espece.nomespece") + t("QueryForm.empty") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, espece: { ...prev.espece, nomespece: undefined } }))
        },
        {
            key: "cultivar",
            i18n: t("espece.cultivar"),
            component: (key, i18n, setValue, onRemove) => <TextFilter data-testid="cultivar-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, espece: { ...prev.espece, cultivar: x } }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && value.espece?.cultivar === "") return t("espece.cultivar") + t("QueryForm.empty") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, espece: { ...prev.espece, cultivar: undefined } }))
        },
        {
            key: "code",
            i18n: t("espece.code"),
            component: (key, i18n, setValue, onRemove) => <TextFilter data-testid="code-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, espece: { ...prev.espece, code: x } }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && value.espece?.code === "") return t("espece.code") + t("QueryForm.empty") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, espece: { ...prev.espece, code: undefined } }))
        },
        {
            key: "typeespece",
            i18n: t("filters.type"),
            component: (key, i18n, setValue, onRemove) => <DomaineFilter data-testid="typeespece-input" key={key} text={i18n} domaineServiceType={Container.get(TypeEspeceService)} onChange={(x) => setValue(prev => ({ ...prev, espece: { ...prev.espece, typeespece: { id: x.id } } }))} onRemove={onRemove} />,
            invalid: () => undefined,
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, espece: { ...prev.espece, typeespece: undefined } }))
        },
        ...BaseForm(t)] as Array<SearchParameter<SearchCommandeParams>>, [t])
    const { set } = useTabStack()

    const search = useCallback(async () => {
        const data = await service.search.searchForIds(params)
        set(new ModelListViewInfo(CommandeResources, data, true))
    }, [params, set, service])

    return (
        <QueryForm onSearch={search} parameters={parameters} value={params} onChange={setParams} onClose={onClose} />
    )
})