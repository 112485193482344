import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Vegetal, VegetalParams } from "soverdi-api-models";
import { useMovePoint } from "../../../../hooks/move-point";
import { getService } from "api-client-shared";
import { VegetalService } from "soverdi-api-client";
import { useStatusToast } from "react-shared";
import { useErrorHandler } from "../../../model-forms/hooks/error-handler";

export function usePlanting() {
    const { t } = useTranslation()
    const { move } = useMovePoint(Vegetal.layer)
    const vegetalService = getService(VegetalService)
    const status = useStatusToast()
    const handleError = useErrorHandler()

    const createVegetal = useCallback(async (idcommande: number) => {
        const vegetal = new VegetalParams()
        const position = await new Promise<GeolocationPosition>((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject))
        vegetal.geometry = {
            type: "Point", coordinates: [
                position.coords.longitude,
                position.coords.latitude
            ]
        }
        vegetal.idcommande = idcommande
        const result = await vegetalService.create(vegetal)
        return result
    }, [vegetalService])

    const startPlanting = useCallback(async (idcommande: number): Promise<Vegetal | undefined> => {
        try {
            const result = await createVegetal(idcommande)
            try {
                const confirm = await move(result.id, async (updated) => {
                    const params = new VegetalParams()
                    params.geometry = updated
                    await vegetalService.update(result.id, params)
                })
                if (!confirm) {
                    await vegetalService.delete(result.id!)
                    return undefined
                }
                status.onSuccess(t("models.vegetal"), "save")
                return result
            }
            catch (e) {
                console.error(e)
                await vegetalService.delete(result.id!)
            }
        }
        catch (e: any) {
            handleError("vegetal", e)
        }
    }, [createVegetal, vegetalService, move, status, t, handleError])

    return { startPlanting }
}