import { Type } from "class-transformer"
import { ValidateNested, IsNumber, IsOptional, IsNotEmpty, IsString } from "class-validator";
import { Model } from "../../models/base/model"
import { Produit } from "../../models/produit.model";
import { Params } from "./_params.type";

export class ProduitParams implements Params<Produit>{
    @IsOptional()
    @IsString()
    sku: string | undefined

    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Model)
    espece!: Model

    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Model)
    calibre!: Model

    @IsNumber()
    @IsOptional()
    dhs?: number

    @IsNumber()
    @IsNotEmpty()
    hauteur!: number

    @ValidateNested()
    @Type(() => Model)
    @IsNotEmpty()
    fournisseur!: Model

    @ValidateNested()
    @Type(() => Model)
    @IsNotEmpty()
    formatpot!: Model
}