import { IsDate, IsNotEmpty, IsNumber, IsOptional, IsString } from "class-validator";
import { Transform } from "class-transformer"
import { VegetalImportCSV } from "./vegetal-import.csv"
import { CSVModel } from "./_csv-model.type";
import { Vegetal } from "../models/vegetal.model";

export class VegetalExportCSV extends VegetalImportCSV implements CSVModel<Vegetal, "idcommande" | "produit"> {
    @IsNumber()
    @IsNotEmpty()
    idvegetal!: number
    @IsNumber()
    @IsOptional()
    remplacementid?: number
    @IsString()
    @IsOptional()
    specimenid?: string
    @IsNumber()
    @IsNotEmpty()
    annee!: number
    @IsString()
    @IsOptional()
    @Transform(({ value }) => value ? value.toLowerCase() : undefined, { toClassOnly: true })
    presence: string | undefined
    @IsString()
    @IsOptional()
    dommages: string | undefined
    @IsString()
    @IsOptional()
    @Transform(({ value }) => value ? value.toLowerCase() : undefined, { toClassOnly: true })
    cuvette?: string
    @IsString()
    @IsOptional()
    tags: string | undefined
    @IsDate()
    @Transform(({ value }) => value ? new Date(value) : undefined, { toClassOnly: true })
    dernieremodification!: Date
    @IsString()
    @IsOptional()
    photos: string | undefined
}