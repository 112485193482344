import {
    AddFeatureLayerOptions,
    FeatureLayer,
    FeatureUpdatedEventPayload,
    FeaturesIdentifiedEventPayload,
    GOnetHandle,
    Layer,
    LayerDefinition,
    MapSelectionEventPayload,
} from "@goazimut/gonet-messenger";
import { Geomodel, Projet, Site, Vegetal } from "soverdi-api-models";
import { MapHandle, MapLayer } from "./map-handle.interface";

class State {

    private state: { [key: string]: Array<number> } = {}
    private statefulLayers = [Vegetal.layer]

    public save(layer: string, ids: Array<number>) {
        const baseLayer = this.statefulLayers.find(s => layer.includes(s))
        if (!baseLayer) return
        this.state[baseLayer] = ids
    }

    public load(layer: string) {
        const baseLayer = this.statefulLayers.find(s => layer.includes(s))
        if (!baseLayer) return undefined
        return this.state[baseLayer]
    }

    public clear() { this.state = {} }
}

export class GOnetMapHandle extends MapHandle {


    public readonly layers: MapLayer[]
    private readonly identifyOrder = [Projet.layer, Site.layer, Vegetal.layer]
    private state = new State()
    private featureLayer?: FeatureLayer

    constructor(private gonetHandle: GOnetHandle, layers: Layer[]) {
        super()
        //Pour handle un click à travers toutes les couches
        gonetHandle.addEventListener("featuresIdentified", (payload) => this.onIdentify(payload));
        gonetHandle.addEventListener<Geomodel>("featureUpdated", (payload) => this.onUpdate(payload));
        //Pour handle une selection à partir des outils de selection gonet
        gonetHandle.addEventListener<Geomodel>("mapSelection", (payload) => this.onSelect(payload))
        this.layers = layers.map(l => ({ id: l.id, name: l.name, defaultVisibility: l.defaultVisibility, visibility: l.defaultVisibility }))
    }

    public async watchPosition() {
        this.gonetHandle.call("watchGeolocationPosition", { enableHighAccuracy: true })
    }

    public async stopWatchPosition() {
        this.gonetHandle.call("stopWatchGeolocationPosition")
    }

    public async select(layer: string, ...models: number[]) {
        if (models.length === 0) return
        await this.gonetHandle.call<Geomodel>("selectFeatures", {
            layerNameOrId: layer,
            where: (`id IN (${models.join(", ")})`)
        })
    }

    private async filterOnFeatureLayer(models: number[], layer: string) {
        this.featureLayer = await this.gonetHandle.call("addFeatureLayer", {
            zoomFilteredFeatures: true,
            layerNameOrId: layer,
            expression: `id IN (${models.join(", ")})`,
            icon: { url: `https://www.goazimut.com/GOnet6/images/PictureMarker/tree.png`, width: 28, height: 72 }
        } as AddFeatureLayerOptions) as FeatureLayer
    }

    public async filter(ids: number[], layer: string, options?: { draggable?: boolean }) {
        const sublayer = this.layers.filter(l => l.visibility).find(l => l.name.includes(layer))!.name
        if (options?.draggable) return this.filterOnFeatureLayer(ids, sublayer)
        await this.gonetHandle.call("filterFeatures", {
            zoomFilteredFeatures: true,
            layerDefinitions: [
                ...this.layers.filter(l => !!this.identifyOrder.find(x => l.name.includes(x)) && l.name !== sublayer).map(l => ({ layerNameOrId: l.name, expression: "1=2" } as LayerDefinition)),
                { layerNameOrId: sublayer, expression: `id IN (${ids.join(", ")})` }
            ]
        })
        this.state.save(layer, ids)
    }

    public async setVisibleLayers(_layers: MapLayer[]): Promise<void> {
        await this.gonetHandle.call("setVisibleLayers", { ids: _layers.map(x => x.id) })
        const ids = _layers.map(l => l.id)
        this.layers.forEach(l => { l.visibility = ids.includes(l.id) })
        _layers.forEach(l => {
            const state = this.state.load(l.name)
            if (!state) return
            this.filter(state, l.name)
        })
    }

    public async clear() {
        if (this.featureLayer)
            await this.gonetHandle.call("removeFeatureLayer", { id: this.featureLayer.id })
        await this.gonetHandle.call("clearFilters")
        await this.gonetHandle.call<Geomodel>("selectFeatures", { layerNameOrId: Projet.layer, where: (`1=2`) })
        await this.gonetHandle.call<Geomodel>("selectFeatures", { layerNameOrId: Site.layer, where: (`1=2`) })
        await this.gonetHandle.call<Geomodel>("selectFeatures", { layerNameOrId: Vegetal.layer, where: (`1=2`) })
        await this.gonetHandle.call("refreshMap")
        this.state.clear()
    }


    private getModelFromLayer(layer: string) {
        if (layer.includes(Vegetal.layer))
            return Vegetal
        if (layer.includes(Site.layer))
            return Site
        if (layer.includes(Projet.layer))
            return Projet
        else
            throw new Error(`Layer ${layer} could not be mapped to a model`)
    }

    /** Identify est une fonctionalité de arcgis pour identifier les features en dessous d'un clic, indépendemment des couches */
    private async onIdentify(payload: FeaturesIdentifiedEventPayload<Geomodel>) {
        try {
            if (payload.features.length === 0) return
            for (let layer of this.identifyOrder) {
                const features = payload.features.filter(f => f.layerName?.includes(layer))
                if (features.length > 0) {
                    const models = features.map(f => f.attributes.id)
                    await this.select(payload.features[0].layerName!, ...models)
                    this.onSelect({ features, layer: { name: layer } as Layer } as MapSelectionEventPayload<Geomodel>)
                    return
                }
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    /** Select est une fonctionalité de arcgis qui retourne la selection dans la couche la plus élévée */
    private onSelect(payload: MapSelectionEventPayload<Geomodel>) {
        try {
            if (payload.features.length === 0) return
            const clazz = this.getModelFromLayer(payload.features[0].layerName!)
            const ids = payload.features.map(f => f.attributes.id)
            console.log(this.subscribers.onSelect.length)
            this.subscribers.onSelect.forEach(s => s.next({ clazz, ids }))
        }
        catch (e) {
            console.error(e)
        }
    }

    private onUpdate(payload: FeatureUpdatedEventPayload<Geomodel>) {
        this.subscribers.onUpdate.forEach(s => s.next(payload.feature))
    }

}