import {
    Popover,
    PopoverTrigger,
    IconButton,
    Icon,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    VStack,
    Select,
    FormLabel,
    FormControl,
} from "@chakra-ui/react";
import { t } from "i18next"
import { BiFilter } from "react-icons/bi"
import { useRoutingDisclosure, useSelectState } from "react-shared";
import { ProjetQueryForm } from "./query-forms/projet-query-form.component";
import { SiteQueryForm } from "./query-forms/site-query-form.component";
import { memo } from "react";
import { EvenementQueryForm } from "./query-forms/evenement-query-form.component";
import { InterventionQueryForm } from "./query-forms/intervention-query-form.component";
import { CommandeQueryForm } from "./query-forms/commande-query-form.component";
import { VegetalQueryForm } from "./query-forms/vegetal-query-form.component";
import "./query-builder.scss"

export const QueryBuilder = memo(() => {

    const { isOpen, onOpen, onClose } = useRoutingDisclosure("/query")
    const [queryTypeValue, setQueryTypeValue] = useSelectState("0")


    return (
        <Popover isLazy isOpen={isOpen} onOpen={onOpen} onClose={() => null} id="query-builder">
            <PopoverTrigger>
                <IconButton variant={"ghost"} icon={<Icon fontSize={20} as={BiFilter} />} aria-label='filters'></IconButton >
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverBody p={4}>
                    <VStack alignItems={"stretch"} spacing={"4"}>
                        <FormControl>
                            <FormLabel>{t("filters.queryType")}</FormLabel>
                            <Select data-testid="query-type-button" value={queryTypeValue} onChange={setQueryTypeValue}>
                                <option data-testid="query-type-option" value={0}>{t("models.projet")}</option>
                                <option data-testid="query-type-option" value={1}>{t("models.site")}</option>
                                <option data-testid="query-type-option" value={2}>{t("models.evenement")}</option>
                                <option data-testid="query-type-option" value={3}>{t("models.intervention")}</option>
                                <option data-testid="query-type-option" value={4}>{t("models.commande")}</option>
                                <option data-testid="query-type-option" value={5}>{t("models.vegetal")}</option>
                            </Select>
                        </FormControl>
                        {queryTypeValue === "0" && <ProjetQueryForm onClose={onClose} />}
                        {queryTypeValue === "1" && <SiteQueryForm onClose={onClose} />}
                        {queryTypeValue === "2" && <EvenementQueryForm onClose={onClose} />}
                        {queryTypeValue === "3" && <InterventionQueryForm onClose={onClose} />}
                        {queryTypeValue === "4" && <CommandeQueryForm onClose={onClose} />}
                        {queryTypeValue === "5" && <VegetalQueryForm onClose={onClose} />}
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover >
    )
})