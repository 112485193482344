import { IconButton } from "@chakra-ui/button";
import { Icon } from "@chakra-ui/icon";
import { HStack } from "@chakra-ui/layout";
import { Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody } from "@chakra-ui/popover";
import { RestService } from "api-client-shared";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { BiFilter } from "react-icons/bi";
import { useRoutingDisclosure } from "react-shared";
import { DomaineParams, Domaine } from "soverdi-api-models";
import { DomaineBadge } from "../domaine-badge.component";

interface IProps {
    onSelect: (value: Domaine) => void
    domaineService: RestService<Domaine, DomaineParams, DomaineParams>
    customTrigger?: ReactNode
}
export function DomaineSelector({ domaineService, onSelect, customTrigger }: IProps) {

    const [loading, setLoading] = useState(true)
    const [domaines, setDomaines] = useState<Array<Domaine>>([])
    const { isOpen, onOpen, onClose } = useRoutingDisclosure(domaineService.endpoint + `/domaineselector`)

    const refresh = useCallback(async () => {
        setLoading(true)
        const result = await domaineService.getAll()
        setDomaines(result)
        setLoading(false)
    }, [setLoading, setDomaines, domaineService])

    const addDomaine = useCallback((i: number) => {
        const domaine = domaines[i]
        onSelect(domaine)
        onClose()
    }, [onSelect, domaines, onClose])

    useEffect(() => {
        if (!isOpen) return
        refresh()
    }, [isOpen, refresh])

    return (
        <>
            <HStack>
                <Popover isLazy isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
                    <PopoverTrigger>
                        {customTrigger || <IconButton variant={"ghost"} icon={<Icon as={BiFilter} />} aria-label="filter" />}
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody p={4}>
                            <HStack flexWrap={"wrap"} maxHeight={"30vh"} overflowY={"scroll"}>
                                {(loading ? [undefined, undefined, undefined] : domaines).map((d, i) => <DomaineBadge size="md" key={d?.id || i} domaine={d} onClick={() => addDomaine(i)} />)}</HStack>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </HStack>
        </>
    )
}