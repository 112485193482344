import { Step, StepIndicator, StepStatus, StepIcon, Stepper as ChakraStepper, StepSeparator, StepNumber, Box, StepDescription, StepTitle } from "@chakra-ui/react";
import { useMemo } from "react";

interface IProps {
    /** true for invalid */
    formControlStates: Array<boolean>
    pb?: string
}
export function Stepper({ formControlStates, pb }: IProps) {

    const stepIndex = useMemo(() => {
        if (formControlStates.every(x => !x)) return formControlStates.length
        return formControlStates.findIndex(x => x)
    }, [formControlStates])

    const gap0 = useMemo(() => ({ gap: 0 }), [])
    const StepSeparatorStyle = useMemo(() => ({ marginInlineStart: 0 }), [])
    return (
        <ChakraStepper pb={pb} zIndex={-1} position={"relative"} colorScheme="teal" index={stepIndex} orientation='vertical' height="full" gap="0">
            {formControlStates.map((step, i) => (
                <Step key={i} style={gap0}>
                    <StepIndicator data-testid="step-indicator">
                        <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                        />
                    </StepIndicator>
                    <StepSeparator style={StepSeparatorStyle} />
                </Step>
            ))}
        </ChakraStepper>
    )
}