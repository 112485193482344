import { useToast } from "@chakra-ui/react"
import { useCallback } from "react";
import { useTranslation } from "react-i18next"
import { useStatusToast } from "react-shared"
import { AxiosError } from "axios"
import { GetErrors } from "soverdi-api-models";

export function useErrorHandler() {
    const { t } = useTranslation()
    const toast = useToast()
    const { onError } = useStatusToast()

    return useCallback(async (modelName: string, e: any, options: { duration: number } = { duration: 9000 }) => {
        const normalizedModelName = modelName.toLowerCase()
        const modelNamei18n = t("models." + normalizedModelName)
        function TranslateProperty(property: string) { return t(normalizedModelName + "." + property) }
        const axiosError = e as AxiosError<any>
        console.error(axiosError)
        const errors = GetErrors(axiosError)
        if (errors.db) {
            toast({
                duration: options.duration, colorScheme: "red", title: t("common.errorTitle"), description: `${modelNamei18n} ${t("common.with")} ${errors.db.errors.map(x => `${TranslateProperty(x.property)} ${x.value}`)}${t("common.uniqueconstraint")}`
            })
        }
        else if (errors.api) {
            toast({ duration: options.duration, colorScheme: "red", title: t("common.errorTitle"), description: t("errors.api." + errors.api.message) })
        }
        else if (errors.validation) {
            toast({ duration: options.duration, colorScheme: "red", title: t("common.errorTitle"), description: errors.validation.errors.map(x => `${x.value} ${x.constraints.map(c => t("errors.validation." + c)).join(", ")}`).join(". ") })
        }
        else
            onError(modelNamei18n, "save")
    }, [onError, t, toast]);
}