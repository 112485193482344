import { Fade, HStack, Spinner } from "@chakra-ui/react";

interface IProps { show: boolean }
export function LoadingOverlay({ show }: IProps) {

    return (
        <Fade in={show} unmountOnExit={true}>
            <HStack data-testid="gonet-loading" position={"absolute"} background={"white"} top={0} height={"full"} width={"full"} flexGrow={"1"} justifyContent={"center"} alignItems={"center"}>
                (<Spinner size={"xl"} />)
            </HStack>
        </Fade >
    )
}