import { ParentHandshake, WindowMessenger } from "post-me"
import { GOnetHandle } from "./common"

export async function useGOnetMessengerInParent<T>(iframe: HTMLIFrameElement) {
    if (!iframe?.contentWindow) return undefined
    try {
        const messenger = new WindowMessenger({
            localWindow: window,
            remoteWindow: iframe.contentWindow!,
            remoteOrigin: "*"
        })
        const connection = await ParentHandshake(messenger, undefined, 50, 500)
        const handle = connection.remoteHandle() as unknown as GOnetHandle
        return handle
    }
    catch (e) {
        console.error("[parent] Hanshake failed")
        console.error(e)
        return undefined
    }
}