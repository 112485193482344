import { IsNotEmpty, IsNumber, ValidateNested } from "class-validator";
import { ModelWithMeta } from "./base/model-with-meta";
import { Produit } from "./produit.model";
import { SearchableModel } from "./composition/searchable.model";
import { Type } from "class-transformer";
import { FormConfig } from "@class-former/decorators"
import { FormableModel } from "./composition/formable.model";
import { DOMAINE_TYPE } from "../enums/domaine.enum";
import { AddDomaines } from "../decorators/_add-domaine.decorator";
import { IsParent } from "../decorators/_parent.decorator";
import { PARENT_TYPE } from "../enums/parent-type.enum";
import { IsDomaine } from "../decorators/_domaine.decorator";
import { Domaine } from "./domaine.model";
import { TypedDomaine } from "./typed-domaine.model";

@AddDomaines(DOMAINE_TYPE.FOURNISSEUR, DOMAINE_TYPE.FORMAT_POT, DOMAINE_TYPE.TYPE_ESPECE, DOMAINE_TYPE.CALIBRE)
export class Commande extends ModelWithMeta implements SearchableModel, FormableModel {

    @IsNumber()
    @IsNotEmpty()
    quantite!: number

    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Produit)
    produit!: Produit

    @FormConfig({ skip: true })
    @IsNumber(undefined, { each: true })
    vegetaux!: Array<number>

    @IsParent(PARENT_TYPE.EVENEMENT)
    @FormConfig({ skip: true, preserve: true })
    @IsNumber()
    idevenement!: number

    @IsDomaine(DOMAINE_TYPE.TAG)
    @ValidateNested({ each: true })
    @Type(() => Domaine)
    tags: Domaine[] = []

    public get searchBy(): string { return `${this.vegetaux.length}/${Math.max(this.vegetaux.length, this.quantite)} ${this.produit.searchBy}` }
    public get subtext() { return this.produit.subtext }
    public get domaines() {
        return [
            ...this.produit.domaines,
            ...this.tags.map(t => new TypedDomaine(t, DOMAINE_TYPE.TAG))
        ]
    }
    get parentid() { return this.idevenement }
    set parentid(id: number) { this.idevenement = id }
}