import { FieldMetadata } from "@class-former/react";
import { GetDomaineService, GetModelService } from "soverdi-api-client";
import { DOMAINE_TYPE, METADATA_TAG, MetadataStorage, Model, PARENT_TYPE, MetadataHelper } from "soverdi-api-models";

export function AttachDomaineType(meta: FieldMetadata<any>) {
    const domaineType = MetadataStorage.getByClassProperty(METADATA_TAG.DOMAINE_TAG, meta.prototype.constructor, meta.propertyName) as DOMAINE_TYPE | undefined
    if (!domaineType) throw new Error("Could not attach domaineType")
    return { domaineType }
}

export function AttachService(meta: FieldMetadata<any>) {
    const isdomaine = MetadataStorage.getByClassProperty(METADATA_TAG.DOMAINE_TAG, meta.prototype.constructor, meta.propertyName) as DOMAINE_TYPE | undefined
    const service = isdomaine ? GetDomaineService(isdomaine) : GetModelService(meta.propertyType)
    if (!service) throw new Error(`Could not find service for ${meta.propertyName} of ${meta.prototype.name}`)
    return { service }
}

export function AttachDataTestId(meta: FieldMetadata<any>) {
    return { "data-testid": meta.propertyName + "-input" }
}

export function AttachDomaineTypesFromMe(meta: FieldMetadata<any>) {
    const domaineTypes = MetadataHelper.getDomainesTypesFromModel(meta.prototype.constructor)
    return { domaineTypes }
}

export function AttachDomaineTypesFromModel(model: new () => Model) {
    const domaineTypes = MetadataHelper.getDomainesTypesFromModel(model)
    return { domaineTypes }
}

export function AttachDomaineTypesFromParent(parentType: PARENT_TYPE) {
    const domaineTypes = MetadataHelper.getDomainesTypesFromParentType(parentType)
    return { domaineTypes }
}