import { memo, useCallback, useContext, useState } from "react";
import { LuTreeDeciduous } from "react-icons/lu"
import { useTranslation } from "react-i18next";
import { IVegetauxService } from "soverdi-api-client";
import { ModalSheetContext } from "react-shared";
import { BulkActionProps } from "./bulk-action.props";
import { MapContext } from "../../../contexts/map.context";
import { Button, Icon, useToast } from "@chakra-ui/react";
import { Vegetal } from "soverdi-api-models";

function ShowVegetauxBulkActionComponent({ service, data }: BulkActionProps<any, IVegetauxService>) {

    const { t } = useTranslation()
    const { mapHandle } = useContext(MapContext)
    const { onMinimize } = useContext(ModalSheetContext)
    const [loading, setLoading] = useState(false)
    const toast = useToast()

    const onShowTrees = useCallback(async () => {
        setLoading(true)
        try {
            const vegetauxIds = await service.vegetaux.vegetauxForEntityIds(data)
            if (vegetauxIds.length === 0) {
                toast({ colorScheme: "red", title: t("ShowVegetauxAction.novegetaux"), duration: 90000, status: "error", })
                return
            }
            await mapHandle?.filter(vegetauxIds, Vegetal.layer)
            onMinimize()
        }
        catch (e) {
            console.error(e)
        }
        finally {
            setLoading(false)
        }
    }, [service, mapHandle, data, onMinimize, setLoading, t, toast])

    return (
        <Button isLoading={loading} loadingText={t("DefaultBulkActions.showTrees")} data-testid="show-vegetaux-button" onClick={onShowTrees} rightIcon={<Icon as={LuTreeDeciduous} />}>{t("DefaultBulkActions.showTrees")}</Button>
    )
}

export const ShowVegetauxBulkAction = memo(ShowVegetauxBulkActionComponent) as typeof ShowVegetauxBulkActionComponent
