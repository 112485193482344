import { DOMAINE_TYPE } from "soverdi-api-models";
import { EntiteAdministrativeService } from "../services/domaines/entite-administrative.service";
import { CalibreService } from "../services/domaines/calibre.service";
import { EtatSanteService } from "../services/domaines/etat-sante.service";
import { FormatpotService } from "../services/domaines/formatpot.service";
import { FournisseurService } from "../services/domaines/fournisseur.service";
import { RaisonMortaliteService } from "../services/domaines/raison-mortalite.service";
import { ReseauService } from "../services/domaines/reseau.service";
import { TypeEspeceService } from "../services/domaines/type-espece.service";
import { TypeEvenementService } from "../services/domaines/type-evenement.service";
import { TypeInterventionService } from "../services/domaines/type-intervention.service";
import { RestService } from "api-client-shared";
import { InterventionProgressService } from "../services/domaines/intervention-progress.service";
import { Container } from "typedi"
import { PresenceService } from "../services/domaines/presence.service";
import { DommageService } from "../services/domaines/dommage.service";
import { CuvetteService } from "../services/domaines/cuvette.service";
import { TerritoireService } from "../services/domaines/territoire.service";
import { TagService } from "../services/domaines/tag.service";

export function GetDomaineService(type: DOMAINE_TYPE): RestService<any, any, any> {
    const service = (() => {
        switch (type) {
            case DOMAINE_TYPE.ENTITE_ADMINISTRATIVE: return EntiteAdministrativeService
            case DOMAINE_TYPE.CALIBRE: return CalibreService
            case DOMAINE_TYPE.ETAT_SANTE: return EtatSanteService
            case DOMAINE_TYPE.FORMAT_POT: return FormatpotService
            case DOMAINE_TYPE.FOURNISSEUR: return FournisseurService
            case DOMAINE_TYPE.RAISON_MORTALITE: return RaisonMortaliteService
            case DOMAINE_TYPE.RESEAU: return ReseauService
            case DOMAINE_TYPE.TYPE_ESPECE: return TypeEspeceService
            case DOMAINE_TYPE.TYPE_EVENEMENT: return TypeEvenementService
            case DOMAINE_TYPE.TYPE_INTERVENTION: return TypeInterventionService
            case DOMAINE_TYPE.INTERVENTION_PROGRESS: return InterventionProgressService
            case DOMAINE_TYPE.PRESENCE: return PresenceService
            case DOMAINE_TYPE.DOMMAGE: return DommageService
            case DOMAINE_TYPE.CUVETTE: return CuvetteService
            case DOMAINE_TYPE.TERRITOIRE: return TerritoireService
            case DOMAINE_TYPE.TAG: return TagService
            default: throw new Error(`Could not associate ${type} to a service`)
        }
    })()
    return Container.get(service as any)
}