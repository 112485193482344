import { Alert, AlertIcon, Spacer, Button, Icon, Text, Box } from '@chakra-ui/react';
import { t } from 'i18next';
import { RxUpdate } from 'react-icons/rx';
import { useCallback, useState } from 'react';
import axios from 'axios';
import { useInit } from '../hooks/init.hook';
import { addTranslations } from "./_update-app.i18n"
interface IProps {
    version: string
}
export function UpdateApp({ version }: IProps) {
    addTranslations()
    const [needsUpdate, setNeedsUpdate] = useState(false)
    const onUpdate = useCallback(async () => {
        const registrations = await navigator.serviceWorker.getRegistrations()
        for (let registration of registrations) {
            await registration.unregister();
        }
        window.location.reload()
    }, [])

    useInit(async () => {
        try {
            const res = await axios.get("version.json", { params: { t: Date.now() } })
            if (res.data.version !== version)
                setNeedsUpdate(true)
        }
        catch (e) {
            console.log("Probably offline, could not fetch version.json")
        }
    })

    return (
        <>
            {needsUpdate &&
                <Box p={4} pb={0}>
                    <Alert status='warning' zIndex={99999}>
                        <AlertIcon display={["none", "inherit"]} />
                        <Text fontSize='lg'>{t("updateapp.updateHeader")}</Text>
                        <Spacer minWidth={4}></Spacer>
                        <Box alignSelf={"end"}>
                            <Button onClick={onUpdate} rightIcon={<Icon as={RxUpdate} />} colorScheme='teal'>
                                {t("updateapp.update")}
                            </Button>
                        </Box>
                    </Alert>
                </Box>
            }
        </>
    )
}