import { Service, Inject } from "typedi";
import { RestService } from "api-client-shared";
import { Domaine, InterventionProgressComplete, InterventionProgressEnCours } from "soverdi-api-models";
import { environment, DomaineParams } from "soverdi-api-models";
import { SoverdiAPIUrl } from "../../utils/soverdi-api-url";

@Service()
export class InterventionProgressService extends RestService<Domaine, DomaineParams, DomaineParams>{
    modelClazz = Domaine
    @Inject()
    protected url!: SoverdiAPIUrl
    endpoint = environment.endpoints.domaines.typeintervention.base
    private readonly domaines = [InterventionProgressEnCours, InterventionProgressComplete]

    async get(id: number): Promise<Domaine> {
        const result = this.domaines.find(d => id === d.id)
        if (!result)
            throw new Error("Domaine does not exists")
        return result
    }

    async getAll(): Promise<Domaine[]> {
        return [...this.domaines]
    }

    update(id: number, body: Domaine): Promise<Domaine> {
        throw "not implemented"
    }

    delete(id: number): Promise<void> {
        throw "not implemented"
    }

    create(body: Domaine): Promise<Domaine> {
        throw "not implemented"
    }

    some(ids: number[]): Promise<Domaine[]> {
        throw "not implemented"
    }
}