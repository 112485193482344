import { ReactNode, createContext } from "react"
export interface PromptOptions {
    subtext?: string, confirmButton?: boolean
}
interface IModalSheetContext {
    onMaximize: () => void
    onMinimize: () => void
    onToggle: () => void
    onClose: () => void
    onPrompt: (message: string, options?: PromptOptions) => Promise<boolean>
    onClosePrompt: () => void
    setHeader: (value: ReactNode) => void
}
export const ModalSheetContext = createContext<IModalSheetContext>({} as any)