import { Service, Inject } from "typedi";
import { RestService } from "api-client-shared";
import { Domaine } from "soverdi-api-models";
import { environment, DomaineParams } from "soverdi-api-models";
import { SoverdiAPIUrl } from "../../utils/soverdi-api-url";
import { SOVERDI_API_HOSTNAME_TOKEN } from "../../tokens/soverdi-api-hostname.token";
import { AutocompleteService, IAutocompleteService } from "../composition/autocomplete.service";

@Service()
export class FournisseurService extends RestService<Domaine, DomaineParams, DomaineParams> implements IAutocompleteService<Domaine> {
    modelClazz = Domaine
    @Inject()
    protected url!: SoverdiAPIUrl
    endpoint = environment.endpoints.domaines.fournisseur.base

    autocomplete = new AutocompleteService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
}