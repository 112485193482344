import { Type } from "class-transformer";
import { IsArray, IsNotEmpty, IsOptional, IsString, ValidateNested } from "class-validator";
import { Model } from "../../models/base/model";
import { Params } from "./_params.type";
import { Projet } from "../../models/projet.model";
import { CartographicModel } from "../../models/composition/cartographic.model";

export class CreateProjetParams implements Params<Projet, "sites" | keyof CartographicModel> {
    @IsString()
    @IsNotEmpty()
    nom!: string

    @IsOptional()
    @ValidateNested({ each: true })
    @Type(() => Model)
    users!: Array<Model>
    
    @IsOptional()
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => Model)
    tags?: Array<Model>
}