import { Model, RestRoles } from "soverdi-api-models";
import { RestService } from "api-client-shared";
import { IconType } from "react-icons";
import { IFormProps } from "../components/model-forms/props/form.props";
import { FC } from "react";
import { BulkActionProps } from "../components/menu-actions/bulk-actions/bulk-action.props";
import { ActionProps } from "../components/menu-actions/actions/action.props";
import { TabIdentifier } from "../hooks/tab-stack/tab-identifier.enum";
import { TFunction } from "i18next";

export interface ChildResources<T extends Model, Y extends Model> {
    resources: ModelResources<Y>
    get: (me?: T) => (number[])
    set: (me: T, values: number[]) => T
    subtitle?: (me: T, t: TFunction<"translation", undefined>) => string
}

export abstract class ModelResources<T extends Model> {
    abstract tabIdentifier: TabIdentifier
    abstract get service(): RestService<T, any, any>
    abstract get parentService(): RestService<any, any, any> | undefined
    abstract form: FC<IFormProps<any, T>> | undefined
    abstract icon: IconType
    abstract bulkActions: Array<FC<BulkActionProps<T, any>>>
    abstract actions: Array<FC<ActionProps<T, any>>>
    abstract lists: Array<ChildResources<T, any>>
    abstract roles: { rest: RestRoles }
    abstract Model: new () => T
    abstract CreateParams: new () => any
    abstract UpdateParams: new () => any

    clone<T extends ModelResources<any>>() {
        const constructor = this.constructor as { new(): T };
        const clone = new constructor()
        Object.assign(clone, this)
        return clone
    }
}