import {
    Commande,
    Espece,
    Evenement,
    Intervention,
    Produit,
    Projet,
    Site,
    User,
    Vegetal,
} from "soverdi-api-models";
import { RestService } from "api-client-shared";
import { ProjetService } from "../services/projet.service";
import { SiteService } from "../services/site.service";
import { EvenementService } from "../services/evenement.service";
import { InterventionService } from "../services/intervention.service";
import { CommandeService } from "../services/commande.service";
import { VegetalService } from "../services/vegetal.service";
import { UserService } from "../services/user.service";
import { IAutocompleteService } from "../services/composition/autocomplete.service";
import { IBulkService } from "../services/composition/bulk.service";
import { ProduitService } from "../services/produit.service";
import { EspeceService } from "../services/espece.service";
import { Container } from "typedi";

export function GetModelService(model: any): RestService<any, any, any> | IBulkService<any, any> | IAutocompleteService<any> {
    const service = (() => {
        switch (model) {
            case Projet: return ProjetService
            case Site: return SiteService
            case Evenement: return EvenementService
            case Intervention: return InterventionService
            case Commande: return CommandeService
            case Produit: return ProduitService
            case Espece: return EspeceService
            case Vegetal: return VegetalService
            case User: return UserService
            default: throw new Error(`Could not associate ${model.prototype.name} to a service`)
        }
    })()
    return Container.get(service as any)
}