import { Stack, HStack, Heading, SkeletonText, Text } from "@chakra-ui/react"
import { Model, SearchableModel } from "soverdi-api-models"
import { ChakraColor } from "../../types/chakra-color"
import { DomaineBadge } from "../domaine-badge.component"
import { memo, useMemo } from "react"
import { useTranslation } from "react-i18next"

interface IProps<T extends Model & SearchableModel> {
    data: T | undefined
    onClick: () => any
}
function ListItemBodyComponent<T extends Model & SearchableModel>({ data, onClick }: IProps<T>) {

    const { t } = useTranslation()

    const subtext = useMemo(() => {
        return data?.subtext.map(subtext => {
            if (subtext.i18nprefix)
                return t(subtext.i18nprefix) + ": " + subtext.value
            return subtext.value
        }).join(", ")
    }, [data?.subtext, t])

    return data ? (
        <Stack data-testid="list-item-body" flexGrow={1} cursor={"pointer"} onClick={onClick} gap={0}>
            <HStack flexWrap={"wrap"}>
                {data.searchBy !== "" && <Heading size='md' data-testid="list-item-heading">{data.searchBy}</Heading>}
                {data.domaines.filter(d => !d.options?.hide).map(d => (<DomaineBadge color={d.options?.color as ChakraColor | undefined} key={d.domaine.nom + d.domaine.id} domaine={d.domaine} />))}
            </HStack>
            <Text data-testid="list-item-subtext">{`${subtext}`}</Text>
        </Stack>
    )
        : (
            <Stack>
                <SkeletonText mb={2} noOfLines={1} width={"180px"} skeletonHeight="6"></SkeletonText>
                <SkeletonText noOfLines={1} width={"100px"} skeletonHeight="4"></SkeletonText>
            </Stack>
        )

}

export const ListItemBody = memo(ListItemBodyComponent) as typeof ListItemBodyComponent