import { Box, Button, Container, FormControl, FormErrorMessage, FormLabel, HStack, Image, Input, Spinner, VStack, useToast } from "@chakra-ui/react";
const azilogo = require("../../assets/goazimut.webp")
import { FormEvent, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { getService } from "api-client-shared";
import { AuthService, IGOazimutApp, IGOazimutUser, Token } from "portail-api-client";
import { AxiosError } from "axios";
import { TokenRepository } from "../../repositories/token.repository";
import { useInputState } from "../../hooks/input-state.hook"
import "./login.page.css"
import { addTranslations } from "./_login.i18n"
import { LanguageSwitcher } from "../../components/language-switcher.component";
import { useNavigate } from "react-router-dom";

interface IProps {
    ready: boolean
    authentified: boolean
    setAuthentified: (token: Token) => void
    setLanguage: (value: string) => void
    authentifiedRedirect: string
    app: IGOazimutApp
    logo?: string
    children?: ReactNode
}
export function LoginPage({ app, ready, authentified, setAuthentified, authentifiedRedirect, setLanguage, logo, children }: IProps) {

    addTranslations()

    const { t } = useTranslation();
    const api = getService(AuthService)
    const tokenRepository = getService(TokenRepository)

    const [dirty, setDirty] = useState(false)

    const [usernameInput, [setUsernameInput]] = useInputState('')
    const isUsernameError = usernameInput === '' && dirty

    const [passwordInput, [setPasswordInput]] = useInputState('')
    const isPasswordError = passwordInput === '' && dirty

    const toast = useToast()
    const navigate = useNavigate();

    useEffect(() => {
        if (authentified)
            navigate(authentifiedRedirect)
    }, [authentified, navigate, authentifiedRedirect])

    const submit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDirty(true)
        try {
            const user: IGOazimutUser = { username: usernameInput, password: passwordInput, app }
            const token = await api.authentificateWithUser(user)
            await tokenRepository.set(token)
            setAuthentified(token)
        }
        catch (e: any) {
            const _e = e as AxiosError
            console.error(e)
            const status = _e.response?.status || _e.code || _e.status
            toast.closeAll()
            return toast({
                title: t(`login.loginErrors.${status}.title`),
                description: t(`login.loginErrors.${status}.subtitle`),
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [usernameInput, passwordInput, setDirty, toast, api, t, tokenRepository])

    const fullWidth = useMemo(() => ({ width: "100%" }), [])

    return (
        <Container>
            <Box p={10}>
                <Image m={"auto"} w={"3xs"} src={logo || azilogo}></Image>
            </Box>
            <VStack spacing={6}>
                {
                    ready ? (
                        <>
                            <div className="login-language">
                                <LanguageSwitcher setLanguage={setLanguage} />
                            </div>
                            <form id="login-form" onSubmit={submit} style={fullWidth}>
                                <FormControl isInvalid={isUsernameError}>
                                    <FormLabel>{t("login.username")}</FormLabel>
                                    <Input id="username-input" data-testid="username-input" type='text' value={usernameInput} onChange={setUsernameInput} />
                                    {isUsernameError && <FormErrorMessage>{t("login.usernameError")}</FormErrorMessage>}
                                </FormControl>
                                <FormControl mt={4} isInvalid={isPasswordError}>
                                    <FormLabel>{t("login.password")}</FormLabel>
                                    <Input type='password' data-testid="password-input" value={passwordInput} onChange={setPasswordInput} />
                                    {!isPasswordError && <FormErrorMessage>{t("login.passwordError")}</FormErrorMessage>}
                                </FormControl>
                                <HStack w={"full"} mt={6} justifyContent={"end"}>
                                    <Button id="login-button" data-testid="login-button" type="submit" isDisabled={!usernameInput || !passwordInput} rightIcon={<ArrowForwardIcon />} colorScheme='teal' variant='outline'>
                                        {t("login.login")}
                                    </Button>
                                </HStack>
                            </form>
                            {children}
                        </>
                    ) :
                        (<Spinner size={"xl"} />)
                }
            </VStack >
        </Container >
    )
}