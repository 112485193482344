import { FormEvent, ReactNode, memo, useCallback, useState } from "react";
import { DisclosuredProps, ModalSheet, ModalSheetBody } from "react-shared";
import { useTranslation } from "react-i18next";
import { CSVUpload } from "./components/csv-upload.component";
import { useToast } from "@chakra-ui/toast";
import { ImportResponse } from "soverdi-api-models";
import { Button } from "@chakra-ui/button";
import { HStack } from "@chakra-ui/layout";

interface IProps extends DisclosuredProps {
    title: string
    description?: string,
    csvClass: new () => any
    header: ReactNode
    submit: (value: string) => Promise<ImportResponse>
}
export const CSVImport = memo(({ title, description, isOpen, onClose, submit, csvClass, header }: IProps) => {

    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [csvcontent, setCsvcontent] = useState<string | undefined>(undefined)
    const toast = useToast()

    const onSubmit = useCallback(async (e: FormEvent) => {
        e.preventDefault()
        if (!csvcontent) return
        setLoading(true)
        try {
            const result = await submit(csvcontent)
            const added = Object.keys(result.added).map(key => `${(result.added as any)[key]} ${t("models." + key)}`).join(", ")
            const skipped = Object.keys(result.skipped).map(key => `${(result.skipped as any)[key]} ${t("models." + key)}`).join(", ")
            toast({
                colorScheme: "green",
                title: t("import.inventorySuccess"),
                isClosable: true,
                duration: 9999999,
                description: `${added} ${t("import.imported")}.
                ${skipped} ${t("import.skipped")}`
            })
        }
        catch (e) {
            console.error(e)
            toast({
                colorScheme: "red",
                title: t("common.errorTitle"),
                isClosable: true,
                duration: 9999999,
                description: t("import.error")
            })
        }
        finally {
            setLoading(false)
        }
    }, [csvcontent, t, toast, submit])

    return (
        <ModalSheet isOpen={isOpen} onClose={onClose} full={true} header={header}>
            <ModalSheetBody>
                <form onSubmit={onSubmit}>
                    <CSVUpload title={title} description={description} onChange={setCsvcontent} csvClass={csvClass} />
                    <HStack justifyContent={"space-between"} my={10}>
                        <Button data-testid="form-cancel-button" onClick={onClose}>
                            {t("common.cancel")}
                        </Button>
                        <Button data-testid="form-submit-button" isLoading={loading} loadingText={t("common.submitting")} colorScheme="teal" type="submit">
                            {t("import.button")}
                        </Button>
                    </HStack>
                </form>
            </ModalSheetBody>
        </ModalSheet>
    )
})