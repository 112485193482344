import { IsDate, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { Transform, Type } from "class-transformer";
import { Domaine } from "./domaine.model";
import { SearchableModel } from "./composition/searchable.model";
import { ModelWithMeta } from "./base/model-with-meta";
import { TypedDomaine } from "./typed-domaine.model";
import { DOMAINE_TYPE } from "../enums/domaine.enum";
import { User } from "./user.model";
import { AssignableModel } from "./composition/assignable.model";
import { toCanadianDateString } from "../utils/canadian-date";
import { FormableModel } from "./composition/formable.model";
import { FormConfig } from "@class-former/decorators"
import { IsDomaine } from "../decorators/_domaine.decorator";
import { AddDomaines } from "../decorators/_add-domaine.decorator";
import { IsParent } from "../decorators/_parent.decorator";
import { PARENT_TYPE } from "../enums/parent-type.enum";

const enCours = new Domaine()
enCours.id = 1
enCours.nom = "En cours"
export const InterventionProgressEnCours = enCours
const TypedInterventionProgressEnCours = new TypedDomaine(InterventionProgressEnCours, DOMAINE_TYPE.INTERVENTION_PROGRESS, { color: "yellow" })

const complete = new Domaine()
complete.id = 2
complete.nom = "Complété"
export const InterventionProgressComplete = complete
const TypedInterventionProgressComplete = new TypedDomaine(InterventionProgressComplete, DOMAINE_TYPE.INTERVENTION_PROGRESS, { color: "green" })

AddDomaines(DOMAINE_TYPE.INTERVENTION_PROGRESS)
export class Intervention extends ModelWithMeta implements SearchableModel, AssignableModel, FormableModel {

	@IsDate()
	@IsNotEmpty()
	@Transform(({ value }) => value ? new Date(value) : undefined, { toClassOnly: true })
	dateprevue!: Date

	@IsOptional()
	@FormConfig({ skip: (me: ModelWithMeta) => !!!me.id })
	@IsDate()
	@Transform(({ value }) => value ? new Date(value) : undefined, { toClassOnly: true })
	datedepassage?: Date

	@IsDomaine(DOMAINE_TYPE.TYPE_INTERVENTION)
	@ValidateNested()
	@IsNotEmpty()
	@Type(() => Domaine)
	typeintervention!: Domaine

	@IsString()
	commentaires!: string

	@ValidateNested({ each: true })
	@Type(() => User)
	users: Array<User> = []

	@IsParent(PARENT_TYPE.EVENEMENT)
	@FormConfig({ skip: true, preserve: true })
	@IsNumber()
	idevenement!: number

	@IsDomaine(DOMAINE_TYPE.TAG)
	@ValidateNested({ each: true })
	@Type(() => Domaine)
	tags: Domaine[] = []

	public get searchBy(): string { return this.typeintervention.nom }
	public get subtext() { return [{ i18nprefix: this.datedepassage ? "intervention.datedepassage" : "intervention.dateprevue", value: this.datedepassage ? toCanadianDateString(this.datedepassage) : toCanadianDateString(this.dateprevue) }] }
	public get domaines() {
		return [
			this.datedepassage ? TypedInterventionProgressComplete : TypedInterventionProgressEnCours,
			...this.tags.map(t => new TypedDomaine(t, DOMAINE_TYPE.TAG))
		]
	}
	get parentid() { return this.idevenement }
	set parentid(id: number) { this.idevenement = id }
}