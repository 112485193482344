import i18next from "i18next";
import { useInit } from "../hooks/init.hook";

export function addTranslations() {
    useInit(() => {
        i18next.addResourceBundle("en", "translation", {
            updateapp: {
                updateHeader: "A new version is available!",
                update: "Update"
            },
        })

        i18next.addResourceBundle("fr", "translation", {
            updateapp: {
                updateHeader: "Une nouvelle version est disponible!",
                update: "Mettre à jour"
            },
        })
    })
}