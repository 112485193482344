import { IsNotEmpty, IsOptional, IsString, ValidateNested } from "class-validator";
import { SearchableModel } from "./composition/searchable.model";
import { Type } from "class-transformer";
import { Domaine } from "./domaine.model";
import { ModelWithMeta } from "./base/model-with-meta";
import { TypedDomaine } from "./typed-domaine.model";
import { DOMAINE_TYPE } from "../enums/domaine.enum";
import { IsDomaine } from "../decorators/_domaine.decorator";
import { FormableModel } from "./composition/formable.model";
import { FormConfig } from "@class-former/decorators";
import { SoverdiPalette } from "../config/environment";

export class Espece extends ModelWithMeta implements SearchableModel, FormableModel {

    @IsString()
    @IsNotEmpty()
    genre!: string

    @IsString()
    @IsNotEmpty()
    nomespece!: string

    @IsString()
    @IsOptional()
    cultivar?: string

    /** Code abbreviation du nom latin */
    @IsString()
    @IsNotEmpty()
    code!: string

    @FormConfig({ specialType: "couleur" })
    @IsString()
    @IsNotEmpty()
    couleur: string = SoverdiPalette[0]

    @IsDomaine(DOMAINE_TYPE.TYPE_ESPECE)
    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Domaine)
    typeespece!: Domaine

    public get searchBy() { return [this.genre, this.nomespece, ...(this.cultivar ? [this.cultivar] : [])].join(" ") }
    public get subtext() { return [{ value: this.code }] }
    public get domaines() { return [new TypedDomaine(this.typeespece, DOMAINE_TYPE.TYPE_ESPECE)] }
    get parentid() { return 0 }
    set parentid(id: number) { }

}