import { Transform, Type } from "class-transformer"
import { IsString, ValidateNested, IsNumber, IsDate, IsNotEmpty, IsArray, IsOptional } from "class-validator"
import { Model } from "../../models/base/model"
import { Params } from "./_params.type"
import { Intervention } from "../../models/intervention.model"

export class CreateInterventionParams implements Params<Intervention, "datedepassage"> {

    @IsNumber()
    idevenement!: number

    @IsDate()
    @IsNotEmpty()
    @Transform(({ value }) => new Date(value), { toClassOnly: true })
    dateprevue!: Date

    @IsString()
    commentaires: string = ""

    @ValidateNested()
    @Type(() => Model)
    @IsNotEmpty()
    typeintervention!: Model

    @ValidateNested({ each: true })
    @Type(() => Model)
    users!: Array<Model>
    
    @IsOptional()
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => Model)
    tags?: Array<Model>
}