import { METADATA_TAG } from "../enums/metadata-tag.enum";
import { Model } from "../models/base/model";

export class MetadataStorage {

    private static CLASS_STORAGE: { [key: string]: Map<new () => any, any> }
    private static CLASS_PROPERTIES_STORAGE: { [key: string]: Map<new () => any, { [key: string]: any }> }

    public static setOnClass(key: METADATA_TAG, target: new () => Model, value: any) {
        if (!MetadataStorage.CLASS_STORAGE)
            MetadataStorage.CLASS_STORAGE = {}
        if (!Object.hasOwn(MetadataStorage.CLASS_STORAGE, key))
            MetadataStorage.CLASS_STORAGE[key] = new Map()
        MetadataStorage.CLASS_STORAGE[key].set(target, value)
    }

    public static getByClass(key: METADATA_TAG, target: new () => Model) {
        const result = MetadataStorage.CLASS_STORAGE?.[key]?.get(target)
        return result
    }

    public static setOnClassProperty(key: METADATA_TAG, target: new () => Model, property: string, value: any) {
        if (!MetadataStorage.CLASS_PROPERTIES_STORAGE)
            MetadataStorage.CLASS_PROPERTIES_STORAGE = {}
        if (!Object.hasOwn(MetadataStorage.CLASS_PROPERTIES_STORAGE, key))
            MetadataStorage.CLASS_PROPERTIES_STORAGE[key] = new Map()
        if (!MetadataStorage.CLASS_PROPERTIES_STORAGE[key].has(target))
            MetadataStorage.CLASS_PROPERTIES_STORAGE[key].set(target, {})
        const clazz = MetadataStorage.CLASS_PROPERTIES_STORAGE[key].get(target)!
        clazz[property] = value
    }

    public static getByClassProperty(key: METADATA_TAG, target: new () => Model, property: string) {
        const result = MetadataStorage.CLASS_PROPERTIES_STORAGE?.[key]?.get(target)?.[property]
        return result
    }

}