import { ChildResources, ModelResources } from "./model.resources";
import { ProjetService } from "soverdi-api-client";
import { TabIdentifier } from "../hooks/tab-stack/tab-identifier.enum";
import { Container } from "typedi"
import { CreateProjetParams, Projet, Site, UpdateProjetParams, environment } from "soverdi-api-models";
import { AiOutlineFolder } from "react-icons/ai";
import { DeleteBulkAction } from "../components/menu-actions/bulk-actions/delete.bulk-action";
import { SiteResources } from "./sites.resources";
import { instanceToInstance } from "class-transformer"
import { AssignMeBulkAction } from "../components/menu-actions/bulk-actions/assignme.bulk-action";
import { AssignBulkAction } from "../components/menu-actions/bulk-actions/assign.bulk-action";
import { ShowBulkAction } from "../components/menu-actions/bulk-actions/show.bulk-action";
import { ShowVegetauxBulkAction } from "../components/menu-actions/bulk-actions/show-vegetaux.bulk-action";
import { UnassignAllBulkAction } from "../components/menu-actions/bulk-actions/unassign-all.bulk-action";
import { CustomForm } from "../components/model-forms/form.component";
import { ExportVegetauxBulkAction } from "../components/menu-actions/bulk-actions/export-vegetaux.bulk-action";
import { ExportInterventionsBulkAction } from "../components/menu-actions/bulk-actions/export-interventions.bulk-action";
import { SetTagsAction } from "../components/menu-actions/bulk-actions/set-tags.bulk-action";

class ProjetResourcesClass extends ModelResources<Projet> {
    form = CustomForm
    get service() { return Container.get(ProjetService) }
    get parentService() { return undefined }

    icon = AiOutlineFolder
    bulkActions = [
        SetTagsAction,
        AssignMeBulkAction,
        AssignBulkAction,
        UnassignAllBulkAction,
        ShowBulkAction,
        ShowVegetauxBulkAction,
        ExportVegetauxBulkAction,
        ExportInterventionsBulkAction,
        DeleteBulkAction]
    actions = []
    tabIdentifier = TabIdentifier.projet
    lists = [
        {
            resources: SiteResources,
            get: (me) => me?.sites,
            set: (me, values) => {
                me.sites = values
                return instanceToInstance(me)
            }
        } as ChildResources<Projet, Site>
    ];
    roles = { rest: environment.endpoints.projet.rest };
    Model = Projet
    CreateParams = CreateProjetParams
    UpdateParams = UpdateProjetParams
}

export const ProjetResources = new ProjetResourcesClass()