import { IsString, IsNumber, ValidateNested, IsNotEmpty } from "class-validator";
import { Type } from "class-transformer"
import { Domaine } from "./domaine.model";
import { SearchableModel } from "./composition/searchable.model";
import { CartographicModel } from "./composition/cartographic.model";
import { IsGeoJSON } from "../validators/geojson.validator";
import { Point } from "./geojson/point.geojson";
import { ModelWithMeta } from "./base/model-with-meta";
import { TypedDomaine } from "./typed-domaine.model";
import { DOMAINE_TYPE } from "../enums/domaine.enum";
import { User } from "./user.model";
import { AssignableModel } from "./composition/assignable.model";
import { FormConfig } from "@class-former/decorators"
import { IsDomaine } from "../decorators/_domaine.decorator";
import { FormableModel } from "./composition/formable.model";
import { Model } from "./base/model";
import { IsParent } from "../decorators/_parent.decorator";
import { PARENT_TYPE } from "../enums/parent-type.enum";
import { Adresse } from "./adresse.model";

export class Site extends ModelWithMeta implements SearchableModel, CartographicModel, AssignableModel, FormableModel {

    @IsString()
    @IsNotEmpty()
    nom!: string

    @IsNotEmpty()
    @IsDomaine(DOMAINE_TYPE.RESEAU)
    @ValidateNested()
    @Type(() => Domaine)
    reseau!: Domaine

    @ValidateNested()
    @Type(() => Adresse)
    @IsNotEmpty()
    adresse!: Adresse

    @FormConfig({ skip: true })
    @IsNumber(undefined, { each: true })
    evenements!: Array<number>

    @IsDomaine(DOMAINE_TYPE.ENTITE_ADMINISTRATIVE)
    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Domaine)
    entiteadministrative!: Domaine

    @IsDomaine(DOMAINE_TYPE.TERRITOIRE)
    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Domaine)
    territoire!: Domaine

    @FormConfig({ skip: (me: Model) => !!!me.id })
    @IsGeoJSON()
    @ValidateNested()
    @Type(() => Point)
    geometry!: Point

    @ValidateNested({ each: true })
    @Type(() => User)
    users: Array<User> = []

    @IsParent(PARENT_TYPE.PROJET)
    @FormConfig({ skip: true, preserve: true })
    @IsNotEmpty()
    @IsNumber()
    idprojet!: number

    @IsDomaine(DOMAINE_TYPE.TAG)
    @ValidateNested({ each: true })
    @Type(() => Domaine)
    tags: Domaine[] = []

    public static layer = "Sites"
    get layer() { return Site.layer }
    public get searchBy() { return this.nom }
    public get subtext() { return [{ value: this.adresse.printableformat }] }
    public get domaines() {
        return [
            new TypedDomaine(this.reseau, DOMAINE_TYPE.RESEAU),
            new TypedDomaine(this.entiteadministrative, DOMAINE_TYPE.ENTITE_ADMINISTRATIVE, { hide: true }),
            ...this.tags.map(t => new TypedDomaine(t, DOMAINE_TYPE.TAG))
        ]
    }
    get parentid() { return this.idprojet }
    set parentid(id: number) { this.idprojet = id }
}
