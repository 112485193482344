import { AddressCandidate, Adresse, Domaine, Espece, Photo, Point, Produit, Site, User } from "soverdi-api-models";
import { AddressInput } from "../../inputs/address.input"
import { DomaineInput } from "../../inputs/domaine.input"
import { Input } from "@chakra-ui/react"
import { FromModelListInput, FromModelListInputProps } from "../../inputs/from-model-list.input"
import { AttachService, AttachDataTestId, AttachDomaineType } from "../utils/attach-props";
import { DateInput } from "../../inputs/date.input"
import { NumberInput } from "../../inputs/number.input";
import { PointInput, PointInputIProps } from "../../inputs/point.input";
import { FormConfig } from "@class-former/react/src/classes/form-config";
import { AutocompleteMultipleInput, AutocompleteMultipleInputIprops } from "../../inputs/autocomplete-multiple.input";
import { Container } from "typedi"
import { UserService } from "soverdi-api-client";
import { RemplacementInput } from "../../inputs/remplacement.input";
import { PhotosInput } from "../../inputs/photos.input";
import { ColorInput } from "../../inputs/color.input";

export const CustomFormConfig = new FormConfig<any>()
    .add({
        type: () => Adresse, component: () => AddressInput,
        pipein: (value) => ({ adresse: value } as AddressCandidate),
        pipeout: ({ value: v, setValues }) => {
            const _v = v as AddressCandidate
            setValues((prev: Site) => ({ ...prev, geometry: _v?.position, entiteadministrative: _v?.entiteadministrative, adresse: _v?.adresse as Adresse } as Partial<Site>), true);
        }
    })
    .add({ type: () => String, component: () => Input, attachProps: ({ meta }) => ({ ...AttachDataTestId(meta), type: "text" }) })
    .add({ type: () => Number, component: () => NumberInput, attachProps: ({ meta }) => AttachDataTestId(meta) })
    .add({ type: () => Domaine, component: () => DomaineInput, attachProps: ({ meta }) => ({ ...AttachDataTestId(meta), ...AttachService(meta), ...AttachDomaineType(meta) }) })
    .add({ type: () => [Domaine], component: () => DomaineInput, attachProps: ({ meta }) => ({ ...AttachDataTestId(meta), ...AttachService(meta), ...AttachDomaineType(meta), multiple: true }) })
    .add({ type: () => Espece, component: () => FromModelListInput, attachProps: ({ meta }) => ({ ...AttachDataTestId(meta), model: Espece } as FromModelListInputProps<any>) })
    .add({ type: () => Produit, component: () => FromModelListInput, attachProps: ({ meta }) => ({ ...AttachDataTestId(meta), model: Produit } as FromModelListInputProps<any>) })
    .add({ type: "couleur", component: () => ColorInput, attachProps: ({ meta }) => AttachDataTestId(meta) })
    .add({ type: () => Date, component: () => DateInput, attachProps: ({ meta }) => AttachDataTestId(meta) })
    .add({ type: () => Point, component: () => PointInput, attachProps: ({ meta, data }) => ({ ...AttachDataTestId(meta), layer: meta.prototype.layer, modelid: data.id } as Partial<PointInputIProps>) })
    .add({ type: () => [User], component: () => AutocompleteMultipleInput, attachProps: ({ meta }) => ({ ...AttachDataTestId(meta), service: Container.get(UserService), inputMinLength: 0 } as AutocompleteMultipleInputIprops<any, any>) })
    .add({ type: "remplacement", component: () => RemplacementInput })
    .add({ type: () => [Photo], component: () => PhotosInput })
