import { useCallback, useContext } from "react"
import { ModalSheetContext } from "react-shared";
import { MapContext } from "../contexts/map.context"
import { Point } from "soverdi-api-models";
import { Convert3857to4326 } from "../utils/3857to4326"
import { useTranslation } from "react-i18next"

export function useMovePoint(layer: string) {
    const { mapHandle } = useContext(MapContext)
    const { onPrompt } = useContext(ModalSheetContext)
    const { t } = useTranslation()

    /** Will return initialValues if the user cancels the prompt */
    const move = useCallback(async (modelid: number, onChange: (value: Point) => any): Promise<boolean> => {
        await mapHandle?.filter([modelid], layer, { draggable: true })
        const subscription = mapHandle?.events.onUpdate.subscribe({
            next: async (value) => {
                const coordinateUpdate = [value.geometry?.x!, value.geometry?.y!] as [number, number]
                onChange({
                    type: "Point",
                    coordinates: Convert3857to4326(coordinateUpdate)
                } as Point)
            }
        })
        try {
            const confirm = await onPrompt(t("move.confirm"), { subtext: t("move.confirmmessage"), confirmButton: true })
            return confirm
        }
        catch (e) {
            console.error(e)
            return false
        }
        finally {
            subscription?.unsubscribe()
            await mapHandle?.clear()
        }
    }, [mapHandle, onPrompt, t, layer])

    return { move }
}