import { FC, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionProps } from "./action.props";
import {
    Box,
    Button,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    useToast,
} from "@chakra-ui/react";
import { useAsyncEffect, useRoutingDisclosure } from "react-shared";
import { FaBarcode } from "react-icons/fa";
import { Html5Qrcode } from "html5-qrcode";
import { CommandeService } from "soverdi-api-client";
import { getService } from "api-client-shared";
import { usePlanting } from "./hooks/planting.hook";


export const ScanAction: FC<ActionProps<any, any>> = memo(({ data, onChange }: ActionProps<any, any>) => {

    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const toast = useToast()
    const { isOpen, onClose: _onClose, onOpen } = useRoutingDisclosure("/scan")
    const commandeService = getService(CommandeService)
    const { startPlanting } = usePlanting()
    const [html5Qrcode, setHtml5Qrcode] = useState<Html5Qrcode | undefined>()
    const [sku, setSku] = useState<string | undefined>()

    const onClose = useCallback(() => {
        _onClose()
        setSku(undefined)
        if (!html5Qrcode) return
        try {
            html5Qrcode.stop()
        }
        catch (e) { }
    }, [_onClose, html5Qrcode])

    const plant = useCallback(async (idcommande: number) => {
        setLoading(true)
        const v = await startPlanting(idcommande)
        setLoading(false)
        return !!v
    }, [setLoading, startPlanting])

    //because Html5Qrcode uses a callback, onclose doesnt work inside the callback because it doesnt get updated
    useAsyncEffect(async () => {
        if (!sku) return
        onClose()
        try {
            const match = await commandeService.search.searchForIds({ produit: { sku }, idevenement: data })
            if (match.length > 1) {
                toast({ colorScheme: "red", title: t("common.errorTitle"), description: t("ScanAction.multiplecommandes") })
                return
            }
            if (match.length === 0) {
                toast({ colorScheme: "red", title: t("common.errorTitle"), description: t("ScanAction.noCommandeResult") + sku })
                return
            }
            const change = await plant(match[0])
            if (change && onChange)
                onChange(prev => [...prev])
        }
        catch (e) {
            console.error(e)
            toast({ colorScheme: "red", title: t("common.errorTitle"), description: t("common.errorMessage") })
        }
        finally {
            setSku(undefined)
        }
    }, [sku, onClose, commandeService, t, toast, data, plant, setSku])

    const startScanning = useCallback(async () => {
        onOpen()
        setTimeout(() => {
            const _html5QrCode = new Html5Qrcode("reader");
            setHtml5Qrcode(_html5QrCode)
            _html5QrCode.start({ facingMode: "environment" }, {
                fps: 10,
                aspectRatio: 1,
                qrbox: { height: 150, width: 250 }
            }, (decoded: string) => setSku(decoded), () => null)
        }, 1)
    }, [onOpen, setHtml5Qrcode, setSku])

    return (
        <Box>
            <Button isLoading={loading} loadingText={t("ScanAction.button")} data-testid="scan-button" onClick={startScanning} rightIcon={<Icon as={FaBarcode} />}>{t("ScanAction.button")}</Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent width={["100vw", "100vw", "400px"]} maxWidth={"400px"}>
                    <ModalBody py={4}>
                        <div id="reader"></div>
                        <HStack mt={4}>
                            <Button onClick={onClose} variant={"outline"}>{t("common.cancel")}</Button>
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
})