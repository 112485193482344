import { useLocation, Routes, Route, Navigate } from 'react-router-dom';
import './app.css';
import { DashboardPage } from './pages/dashboard.page';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { useCallback, useState } from 'react';
import { DeleteProvider, LanguageRepository, LoginPage, StatusProvider, TokenRepository } from 'react-shared';
import i18next from 'i18next';
import { AuthService, Token } from 'portail-api-client';
import { getService } from 'api-client-shared';
import { useInit } from "react-shared"
import { AppContext } from './app.context';
import { SoverdiToken } from 'soverdi-api-client';
import { environment } from './config/environment';

const theme = extendTheme({
  components: {
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        color: 'bold', // Normally, it is "semibold"
      },
    }
  }
})

function App() {

  const [ready, setReady] = useState(false)
  const location = useLocation();
  const dynamicLocation = Boolean(location.state?.dynamicLocation)
  const languageRepository = getService(LanguageRepository)
  const tokenRepository = getService(TokenRepository)
  const auth = getService(AuthService)
  const [token, _setToken] = useState<SoverdiToken | undefined>()

  const setToken = useCallback(async (_token: Token | undefined) => {
    if (!_token) {
      await tokenRepository.delete()
      _setToken(undefined)
    }
    else {
      await tokenRepository.set(_token)
      _setToken(new SoverdiToken(_token))
    }
  }, [_setToken, tokenRepository])

  const persistLanguage = useCallback(async (_language: string) => {
    languageRepository.set(_language)
    i18next.changeLanguage(_language)
  }, [languageRepository])

  useInit(async () => {
    const language = await languageRepository.get();
    if (language)
      i18next.changeLanguage(language)
    const token = await tokenRepository.get()
    if (token) {
      try {
        const updatedtoken = await auth.authentificateWithRefreshToken(token, environment.app)
        await setToken(updatedtoken)
      }
      catch (e) { console.error(e) }
    }

  }, () => setReady(true))

  return (
    <AppContext.Provider value={{ setLanguage: persistLanguage, token, setToken }}>
      <ChakraProvider theme={theme}>
        <DeleteProvider>
          <StatusProvider>
            <TransitionGroup>
              <CSSTransition
                key={location.pathname.split('/')[1]}
                classNames="fade"
                timeout={300}
              >
                <Routes location={location}>
                  {/* Si tu ajoutes une route, n'oubli pas d'ajouter celle-ci dans service-worker.ts et serviceWorkerRegistration.ts pour que le cache du PWA fonctionne correctement */}
                  <Route path="/login/*" element={<LoginPage app={environment.app} ready={ready} authentified={!!token} setAuthentified={setToken} authentifiedRedirect='/dashboard' setLanguage={persistLanguage}>
                  </LoginPage>} />
                  {token && <Route path="/dashboard/*" element={<DashboardPage />} />}
                  {(!dynamicLocation || !token) && <Route path="*" element={<Navigate to="/login" replace />} />}
                </Routes>
              </CSSTransition>
            </TransitionGroup>
          </StatusProvider>
        </DeleteProvider>
      </ChakraProvider>
    </AppContext.Provider>
  );
}

export default App;
