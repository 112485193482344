import { ModelResources } from "./model.resources";
import { EspeceService } from "soverdi-api-client";
import { TabIdentifier } from "../hooks/tab-stack/tab-identifier.enum";
import { Container } from "typedi"
import { Espece, EspeceParams, environment } from "soverdi-api-models";
import { DeleteBulkAction } from "../components/menu-actions/bulk-actions/delete.bulk-action";
import { PiPlant } from "react-icons/pi";
import { CustomForm } from "../components/model-forms/form.component";

class EspeceResourcesClass extends ModelResources<Espece> {
    form = CustomForm
    get service() { return Container.get(EspeceService) }
    get parentService() { return undefined }
    icon = PiPlant
    bulkActions = [DeleteBulkAction]
    actions = []
    tabIdentifier = TabIdentifier.espece
    lists = []
    roles = { rest: environment.endpoints.espece.rest };
    Model = Espece
    CreateParams = EspeceParams
    UpdateParams = EspeceParams
}

export const EspeceResources = new EspeceResourcesClass()