import 'reflect-metadata';
import { getMetadataStorage } from 'class-validator';
import { METADATA_TAGS, FormConfigParameters } from '@class-former/decorators';

export function createInitialValues<T extends Object>(model: T): { initialValues: T, preservedValues: Partial<T> } {
  const proto = Object.getPrototypeOf(model);
  const initialValues = {} as T
  const preservedValues = {} as Partial<T>
  const storage = getMetadataStorage();
  const validatorsMetadata = storage.getTargetValidationMetadatas(proto.constructor, '', true, false);
  for (const key of Object.keys(model)) {
    const config = Reflect.getMetadata(METADATA_TAGS.FORM_CONFIG_TAG, proto, key) as FormConfigParameters<T>;
    if (config?.skip !== undefined) {
      if ((typeof config.skip === "function" && config.skip(model)) || config.skip === true) {
        if (config.preserve)
          (preservedValues as any)[key] = (model as any)[key]
        continue;
      }
    }
    const validations = validatorsMetadata.filter(v => v.propertyName === key);
    const defaultValue = validations.find(v => v.name === 'isNumber') ? 0 : validations.find(v => v.name === 'isString') ? '' : validations.find(v => v.name === 'isBoolean') ? false : undefined;
    (initialValues as any)[key] = (model as any)[key] || defaultValue;
  }
  return { initialValues, preservedValues };
}
