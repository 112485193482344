import { useTranslation } from "react-i18next";
import { QueryForm } from "./query-form.component";
import { memo, useCallback, useMemo, useState } from "react";
import { SearchEvenementParams } from "soverdi-api-models";
import { BaseForm } from "../base-form";
import { getService } from "api-client-shared";
import { EvenementService, TypeEvenementService } from "soverdi-api-client";
import { useTabStack } from "../../../../hooks/tab-stack/tab-stack.hook";
import { ModelListViewInfo } from "../../../../hooks/tab-stack/tab-stack-item.interface";
import { TextFilter } from "../form-controls/text-filter.component";
import { DomaineFilter } from "../form-controls/domaine-filter.component";
import { Container } from "typedi"
import { EvenementResources } from "../../../../model-resources/evenement.resources";
import { SearchParameter } from "./search-parameters.interface";

interface IProps {
    onClose: () => void
}
export const EvenementQueryForm = memo(({ onClose }: IProps) => {
    const { t } = useTranslation()
    const [params, setParams] = useState(new SearchEvenementParams())
    const service = getService(EvenementService)
    const parameters = useMemo(() => [
        {
            key: "name",
            i18n: t("filters.name"),
            component: (key, i18n, setValue, onRemove) => <TextFilter data-testid="nom-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, nom: x }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && value.nom === "") return t("filters.name") + t("QueryForm.empty") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, nom: undefined }))
        },
        {
            key: "typeevenement",
            i18n: t("filters.type"),
            component: (key, i18n, setValue, onRemove) => <DomaineFilter data-testid="typeevenement-input" key={key} text={i18n} domaineServiceType={Container.get(TypeEvenementService)} onChange={(x) => setValue(prev => ({ ...prev, typeevenement: { id: x.id } }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && !value.typeevenement) return t("filters.type") + t("QueryForm.empty") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, typeevenement: undefined }))
        },
        {
            key: "noevenement",
            i18n: t("evenement.noevenement"),
            component: (key, i18n, setValue, onRemove) => <TextFilter data-testid="noevenement-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, noevenement: x }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && value.noevenement === "") return t("evenement.noevenement") + t("QueryForm.empty") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, noevenement: undefined }))
        },
        ...BaseForm(t)] as Array<SearchParameter<SearchEvenementParams>>, [t])
    const { set } = useTabStack()

    const search = useCallback(async () => {
        const data = await service.search.searchForIds(params)
        set(new ModelListViewInfo(EvenementResources, data, true))
    }, [params, set, service])

    return (
        <QueryForm onSearch={search} parameters={parameters} value={params} onChange={setParams} onClose={onClose} />
    )
})