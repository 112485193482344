import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from "@chakra-ui/react";
import { MdApps } from "react-icons/md";
import { IoAddCircleOutline } from "react-icons/io5"
import { useRoutingDisclosure } from "react-shared";
import { CreateProject } from "./components/create-project.component";
import { GrCatalog } from "react-icons/gr"
import { memo, useCallback, useContext } from "react";
import { useTabStack } from "../../hooks/tab-stack/tab-stack.hook";
import { CatalogResources } from "../../model-resources/catalog/catalog.resources";
import { useTranslation } from "react-i18next";
import { MANAGERS, environment } from "soverdi-api-models";
import { AppContext } from "../../app.context";
import { FaRegStar } from "react-icons/fa";
import { FavoritesInfo, ModelViewInfo } from "../../hooks/tab-stack/tab-stack-item.interface";

export const StartMenu = memo(() => {

    const { t } = useTranslation()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenCreate, onOpen: onOpenCreate, onClose: onCloseCreate } = useRoutingDisclosure("/createproject", { openDelay: 200 })
    const { set } = useTabStack()
    const { token } = useContext(AppContext)

    const onOpenCatalog = useCallback(async () => set(new ModelViewInfo(CatalogResources, 1, true)), [set])
    const openFavorites = useCallback(() => set(new FavoritesInfo()), [set])

    return (
        <>
            <Menu isLazy isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
                <MenuButton data-testid="start-menu-button" boxShadow={"lg"} as={IconButton} icon={<Icon fontSize="20px" as={MdApps}></Icon>} />
                <MenuList>
                    {environment.endpoints.projet.rest.create.includes(token!.role) && <MenuItem data-testid="create-projet-button" onClick={onOpenCreate} icon={<IoAddCircleOutline fontSize="20px" />}>{t("SearchActions.createProject")}</MenuItem>}
                    {MANAGERS.includes(token!.role) && <MenuItem data-testid="open-inventory-button" onClick={onOpenCatalog} icon={<GrCatalog fontSize="20px" />}>{t("SearchActions.catalog")}</MenuItem>}
                    <MenuItem data-testid="open-favorites-button" onClick={openFavorites} icon={<FaRegStar fontSize="20px" />}>{t("SearchActions.favorites")}</MenuItem>
                </MenuList>
            </Menu>
            {isOpenCreate && <CreateProject isOpen={isOpenCreate} onClose={onCloseCreate} />}

        </>
    )
})