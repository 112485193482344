import { IsString, IsNumber, IsDate, ValidateNested, IsNotEmpty, IsOptional } from 'class-validator';
import { Domaine } from './domaine.model';
import { Transform, Type } from "class-transformer"
import { SearchableModel } from './composition/searchable.model';
import { ModelWithMeta } from './base/model-with-meta';
import { TypedDomaine } from './typed-domaine.model';
import { DOMAINE_TYPE } from '../enums/domaine.enum';
import { User } from './user.model';
import { AssignableModel } from './composition/assignable.model';
import { toCanadianDateString } from '../utils/canadian-date';
import { FormConfig } from "@class-former/decorators"
import { IsDomaine } from '../decorators/_domaine.decorator';
import { FormableModel } from './composition/formable.model';
import { PARENT_TYPE } from '../enums/parent-type.enum';
import { IsParent } from '../decorators/_parent.decorator';

export class Evenement extends ModelWithMeta implements SearchableModel, AssignableModel, FormableModel {

    @IsString()
    @IsOptional()
    nom?: string

    /** Numero administratif d'evenement */
    @IsString()
    noevenement!: string

    @IsDate()
    @IsNotEmpty()
    @Transform(({ value }) => value ? new Date(value) : undefined, { toClassOnly: true })
    dateprevue!: Date

    @IsDomaine(DOMAINE_TYPE.TYPE_EVENEMENT)
    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Domaine)
    typeevenement!: Domaine

    @FormConfig({ skip: true })
    @IsNumber(undefined, { each: true })
    commandes!: Array<number>

    @FormConfig({ skip: true })
    @IsNumber(undefined, { each: true })
    interventions!: Array<number>

    @IsString()
    description!: string

    @ValidateNested({ each: true })
    @Type(() => User)
    users: Array<User> = []

    @IsParent(PARENT_TYPE.SITE)
    @FormConfig({ skip: true, preserve: true })
    @IsNumber()
    idsite!: number

    @FormConfig({ skip: true })
    @IsString()
    @IsOptional()
    progres?: string

    @IsDomaine(DOMAINE_TYPE.TAG)
    @ValidateNested({ each: true })
    @Type(() => Domaine)
    tags: Domaine[] = []

    public get searchBy(): string { return this.nom || toCanadianDateString(this.dateprevue) }
    public get subtext() { return [{ i18nprefix: "evenement.dateprevue", value: toCanadianDateString(this.dateprevue) }] }
    public get domaines() {
        return [
            new TypedDomaine(this.typeevenement, DOMAINE_TYPE.TYPE_EVENEMENT),
            ...this.tags.map(t => new TypedDomaine(t, DOMAINE_TYPE.TAG))
        ]
    }
    get parentid() { return this.idsite }
    set parentid(id: number) { this.idsite = id }
    get sortBy() { return this.dateprevue.getTime() }

}