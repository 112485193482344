import { memo, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiMapPin } from "react-icons/fi";
import { ModalSheetContext } from "react-shared";
import { BulkActionProps } from "./bulk-action.props";
import { MapContext } from "../../../contexts/map.context";
import { Button, Icon } from "@chakra-ui/react";
import { Model, CartographicModel } from "soverdi-api-models";

function ShowBulkActionComponent<T extends Model & CartographicModel>({ data, model }: BulkActionProps<T, any>) {

    const { t } = useTranslation()
    const { mapHandle } = useContext(MapContext)
    const { onMinimize } = useContext(ModalSheetContext)
    const layer = useMemo(() => new model().layer, [model])
    const [loading, setLoading] = useState(false)

    const onShow = useCallback(async () => {
        setLoading(true)
        try {
            if (data.length === 0) return
            onMinimize()
            await mapHandle?.filter(data, layer)
        }
        catch (e) { console.error(e) }
        setLoading(false)
    }, [mapHandle, data, onMinimize, setLoading, layer])

    return (
        <Button isLoading={loading} loadingText={t("DefaultBulkActions.show")} data-testid="show-me-button" onClick={onShow} rightIcon={<Icon as={FiMapPin} />}>{t("DefaultBulkActions.show")}</Button>
    )
}

export const ShowBulkAction = memo(ShowBulkActionComponent) as typeof ShowBulkActionComponent
