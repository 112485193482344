import { IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { SearchParams } from "./search.params"
import { Model } from "../../models/base/model";
import { ISearchParams } from "./search-params.type";
import { Produit } from "../../models/produit.model";

export class SearchProduitParams extends SearchParams implements ISearchParams<Produit, "espece"> {

    @IsString()
    @IsOptional()
    sku?: string

    @ValidateNested()
    @IsOptional()
    fournisseur?: Model

    @ValidateNested()
    @IsOptional()
    calibre?: Model

    @IsNumber()
    @IsOptional()
    hauteur?: number

    @ValidateNested()
    @IsOptional()
    formatpot?: Model

    @IsNumber()
    @IsOptional()
    dhs?: number

}