import i18next from "i18next";
import { useInit } from "../../hooks/init.hook";

export function addTranslations() {
    useInit(() => {
        i18next.addResourceBundle("en", "translation", {
            login: {
                "username": "Username",
                "password": "Password",
                "usernameError": "Username required",
                "passwordError": "Password required",
                "login": "Login",
                "loginErrors": {
                    "400": { title: "Invalid request", subtitle: "Please try again later, GOAzimut servers might be under maintenance." },
                    "404": { title: "Our server is unavailable", subtitle: "Please try again later, GOAzimut servers might be under maintenance." },
                    "401": { title: "Invalid credentials", subtitle: "Invalid username or password." },
                    "403": { title: "Access revoked", subtitle: "Your access rights do not match this app or its version. Please contact your administrator." },
                    "ERR_NETWORK": { title: "Internet not available", subtitle: "Do you have an active internet connection?" },
                }
            }
        })

        i18next.addResourceBundle("fr", "translation", {
            login: {
                "username": "Nom d'utilisateur",
                "password": "Mot de passe",
                "usernameError": "Nom d'utilisateur requis",
                "passwordError": "Mot de passe requis",
                "login": "Se connecter",
                "loginErrors": {
                    "400": { title: "Requête invalide", subtitle: "Svp veuillez réessayer plus tard, les serveurs GOAzimut sont peut-être en maintenance." },
                    "404": { title: "Notre serveur est indisponible", subtitle: "Svp veuillez réessayer plus tard, les serveurs GOAzimut sont peut-être en maintenance." },
                    "401": { title: "Identification invalide", subtitle: "Nom d'utilisateur ou mot de passe invalide." },
                    "403": { title: "Accès interdit", subtitle: "Vos droits d'accès ne coïncident pas avec cet application ou sa version. Veuillez contacter votre administrateur." },
                    "ERR_NETWORK": { title: "Connexion internet non disponible", subtitle: "Avez-vous une connexion internet active?" },
                }
            }
        })
    })
}