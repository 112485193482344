import { useTranslation } from "react-i18next";
import { QueryForm } from "./query-form.component";
import { memo, useCallback, useMemo, useState } from "react";
import { SearchVegetalParams } from "soverdi-api-models";
import { BaseForm } from "../base-form";
import { getService } from "api-client-shared";
import {
    EtatSanteService,
    FormatpotService,
    RaisonMortaliteService,
    TypeEspeceService,
    VegetalService,
} from "soverdi-api-client";
import { useTabStack } from "../../../../hooks/tab-stack/tab-stack.hook";
import { ModelListViewInfo } from "../../../../hooks/tab-stack/tab-stack-item.interface";
import { TextFilter } from "../form-controls/text-filter.component";
import { DomaineFilter } from "../form-controls/domaine-filter.component";
import { Container } from "typedi"
import { VegetalResources } from "../../../../model-resources/vegetal.resources";
import { SearchParameter } from "./search-parameters.interface";
import { NumberFilter } from "../form-controls/number-filter.component";

interface IProps {
    onClose: () => void
}
export const VegetalQueryForm = memo(({ onClose }: IProps) => {
    const { t } = useTranslation()
    const [params, setParams] = useState(new SearchVegetalParams())
    const service = getService(VegetalService)
    const parameters = useMemo(() => [
        {
            key: "id",
            i18n: t("vegetal.id"),
            component: (key, i18n, setValue, onRemove) => <NumberFilter data-testid="id-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, id: x }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && !value.id) return t("vegetal.id") + t("QueryForm.empty") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, id: undefined }))
        },
        {
            key: "specimenid",
            i18n: t("vegetal.specimenid"),
            component: (key, i18n, setValue, onRemove) => <TextFilter data-testid="specimenid-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, specimenid: x }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && !value.specimenid) return t("vegetal.specimenid") + t("QueryForm.empty") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, specimenid: undefined }))
        },
        {
            key: "genre",
            i18n: t("espece.genre"),
            component: (key, i18n, setValue, onRemove) => <TextFilter data-testid="genre-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, genre: x }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && value.genre === "") return t("espece.genre") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, genre: undefined }))
        },
        {
            key: "nomespece",
            i18n: t("espece.nomespece"),
            component: (key, i18n, setValue, onRemove) => <TextFilter data-testid="nomespece-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, nomespece: x }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && value.nomespece === "") return t("espece.nomespece") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, nomespece: undefined }))
        },
        {
            key: "cultivar",
            i18n: t("espece.cultivar"),
            component: (key, i18n, setValue, onRemove) => <TextFilter data-testid="cultivar-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, cultivar: x }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && value.cultivar === "") return t("espece.cultivar") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, cultivar: undefined }))
        },
        {
            key: "code",
            i18n: t("espece.code"),
            component: (key, i18n, setValue, onRemove) => <TextFilter data-testid="code-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, code: x }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && value.code === "") return t("espece.code") + t("QueryForm.empty") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, code: undefined }))
        },
        {
            key: "remplacementid",
            i18n: t("vegetal.remplacementid"),
            component: (key, i18n, setValue, onRemove) => <NumberFilter data-testid="remplacementid-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, remplacementid: x }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && !value.remplacementid) return t("vegetal.remplacementid") + t("QueryForm.empty") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, remplacementid: undefined }))
        },
        {
            key: "typeespece",
            i18n: t("filters.type"),
            component: (key, i18n, setValue, onRemove) => <DomaineFilter data-testid="typeespece-input" key={key} text={i18n} domaineServiceType={Container.get(TypeEspeceService)} onChange={(x) => setValue(prev => ({ ...prev, typeespece: { id: x.id } }))} onRemove={onRemove} />,
            invalid: () => undefined,
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, typeespece: undefined }))
        },
        {
            key: "etatsante",
            i18n: t("vegetal.etatsante"),
            component: (key, i18n, setValue, onRemove) => <DomaineFilter data-testid="etatsante-input" key={key} text={i18n} domaineServiceType={Container.get(EtatSanteService)} onChange={(x) => setValue(prev => ({ ...prev, etatsante: { id: x.id } }))} onRemove={onRemove} />,
            invalid: () => undefined,
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, etatsante: undefined }))
        },
        {
            key: "raisonmortalite",
            i18n: t("vegetal.raisonmortalite"),
            component: (key, i18n, setValue, onRemove) => <DomaineFilter data-testid="raisonmortalite-input" key={key} text={i18n} domaineServiceType={Container.get(RaisonMortaliteService)} onChange={(x) => setValue(prev => ({ ...prev, raisonmortalite: { id: x.id } }))} onRemove={onRemove} />,
            invalid: () => undefined,
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, raisonmortalite: undefined }))
        },
        {
            key: "formatpot",
            i18n: t("produit.formatpot"),
            component: (key, i18n, setValue, onRemove) => <DomaineFilter data-testid="formatpot-input" key={key} text={i18n} domaineServiceType={Container.get(FormatpotService)} onChange={(x) => setValue(prev => ({ ...prev, formatpot: { id: x.id } }))} onRemove={onRemove} />,
            invalid: () => undefined,
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, formatpot: undefined }))
        },
        ...BaseForm(t)] as Array<SearchParameter<SearchVegetalParams>>, [t])
    const { set } = useTabStack()

    const search = useCallback(async () => {
        const data = await service.search.searchForIds(params)
        set(new ModelListViewInfo(VegetalResources, data, true))
    }, [params, set, service])

    return (
        <QueryForm onSearch={search} parameters={parameters} value={params} onChange={setParams} onClose={onClose} />
    )
})