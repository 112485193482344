import {
    Popover,
    PopoverTrigger,
    IconButton,
    Icon,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    VStack,
    Checkbox,
    Radio,
    RadioGroup,
    Stack,
    Card,
    CardBody,
    useDisclosure,
    Button,
} from "@chakra-ui/react";
import { useCallback, useContext, useMemo, useState } from "react";
import { useAsyncEffect } from "react-shared";
import { IoLayersSharp } from "react-icons/io5";
import { MapContext } from "../../contexts/map.context";
import { MapLayer } from "../map-wrapper/map-handle.interface";
import { environment } from "../../config/environment";
import { useTranslation } from "react-i18next";

type ChckedLayer = {
    layer: MapLayer;
    checked: boolean;
}
export function Layers() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { mapHandle } = useContext(MapContext)
    const [layers, _setLayers] = useState<Array<ChckedLayer>>([])
    const categories = useMemo(() => Array.from(new Set(layers.map(x => x.layer.name.split(environment.mapLayerSplit)[0]))), [layers])
    const layersByCategory = useMemo(() => categories.map(c => ({ category: c, layers: layers.filter(l => l.layer.name.includes(c)) })), [categories, layers])
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const [clearMapLoading, setClearMapLoading] = useState(false)

    const setLayers = useCallback((action: (_prev: ChckedLayer[]) => ChckedLayer[]) => {
        _setLayers(prev => {
            const updatedLayers = action(prev)
            setLoading(true)
            mapHandle?.setVisibleLayers(updatedLayers.filter(l => l.checked).map(l => l.layer)).then(() => {
                setLoading(false)
            })
            return updatedLayers
        })
    }, [_setLayers, mapHandle])

    useAsyncEffect(async () => {
        if (!mapHandle) return
        _setLayers(mapHandle.layers.map(layer => ({ layer, checked: layer.defaultVisibility })))
    }, [mapHandle, _setLayers])

    const onCheckboxChange = useCallback((layer: { layer: MapLayer, checked: boolean }) => {
        setLayers(prev => prev.map(p => p.layer.name === layer.layer.name ? { ...p, checked: !p.checked } : p))
    }, [setLayers])

    const onRadioChange = useCallback((categoryName: string, layerName: string) => {
        setLayers(prev => prev.map(p => p.layer.name.includes(categoryName) ? ({ layer: p.layer, checked: false }) : p).map(p => p.layer.name === layerName ? ({ layer: p.layer, checked: true }) : p))
    }, [setLayers])

    const toggleCategory = useCallback((categoryName: string, value: boolean) => {
        if (!value)
            setLayers(prev => prev.map(p => p.layer.name.includes(categoryName) ? ({ layer: p.layer, checked: false }) : p))
        else
            onRadioChange(categoryName, categoryName)
    }, [setLayers, onRadioChange])

    const onClearMap = useCallback(async () => {
        setClearMapLoading(true)
        try {
            await mapHandle?.clear()
        }
        catch (e) {
            console.error(e)
        }
        setClearMapLoading(false)
    }, [mapHandle])


    return (
        <>
            <Popover isLazy isOpen={isOpen} onOpen={onOpen} onClose={onClose} boundary={document.body}>
                <PopoverTrigger>
                    <IconButton isLoading={loading} className="full-opacity" boxShadow={"lg"} fontSize="20px" icon={< Icon as={IoLayersSharp} />} aria-label='layers'></IconButton >
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody p={4} pb={12} maxHeight={"calc(100vh - 124px)"} overflowY={"auto"}>
                        <VStack alignItems={"stretch"} spacing={"4"}>
                            <Button isLoading={clearMapLoading} loadingText={t("layers.clearMap")} isDisabled={loading} onClick={onClearMap}>{t("layers.clearMap")}</Button>
                            {layersByCategory.map((c) => {
                                const categoryActive = c.layers.some(l => l.checked)
                                if (c.layers.length === 1) return (
                                    <Checkbox key={c.layers[0].layer.name} isDisabled={loading} isChecked={c.layers[0].checked} onChange={() => onCheckboxChange(c.layers[0])}>{c.layers[0].layer.name}</Checkbox>
                                )
                                else return (
                                    <Card key={c.category}>
                                        <CardBody>
                                            <VStack alignItems={"start"}>
                                                <Checkbox mb={1} isDisabled={loading} isChecked={categoryActive} onChange={(e) => toggleCategory(c.category, e.target.checked)}>{c.category}</Checkbox>
                                                <RadioGroup isDisabled={loading} value={c.layers.find(l => l.checked)?.layer.name} onChange={(value) => onRadioChange(c.category, value)}>
                                                    <Stack spacing={4} direction='column'>
                                                        {c.layers.map(x => (
                                                            <Radio key={x.layer.name} value={x.layer.name}>
                                                                {x.layer.name.replace(c.category + environment.mapLayerSplit, "").replace(c.category, "Base")}
                                                            </Radio>
                                                        ))}
                                                    </Stack>
                                                </RadioGroup>
                                            </VStack>
                                        </CardBody>
                                    </Card>
                                )
                            })}
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    )
}