import { DOMAINE_TYPE } from "../enums/domaine.enum";
import { PARENT_TYPE } from "../enums/parent-type.enum";
import { Model } from "../models/base/model";
import { getParentModel } from "./parent-type-to-model";
import { MetadataStorage } from "./metadata-storage";
import { METADATA_TAG } from "../enums/metadata-tag.enum";

export class MetadataHelper {

    public static getDomainesTypesFromModel(target: new () => Model): Array<DOMAINE_TYPE> {
        const types = MetadataStorage.getByClass(METADATA_TAG.DOMAINES_TAG, target) ?? []
        return types
    }

    public static getParentTypeFromModel(target: new () => Model): PARENT_TYPE {
        const result = MetadataStorage.getByClass(METADATA_TAG.PARENT_TAG, target)
        return result
    }

    public static getDomainesTypesFromParentType(parentType: PARENT_TYPE): Array<DOMAINE_TYPE> {
        const modelClass = getParentModel(parentType)
        const target = modelClass
        const result = MetadataStorage.getByClass(METADATA_TAG.DOMAINES_TAG, target) || []
        return result
    }
}