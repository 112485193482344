import { EspeceService, ProduitService } from "soverdi-api-client"
import { Container } from "typedi"
import { Catalog } from "./catalog.model"

export class CatalogService {

    async get(id: number): Promise<Catalog> {
        const produitService = Container.get(ProduitService)
        const especeService = Container.get(EspeceService)
        const catalog = new Catalog()
        catalog.especes = await especeService.getAllIds()
        catalog.produits = await produitService.getAllIds()
        return catalog
    }

}