import { memo, useCallback, useMemo } from "react";
import { Virtuoso } from "react-virtuoso";
import { ListItemPlaceholder } from "../model-list-item/list-item.placeholder";
import { Box } from "@chakra-ui/react";

const arr = new Array(10).fill(false)
interface IProps {
    style?: any
    scrollParent?: HTMLElement
}
export const ModelListLoading = memo(({ style, scrollParent }: IProps) => {

    const prerenderedItems = useMemo(() => {
        return arr.map((x, i) => (
            <Box data-testid="model-list-item-placeholder" mb={4} key={i}>
                <ListItemPlaceholder />
            </Box>
        ))
    }, [])

    const getPrerenderedListItem = useCallback((i: number) => (prerenderedItems[i]), [prerenderedItems])

    return (
        <Virtuoso
            style={style}
            customScrollParent={scrollParent}
            data={prerenderedItems}
            itemContent={getPrerenderedListItem}
        />
    )
})