import { ROLES } from "../enums/roles.enum"
import { Adresse } from "../models/adresse.model"

export const soverdiHeadquarters = [-73.595310801, 45.526189499]
export const SoverdiPalette = ["#009486", "#35AB87", "#61C183", "#8FD67C", "#C2E874", "#F9F871"]

export const AUTHENTIFICATION_GOAZIMUT_APPNAME = "Soverdi"

export const DatePrintOptions = { day: 'numeric', month: 'numeric', year: 'numeric' }

export interface RestRoles {
    getOne: Array<ROLES>,
    getSome: Array<ROLES>,
    getAll: Array<ROLES>,
    create: Array<ROLES>,
    update: Array<ROLES>,
    delete: Array<ROLES>
}

export const ADMINS = [ROLES.admin]
export const MANAGERS = [...ADMINS, ROLES.chargeProjet, ROLES.partenaire]
export const READ = [...ADMINS, ROLES.chargeProjet, ROLES.terrain]
export const ALL = [ROLES.admin, ROLES.chargeProjet, ROLES.partenaire, ROLES.terrain]
export const ChangeParentResourceRoles = [...ADMINS, ROLES.chargeProjet]

export const restDefaultRoles = {
    getOne: ALL,
    getSome: ALL,
    getAll: [...ADMINS, ROLES.chargeProjet],
    create: MANAGERS,
    update: MANAGERS,
    delete: ADMINS
}

export const restDomaineDefaultRoles = {
    getOne: ALL,
    getSome: ALL,
    getAll: ALL,
    create: MANAGERS,
    update: MANAGERS,
    delete: ADMINS
}

export const searchDefaultRoles = {
    searchForEntities: READ,
    searchForIds: READ,
    filter: ALL
}

export const vegetauxDefaultRoles = {
    vegetauxForEntityIds: ALL,
    exportVegetauxForEntityIds: MANAGERS,
    exportInterventionsForEntityIds: MANAGERS
}

export const bulkDefaultRoles = {
    edit: MANAGERS,
    delete: ADMINS
}

export const autocompleteDefaultRoles = {
    autocomplete: ALL
}

export const assignationDefaultRoles = {
    assignMe: MANAGERS,
    unassignMe: MANAGERS,
    assign: MANAGERS,
    unassign: MANAGERS,
    unassignAll: MANAGERS
}

export const environment = {
    formTags: {
        UAPMQ: "UAPMQ"
    },
    soverdiAdresse: {
        printableformat: "5333 Avenue Casgrain, Montreal",
        numerodeporte: "5333",
        typerue: "Avenue",
        nomrue: "Casgrain",
        arrondissement: "Mile-End",
        ville: "Montreal",
        mrc: "Agglomération de Montréal",
        codepostal: "H2T 1X3"
    } as Adresse,
    soverdiHeadquarters: [-73.595310801, 45.526189499],
    endpoints: {
        generics: {
            rest: {
                base: "/rest",
                getSome: "/some",
                getAllIds: "/allids"
            },
            vegetaux: {
                base: "/vegetaux",
                in: "/in",
                exportvegetaux: "/exportvegetaux",
                exportvegetauxinterventions: "/exportinterventions"
            },
            assignation: {
                base: "/assignations",
                assignMe: "/assignme",
                unassignMe: "/unassignme",
                assign: "/assign",
                unassign: "/unassign",
                unassignAll: "/unassignall"
            },
            autocomplete: {
                base: "/autocomplete"
            },
            bulk: {
                base: "/bulk",
                edit: "/edit",
                delete: "/delete"
            },
            search: {
                base: "/search",
                searchForIds: "/ids",
                filter: "/filter"
            },
        },
        import: {
            base: "/import",
            inventory: {
                route: "/inventory",
                roles: ADMINS
            },
            vegetaux: {
                route: "/vegetaux",
                roles: ADMINS
            },
            interventions: {
                route: "/interventions",
                roles: ADMINS
            }
        },
        geocoder: {
            base: "/geocoder",
            findAddressCandidates: {
                route: "/findaddresscandidates",
                roles: ALL
            }
        },
        user: {
            base: "/users",
            rest: restDefaultRoles,
            bulk: bulkDefaultRoles,
            search: searchDefaultRoles,
            autocomplete: { autocomplete: MANAGERS },
            me: {
                route: "/me",
                roles: ALL
            }
        },
        espece: {
            base: "/especes",
            rest: {
                ...restDefaultRoles,
                create: ADMINS,
                update: ADMINS,
                getAll: ALL
            },
            search: searchDefaultRoles,
            bulk: bulkDefaultRoles,
            autocomplete: autocompleteDefaultRoles,
            vegetaux: vegetauxDefaultRoles
        },
        produit: {
            base: "/produits",
            rest: {
                ...restDefaultRoles,
                create: MANAGERS,
                update: MANAGERS,
                getAll: ALL
            },
            search: searchDefaultRoles,
            bulk: bulkDefaultRoles,
            autocomplete: autocompleteDefaultRoles,
            vegetaux: vegetauxDefaultRoles
        },
        projet: {
            base: "/projets",
            rest: { ...restDefaultRoles, create: [ROLES.admin, ROLES.chargeProjet] },
            bulk: bulkDefaultRoles,
            search: searchDefaultRoles,
            assignation: assignationDefaultRoles,
            vegetaux: vegetauxDefaultRoles,
            autocomplete: {
                autocomplete: READ
            }
        },
        site: {
            base: "/sites",
            rest: restDefaultRoles,
            bulk: bulkDefaultRoles,
            search: searchDefaultRoles,
            assignation: assignationDefaultRoles,
            vegetaux: vegetauxDefaultRoles
        },
        evenement: {
            base: "/evenements",
            rest: restDefaultRoles,
            bulk: bulkDefaultRoles,
            search: searchDefaultRoles,
            assignation: assignationDefaultRoles,
            vegetaux: vegetauxDefaultRoles
        },
        commande: {
            base: "/commandes",
            rest: restDefaultRoles,
            bulk: bulkDefaultRoles,
            vegetaux: vegetauxDefaultRoles,
            search: searchDefaultRoles
        },
        intervention: {
            base: "/interventions",
            rest: { ...restDefaultRoles, create: ALL, update: ALL },
            bulk: bulkDefaultRoles,
            search: searchDefaultRoles,
            assignation: assignationDefaultRoles
        },
        vegetal: {
            base: "/vegetaux",
            rest: { ...restDefaultRoles, create: ALL, update: ALL },
            bulk: bulkDefaultRoles,
            search: searchDefaultRoles,
            vegetaux: vegetauxDefaultRoles
        },
        files: {
            get: "/files",
            post: "/upload"
        },
        testing: {
            base: "/testing",
            bootstrapProjet: "/bootstrapprojet",
            bootstrapSite: "/bootstrapsite",
            bootstrapInventory: "/bootstrapinventory",
            bootstrapEvenement: "/bootstrapevenement",
            clean: "/clean"
        },
        domaines: {
            default: {
                autocomplete: autocompleteDefaultRoles,
                rest: restDomaineDefaultRoles
            },
            entiteadministrative: {
                base: "/entitesadministratives"
            },
            calibre: {
                base: "/calibres"
            },
            etatsante: {
                base: "/etatssante"
            },
            presence: {
                base: "/presences"
            },
            dommage: {
                base: "/dommages"
            },
            cuvette: {
                base: "/cuvettes"
            },
            formatpot: {
                base: "/formatspot"
            },
            fournisseur: {
                base: "/fournisseurs"
            },
            raisonmortalite: {
                base: "/raisonsmortalite"
            },
            reseau: {
                base: "/reseaux"
            },
            territoire: {
                base: "/territoires"
            },
            typeespece: {
                base: "/typesespece"
            },
            typeevenement: {
                base: "/typesevenement"
            },
            typeintervention: {
                base: "/typesintervention"
            },
            tag: {
                base: "/tags"
            },
        }
    }
}