import { getService } from "api-client-shared"
import { memo, useCallback, useContext, useState } from "react";
import { GrUserWorker } from "react-icons/gr"
import { useStatusToast } from "react-shared";
import { IAssignationService, UserService } from "soverdi-api-client";
import { useTranslation } from "react-i18next"
import { AssignableModel, Model, User, assignationDefaultRoles } from "soverdi-api-models";
import { BulkActionProps } from "./bulk-action.props";
import {
    Box,
    Button,
    HStack,
    Icon,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    VStack,
    useDisclosure,
} from "@chakra-ui/react";
import { AppContext } from "../../../app.context";
import { AutocompleteInput } from "../../inputs/autocomplete.input";

function AssignBulkActionComponent<T extends Model & AssignableModel>({ service, data, onChange }: BulkActionProps<T, IAssignationService<T>>) {

    const { t } = useTranslation()
    const toast = useStatusToast()
    const userService = getService(UserService)
    const { token } = useContext(AppContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState<User | undefined>()

    const onCancel = useCallback(() => {
        setUser(undefined)
        onClose()
    }, [setUser, onClose])

    const onSubmit = useCallback(async () => {
        if (!user) return
        setLoading(true)
        try {
            await service.assignation.assign(user.id as number, { ids: data })
            toast.onSuccess(t("BulkActions.soustraitantAlert"), "save")
            if (!onChange) return
            onChange(prev => [...prev])
            onClose()
        }
        catch (e) {
            toast.onError(t("BulkActions.soustraitantAlert"), "save")
        }
        setLoading(false)
    }, [service, toast, t, data, onChange, setLoading, user, onClose])

    return (
        <Box>
            {assignationDefaultRoles.assign.includes(token!.role) &&
                <>
                    <Popover isOpen={isOpen}>
                        <PopoverTrigger>
                            <Button data-testid="assign-someone-button" onClick={onOpen} rightIcon={<Icon as={GrUserWorker} />}>{t("BulkActions.soustraitant")}</Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverBody>
                                <VStack width={"full"} alignItems={"start"}>
                                    <AutocompleteInput
                                        data-testid="autocomplete-selector-input"
                                        service={userService} onChange={setUser}
                                        value={user}
                                        placeholder={t("User.username")}
                                        inputMinLength={0}
                                    />
                                    <HStack mt={2} width={"full"} justifyContent={"space-between"}>
                                        <Button data-testid="assign-cancel-button" onClick={onCancel}>{t("common.cancel")}</Button>
                                        <Button isLoading={loading} loadingText={t("common.confirm")} data-testid="assign-confirm-button" isDisabled={!user || loading} onClick={onSubmit} >{t("common.confirm")}</Button>
                                    </HStack>
                                </VStack>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </>
            }
        </Box>
    )
}

export const AssignBulkAction = memo(AssignBulkActionComponent) as typeof AssignBulkActionComponent