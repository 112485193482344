import { VStack, InputGroup, Input, Button, HStack, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from "@chakra-ui/react"
import { RestService } from "api-client-shared"
import { ChangeEvent, memo, useCallback, useMemo, useRef, useState } from "react"
import { IAutocompleteService } from "soverdi-api-client"
import { Domaine, DOMAINE_TYPE } from "soverdi-api-models"
import { DomaineBadge } from "../../domaine-badge.component"
import { EmptyList } from "../../empty-list.component"
import { CustomInputProps } from "../input.props"
import { useTranslation } from "react-i18next"
import { useAsyncEffect, useDebounce, useRoutingDisclosure, useStatusToast } from "react-shared"
import { environment } from "../../../config/environment"
import { useErrorHandler } from "../../model-forms/hooks/error-handler"

export interface DomaineSelectorIProps<T extends Domaine | Domaine[]> extends Pick<CustomInputProps<T, RestService<Domaine, any, any> & IAutocompleteService<Domaine>>, "value" | "service"> {
    domaineType: DOMAINE_TYPE,
    multiple?: boolean,
    onChange: (value: T | undefined) => void
}
function DomaineSelectorComponent<T extends Domaine | Domaine[]>({ value, service, onChange, domaineType, multiple }: DomaineSelectorIProps<T>) {

    const values = useMemo(() => value as Domaine[] || [], [value])
    const onValuesChange = useMemo(() => onChange as (value: Domaine[]) => void, [onChange])
    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: _onCloseDelete } = useRoutingDisclosure(`/delete`, { openDelay: 200 })
    const [loading, setLoading] = useState(true)
    const [domaines, setDomaines] = useState<Array<Domaine>>([])
    const [nom, setNom] = useState("")
    const debouncedInput = useDebounce(nom, environment.debounceDelay)
    const createAllowed = useMemo(() => nom && !domaines.some(d => d.nom === nom), [domaines, nom])
    const { t } = useTranslation()
    const [deleteRequest, setDeleteRequest] = useState<Domaine | undefined>()
    const cancelRef = useRef(null)
    const { onError } = useStatusToast()
    const handleError = useErrorHandler()

    const setNomFromInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setNom(e.target.value.toLowerCase())
    }, [setNom])

    const addDomaine = useCallback((d: Domaine) => {
        multiple ? onValuesChange([...values, d]) : onChange(d as T)
    }, [onChange, multiple, onValuesChange, values])

    const createDomaine = useCallback(async () => {
        try {
            setLoading(true)
            const d = new Domaine()
            d.nom = nom
            const created = await service.create(d)
            setNom("")
            multiple ? onValuesChange([...values, created]) : onChange(created as T)
        }
        catch (e) {
            handleError(domaineType as string, e)
        }
        finally {
            setLoading(false)
        }
    }, [nom, setNom, service, domaineType, handleError, onChange, multiple, onValuesChange, values])


    const onDeleteRequest = useCallback((d: Domaine) => {
        setDeleteRequest(d)
        onOpenDelete()
    }, [setDeleteRequest, onOpenDelete])

    const onCloseDelete = useCallback(async () => {
        setDeleteRequest(undefined)
        _onCloseDelete()
    }, [setDeleteRequest, _onCloseDelete])

    const onDeleteConfirm = useCallback(async () => {
        setLoading(true)
        try {
            await service.delete(deleteRequest?.id as number)
            setDomaines(prev => prev.filter(p => p.id !== deleteRequest?.id))
            if (multiple && values.some(v => v.id === deleteRequest?.id)) {
                onValuesChange(values.filter(v => v.id !== deleteRequest?.id))
            }
            else if (value === deleteRequest?.id) onChange(undefined)
        }
        catch (e: any) {
            console.error(e)
            const table = e.response.data.table
            onError(deleteRequest!.nom, "delete", `${t("models." + table).toLowerCase()}${t("DeleteAction.foreignkey")}`)
        }
        setLoading(false)
        setDeleteRequest(undefined)
        onCloseDelete()
    }, [deleteRequest, service, setLoading, setDeleteRequest, onError, t, setDomaines, onChange, multiple, onCloseDelete, onValuesChange, value, values])


    useAsyncEffect(async () => {
        const result = debouncedInput ? await service.autocomplete.autocomplete(debouncedInput) : await service.getAll()
        setDomaines(result)
        setLoading(false)
    }, [debouncedInput, debouncedInput])

    return (
        <>
            <VStack width={"full"} alignItems={"stretch"} spacing={"4"}>
                {!loading && domaines.length === 0 && <EmptyList sm />}
                <InputGroup>
                    <Input placeholder={t("common.search")} data-testid="create-domaine-input" value={nom} onChange={setNomFromInput} />
                </InputGroup>
                {createAllowed && <Button flexGrow={1} isLoading={loading} data-testid="create-domaine-button" onClick={createDomaine}>{`${t("common.create")} ${nom}`}</Button>}
                <HStack className="domaine-selector" flexWrap={"wrap"} maxHeight={"30vh"} overflowY={"scroll"}>
                    {((loading) ? [undefined, undefined, undefined] : domaines).map((d, i) => <DomaineBadge key={d?.id || i} domaine={d} onClick={() => addDomaine(d!)} onDeleteClick={() => onDeleteRequest(d!)} />)}
                </HStack>
            </VStack>
            {
                deleteRequest &&
                <AlertDialog
                    isOpen={isOpenDelete}
                    onClose={onCloseDelete}
                    leastDestructiveRef={cancelRef}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                {t("Domaine.deleteConfirmTitle") + ` <${deleteRequest?.nom.toUpperCase()}>`}
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                {t("Domaine.deleteConfirmBody")}
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button data-testid="cancel-delete-button" ref={cancelRef} onClick={onCloseDelete}>
                                    {t("common.cancel")}
                                </Button>
                                <Button data-testid="confirm-delete-button" colorScheme='red' onClick={onDeleteConfirm} ml={3}>
                                    {t("common.delete")}
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            }
        </>
    )
}

export const DomaineSelector = memo(DomaineSelectorComponent) as typeof DomaineSelectorComponent