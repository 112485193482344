import { CommandeService, EvenementService, ProjetService, SiteService } from "soverdi-api-client"
import { PARENT_TYPE } from "soverdi-api-models"
import { Container } from "typedi"

export function getParentTypeService(type: PARENT_TYPE) {
    switch (type) {
        case PARENT_TYPE.PROJET:
            return Container.get(ProjetService)
        case PARENT_TYPE.SITE:
            return Container.get(SiteService)
        case PARENT_TYPE.EVENEMENT:
            return Container.get(EvenementService)
        case PARENT_TYPE.COMMANDE:
            return Container.get(CommandeService)
    }
}