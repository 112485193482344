import { Type } from "class-transformer";
import { IsArray, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { Point } from "../../models/geojson/point.geojson";
import { IsGeoJSON } from "../../validators/geojson.validator";
import { Model } from "../../models/base/model";
import { Params } from "./_params.type";
import { Vegetal } from "../../models/vegetal.model";

export class VegetalParams implements Params<Vegetal, "produit"> {
    @IsNumber()
    idcommande!: number

    @ValidateNested()
    @IsOptional()
    @Type(() => Model)
    etatsante?: Model

    @IsOptional()
    @ValidateNested()
    @Type(() => Model)
    raisonmortalite?: Model

    @IsString()
    commentaires: string = ""

    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => Model)
    photos: Model[] = new Array()

    @IsOptional()
    @IsNumber()
    remplacementid?: number

    @IsGeoJSON()
    @ValidateNested()
    @Type(() => Point)
    geometry!: Point

    @IsOptional()
    @ValidateNested()
    @Type(() => Model)
    presence?: Model

    @IsOptional()
    @ValidateNested()
    @Type(() => Model)
    cuvette?: Model

    @IsOptional()
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => Model)
    dommages?: Model[]
    
    @IsOptional()
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => Model)
    tags?: Array<Model>
}