import { useMemo } from "react";
import { GOAzimutConfig } from "../../config/goazimut.config";

interface GOnetURLParams { hostname?: string, gde?: string, geoprojetShareId?: string, restoreSession?: boolean, timestamp?: Date, language: string, mapFullscreen?: boolean, useIdentifyInsteadOfSelect?: boolean, showAdjacentMunicipalities?: boolean }
export function useGOnetURL(codemuni: string, { hostname, gde, geoprojetShareId: geoprojet, restoreSession, timestamp, language, mapFullscreen, useIdentifyInsteadOfSelect, showAdjacentMunicipalities }: GOnetURLParams) {
    return useMemo(() => {
        const host = window.location.host.includes("localhost") ? "gonet.goazimut.com" : window.location.host
        var url = new URL(hostname ? hostname : "https://" + host + GOAzimutConfig.GONET.BASE);
        url.searchParams.append(GOAzimutConfig.GONET.PARAMETERS.CODE_MUNI, codemuni)
        url.searchParams.append(GOAzimutConfig.GONET.PARAMETERS.PUBLIC, !!gde ? "0" : "1")
        url.searchParams.append(GOAzimutConfig.GONET.PARAMETERS.IFRAMEMODE, "1")
        url.searchParams.append(GOAzimutConfig.GONET.PARAMETERS.LANG, language)
        geoprojet && url.searchParams.append(GOAzimutConfig.GONET.PARAMETERS.GEOPROJECT, geoprojet)
        gde && url.searchParams.append(GOAzimutConfig.GONET.PARAMETERS.GDE, gde)
        url.searchParams.append(GOAzimutConfig.GONET.PARAMETERS.RESTORE_SESSION, restoreSession ? "1" : "0")

        if (mapFullscreen || useIdentifyInsteadOfSelect || showAdjacentMunicipalities) {
            url.searchParams.append(GOAzimutConfig.GONET.PARAMETERS.DEBUG, "1")
        }
        if (GOAzimutConfig.GONET.PARAMETERS.mapFullscreen)
            url.searchParams.append(GOAzimutConfig.GONET.PARAMETERS.mapFullscreen, "1")
        if (useIdentifyInsteadOfSelect)
            url.searchParams.append(GOAzimutConfig.GONET.PARAMETERS.forceIndentifyInsteadOfSelect, "1")
        if (showAdjacentMunicipalities)
            url.searchParams.append(GOAzimutConfig.GONET.PARAMETERS.municipalites, "1")

        //this timestamp is used to reload the url in case of a restart request
        if (timestamp)
            url.searchParams.append("reloadTimestamp", timestamp.getMilliseconds().toString())
        //to test clipboard with localhost
        //return "http://localhost:5173"
        return url.toString()
    }, [codemuni, gde, geoprojet, restoreSession, timestamp])
}
