import { Transform } from "class-transformer";
import { IsDate, IsOptional } from "class-validator";
import { CreateInterventionParams } from "./create-intervention.params"
import { Intervention } from "../../models/intervention.model";
import { Params } from "./_params.type";

export class UpdateInterventionParams extends CreateInterventionParams implements Params<Intervention>{
    @IsOptional()
    @IsDate()
    @Transform(({ value }) => value ?? new Date(value), { toClassOnly: true })
    datedepassage: Date | undefined
}