import i18next from "i18next";
import { useInit } from "../../hooks/init.hook";

export function addTranslations() {
    useInit(() => {
        i18next.addResourceBundle("en", "translation", {
            StatusProvider: {
                successsave: " was successfully saved.",
                errorsave: " could not be saved.",
                successdelete: " was successfully deleted.",
                errordelete: " could not be deleted.",
                successget: " was successfully found.",
                errorget: " could not be found."
            }
        })

        i18next.addResourceBundle("fr", "translation", {
            StatusProvider: {
                successsave: " sauvegardé(es) avec succès.",
                errorsave: " n'ont pas pu être sauvegardé(es).",
                successdelete: " supprimé(es) avec succès.",
                errordelete: " n'ont pas pu être supprimé(es).",
                successget: " ont été trouvé(es) avec succès.",
                errorget: " n'ont pas pu être trouvé(es)."
            }
        })
    })
}