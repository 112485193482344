import { FormComponent } from "../types/form-component.type";
import { InputProps } from "../props/input.props";
import { ReactNode } from "react";

export class FormConfig<FormDataType> {
    components: Array<FormComponent<FormDataType, any>> = []

    add<ComponentType extends (props: any) => ReactNode>(component: FormComponent<FormDataType, ComponentType>) {
        this.components.push(component)
        return this
    }
}