import { DisclosuredProps, ModalSheet, ModalSheetBody } from "react-shared";
import { FC, memo, useCallback } from "react";
import { Projet } from "soverdi-api-models";
import { ModalHeader } from "../../modal/modal-header.component";
import { useTabStack } from "../../../hooks/tab-stack/tab-stack.hook";
import { ProjetResources } from "../../../model-resources/projet.resources";
import { ModelViewInfo } from "../../../hooks/tab-stack/tab-stack-item.interface";
import { useTranslation } from "react-i18next";
import { CustomForm } from "../../model-forms/form.component";

export const CreateProject: FC<DisclosuredProps> = memo(({ isOpen, onClose }: DisclosuredProps) => {

    const { t } = useTranslation()
    const { set } = useTabStack()

    const onCreateProjet = useCallback((p: Projet) => {
        onClose()
        set(new ModelViewInfo(ProjetResources, p.id as number))
    }, [onClose, set])

    return (
        <ModalSheet isOpen={isOpen} onClose={onClose} full header={<ModalHeader title={t("SearchActions.createProject")} actions={[]} />}>
            <ModalSheetBody>
                <CustomForm
                    tabIdentifier={ProjetResources.tabIdentifier}
                    service={ProjetResources.service}
                    model={new Projet()}
                    FormSubmitType={ProjetResources.CreateParams}
                    onClose={onClose}
                    onChange={onCreateProjet}
                />
            </ModalSheetBody>
        </ModalSheet>
    )
})