import { t } from "i18next";
import { ModelWithMeta, SearchableModel } from "soverdi-api-models";

export class Catalog extends ModelWithMeta implements SearchableModel {

    id = 1
    get nom() { return t("models.catalog") }
    produits!: Array<number>
    especes!: Array<number>
    public get searchBy() { return this.nom }
    public get subtext() { return [{ value: "" }] }
    public get domaines() { return [] }
    public get domaineTypes() { return [] }
}