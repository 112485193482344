import { Service, Inject } from "typedi";
import { AuthService } from "./auth.service";
import axios from "axios";
import { GeolocationResult, IGeolocationResult } from "portail-api-models";
import { environment } from "../config/_environment";
import { plainToClass } from "class-transformer";
import { PortailAPIUrl } from "../utils/portail-api-url";

@Service()
export class ServicesService {

    @Inject()
    private url!: PortailAPIUrl

    constructor(@Service() private auth: AuthService) { }

    async geolocate(x: number, y: number): Promise<GeolocationResult> {
        await this.auth.beforeRequest()
        const res = await axios.get<IGeolocationResult>(this.url.join([environment.API.ENDPOINTS.geolocate]), { params: { x, y } })
        const clazzed = plainToClass(GeolocationResult, res.data)
        return clazzed
    }

}