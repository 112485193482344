import { IsOptional, ValidateNested } from "class-validator";
import { Type } from "class-transformer";
import { BetweenDates } from "../between-dates.params"
import { Model } from "../../models/base/model";

export abstract class SearchParams {

    @IsOptional()
    @Type(() => BetweenDates)
    @ValidateNested()
    betweenDates?: BetweenDates

    @IsOptional()
    @ValidateNested({ each: true })
    tags?: Array<Model>
}