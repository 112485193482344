import { IsOptional, IsPostalCode, IsString } from "class-validator";
import { Model } from "./base/model";

export class Adresse extends Model {
    @IsString()
    printableformat!: string
    @IsString()
    numerodeporte!: string
    @IsString()
    @IsOptional()
    typerue?: string
    @IsString()
    nomrue!: string
    @IsString()
    @IsOptional()
    arrondissement?: string
    @IsString()
    ville!: string
    @IsString()
    mrc!: string
    @IsPostalCode("CA")
    @IsString()
    codepostal!: string
}