import { ModelResources } from "./model.resources";
import { EvenementService, InterventionService } from "soverdi-api-client";
import { TabIdentifier } from "../hooks/tab-stack/tab-identifier.enum";
import { DeleteBulkAction } from "../components/menu-actions/bulk-actions/delete.bulk-action";
import { CreateInterventionParams, Intervention, UpdateInterventionParams, environment } from "soverdi-api-models";
import { Container } from "typedi"
import { GiWateringCan } from "react-icons/gi"
import { AssignMeBulkAction } from "../components/menu-actions/bulk-actions/assignme.bulk-action";
import { AssignBulkAction } from "../components/menu-actions/bulk-actions/assign.bulk-action";
import { MarkCompleteBulkAction } from "../components/menu-actions/bulk-actions/mark-complete.bulk-action";
import { CustomForm } from "../components/model-forms/form.component";
import { ChangeParentAction } from "../components/menu-actions/actions/change-parent.action";
import { SetTagsAction } from "../components/menu-actions/bulk-actions/set-tags.bulk-action";

class InterventionResourcesClass extends ModelResources<Intervention> {
    form = CustomForm
    get service() { return Container.get(InterventionService) }
    get parentService() { return Container.get(EvenementService) }

    icon = GiWateringCan
    bulkActions = [SetTagsAction, MarkCompleteBulkAction, AssignMeBulkAction, AssignBulkAction, DeleteBulkAction]
    actions = [ChangeParentAction]
    tabIdentifier = TabIdentifier.intervention
    lists = [];
    roles = { rest: environment.endpoints.intervention.rest };
    Model = Intervention
    CreateParams = CreateInterventionParams
    UpdateParams = UpdateInterventionParams
}

export const InterventionResources = new InterventionResourcesClass()