import { ModelResources } from "./model.resources";
import { ProduitService } from "soverdi-api-client";
import { TabIdentifier } from "../hooks/tab-stack/tab-identifier.enum";
import { Container } from "typedi"
import { Produit, ProduitParams, environment } from "soverdi-api-models";
import { DeleteBulkAction } from "../components/menu-actions/bulk-actions/delete.bulk-action";
import { FaBarcode } from "react-icons/fa"
import { CustomForm } from "../components/model-forms/form.component";

class ProduitResourcesClass extends ModelResources<Produit> {
    form = CustomForm
    get service() { return Container.get(ProduitService) }
    get parentService() { return undefined }

    icon = FaBarcode
    bulkActions = [DeleteBulkAction]
    actions = []
    tabIdentifier = TabIdentifier.produit
    lists = []
    roles = { rest: environment.endpoints.produit.rest };
    Model = Produit
    CreateParams = ProduitParams
    UpdateParams = ProduitParams
}

export const ProduitResources = new ProduitResourcesClass()