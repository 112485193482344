import { IsNumber, IsOptional, ValidateNested } from "class-validator";
import { CreateEvenementParams } from "./create-evenement.params"
import { Evenement } from "../../models/evenement.model";
import { ModelWithMeta } from "../../models/base/model-with-meta";
import { FormableModel } from "../../models/composition/formable.model";
import { SearchableModel } from "../../models/composition/searchable.model";
import { Params } from "./_params.type";
import { Type, Transform } from "class-transformer";
import { Model } from "../../models/base/model";

export class UpdateEvenementParams extends CreateEvenementParams implements Params<Evenement, "progres"> {
    @IsOptional()
    @ValidateNested({ each: true })
    @Type(() => Model)
    @Transform(({ value }) => (!value || value[0]?.id) ? value : value.map((v: number) => ({ id: v } as Model)))
    commandes: Array<Model> | undefined

    @IsOptional()
    @ValidateNested({ each: true })
    @Type(() => Model)
    @Transform(({ value }) => (!value || value[0]?.id) ? value : value.map((v: number) => ({ id: v } as Model)))
    interventions: Array<Model> | undefined
}