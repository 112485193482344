import axios from "axios"
import { plainToInstance } from "class-transformer"
import { CompositionService } from "api-client-shared"
import { environment } from "soverdi-api-models"

export class AutocompleteService<T> extends CompositionService<T> {

    async autocomplete(value: string): Promise<T[]> {
        const res = await axios.get<T[]>(this.url.join([this.endpoint, environment.endpoints.generics.autocomplete.base, value]))
        const clazzed = plainToInstance(this.modelClazz, res.data)
        return clazzed
    }
}

export interface IAutocompleteService<T> {
    readonly autocomplete: AutocompleteService<T>
}
