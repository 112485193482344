import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Button,
    Icon,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Skeleton,
    Text,
    VStack,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons"
import { memo, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Parent } from "soverdi-api-models/src/models/parent.model"
import { getParentTypeService } from "../../utils/parent-type-to-service"
import { ModelWithParent, SearchableModel } from "soverdi-api-models"
import { DomaineBadge } from "../domaine-badge.component"
import { IoMdOpen } from "react-icons/io";
import { useTabStack } from "../../hooks/tab-stack/tab-stack.hook"
import { getParentTypeResources } from "../../utils/parent-type-to-resources"
import { ModelViewInfo } from "../../hooks/tab-stack/tab-stack-item.interface";
import { ModelResources } from "../../model-resources/model.resources";
import { ChakraColor } from "../../types/chakra-color";

interface IProps {
    parent: Parent
}
function HierarchyItem({ parent }: IProps) {

    const { t } = useTranslation()
    const [model, setModel] = useState<SearchableModel | undefined>()
    const { set } = useTabStack()

    const onPeek = useCallback(async () => {
        const service = getParentTypeService(parent.type)
        const result = await service.get(parent.id as number)
        setModel(result)
    }, [setModel, parent])

    const onOpen = useCallback(() => {
        const resources = getParentTypeResources(parent.type)
        set(new ModelViewInfo(resources as ModelResources<any>, parent.id))
    }, [parent, set])

    return (
        <>
            {parent.parent && <HierarchyItem parent={parent.parent} />}
            <BreadcrumbItem>
                {parent.parent && <BreadcrumbSeparator mx="1" color="gray.300">
                    <ChevronRightIcon color='gray.500' />
                </BreadcrumbSeparator>
                }
                <Popover isLazy>
                    <PopoverTrigger>
                        <BreadcrumbLink onClick={onPeek}>{t("models." + parent.type)}</BreadcrumbLink>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>{model ? model.searchBy : (<Skeleton width="100px" height="20px" noOfLines={1} />)}</PopoverHeader>
                        <PopoverBody>
                            <VStack gap={2} alignItems={"start"}>
                                {model?.domaines.filter(d => !d.options?.hide).map(x => (<DomaineBadge size="md" key={x.type + x.domaine.id} color={x.options?.color as ChakraColor | undefined} domaine={x.domaine} />))}
                                {model ?
                                    <Text>{model.subtext.map(subtext => (subtext.i18nprefix ? t(subtext.i18nprefix) + " " : "") + subtext.value).join(", ")}</Text>
                                    : (<Skeleton width="100px" height="20px" noOfLines={1} />)}
                                <VStack mt={2} width="full" alignItems={"end"}>
                                    <Button data-testid="hierarchy-parent-button" onClick={onOpen} rightIcon={<Icon as={IoMdOpen} />}>{t("common.open")}</Button>
                                </VStack>
                            </VStack>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </BreadcrumbItem>
        </>
    )
}

interface IHierarchyProps {
    model: ModelWithParent & SearchableModel
}
function HierarchyComponent({ model }: IHierarchyProps) {

    return (
        <Breadcrumb>
            {model.parent && <HierarchyItem parent={model.parent} />}
            <BreadcrumbItem minWidth={0} isCurrentPage>
                <BreadcrumbSeparator mx="1" color="gray.300">
                    <ChevronRightIcon color='gray.500' />
                </BreadcrumbSeparator>
                <BreadcrumbLink className="ellipsis">
                    {model.searchBy}
                </BreadcrumbLink>
            </BreadcrumbItem>
        </Breadcrumb>
    )
}

export const Hierarchy = memo(HierarchyComponent) as typeof HierarchyComponent