import { Select } from "@chakra-ui/react"
import i18next, { t } from "i18next"
import { useCallback, ChangeEvent } from "react"

interface IProps {
    setLanguage: (value: string) => void
}
export function LanguageSwitcher({ setLanguage }: IProps) {

    const changeLanguage = useCallback(async (e: ChangeEvent<HTMLSelectElement>) => {
        setLanguage(e.target.value)
    }, [setLanguage])

    return (
        <Select onChange={changeLanguage} defaultValue={i18next.language} >
            <option value='fr'> Français </option>
            <option value='en'> English </option>
        </Select>
    )
}