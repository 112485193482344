import { useGOnetURL, useInitSync } from "react-shared";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import i18next from "i18next";
import { LoadingOverlay } from "./loading-overlay.component";
import { GOnetHandle, useGOnetMessengerInParent } from "@goazimut/gonet-messenger";
import { useAsyncEffect } from "../../hooks/async-effect.hook"

export interface GOnetIProps {
    code: string
    restoreSession?: boolean,
    gde?: string,
    geoprojetShareId?: string
    mapFullscreen?: boolean
    useIdentifyInsteadOfSelect?: boolean
    hostname?: string
    onHandshake?: (handle: GOnetHandle) => void
}
function GOnet({ code, restoreSession, gde, geoprojetShareId, mapFullscreen, hostname, useIdentifyInsteadOfSelect, onHandshake: onReady }: GOnetIProps) {

    const [timestamp] = useState(new Date())
    const url = useGOnetURL(code, { hostname, gde, geoprojetShareId, restoreSession: restoreSession ? true : false, timestamp, language: i18next.language, useIdentifyInsteadOfSelect })
    const ref = useRef<HTMLIFrameElement>(null)
    const [ready, setReady] = useState(false);
    const [handle, setHandle] = useState<GOnetHandle | undefined>(undefined)

    useAsyncEffect(async () => {
        if (!ref?.current) return
        const _handle = await useGOnetMessengerInParent(ref.current)
        setHandle(_handle)
    }, [ref, setHandle])

    const full = useMemo(() => ({ width: "100%", height: "100%" }), [])

    const iframeRender = useMemo(() => {
        return (
            <iframe allow="geolocation" className="gonet-iframe" style={full} ref={ref} title={code} src={url}>
            </iframe >
        )
    }, [code, url])

    useInitSync(() => {
        if (!ref?.current || onReady) return
        ref.current.onload = function (e) {
            setReady(true)
        }
    })

    useEffect(() => {
        if (!handle || !onReady) return
        setReady(true)
        onReady(handle)
    }, [handle, onReady])

    return (
        <>
            <LoadingOverlay show={!ready} />
            {iframeRender}
        </>
    )
}

export default memo(GOnet) as typeof GOnet