import { Service, Inject } from "typedi";
import { RestService } from "api-client-shared";
import { CreateSiteParams, SearchSiteParams, Site, UpdateSiteParams } from "soverdi-api-models";
import { environment } from "soverdi-api-models";
import { SoverdiAPIUrl } from "../utils/soverdi-api-url";
import { SOVERDI_API_HOSTNAME_TOKEN } from "../tokens/soverdi-api-hostname.token";
import { IAutocompleteService, AutocompleteService } from "./composition/autocomplete.service";
import { IBulkService, BulkService } from "./composition/bulk.service";
import { ISearchService, SearchService } from "./composition/search-service";
import { IVegetauxService, VegetauxService } from "./composition/vegetaux.service";
import { AssignationService, IAssignationService } from "./composition/assignation.service";


@Service()
export class SiteService extends RestService<Site, CreateSiteParams, UpdateSiteParams> implements IBulkService<Site, never>, IAutocompleteService<Site>, ISearchService<Site, SearchSiteParams>, IVegetauxService, IAssignationService<Site> {
 
    modelClazz = Site
    @Inject()
    protected url!: SoverdiAPIUrl
    endpoint = environment.endpoints.site.base

    readonly search = new SearchService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly autocomplete = new AutocompleteService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly bulk = new BulkService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly vegetaux = new VegetauxService(this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly assignation = new AssignationService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    
}