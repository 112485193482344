export enum TabIdentifier {
    favorites = "favorites",
    projet = "projet",
    site = "site",
    evenement = "evenement",
    intervention = "intervention",
    commande = "commande",
    produit = "produit",
    espece = "espece",
    catalog = "catalog",
    vegetal = "vegetal",
    user = "user"
}