import { ValidationError as _ValidationError } from "class-validator"
interface SimplifiedValidationError {
    property: string,
    value: any
    constraints: string[]
}
export class ValidationError {

    errors: SimplifiedValidationError[]

    constructor(data: { errors: _ValidationError[] }) {
        this.errors = data.errors.map(x => this.handleValidationError(x)).flat()
    }

    private handleValidationError(e: _ValidationError): SimplifiedValidationError[] {
        if (e.children && e.children.length > 0)
            return e.children?.map(x => this.handleValidationError(x)).flat()
        return [{ property: e.property, value: e.value, constraints: Object.keys(e.constraints!) }]
    }
}