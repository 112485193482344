export enum DOMAINE_TYPE {
    ENTITE_ADMINISTRATIVE = "ENTITE_ADMINISTRATIVE",
    TERRITOIRE = "TERRITOIRE",
    CALIBRE = "CALIBRE",
    ETAT_SANTE = "ETAT_SANTE",
    FORMAT_POT = "FORMAT_POT",
    FOURNISSEUR = "FOURNISSEUR",
    RAISON_MORTALITE = "RAISON_MORTALITE",
    RESEAU = "RESEAU",
    TYPE_ESPECE = "TYPE_ESPECE",
    TYPE_EVENEMENT = "TYPE_EVENEMENT",
    TYPE_INTERVENTION = "TYPE_INTERVENTION",
    INTERVENTION_PROGRESS = "INTERVENTION_PROGRESS",
    PRESENCE = "PRESENCE",
    DOMMAGE = "DOMMAGE",
    CUVETTE = "CUVETTE",
    TAG = "TAG"
}