import { useTranslation } from "react-i18next";
import { QueryForm } from "./query-form.component";
import { memo, useCallback, useMemo, useState } from "react";
import { SearchInterventionParams } from "soverdi-api-models";
import { BaseForm } from "../base-form";
import { getService } from "api-client-shared";
import { InterventionService, TypeInterventionService } from "soverdi-api-client";
import { useTabStack } from "../../../../hooks/tab-stack/tab-stack.hook";
import { ModelListViewInfo } from "../../../../hooks/tab-stack/tab-stack-item.interface";
import { DomaineFilter } from "../form-controls/domaine-filter.component";
import { Container } from "typedi"
import { InterventionResources } from "../../../../model-resources/intervention.resources";
import { YearFilter } from "../form-controls/year-filter.component";
import { DateRangeFilter } from "../form-controls/date-range-filter.component";
import { SearchParameter } from "./search-parameters.interface";

interface IProps {
    onClose: () => void
}
export const InterventionQueryForm = memo(({ onClose }: IProps) => {
    const { t } = useTranslation()
    const [params, setParams] = useState(new SearchInterventionParams())
    const service = getService(InterventionService)
    const parameters = useMemo(() => [
        {
            key: "typeintervention",
            i18n: t("filters.type"),
            component: (key, i18n, setValue, onRemove) => <DomaineFilter data-testid="typeintervention-input" key={key} text={i18n} domaineServiceType={Container.get(TypeInterventionService)} onChange={(x) => setValue(prev => ({ ...prev, typeintervention: { id: x.id } }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && !value.typeintervention) return t("filters.type") + t("QueryForm.empty") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, typeintervention: undefined }))
        },
        {
            key: "passageyear",
            i18n: t("intervention.passageyear"),
            component: (key, i18n, setValue, onRemove) => <YearFilter key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, betweenDatesDatePassage: x }))} onRemove={onRemove} />,
            invalid: () => undefined,
            onClear: ({ setValue, filters }) => { if (!filters.some(f => f.key === "betweenDatesDatePassage")) setValue(prev => ({ ...prev, betweenDatesDatePassage: undefined })) }
        },
        {
            key: "betweenDatesDatePassage",
            i18n: t("intervention.passagebetween"),
            component: (key, i18n, setValue, onRemove) => <DateRangeFilter key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, betweenDatesDatePassage: x }))} onRemove={onRemove} />,
            invalid: ({ filters }) => { if (filters.some(f => (f.key as any) === "passageyear")) return t("QueryForm.between") },
            onClear: ({ setValue, filters }) => { if (!filters.some(f => (f.key as any) === "passageyear")) setValue(prev => ({ ...prev, betweenDatesDatePassage: undefined })) }
        },
        ...BaseForm(t)] as Array<SearchParameter<SearchInterventionParams>>, [t])
    const { set } = useTabStack()

    const search = useCallback(async () => {
        const data = await service.search.searchForIds(params)
        set(new ModelListViewInfo(InterventionResources, data, true))
    }, [params, set, service])

    return (
        <QueryForm onSearch={search} parameters={parameters} value={params} onChange={setParams} onClose={onClose} />
    )
})