import { urlJoin } from 'url-join-ts';
import { Container, Token } from "typedi"

export class APIUrl {
    public join(paths: string[], options?: { queryParams?: Array<[string, string]>, ids?: Array<number> }) {
        const urlString = urlJoin(this.baseUrl, ...paths)
        const url = new URL(urlString)
        if (options?.queryParams)
            options.queryParams.forEach(q => url.searchParams.append(q[0], q[1]))
        if (options?.ids)
            options.ids.forEach(q => url.searchParams.append("ids", q.toString()))
        const cool = url.toString()
        return cool
    }

    constructor(private urlInjectionToken: Token<string>) { }

    protected get baseUrl() { return Container.get(this.urlInjectionToken) as string }
}