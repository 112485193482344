import { useCallback, useContext } from "react";
import { Model, Projet, Site, Vegetal } from "soverdi-api-models";
import { useTabStack } from "./tab-stack/tab-stack.hook";
import { ProjetService, SiteService, VegetalService } from "soverdi-api-client";
import { Container } from "typedi";
import { VegetalResources } from "../model-resources/vegetal.resources";
import { SiteResources } from "../model-resources/sites.resources";
import { ProjetResources } from "../model-resources/projet.resources";
import { OnSelectParams } from "../components/map-wrapper/map-handle.interface";
import { ModelListViewInfo, ModelViewInfo } from "./tab-stack/tab-stack-item.interface";
import { ModelResources } from "../model-resources/model.resources";
import { ModalLockContext } from "../contexts/modal-lock.context";

export function useGOnetEventsHandler() {

    const { set } = useTabStack()
    const { lock } = useContext(ModalLockContext)

    const getLayerResources = useCallback((clazz: new () => Model) => {
        switch (clazz) {
            case Vegetal:
                return { service: Container.get(VegetalService), resources: VegetalResources as ModelResources<any> }
            case Site:
                return { service: Container.get(SiteService), resources: SiteResources as ModelResources<any> }
            case Projet:
                return { service: Container.get(ProjetService), resources: ProjetResources as ModelResources<any> }
            default:
                throw new Error(`Layer ${clazz} could not be mapped to a model`)
        }
    }, [])

    const onMapSelection = useCallback(async ({ clazz, ids }: OnSelectParams) => {
        if (lock) return
        try {
            if (ids.length === 0) return
            const { resources } = getLayerResources(clazz)
            if (ids.length === 1) set(new ModelViewInfo(resources, ids[0] as number))
            else {
                set(new ModelListViewInfo(resources, ids))
            }
        }
        catch (e) {

        }
    }, [getLayerResources, set, lock])

    return { onMapSelection }
}