import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Alert, AlertIcon, AlertTitle, Badge, Box, Button, Card, CardBody, HStack, Icon, Text } from "@chakra-ui/react";
import { ChangeEvent, memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import csv from "csvtojson";
import { plainToInstance } from "class-transformer";
import { getMetadataStorage, validate } from "class-validator"
import { environment } from "../../../config/environment";
import { useDownload } from "../../../hooks/download";
import { TbTemplate } from "react-icons/tb";
import { normalizeText } from "normalize-text"
import isUtf8 from 'isutf8';

interface IProps {
    title: string
    description?: string
    csvClass: new () => any
    onChange: (value: string | undefined) => void
}
export const CSVUpload = memo(({ title, description, onChange, csvClass }: IProps) => {

    const { t } = useTranslation()
    const properties = useMemo(() => {
        const storage = getMetadataStorage();
        const validatorsMetadata = storage.getTargetValidationMetadatas(csvClass, '', true, false);
        const properties = Array.from(new Set(validatorsMetadata.map(v => v.propertyName))).map(property => ({
            property,
            constraints: validatorsMetadata.filter(v => v.propertyName === property && v.name !== "isNotEmpty")
        }))
        return properties.map(p => ({ ...p, optional: p.constraints.some(c => c.type === "conditionalValidation") }))
    }, [csvClass])
    const format = useMemo(() => {
        return properties.map((x, i) => <Badge colorScheme={x.optional ? "yellow" : "green"} textTransform="lowercase" key={x.property} p={1} borderRadius={4}>{`${x.property} (${t("import." + x.constraints.find(c => c.name !== undefined)!.name)})`}</Badge>)
    }, [properties, t])
    const download = useDownload()
    const [error, setError] = useState<string | undefined>(undefined)
    const [estimate, setEstimate] = useState<number | undefined>(undefined)

    const onDownloadTemplate = useCallback(() => {
        download(format.map(x => x.key).join(","), `template-${normalizeText(title)}-${Date.now()}.csv`)
    }, [download, format, title])

    const changeHandler = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
        setError(undefined)
        const { files } = e.target;
        if (!files || !files[0]) {
            onChange(undefined)
            return
        }
        const csvcontent = await files[0].text()

        const lines = csvcontent.split("\n")
        if (lines.length < 2) {
            setError(t("import.errors.nodata"))
            return
        }
        
        const buff = await files[0].arrayBuffer()
        const uint8array = new Uint8Array(buff)
        if (!isUtf8(uint8array)) {
            setError(t("import.errors.encoding"))
            return
        }

        const json = await csv().fromString(csvcontent)
        const missing = properties.filter(p => !p.optional && json.some(x => x[p.property] === undefined))
        if (missing.length > 0) {
            setError(`${t("import.errors.missing")} ${missing.map(e => e.property).join(", ")}`)
            return
        }
        
        let i = 1   
        for (let item of json) {
            const clazzed = plainToInstance(csvClass, item)
            const errors = await validate(clazzed)
            if (errors.length > 0) {
                setError(`${t("import.errors.validation") + i}: ${errors.map(e => `${e.property} "${item[e.property]}" ${t("import.errors." + Object.keys(e.constraints as any)[0])}`).join(", ")}`)
                return
            }
            i++
        }

        setEstimate(Math.ceil((lines.length - 1) * environment.processingTimePerVegetalCSVEntry))
        onChange(csvcontent)
    }, [onChange, csvClass, t, properties])

    return (
        <Box>
            <FormLabel>{t("import.csv")}</FormLabel>
            <HStack mb={4} gap={2} flexWrap={"wrap"}>{format}</HStack>
            <Card mb={4}>
                <CardBody p={3}>
                    <HStack mb={2} flexWrap={"wrap"} overflowX={"hidden"}>
                        <Text>{t("import.legende")}</Text>
                        <Badge colorScheme={"yellow"} p={1} px={2} borderRadius={4}>{t("import.optional")}</Badge>
                        <Badge colorScheme={"green"} p={1} px={2} borderRadius={4}>{t("import.required")}</Badge>
                    </HStack>
                    <HStack mb={4} flexWrap={"wrap"} overflowX={"hidden"}>
                        <Text>{t("import.formats")}</Text>
                        <Badge p={1} px={2}>{t("import.date")}</Badge>
                        <Badge p={1} px={2}>{t("import.latlon")}</Badge>
                    </HStack>
                    <HStack>
                        <Button leftIcon={<Icon as={TbTemplate} />} onClick={onDownloadTemplate}>{t("import.download")}</Button>
                    </HStack>
                </CardBody>
            </Card>

            {description && <Alert mb={4} status="info"><AlertIcon /><AlertTitle>{description}</AlertTitle></Alert>}
            <FormControl>
                <Input data-testid="csv-input" p="5px" type="file" onChange={changeHandler} accept=".csv" />
            </FormControl>
            {error && <Text mt={2} data-testid="csv-upload-error" color="red">{error}</Text>}
            {
                estimate &&
                <Alert mt={6} data-testid="csv-import-estimate" status='success'>
                    <AlertIcon />
                    {`${t("common.estimate")} ${estimate} ${t("time.minutes")}`}
                </Alert>
            }
        </Box >
    )
})