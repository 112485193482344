import { ChildResources, ModelResources } from "./model.resources";
import { EvenementService, SiteService } from "soverdi-api-client";
import { BsCalendar4Event } from "react-icons/bs";
import { TabIdentifier } from "../hooks/tab-stack/tab-identifier.enum";
import { InterventionResources } from "./intervention.resources";
import { Container } from "typedi"
import { Commande, CreateEvenementParams, Evenement, Intervention, UpdateEvenementParams, environment } from "soverdi-api-models";
import { CommandeResources } from "./commande.resources";
import { instanceToInstance } from "class-transformer"
import { AssignMeBulkAction } from "../components/menu-actions/bulk-actions/assignme.bulk-action";
import { DeleteBulkAction } from "../components/menu-actions/bulk-actions/delete.bulk-action";
import { AssignBulkAction } from "../components/menu-actions/bulk-actions/assign.bulk-action";
import { ShowVegetauxBulkAction } from "../components/menu-actions/bulk-actions/show-vegetaux.bulk-action";
import { CustomForm } from "../components/model-forms/form.component";
import { ExportVegetauxBulkAction } from "../components/menu-actions/bulk-actions/export-vegetaux.bulk-action";
import { ExportInterventionsBulkAction } from "../components/menu-actions/bulk-actions/export-interventions.bulk-action";
import { ChangeParentAction } from "../components/menu-actions/actions/change-parent.action";
import { ScanAction } from "../components/menu-actions/actions/scan.action";
import { SetTagsAction } from "../components/menu-actions/bulk-actions/set-tags.bulk-action";

class EvenementResourcesClass extends ModelResources<Evenement> {
    form = CustomForm
    get service() { return Container.get(EvenementService) }
    get parentService() { return Container.get(SiteService) }

    icon = BsCalendar4Event
    bulkActions = [
        SetTagsAction,
        AssignMeBulkAction,
        AssignBulkAction,
        ShowVegetauxBulkAction,
        ExportVegetauxBulkAction,
        ExportInterventionsBulkAction,
        DeleteBulkAction]
    actions = [ScanAction, ChangeParentAction]
    tabIdentifier = TabIdentifier.evenement
    lists = [
        {
            resources: InterventionResources,
            get: (me) => me?.interventions,
            set: (me, values) => {
                me.interventions = values
                return instanceToInstance(me)
            }
        } as ChildResources<Evenement, Intervention>,
        {
            resources: CommandeResources,
            get: (me) => me?.commandes,
            set: (me, values) => {
                me.commandes = values
                return instanceToInstance(me)
            },
            subtitle: (me, t) => {
                return me?.progres ? `${t("commande.planted")}: ${me.progres}` : ""
            }
        } as ChildResources<Evenement, Commande>
    ];
    roles = { rest: environment.endpoints.evenement.rest };
    Model = Evenement
    CreateParams = CreateEvenementParams
    UpdateParams = UpdateEvenementParams
}

export const EvenementResources = new EvenementResourcesClass()