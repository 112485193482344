import { Service } from "typedi";
import axios from "axios";
import { environment } from "../config/_environment";

@Service()
export class ReportService {

    async post(app: string, userId: string, reportType: string, message: string) {
        const text = `App: ${app}\nUser: ${userId}\nReport type: ${reportType}\n\n${message}`
        await axios.post(environment.USER_REPORT.SLACK_WEBHOOK_URL, JSON.stringify({ text }), { headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: undefined } })
    }
}