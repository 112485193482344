import { Type } from "class-transformer"
import { IsArray, IsNotEmpty, IsNumber, IsOptional, ValidateNested } from "class-validator";
import { Model } from "../../models/base/model";
import { Commande } from "../../models/commande.model";
import { Params } from "./_params.type";

export class CreateCommandeParams implements Params<Commande, "vegetaux"> {

    @IsNumber()
    @IsNotEmpty()
    quantite!: number

    @IsNumber()
    @IsNotEmpty()
    idevenement!: number

    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Model)
    produit!: Model

    @IsOptional()
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => Model)
    tags?: Array<Model>
}