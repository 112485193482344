import { Service, Inject } from "typedi";
import { RestService } from "api-client-shared";
import { CreateEvenementParams, Evenement, SearchEvenementParams, UpdateEvenementParams } from "soverdi-api-models";
import { environment } from "soverdi-api-models";
import { SoverdiAPIUrl } from "../utils/soverdi-api-url";
import { SOVERDI_API_HOSTNAME_TOKEN } from "../tokens/soverdi-api-hostname.token";
import { IAutocompleteService, AutocompleteService } from "./composition/autocomplete.service";
import { IBulkService, BulkService } from "./composition/bulk.service";
import { IVegetauxService, VegetauxService } from "./composition/vegetaux.service";
import { AssignationService, IAssignationService } from "./composition/assignation.service";
import { ISearchService, SearchService } from "./composition/search-service";

@Service()
export class EvenementService extends RestService<Evenement, CreateEvenementParams, UpdateEvenementParams> implements IBulkService<Evenement, never>, IAutocompleteService<Evenement>, IVegetauxService, IAssignationService<Evenement>, ISearchService<Evenement, SearchEvenementParams>{
    modelClazz = Evenement
    @Inject()
    protected url!: SoverdiAPIUrl
    endpoint = environment.endpoints.evenement.base

    readonly bulk = new BulkService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly autocomplete = new AutocompleteService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly vegetaux = new VegetauxService(this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly assignation = new AssignationService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly search = new SearchService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
}