import { IsOptional, ValidateNested } from "class-validator";
import { CreateCommandeParams } from "./create-commande.params";
import { Commande } from "../../models/commande.model";
import { Params } from "./_params.type";
import { Type, Transform } from "class-transformer";
import { Model } from "../../models/base/model";

export class UpdateCommandeParams extends CreateCommandeParams implements Params<Commande> {
    @IsOptional()
    @ValidateNested({ each: true })
    @Type(() => Model)
    @Transform(({ value }) => (!value || value[0]?.id) ? value : value.map((v: number) => ({ id: v } as Model)))
    vegetaux?: Array<Model>
}