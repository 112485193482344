import { PARENT_TYPE } from "soverdi-api-models"
import { ProjetResources } from "../model-resources/projet.resources"
import { SiteResources } from "../model-resources/sites.resources"
import { EvenementResources } from "../model-resources/evenement.resources"
import { CommandeResources } from "../model-resources/commande.resources"

export function getParentTypeResources(type: PARENT_TYPE) {
    switch (type) {
        case PARENT_TYPE.PROJET:
            return ProjetResources
        case PARENT_TYPE.SITE:
            return SiteResources
        case PARENT_TYPE.EVENEMENT:
            return EvenementResources
        case PARENT_TYPE.COMMANDE:
            return CommandeResources
    }
}