import { Type } from "class-transformer";
import { IsArray, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { Point } from "../../models/geojson/point.geojson";
import { IsGeoJSON } from "../../validators/geojson.validator";
import { Model } from "../../models/base/model";
import { Site } from "../../models/site.model";
import { Params } from "./_params.type";
import { AdresseParams } from "./adresse-params";

export class CreateSiteParams implements Params<Site, "adresse" | "evenements">  {

    @IsString()
    @IsNotEmpty()
    nom!: string

    @IsNumber()
    idprojet!: number

    @ValidateNested()
    @Type(() => Model)
    @IsNotEmpty()
    reseau!: Model

    @ValidateNested()
    @Type(() => AdresseParams)
    @IsNotEmpty()
    adresse!: AdresseParams

    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Model)
    entiteadministrative!: Model

    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Model)
    territoire!: Model

    @IsGeoJSON()
    @ValidateNested()
    @Type(() => Point)
    geometry!: Point

    @ValidateNested({ each: true })
    @Type(() => Model)
    users!: Array<Model>
    
    @IsOptional()
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => Model)
    tags?: Array<Model>
}