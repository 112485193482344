import { SearchableModel, Model } from "soverdi-api-models";
import { ComponentType } from "react";
import { ModelResources } from "../../model-resources/model.resources";
import { IModelListProps } from "../../components/model-list/props/model-list.props";
import { IModelViewProps } from "../../components/modal-views/model.view";
import { FavoritesResources } from "../../model-resources/favorites.resources";

export interface ViewInfo {
    readonly as: "ModelView" | "ModelListView" | "Favorites"
    readonly key: string
    readonly resources: ModelResources<any>
    readonly full?: boolean
}

export class ModelViewInfo<T extends Model & SearchableModel> implements ViewInfo {
    readonly as = "ModelView"
    get key() { return this.as + this.modelid }
    constructor(readonly resources: ModelResources<T>, readonly modelid: number, readonly full?: boolean, readonly onChange?: (value: T) => void) { }
};

export class ModelListViewInfo<T extends Model & SearchableModel> implements ViewInfo {
    readonly as = "ModelListView"
    get key() { return this.as + this.data?.join() }
    constructor(readonly resources: ModelResources<T>, readonly data: Array<number>, readonly full?: boolean,) { }
};

export class FavoritesInfo implements ViewInfo {
    readonly resources = FavoritesResources
    readonly as = "Favorites"
    readonly key = "favorites"
    readonly full = true
};

export type ViewCompatibleModel = (Model & SearchableModel) | (Model & SearchableModel)[]
export type ViewCompatibleComponent = ComponentType<IModelListProps<any>> | ComponentType<IModelViewProps<any>>