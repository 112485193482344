export const GOAzimutConfig = {
    GONET: {
        BASE: "/GOnet6/",
        PARAMETERS: {
            CODE_MUNI: "m",
            PUBLIC: "pl",
            GDE: "gde",
            LANG: "lang",
            IFRAMEMODE: "gdm",
            GEOPROJECT: "geoprojet",
            RESTORE_SESSION: "reload",
            DEBUG: "d",
            mapFullscreen: "f_mapFullscreen",
            municipalites: "f_municipalityAdjacent",
            forceIndentifyInsteadOfSelect: "f_infoIdentify"
        }
    },
    GOREQUETE: {
        BASE: ""
    },
    GOGFS: {
        BASE: ""
    },
}