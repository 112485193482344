import { Type } from "class-transformer"
import { IsNotEmpty, IsOptional, IsString, ValidateNested } from "class-validator"
import { Model } from "../../models/base/model"
import { Params } from "./_params.type"
import { Espece } from "../../models/espece.model"
import { SoverdiPalette } from "../../config/environment"

export class EspeceParams implements Params<Espece>{
    /** Code abbreviation du nom latin */
    @IsString()
    @IsNotEmpty()
    code!: string

    @IsString()
    @IsNotEmpty()
    genre!: string

    @IsString()
    @IsNotEmpty()
    nomespece!: string

    @IsString()
    @IsOptional()
    cultivar?: string

    @IsString()
    @IsNotEmpty()
    couleur: string = SoverdiPalette[0]

    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Model)
    typeespece!: Model
}