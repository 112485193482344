import { IsNumber, IsString } from "class-validator";
import { Model } from "./base/model";
import { SearchableModel } from "./composition/searchable.model";
import { Domaine } from "./domaine.model";

export class UserAsDomaine extends Domaine {

}

export class User extends Model implements SearchableModel {

    @IsNumber()
    id!: number

    @IsString()
    username!: string

    get searchBy(): string { return this.username }
    public get subtext() { return [] }
    get domaines() { return [] }

}