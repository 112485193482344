import { memo, useCallback, useContext, useState } from "react";
import { Intervention, InterventionBulkEditParams, bulkDefaultRoles } from "soverdi-api-models";
import { useTranslation } from "react-i18next";
import { useStatusToast } from "react-shared";
import { IBulkService } from "soverdi-api-client";
import { BulkActionProps } from "./bulk-action.props";
import { AppContext } from "../../../app.context";
import { FaRegCheckCircle } from "react-icons/fa";
import { Button, Icon } from "@chakra-ui/react";

function MarkCompleteBulkActionComponent({ service, onChange, data }: BulkActionProps<any, IBulkService<Intervention, InterventionBulkEditParams>>) {

    const { t } = useTranslation()
    const salert = useStatusToast()
    const { token } = useContext(AppContext)
    const [loading, setLoading] = useState(false)

    const onClick = useCallback(async () => {
        setLoading(true)
        try {
            const ids = data.map(d => d)
            const now = new Date()
            await service.bulk.edit({ ids, body: { datedepassage: now } })
            if (onChange) onChange(prev => [...prev])
            salert.onSuccess(t("models.asList.intervention"), "save")
        }
        catch (e) {
            console.error(e)
            salert.onError(t("models.asList.intervention"), "save")
        }
        setLoading(false)
    }, [data, salert, t, onChange, service.bulk, setLoading])

    return (
        <>
            {bulkDefaultRoles.edit.includes(token!.role) &&
                <Button isLoading={loading} loadingText={t("markComplete.mark")} colorScheme="green" data-testid="mark-complete-button" onClick={onClick} rightIcon={<Icon as={FaRegCheckCircle} />}>{t("markComplete.mark")}</Button>
            }
        </>
    )

}

export const MarkCompleteBulkAction = memo(MarkCompleteBulkActionComponent) as typeof MarkCompleteBulkActionComponent
