import { memo, useCallback, useContext, useState } from "react";
import { useStatusToast } from "react-shared";
import { IAssignationService } from "soverdi-api-client";
import { useTranslation } from "react-i18next"
import { AssignableModel, Model, assignationDefaultRoles } from "soverdi-api-models";
import { BulkActionProps } from "./bulk-action.props";
import { AppContext } from "../../../app.context";
import { MdGroupRemove } from "react-icons/md";
import { Button, Icon } from "@chakra-ui/react";

function UnassignAllBulkActionComponent<T extends Model & AssignableModel>({ service, data, onChange }: BulkActionProps<T, IAssignationService<T>>) {

    const { t } = useTranslation()
    const toast = useStatusToast()
    const { token } = useContext(AppContext)
    const [loading, setLoading] = useState(false)

    const onSubmit = useCallback(async () => {
        setLoading(true)
        try {
            await service.assignation.unassignAll(data)
            toast.onSuccess(t("BulkActions.soustraitantAlert"), "save")
            if (!onChange) return
            onChange(prev => [...prev])
        }
        catch (e) {
            toast.onError(t("BulkActions.soustraitantAlert"), "save")
        }
        setLoading(false)
    }, [service, toast, t, data, onChange, setLoading])

    return (
        <>
            {assignationDefaultRoles.unassignAll.includes(token!.role) &&
                <Button isLoading={loading} loadingText={t("BulkActions.unassignAll")} data-testid="unassign-all-button" onClick={onSubmit} rightIcon={<Icon as={MdGroupRemove} />}>{t("BulkActions.unassignAll")}</Button >
            }
        </>
    )
}

export const UnassignAllBulkAction = memo(UnassignAllBulkActionComponent) as typeof UnassignAllBulkActionComponent