import { getService } from "api-client-shared"
import { memo, useCallback, useContext, useState } from "react";
import { useStatusToast } from "react-shared";
import { IBulkService, TagService } from "soverdi-api-client";
import { useTranslation } from "react-i18next"
import {
    DOMAINE_TYPE,
    Domaine,
    Model,
    TaggableBulkEditParams,
    assignationDefaultRoles,
} from "soverdi-api-models";
import { BulkActionProps } from "./bulk-action.props";
import {
    Box,
    Button,
    HStack,
    Icon,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    VStack,
    useDisclosure,
} from "@chakra-ui/react";
import { AppContext } from "../../../app.context";
import { FaTag } from "react-icons/fa6";
import { DomaineSelector } from "../../inputs/components/domaine-selector.component";
import { DomaineBadge } from "../../domaine-badge.component";


function SetTagsActionComponent<T extends Model>({ service, data, onChange }: BulkActionProps<T, IBulkService<T, TaggableBulkEditParams>>) {

    const { t } = useTranslation()
    const toast = useStatusToast()
    const { token } = useContext(AppContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loading, setLoading] = useState(false)
    const tagService = getService(TagService)
    const [domaines, setDomaines] = useState(new Array<Domaine>())

    const onRemoveBadge = useCallback((d: Domaine) => {
        setDomaines(prev => prev.filter(v => d.id !== v.id))
    }, [setDomaines])

    const onSubmit = useCallback(async () => {
        setLoading(true)
        try {
            const params = new TaggableBulkEditParams()
            params.body.tags = domaines
            params.ids = data
            await service.bulk.edit(params)
            toast.onSuccess(t("BulkActions.addTagAlert"), "save")
            if (!onChange) return
            onChange(prev => [...prev])
            onClose()
        }
        catch (e) {
            toast.onError(t("BulkActions.addTagAlert"), "save")
        }
        setLoading(false)
    }, [service, toast, t, data, onChange, setLoading, onClose, domaines])

    return (
        <Box>
            {assignationDefaultRoles.assign.includes(token!.role) &&
                <>

                    <Popover isOpen={isOpen}>
                        <PopoverTrigger>
                            <Button data-testid="set-tags-button" onClick={onOpen} rightIcon={<Icon as={FaTag} />}>{t("BulkActions.addtag")}</Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverBody>
                                <VStack width={"full"} alignItems={"start"}>

                                    <HStack data-testid="set-tags-list" spacing={1} flexWrap={"wrap"} overflowX={"hidden"}>
                                        {domaines.map(d => (<DomaineBadge key={d.id} domaine={d} size="sm" onClose={() => onRemoveBadge(d)} />))}
                                        {domaines.length === 0 &&
                                            <Text>{t("BulkActions.addTagEmpty")}</Text>
                                        }
                                    </HStack>

                                    <HStack my={2} width={"full"} justifyContent={"space-between"}>
                                        <Button data-testid="set-tags-cancel-button" onClick={onClose}>{t("common.cancel")}</Button>
                                        <Button isLoading={loading} loadingText={t("common.save")} data-testid="set-tags-confirm-button" onClick={onSubmit} >{t("common.save")}</Button>
                                    </HStack>
                                    <DomaineSelector multiple domaineType={DOMAINE_TYPE.TAG} value={domaines} onChange={setDomaines as any} service={tagService} />
                                </VStack>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </>
            }
        </Box>
    )
}

export const SetTagsAction = memo(SetTagsActionComponent) as typeof SetTagsActionComponent