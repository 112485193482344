import i18next from "i18next";
import { useInit } from "../hooks/init.hook";

export function addTranslations() {
    useInit(() => {
        i18next.addResourceBundle("en", "translation", {
            contact: {
                feature: {
                    title: "Request a feature",
                    placeholder: "Please describe as best as you can the feature you want to request"
                },
                bug: {
                    title: "Report a problem",
                    placeholder: "Please describe as best as you can the problem you encountered"
                },
                sent: "Your report was sent successfully. It will be processed by our team in the following days.",
                cancel: "Cancel",
                send: "Send"
            },
        })

        i18next.addResourceBundle("fr", "translation", {
            contact: {
                feature: {
                    title: "Demander une fonctionnalité",
                    placeholder: "Décrivez du mieux que vous pouvez la fonctionnalité demandée"
                },
                bug: {
                    title: "Signaler un problème",
                    placeholder: "Décrivez du mieux que vous pouvez le problème que vous avez rencontré"
                },
                sent: "Votre rapport à été envoyé avec succès. Il sera traité dans les jours qui suivent.",
                cancel: "Annuler",
                send: "Envoyer"
            },
        })
    })
}