import { IsOptional, IsString, ValidateNested } from "class-validator"
import { SearchParams } from "./search.params"
import { Type } from "class-transformer"
import { Model } from "../../models/base/model"
import { ISearchParams } from "./search-params.type"
import { Site } from "../../models/site.model"

export class SearchSiteParams extends SearchParams implements ISearchParams<Site, "adresse" | "idprojet"> {

    @IsOptional()
    @IsString()
    nom?: string

    @IsOptional()
    @ValidateNested()
    @Type(() => Model)
    reseau?: Model

    @IsOptional()
    @ValidateNested()
    @Type(() => Model)
    entiteadministrative?: Model

    @IsOptional()
    @ValidateNested()
    @Type(() => Model)
    territoire?: Model

}