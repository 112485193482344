import { Token } from "portail-api-client";
import { AUTHENTIFICATION_GOAZIMUT_APPNAME, ROLES } from "soverdi-api-models";

export class SoverdiToken {
    public readonly role: ROLES
    constructor(public readonly token: Token) {
        const soverdi = this.token.roles!.find((x) => x.app.description === AUTHENTIFICATION_GOAZIMUT_APPNAME)
        const roleKey = ROLES[soverdi!.name as any]
        const role = ROLES[roleKey as keyof typeof ROLES]
        this.role = role
    }
}