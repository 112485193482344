import { InputGroup, InputLeftElement, Input, InputRightElement, CloseButton, HStack, VStack, Spinner } from "@chakra-ui/react";
import { t } from "i18next";
import { DomainesSelector, TypedDomainesState } from "../../selectors/domaines.selector";
import { DOMAINE_TYPE, Domaine } from "soverdi-api-models";
import { ChangeEvent, Dispatch, SetStateAction, memo, useCallback, useMemo } from "react";
import { DomaineBadge } from "../../domaine-badge.component";

interface IProps {
    stringFilter: string
    onStringFilterChange: (value: string) => any
    domaineTypes: DOMAINE_TYPE[]
    domainefilters: TypedDomainesState[]
    setDomaineFilters: Dispatch<SetStateAction<TypedDomainesState[]>>
    loading?: boolean
}
export const ListFilters = memo(({ domaineTypes, onStringFilterChange, stringFilter, domainefilters, setDomaineFilters, loading }: IProps) => {

    const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        onStringFilterChange(e.target.value)
    }, [onStringFilterChange])

    const onSelectDomaine = useCallback((type: DOMAINE_TYPE, d: Domaine) => {
        setDomaineFilters(prev => prev.map(p => {
            if (p.type !== type) return p
            if (p.filters.map(f => f.id).includes(d.id)) return p
            return { ...p, filters: [...p.filters, d] }
        }))
    }, [setDomaineFilters])

    const onRemoveDomaine = useCallback((type: DOMAINE_TYPE, i: number) => {
        setDomaineFilters(prev => prev.map(p => {
            if (p.type !== type) return p
            return { ...p, filters: p.filters.filter((x, j) => i !== j) }
        }))
    }, [setDomaineFilters])

    const showDomaineFiltersList = useMemo(() => domainefilters.some(x => x.filters.length > 0), [domainefilters])

    return (
        <VStack width={"full"} alignItems={"start"}>
            <InputGroup zIndex={1}>
                {domaineTypes.length > 0 && (
                    <InputLeftElement>
                        <DomainesSelector types={domaineTypes} onSelect={onSelectDomaine} />
                    </InputLeftElement>
                )}
                <Input data-testid="model-list-filter-input" value={stringFilter} onChange={onInputChange} placeholder={t("common.search")} />
                <InputRightElement >
                    {loading ? <Spinner size={"md"} /> : <CloseButton variant={"ghost"} onClick={() => onStringFilterChange("")} />}
                </InputRightElement>
            </InputGroup>
            {showDomaineFiltersList &&
                <HStack flexWrap="wrap" mb={4} gap={2}>
                    {domainefilters.map((x) => x.filters.map((d, i) => (<DomaineBadge size="md" key={d.id} domaine={d} onClose={() => onRemoveDomaine(x.type, i)} />)))}
                </HStack>
            }
        </VStack>
    )
})