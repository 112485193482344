export const environment = {
    API: {
        BASE: "https://portail.goazimut.com/api/",
        ENDPOINTS: {
            token: "auth/token",
            refreshToken: "auth/refreshtoken",
            municipalites: "municipalites",
            geolocate: "services/geolocation",
            partageGeoprojets: "/geoprojets/partage/"
        }
    }
}