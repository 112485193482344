import { IsDate, IsNotEmpty, IsNumber, IsOptional, IsPostalCode, IsString } from "class-validator";
import { InventoryItemCSV } from "./inventory.csv"
import { Transform } from "class-transformer";
import { CSVModel } from "./_csv-model.type";
import { Vegetal } from "../models/vegetal.model";
import { Site } from "../models/site.model";
import { Adresse } from "../models/adresse.model";
import { Evenement } from "../models/evenement.model";
import { ParseAndValidateDate } from "../utils/parse-validate-date";

export class VegetalImportCSV extends InventoryItemCSV
    implements CSVModel<Vegetal, "photos" | "idcommande" | "produit" | "dommages" | "tags">,
    CSVModel<Site, "nom" | "adresse" | "evenements" | "users" | "idprojet" | "tags">,
    CSVModel<Adresse, "printableformat" | "arrondissement">,
    CSVModel<Evenement, "commandes" | "description" | "users" | "interventions" | "idsite" | "tags"> {
    @IsString()
    @IsNotEmpty()
    projet!: string
    @IsString()
    @IsNotEmpty()
    site!: string
    @IsString()
    @IsNotEmpty()
    @Transform(({ value }) => value ? value.toLowerCase() : undefined, { toClassOnly: true })
    territoire!: string

    /** Adresse */
    @IsString()
    numerodeporte!: string
    @IsString()
    typerue!: string
    @IsString()
    nomrue!: string
    @IsString()
    entiteadministrative!: string
    @IsString()
    ville!: string
    @IsString()
    mrc!: string
    @IsPostalCode("CA")
    @IsString()
    codepostal!: string
    //*****

    @IsString()
    @IsNotEmpty()
    @Transform(({ value }) => value ? value.toLowerCase() : undefined, { toClassOnly: true })
    reseau!: string
    @IsString()
    @IsOptional()
    noevenement: string | undefined
    @IsString()
    @IsNotEmpty()
    @Transform(({ value }) => value ? value.toLowerCase() : undefined, { toClassOnly: true })
    typeevenement!: string
    @IsDate()
    @Transform(ParseAndValidateDate, { toClassOnly: true })
    dateprevue!: Date
    @IsString()
    @IsNotEmpty()
    @Transform(({ value }) => value ? value.toLowerCase() : undefined, { toClassOnly: true })
    etatsante!: string
    @IsString()
    @IsOptional()
    @Transform(({ value }) => value ? value.toLowerCase() : undefined, { toClassOnly: true })
    raisonmortalite?: string
    @IsString()
    commentaires!: string
    @IsNumber()
    @Transform(({ value }) => Number.parseFloat(value), { toClassOnly: true })
    lat!: number
    @IsNumber()
    @Transform(({ value }) => Number.parseFloat(value), { toClassOnly: true })
    lon!: number
}