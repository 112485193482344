import { IconButton } from "@chakra-ui/button";
import { Icon } from "@chakra-ui/icon";
import { HStack } from "@chakra-ui/layout";
import { Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody } from "@chakra-ui/popover";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
} from "@chakra-ui/react";
import { ReactNode, memo, useCallback, useEffect, useState } from "react";
import { BiFilter } from "react-icons/bi";
import { useRoutingDisclosure } from "react-shared";
import { DOMAINE_TYPE, Domaine } from "soverdi-api-models";
import { DomaineBadge } from "../domaine-badge.component";
import { useTranslation } from "react-i18next";
import { GetDomaineService } from "soverdi-api-client";

export interface TypedDomainesState {
    type: DOMAINE_TYPE
    filters: Domaine[]
}

interface IProps {
    onSelect: (type: DOMAINE_TYPE, domaine: Domaine) => void
    types: DOMAINE_TYPE[],
    customTrigger?: ReactNode
}
function DomainesSelectorComponent({ types, onSelect, customTrigger }: IProps) {

    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [domaines, setDomaines] = useState<Array<TypedDomainesState>>(types.map(ty => ({ type: ty, filters: [] })))
    const { isOpen, onOpen, onClose } = useRoutingDisclosure(`/multidomaines`)

    const refresh = useCallback(async () => {
        setLoading(true)
        const result = await Promise.all(types.map(async type => {
            const service = GetDomaineService(type)
            const filters = await service.getAll()
            return { type, filters }
        }))
        setDomaines(result)
        setLoading(false)
    }, [setLoading, setDomaines, types])

    const addDomaine = useCallback((type: DOMAINE_TYPE, domaine: Domaine) => {
        onSelect(type, domaine)
        onClose()
    }, [onSelect, onClose])

    useEffect(() => {
        if (!isOpen) return
        refresh()
    }, [isOpen, refresh])

    return (
        <>
            <HStack>
                <Popover isLazy isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
                    <PopoverTrigger>
                        {customTrigger || <IconButton data-testid="domaines-selector-button" size="sm" variant={"ghost"} icon={<Icon fontSize={"20px"} as={BiFilter} />} aria-label="filter" />}
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody data-testid="domaine-selector-body">
                            <Accordion allowToggle defaultIndex={0}>
                                {types.map((ty, j) => {
                                    const _domaines = loading ? [undefined, undefined, undefined] : domaines.find(v => v.type === ty)!.filters
                                    return (
                                        <AccordionItem key={j}>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as='span' flex='1' textAlign='left'>
                                                        {t("models." + ty)}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel>
                                                <HStack flexWrap={"wrap"} maxHeight={"150px"} overflowY={"auto"} overflowX={"hidden"}>
                                                    {_domaines.map((d, i) => <DomaineBadge size="md" key={d?.id || i} domaine={d} onClick={d ? () => addDomaine(ty, d) : () => null} />)}
                                                </HStack>
                                            </AccordionPanel>
                                        </AccordionItem>)
                                })}
                            </Accordion>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </HStack>
        </>
    )
}

export const DomainesSelector = memo(DomainesSelectorComponent) as typeof DomainesSelectorComponent