import { IsString, IsPostalCode, IsOptional } from "class-validator";
import { Params } from "./_params.type";
import { Adresse } from "../../models/adresse.model"

export class AdresseParams implements Params<Adresse> {
    @IsString()
    printableformat!: string
    @IsString()
    numerodeporte!: string
    @IsString()
    @IsOptional()
    typerue?: string
    @IsString()
    nomrue!: string
    @IsString()
    @IsOptional()
    arrondissement?: string
    @IsString()
    ville!: string
    @IsString()
    mrc!: string
    @IsPostalCode("CA")
    @IsString()
    codepostal!: string
}
