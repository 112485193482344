import { HStack, Icon, IconButton, Input, useToast } from "@chakra-ui/react";
import { memo, useCallback, useState } from "react";
import { Point } from "soverdi-api-models";
import { MdModeEdit } from "react-icons/md";
import { useMovePoint } from "../../hooks/move-point";
import { InputProps } from "@class-former/react";
import { useTranslation } from "react-i18next";

export interface PointInputIProps extends InputProps<Point> {
    modelid: number
    layer: string
}
function PointInputComponent({ modelid, value, onChange, layer }: PointInputIProps) {

    const { move } = useMovePoint(layer)
    const [loading, setLoading] = useState(false)
    const toast = useToast()
    const { t } = useTranslation()

    const onChangePos = useCallback(async () => {
        setLoading(true)
        try {
            let change: Point | undefined
            const confirm = await move(modelid, (updated) => change = updated)
            if (!confirm) return
            if (confirm)
                onChange(change)
        }
        catch (e) {
            console.error(e)
            toast({ colorScheme: "red", title: t("common.errorTitle"), description: t("common.errorMessage") })
        } finally {
            setLoading(false)
        }
    }, [move, modelid, onChange, toast, t])

    return (
        <HStack gap={2}>
            <Input data-testid="point-input" value={value?.coordinates.join(", ")} readOnly onChange={() => null} />
            <IconButton isDisabled={!modelid} isLoading={loading} data-testid="edit-point-button" onClick={onChangePos} icon={<Icon as={MdModeEdit} />} aria-label="set" />
        </HStack>
    )
}

export const PointInput = memo(PointInputComponent) as typeof PointInputComponent