import { Spinner, Stack } from "@chakra-ui/react";
import { memo, useCallback } from "react";
import { InView } from "react-intersection-observer";

function LoadMoreComponent(props: { context?: { loadMore: () => void, loading: boolean } }) {

    const onViewEnter = useCallback((inView: boolean) => {
        if (inView)
            props.context?.loadMore()
    }, [props.context])

    return (
        <InView onChange={onViewEnter}>
            <Stack width={"full"} height={"100px"} justifyContent={"center"} alignItems={"center"}>
                {props.context?.loading && <Spinner size={"lg"} />}
            </Stack>
        </InView>
    )
}

export const LoadMore = memo(LoadMoreComponent) as typeof LoadMoreComponent