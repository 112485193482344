import { Transform, Type } from "class-transformer"
import { IsString, ValidateNested, IsNumber, IsDate, IsOptional, IsNotEmpty, IsArray } from "class-validator"
import { Model } from "../../models/base/model"
import { Params } from "./_params.type"
import { Evenement } from "../../models/evenement.model"

export class CreateEvenementParams implements Params<Evenement, "commandes" | "interventions" | "progres"> {

    @IsNumber()
    idsite!: number

    @IsString()
    noevenement!: string

    @IsString()
    @IsOptional()
    nom?: string

    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Model)
    typeevenement!: Model

    @IsString()
    description: string = ""

    @IsDate()
    @IsNotEmpty()
    @Transform(({ value }) => new Date(value), { toClassOnly: true })
    dateprevue!: Date

    @ValidateNested({ each: true })
    @Type(() => Model)
    users!: Array<Model>

    @IsOptional()
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => Model)
    tags?: Array<Model>
}
