import { Type } from "class-transformer";
import { IsOptional, IsString, ValidateNested } from "class-validator";
import { Point } from "../models/geojson/point.geojson";
import { Domaine } from "../models/domaine.model";
import { AdresseParams } from "../params/rest/adresse-params";

export class AddressCandidate {
    @IsString()
    adresse!: AdresseParams
    @ValidateNested()
    @Type(() => Point)
    position!: Point
    @ValidateNested()
    @IsOptional()
    entiteadministrative?: Domaine
}