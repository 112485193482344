import { User, UserWithRelations, environment } from "soverdi-api-models"
import axios from "axios"
import { Inject } from "typedi"
import { SoverdiAPIUrl } from "../utils/soverdi-api-url"
import { Service } from "typedi"
import { plainToInstance } from "class-transformer"
import { RestService } from "api-client-shared"
import { BulkService, IBulkService } from "./composition/bulk.service"
import { SOVERDI_API_HOSTNAME_TOKEN } from "../tokens/soverdi-api-hostname.token"
import { AutocompleteService, IAutocompleteService } from "./composition/autocomplete.service"

@Service()
export class UserService extends RestService<User, User, Partial<User>> implements IBulkService<UserWithRelations, never>, IAutocompleteService<UserWithRelations> {

    modelClazz = UserWithRelations
    @Inject()
    protected url!: SoverdiAPIUrl
    endpoint = environment.endpoints.user.base

    readonly bulk = new BulkService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly autocomplete = new AutocompleteService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)

    async getMe(): Promise<UserWithRelations> {
        const result = await axios.get<UserWithRelations>(this.url.join([this.endpoint, environment.endpoints.user.me.route]))
        return plainToInstance(UserWithRelations, result.data)
    }
}