import { PARENT_TYPE } from "../enums/parent-type.enum";
import { Model } from "../models/base/model";
import { Commande } from "../models/commande.model";
import { Evenement } from "../models/evenement.model";
import { Projet } from "../models/projet.model";
import { Site } from "../models/site.model";

export function getParentModel(type: PARENT_TYPE): new () => Model {
    switch (type) {
        case PARENT_TYPE.PROJET:
            return Projet
        case PARENT_TYPE.SITE:
            return Site
        case PARENT_TYPE.EVENEMENT:
            return Evenement
        case PARENT_TYPE.COMMANDE:
            return Commande
    }
}