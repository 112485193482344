import { FC, memo, useCallback, useState } from "react";
import { TbShovel } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { ActionProps } from "./action.props";
import { Button, Icon } from "@chakra-ui/react";
import { usePlanting } from "./hooks/planting.hook";

export const PlantAction: FC<ActionProps<any, any>> = memo(({ data, onChange }: ActionProps<any, any>) => {

    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const { startPlanting } = usePlanting()

    const plant = useCallback(async () => {
        setLoading(true)
        const vegetal = await startPlanting(data)
        setLoading(false)
        if (!onChange || !vegetal) return
        onChange(prev => [...prev, vegetal.id])
    }, [onChange, setLoading, startPlanting, data])

    return (
        <Button isLoading={loading} loadingText={t("CommandeActions.planter")} data-testid="plant-button" onClick={plant} rightIcon={<Icon as={TbShovel} />}>{t("CommandeActions.planter")}</Button>
    )
})