import { Box, Collapse, HStack, Icon, IconButton, Input, Image, VStack, Button, Heading, Modal, ModalBody, ModalContent, ModalOverlay, Card } from "@chakra-ui/react";
import { InputProps } from "@class-former/react";
import { getService } from "api-client-shared";
import { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from "react";
import { PhotoService } from "soverdi-api-client";
import { Photo } from "soverdi-api-models";
import { FaRegTrashCan } from "react-icons/fa6";
import { useRoutingDisclosure, useStatusToast } from "react-shared";
import { useTranslation } from "react-i18next";
import imageCompression from 'browser-image-compression';

function PhotosInputComponent({ value, onChange }: InputProps<Array<Photo>>) {

    const { t } = useTranslation()
    const fileService = getService(PhotoService)
    const photos = useMemo(() => value || [], [value])
    const { isOpen, onOpen, onClose } = useRoutingDisclosure("/upload")
    const [loading, setLoading] = useState(false)

    const [imageFiles, setImageFiles] = useState<File[]>([]);
    const [images, setImages] = useState<Array<string | ArrayBuffer>>([]);
    const { onError } = useStatusToast()

    const onFileUpload = useCallback(async () => {
        setLoading(true)
        try {
            const _photos = (imageFiles && imageFiles.length > 0) ? await fileService.upload(imageFiles) : []
            onChange([...photos, ..._photos])
            onClose()
        }
        catch (e) {
            console.error(e)
            onError(t("PhotosInput.photos"), "save", t("PhotosInput.error"))
        } finally {
            setLoading(false)
        }
    }, [onChange, fileService, imageFiles, onClose, onError, photos, t])

    const onRemovePhoto = useCallback(async (i: number) => {
        onChange(photos.filter((p, j) => i !== j))
    }, [photos, onChange])

    const changeHandler = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        onOpen()
        if (!files) return
        const validImageFiles = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
            const compressedBlob = await imageCompression(file, {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            });
            var compressedFile = new File([compressedBlob], file.name);
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            validImageFiles.push(compressedFile);
        }
        if (validImageFiles.length) {
            setImageFiles(validImageFiles);
            return;
        }
    }, [onOpen, setImageFiles])

    useEffect(() => {
        const _images = new Array<string | ArrayBuffer>()
        const fileReaders = new Array<FileReader>();
        let isCancel = false;
        if (imageFiles.length) {
            imageFiles.forEach((file) => {
                const fileReader = new FileReader();
                fileReaders.push(fileReader);
                fileReader.onload = (e) => {
                    const result = e.target?.result;
                    if (result) {
                        _images.push(result)
                    }
                    if (_images.length === imageFiles.length && !isCancel) {
                        setImages(_images);
                    }
                }
                fileReader.readAsDataURL(file);
            })
        };
        return () => {
            isCancel = true;
            fileReaders.forEach(fileReader => {
                if (fileReader.readyState === 1) {
                    fileReader.abort()
                }
            })
        }
    }, [imageFiles]);

    return (
        <VStack alignItems={"stretch"}>
            <Input data-testid="photos-input" p="5px" type="file" multiple onChange={changeHandler} accept="image/png, image/jpeg" />
            <Collapse in={photos.length > 0} animateOpacity>
                <HStack overflowX={"scroll"} overflowY={"hidden"} maxHeight="300px" mt={4} gap={4}>
                    {photos.map((p, i) => {
                        const link = fileService.src(p.nomfichier)
                        return (
                            <Box flexShrink={0} key={link} position={"relative"}>
                                <a data-testid="vegetal-image-link" href={link} target="_blank" rel="noreferrer">
                                    <Image data-testid="vegetal-image" borderRadius='lg' mb={2} height="280px" width="auto" src={link} />
                                </a>
                                <IconButton data-testid="vegetal-image-delete-button" position={"absolute"} top={4} right={4} onClick={() => onRemovePhoto(i)} icon={<Icon as={FaRegTrashCan} />} aria-label="trash" />
                            </Box>
                        )
                    })}
                </HStack>
            </Collapse>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxHeight={["100vh", "100vh", "70vh"]} width={["100vw", "100vw", "70vw"]} maxWidth={"600px"}>
                    <ModalBody height={"full"} width={"full"} overflow={"auto"}>
                        <HStack mb={4} mt={2} justifyContent={"space-between"}>
                            <Heading size={["sm", "sm", "md"]}>
                                {t("PhotosInput.prompt")}
                            </Heading>
                            <HStack spacing={4}>
                                <Button onClick={onClose} variant={"outline"}>{t("common.cancel")}</Button>
                                <Button onClick={onFileUpload} isLoading={loading}>{t("common.upload")}</Button>
                            </HStack>
                        </HStack>
                        <VStack mb={"100px"} spacing={4}>
                            {images.map((img, i) => (
                                <Card key={i}>
                                    <Image src={img as any} />
                                </Card>
                            ))}
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </VStack>
    )
}

export const PhotosInput = memo(PhotosInputComponent) as typeof PhotosInputComponent