import { Transform, Type } from "class-transformer";
import { IsOptional, IsDate, IsNumber, ValidateNested } from "class-validator";
import { EditParams } from "../edit.params";
import { TaggableBulkEditBody } from "./taggable-bulk-edit.params";
import { Params } from "../../rest/_params.type";
import { Intervention } from "../../../models/intervention.model";

export class InterventionBulkEditBody extends TaggableBulkEditBody implements Params<Intervention> {
    @IsNumber()
    @IsOptional()
    idevenement!: number
    @IsOptional()
    @IsDate()
    @Transform(({ value }) => new Date(value), { toClassOnly: true })
    dateprevue!: Date
    @IsOptional()
    @IsDate()
    @Transform(({ value }) => new Date(value), { toClassOnly: true })
    datedepassage?: Date
}

export class InterventionBulkEditParams extends EditParams {
    @Type(() => InterventionBulkEditBody)
    @ValidateNested()
    body: Partial<InterventionBulkEditBody> = {};
}