import { Feature } from "@goazimut/gonet-messenger";
import { Observable, Subscriber } from "rxjs";
import { CartographicModel, Geomodel, Model } from "soverdi-api-models";

export interface MapLayer {
    id: number
    name: string
    defaultVisibility: boolean
    visibility: boolean
}

export interface OnSelectParams {
    clazz: new () => (Model & CartographicModel)
    ids: number[]
}

export abstract class MapHandle {

    protected subscribers = {
        onSelect: new Array<Subscriber<OnSelectParams>>(),
        onUpdate: new Array<Subscriber<Feature<Geomodel>>>()
    }

    public readonly events = {
        onSelect: new Observable<OnSelectParams>(subscriber => { this.subscribers.onSelect.push(subscriber); return () => { this.subscribers.onSelect = this.subscribers.onSelect.filter(s => s !== subscriber) } }),
        onUpdate: new Observable<Feature<Geomodel>>(subscriber => { this.subscribers.onUpdate.push(subscriber); return () => { this.subscribers.onUpdate = this.subscribers.onUpdate.filter(s => s !== subscriber) } }),
    }

    abstract get layers(): MapLayer[]
    public abstract watchPosition(): Promise<void>
    public abstract stopWatchPosition(): Promise<void>
    public abstract select(layer: string, ...models: number[]): Promise<void>
    public abstract filter(models: number[], layer: string, options?: { draggable?: boolean }): Promise<void>
    public abstract setVisibleLayers(layers: MapLayer[]): Promise<void>
    public abstract clear(): Promise<void>
}