import { IsNumber, ValidateNested } from "class-validator";
import { ImportResponse } from "./import-response";
import { InventoryImportResult } from "./inventory-import.response";

class VegetauxImportResult extends InventoryImportResult {
    @IsNumber()
    projet!: number
    @IsNumber()
    site!: number
    @IsNumber()
    evenement!: number
    @IsNumber()
    commande!: number
    @IsNumber()
    vegetal!: number
}

export class VegetauxImportResponse extends ImportResponse {
    @ValidateNested()
    added = new VegetauxImportResult()
    @ValidateNested()
    skipped = new VegetauxImportResult()
}