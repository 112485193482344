import { ModalSheetBody, ModalSheetContext } from "react-shared";
import { memo, useContext, useEffect, useState } from "react";
import { Model, SearchableModel } from "soverdi-api-models";
import { ModelList } from "../model-list/model-list.component";
import { IModelListProps } from "../model-list/props/model-list.props";
import { useTranslation } from "react-i18next";
import { BsListUl } from "react-icons/bs";
import { ModalHeader } from "../modal/modal-header.component";

function ModelListViewComponent<T extends Model & SearchableModel>(props: IModelListProps<T>) {

    const { t } = useTranslation()
    const [data, setData] = useState<number[]>(props.ids!)
    const [virtuosoScrollParent, setVirtuosoScrollParent] = useState<HTMLElement>()
    const { setHeader } = useContext(ModalSheetContext)

    useEffect(() => {
        setHeader(<ModalHeader title={t("models.asList." + props.modelResources.tabIdentifier) + ` (${props.ids?.length})`} actions={[]} icon={BsListUl} />)
    }, [props.modelResources, props.ids, t, setHeader])

    return (
        <ModalSheetBody data-testid="model-list-view" ref={r => { r && setVirtuosoScrollParent(r) }}>
            <ModelList marginbottom="lg" {...props} ids={data} onChange={setData} virtuosoScrollParent={virtuosoScrollParent} />
        </ModalSheetBody>
    )
}

export const ModelListView = memo(ModelListViewComponent) as typeof ModelListViewComponent