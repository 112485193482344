import { IsString, ValidateNested, IsOptional, IsNotEmpty, IsNumber } from "class-validator";
import { Site } from "./site.model";
import { Type } from "class-transformer";
import { SearchableModel } from "./composition/searchable.model";
import { ModelWithMeta } from "./base/model-with-meta";
import { Point } from "./geojson/point.geojson";
import { CartographicModel } from "./composition/cartographic.model";
import { User } from "./user.model";
import { AssignableModel } from "./composition/assignable.model";
import { FormConfig } from "@class-former/decorators"
import { FormableModel } from "./composition/formable.model";
import { IsDomaine } from "../decorators/_domaine.decorator";
import { DOMAINE_TYPE } from "../enums/domaine.enum";
import { Domaine } from "./domaine.model";
import { TypedDomaine } from "./typed-domaine.model";

export class Projet extends ModelWithMeta implements SearchableModel, CartographicModel, AssignableModel, FormableModel {

    @IsString()
    @IsNotEmpty()
    nom!: string

    @FormConfig({ skip: true })
    @IsNumber(undefined, { each: true })
    sites!: Array<number>

    @FormConfig({ skip: true })
    @ValidateNested()
    @Type(() => Point)
    geometry!: Point

    @ValidateNested({ each: true })
    @Type(() => User)
    users: Array<User> = []

    @IsDomaine(DOMAINE_TYPE.TAG)
    @ValidateNested({ each: true })
    @Type(() => Domaine)
    tags: Domaine[] = []

    public static layer = "Projets"
    get layer() { return Projet.layer }
    public get searchBy() { return this.nom }
    public get subtext() { return [{ i18nprefix: "common.createdOn", value: this.meta_datecreation.toDateString() }] }
    get domaines() {
        return [
            ...this.tags.map(t => new TypedDomaine(t, DOMAINE_TYPE.TAG))
        ]
    }
    get parentid() { return 0 }
    set parentid(id: number) { }
}