import { useCallback, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { normalizeText } from 'normalize-text';
import urljoin from "url-join"
import { useInitialLocation } from "./location/initial-location";

export function useRoutingDisclosure(path: string, options?: { openDelay?: number, onCloseNavigateTo?: string, useInitialLocation?: boolean }) {

    const navigate = useNavigate()
    const { pathname } = useLocation()
    const initialLocation = useInitialLocation()
    const [latePathPrefix, setPathLatePrefix] = useState<string | undefined>()
    const pathPrefix = useMemo(() => options?.useInitialLocation ? initialLocation : latePathPrefix, [options?.useInitialLocation, initialLocation, latePathPrefix])
    const getNormalizedPath = useCallback((_pathPrefix: string) => normalizeText(urljoin(_pathPrefix, path.replaceAll(" ", ""))), [path])

    const isOpen = useMemo(() => !!pathPrefix && pathname.includes(getNormalizedPath(pathPrefix)), [latePathPrefix, pathname, getNormalizedPath])

    const onOpen = useCallback(async () => {
        const _pathPrefix = options?.useInitialLocation ? initialLocation : (latePathPrefix || pathname)
        if (!latePathPrefix)
            setPathLatePrefix(pathname)
        if (options?.openDelay) await new Promise(resolve => setTimeout(resolve, options.openDelay));
        const normalizedPath = getNormalizedPath(_pathPrefix)
        navigate(normalizedPath, { state: { dynamicLocation: true } })
    }, [navigate, getNormalizedPath])

    const onClose = useCallback(() => {
        if (!pathPrefix) return
        const normalizedPath = getNormalizedPath(pathPrefix)
        if (!pathname.endsWith(normalizedPath) && !pathname.includes(normalizedPath + "/")) return
        options?.onCloseNavigateTo ? navigate(options?.onCloseNavigateTo) : navigate(-1)
    }, [navigate, options?.onCloseNavigateTo, pathPrefix, pathname])

    return { isOpen, onOpen, onClose }
}