import { InputGroup } from "@chakra-ui/input";
import { ChangeEvent, memo, useCallback, useEffect, useRef, useState } from "react";
import { Model, SearchableModel } from "soverdi-api-models";
import { AutoComplete, AutoCompleteInput, AutoCompleteList, AutoCompleteItem, AutoCompleteRefMethods, Item } from "@choc-ui/chakra-autocomplete";
import { useDebounce } from "react-shared";
import { BadgeInsideFakeInput } from "react-shared";
import { IAutocompleteService } from "soverdi-api-client";
import { environment } from "../../config/environment";
import { useToast, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CustomInputProps } from "./input.props";
import { RestService } from "api-client-shared";

export interface AutocompleteInputIProps<T extends Model & SearchableModel, U extends RestService<T, any, any> & IAutocompleteService<T>> extends CustomInputProps<T, U> {
    placeholder?: string
    'data-testid'?: string;
    inputMinLength?: number
}
function AutocompleteInputComponent<T extends Model & SearchableModel, U extends RestService<T, any, any> & IAutocompleteService<T>>({ value, service, onChange, placeholder, 'data-testid': dataTestId, inputMinLength = 3 }: AutocompleteInputIProps<T, U>) {

    const [loading, setLoading] = useState(false)
    const [autocompleteInput, _setAutocompleteInput] = useState(value?.searchBy || "")
    const debouncedInput = useDebounce(autocompleteInput, environment.debounceDelay)
    const autocompleteRef = useRef<AutoCompleteRefMethods>(null)
    const [focusedAtLeastOnce, setFocusedAtLeastOnce] = useState(false)
    const [data, setData] = useState<T[]>([])
    const toast = useToast()
    const { t } = useTranslation()

    const fetchSuggestions = useCallback(async (value: string) => {
        if (value.length < inputMinLength) {
            setData([])
            return
        }
        setLoading(true)
        try {
            autocompleteRef.current?.resetItems(false)
            const results = value ? await service.autocomplete.autocomplete(value) : await service.getAll()
            setData(results)
        }
        catch (e) {
            console.error(e)
            toast({ colorScheme: "red", title: t("common.errorTitle"), description: t("common.errorMessage") })
        }
        finally {
            setLoading(false)
        }
    }, [service, setData, inputMinLength, toast, t])

    const setAutocompleteInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        _setAutocompleteInput(e.target.value)
        if (e.target.value !== value?.searchBy)
            onChange(undefined)
    }, [_setAutocompleteInput, onChange, value?.searchBy])

    const onSelect = useCallback((params: { item: Item }) => {
        const searchby = params.item.value
        const result = data.find(d => d.searchBy === searchby)
        onChange(result)
    }, [onChange, data])

    const onFocus = useCallback(async () => {
        fetchSuggestions(debouncedInput)
        setFocusedAtLeastOnce(true)
    }, [debouncedInput, fetchSuggestions, setFocusedAtLeastOnce])

    useEffect(() => {
        if (focusedAtLeastOnce)
            fetchSuggestions(debouncedInput)
    }, [debouncedInput, fetchSuggestions, focusedAtLeastOnce])

    useEffect(() => value && _setAutocompleteInput(value?.searchBy), [value, _setAutocompleteInput])

    const onRemoveBadge = useCallback(() => {
        _setAutocompleteInput("")
        onChange(undefined)
    }, [_setAutocompleteInput, onChange])

    return (
        <AutoComplete
            openOnFocus
            isLoading={loading}
            ref={autocompleteRef}
            onSelectOption={onSelect}
            filter={() => true}
            emptyState={<Text mx={4}>{t("common.noResult")}</Text>}
        >
            <InputGroup>
                {value ?
                    <BadgeInsideFakeInput label={value.searchBy} onRemove={onRemoveBadge} /> :
                    <AutoCompleteInput onFocus={onFocus} data-testid={dataTestId} type="text" value={autocompleteInput} onChange={setAutocompleteInput} placeholder={placeholder} />}

            </InputGroup>
            {autocompleteInput.length >= inputMinLength &&
                <AutoCompleteList key={data.length}>
                    {data.map((filter) => {
                        return (
                            <AutoCompleteItem
                                data-testid="autocomplete-item"
                                key={`option-${filter.id}`}
                                value={filter.searchBy}
                            >
                                {filter.searchBy}
                            </AutoCompleteItem>
                        )
                    })}
                </AutoCompleteList>
            }
        </AutoComplete>
    )
}

export const AutocompleteInput = memo(AutocompleteInputComponent) as typeof AutocompleteInputComponent