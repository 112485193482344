import { Input } from "@chakra-ui/react";
import { useState, useCallback, useRef } from "react";
import { IFilterFormControlProps } from "./filter-form-control.props";
import { DomaineParams, Domaine } from "soverdi-api-models";
import { FilterFormControl } from "./filter-form-control.component";
import { DomaineSelector } from "../../../selectors/domaine.selector";
import { RestService } from "api-client-shared";

interface IProps extends IFilterFormControlProps<Domaine> {
    domaineServiceType: RestService<Domaine, DomaineParams, DomaineParams>
    'data-testid'?: string;
}
export function DomaineFilter({ domaineServiceType, text, onChange, onRemove, "data-testid": dataTestid }: IProps) {

    const [value, setValue] = useState<string>("")
    const ref = useRef<HTMLInputElement>(null)

    const onDomaineSelect = useCallback((d: Domaine) => {
        setValue(d.nom)
        if (ref.current)
            ref.current.style.width = (5 + d.nom.length) + "ch";
        onChange(d)
    }, [ref, setValue, onChange])

    return (
        <FilterFormControl onClose={onRemove} text={text}>
            <DomaineSelector
                domaineService={domaineServiceType}
                onSelect={onDomaineSelect}
                customTrigger={<Input data-testid={dataTestid} ref={ref} type="text" readOnly textAlign={"center"} px={2} htmlSize={1} value={value}
                    height="25px" width='5ch' variant={"filled"}></Input>} />

        </FilterFormControl>
    )
}