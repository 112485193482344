import { IsOptional, IsString, ValidateNested } from "class-validator";
import { SearchParams } from "./search.params"
import { Type } from "class-transformer";
import { Model } from "../../models/base/model";
import { ISearchParams } from "./search-params.type";
import { Espece } from "../../models/espece.model";

export class SearchEspeceParams extends SearchParams implements ISearchParams<Espece, "couleur"> {

    @IsOptional()
    @IsString()
    genre?: string

    @IsOptional()
    @IsString()
    nomespece?: string

    @IsOptional()
    @IsString()
    cultivar?: string

    @IsOptional()
    @IsString()
    code?: string

    @IsOptional()
    @ValidateNested()
    @Type(() => Model)
    typeespece?: Model

}