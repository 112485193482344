import { DisclosuredProps, ModalSheet } from "react-shared";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Tabs, TabPanel, TabList, Tab } from "react-tabs"
import "./modal.component.scss"
import { useTabStack } from "../../hooks/tab-stack/tab-stack.hook";
import { ModelView } from "../modal-views/model.view";
import { ModelListView } from "../modal-views/model-list.view";
import { FavoritesView } from "../modal-views/favorites.view";
import { ModelListViewInfo, ModelViewInfo } from "../../hooks/tab-stack/tab-stack-item.interface";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"

const animationDuration = 250;
export const MapModal = memo(({ isOpen, onClose }: DisclosuredProps) => {

    const { tabStack, currentTab } = useTabStack()
    const [tabIndex, setTabIndex] = useState(0)

    const futureTabIndex = useMemo(() => {
        const _index = tabStack.length - 1
        setTimeout(() => setTabIndex(_index), animationDuration)
        return _index
    }, [tabStack])

    const tabAnimation = useCallback((i: number) => {
        if (futureTabIndex === i) return ""
        const hideTab = (tabIndex !== i) ? " hide-tab" : ""
        const animate = (i < futureTabIndex) ? " animate-out-left" : " animate-out-right"
        return animate + hideTab
    }, [tabIndex, futureTabIndex])

    useEffect(() => {
        setTimeout(() => {
            const target = document.getElementById("modal-sheet-body")
            isOpen ? disableBodyScroll(target!) : clearAllBodyScrollLocks()
        })
    }, [isOpen, tabIndex])

    return (
        <ModalSheet isOpen={isOpen} onClose={onClose} full={currentTab?.full}>
            <Tabs forceRenderTabPanel={false} className="fullheight" selectedIndex={tabIndex} onSelect={() => true}>
                <TabList style={{ display: "none" }}>
                    {tabStack.map(h => <Tab key={"tablist_" + h.key + h.resources.tabIdentifier} />)}
                </TabList>
                {tabStack.map((h, i) => (
                    <TabPanel key={h.key + h.resources.tabIdentifier} className={"fullheight animated-tab" + tabAnimation(i)}>
                        {h.as === "ModelView" ? <ModelView focused={tabIndex === i} modelid={(h as ModelViewInfo<any>).modelid} modelResources={h.resources} /> :
                            h.as === "ModelListView" ? <ModelListView ids={(h as ModelListViewInfo<any>).data} modelResources={h.resources} /> :
                                h.as === "Favorites" ? <FavoritesView /> :
                                    <div>Could not find component</div>}
                    </TabPanel>
                ))}
            </Tabs>
        </ModalSheet>
    )

})