import { ClassConstructor } from "class-transformer";
import { APIUrl } from "../utils/api-url"
import { Token } from "typedi";

export abstract class CompositionService<T> {

    protected url!: APIUrl
    constructor(protected modelClazz: ClassConstructor<T>, protected endpoint: string, urlInjectionToken: Token<string>) {
        this.url = new APIUrl(urlInjectionToken)
    }
    getEndpoint() { return this.endpoint }
}

