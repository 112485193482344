import { IsOptional, IsString, ValidateNested } from "class-validator"
import { SearchParams } from "./search.params"
import { Type } from "class-transformer"
import { Model } from "../../models/base/model"
import { ISearchParams } from "./search-params.type"
import { Evenement } from "../../models/evenement.model"

export class SearchEvenementParams extends SearchParams implements ISearchParams<Evenement, "dateprevue" | "description" | "idsite"> {

    @IsOptional()
    @IsString()
    nom?: string

    @IsOptional()
    @IsString()
    noevenement?: string

    @IsOptional()
    @ValidateNested()
    @Type(() => Model)
    typeevenement?: Model
}