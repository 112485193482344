import { IsOptional, ValidateNested } from "class-validator";
import { CreateSiteParams } from "./create-site.params";
import { Model } from "../../models/base/model";
import { Transform, Type } from "class-transformer";
import { Site } from "../../models/site.model";
import { Params } from "./_params.type";

export class UpdateSiteParams extends CreateSiteParams implements Params<Site, "adresse"> {
    @IsOptional()
    @ValidateNested({ each: true })
    @Type(() => Model)
    @Transform(({ value }) => (!value || value[0]?.id) ? value : value.map((v: number) => ({ id: v } as Model)))
    evenements: Array<Model> | undefined
}