import { InterventionImportResponse, InventoryImportResponse, VegetauxImportResponse, environment } from "soverdi-api-models";
import axios from "axios"
import { Inject } from "typedi"
import { SoverdiAPIUrl } from "../utils/soverdi-api-url"
import { Service } from "typedi"
import { plainToInstance } from "class-transformer"

@Service()
export class ImportService {

    @Inject()
    protected url!: SoverdiAPIUrl
    endpoint = environment.endpoints.import.base

    async inventory(content: string): Promise<InventoryImportResponse> {
        const result = await axios.post<InventoryImportResponse>(this.url.join([this.endpoint, environment.endpoints.import.inventory.route]), { content })
        return plainToInstance(InventoryImportResponse, result.data)
    }

    async vegetaux(content: string): Promise<VegetauxImportResponse> {
        const result = await axios.post<VegetauxImportResponse>(this.url.join([this.endpoint, environment.endpoints.import.vegetaux.route]), { content })
        return plainToInstance(VegetauxImportResponse, result.data)
    }

    async interventions(content: string): Promise<InterventionImportResponse> {
        const result = await axios.post<InterventionImportResponse>(this.url.join([this.endpoint, environment.endpoints.import.interventions.route]), { content })
        return plainToInstance(InterventionImportResponse, result.data)
    }
}