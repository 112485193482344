import { IsNumber, IsOptional, ValidateNested } from "class-validator";
import { Type } from "class-transformer";
import { SearchEspeceParams } from "./search-espece.params";
import { SearchProduitParams } from "./search-produit.params";
import { SearchParams } from "./search.params";
import { ISearchParams } from "./search-params.type";
import { Commande } from "../../models/commande.model";

export class SearchCommandeParams extends SearchParams implements ISearchParams<Commande, "quantite"> {

    @IsNumber()
    @IsOptional()
    idevenement?: number

    @ValidateNested()
    @Type(() => SearchEspeceParams)
    @IsOptional()
    espece?: SearchEspeceParams = new SearchEspeceParams()

    @ValidateNested()
    @Type(() => SearchProduitParams)
    @IsOptional()
    produit?: SearchProduitParams = new SearchProduitParams()

}