import { IGOazimutApp } from "portail-api-client";
import { AUTHENTIFICATION_GOAZIMUT_APPNAME } from "soverdi-api-models";

const versions = [
    { hostname: "soverdi.goazimut.com", version: "soverdi_prod" },
    { hostname: "soverdibeta.goazimut.com", version: "soverdi_beta" },
    { hostname: "localhost", version: "soverdi_beta" }
]

export const environment = {
    USER_REPORT_APPNAME: "soverdi-app",
    API_URL: process.env.REACT_APP_SOVERDI_API_URL as string,
    OFFLINE: process.env.REACT_APP_OFFLINE === "true",
    VERSION: process.env.PACKAGE_VERSION,
    GONET_URL: "https://www.goazimut.com/GOnet6/index.html",
    debounceDelay: process.env.REACT_APP_NODEBOUNCE === "true" ? 0 : 400,
    dateconfig: { dateFormat: "dd/MM/yyyy" },
    loadChunk: 20,
    processingTimePerVegetalCSVEntry: 0.0002,
    badgeColors: ["cyan", "purple", "blue", "yellow", "green", "orange", "pink", "red", "teal", "yellow"],
    mapLayerSplit: " - ",
    app: { description: AUTHENTIFICATION_GOAZIMUT_APPNAME, version: versions.find(v => v.hostname === window.location.hostname)?.version } as IGOazimutApp
}