import { Box } from "@chakra-ui/react";
import { ReactNode, forwardRef } from "react";

interface IProps { children: ReactNode }
export const ModalSheetBody = forwardRef<HTMLElement, IProps>(({ children }: IProps, ref) => {
    return (
        <Box className="modal-sheet-body" overflow={"auto"} id="modal-sheet-body" height={"full"} ref={ref as any} pt={0} pb={6} px={[3, 6]} width="full">
            {children}
        </Box>
    )
})