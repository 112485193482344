import { RangeDatepicker } from "chakra-dayzed-datepicker"
import { useEffect, useState } from "react";
import { BetweenDates } from "soverdi-api-models"
import { IFilterFormControlProps } from "./filter-form-control.props";
import { FilterFormControl } from "./filter-form-control.component";

export function DateRangeFilter({ text, onChange, onRemove }: IFilterFormControlProps<BetweenDates>) {

    const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);
    useEffect(() => {
        const betweenDates: BetweenDates = { after: selectedDates[0], before: selectedDates[1] }
        onChange(betweenDates)
    }, [selectedDates, onChange])

    return (
        <FilterFormControl data-testid="date-range-input" onClose={onRemove} text={text}>
            <RangeDatepicker
                selectedDates={selectedDates}
                onDateChange={setSelectedDates}
            />
        </FilterFormControl>
    )
}