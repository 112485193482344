import { ChildResources, ModelResources } from "./model.resources";
import { TabIdentifier } from "../hooks/tab-stack/tab-identifier.enum";
import { GrCatalog } from "react-icons/gr";
import { Evenement, Projet, Site, Intervention, restDefaultRoles } from "soverdi-api-models";
import { instanceToInstance } from "class-transformer"
import { UserWithRelations } from "soverdi-api-models";
import { EvenementResources } from "./evenement.resources";
import { ProjetResources } from "./projet.resources";
import { SiteResources } from "./sites.resources";
import { InterventionResources } from "./intervention.resources";
import { UserService } from "soverdi-api-client";
import { Container } from "typedi"

export class FavoritesResourcesClass extends ModelResources<UserWithRelations> {
    form = undefined
    icon = GrCatalog

    bulkActions = []
    actions = []
    get service() { return Container.get(UserService) as any }
    get parentService() { return undefined }
    tabIdentifier = TabIdentifier.favorites
    lists = [
        {
            resources: ProjetResources,
            get: (me) => me?.projets,
            set: (me, values) => {
                me.projets = values
                return instanceToInstance(me)
            }
        } as ChildResources<UserWithRelations, Projet>,
        {
            resources: SiteResources,
            get: (me) => me?.sites,
            set: (me, values) => {
                me.sites = values
                return instanceToInstance(me)
            }
        } as ChildResources<UserWithRelations, Site>,
        {
            resources: EvenementResources,
            get: (me) => me?.evenements,
            set: (me, values) => {
                me.evenements = values
                return instanceToInstance(me)
            }
        } as ChildResources<UserWithRelations, Evenement>, {
            resources: InterventionResources,
            get: (me) => me?.interventions,
            set: (me, values) => {
                me.interventions = values
                return instanceToInstance(me)
            }
        } as ChildResources<UserWithRelations, Intervention>
    ];
    roles = { rest: restDefaultRoles };
    Model = UserWithRelations
    CreateParams = class { }
    UpdateParams = class { }
}

export const FavoritesResources = new FavoritesResourcesClass()