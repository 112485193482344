import { TokenRepository, useInit } from "react-shared";
import { getService } from "api-client-shared";
import { useCallback, useContext, useEffect, useState } from "react";
import { Token } from "portail-api-client";
import GonetComponent from "react-shared/src/components/gonet/gonet.component";
import { environment } from "../../config/environment";
import { GOnetHandle, MapReadyEventPayload } from "@goazimut/gonet-messenger"
import { useGOnetEventsHandler } from "../../hooks/gonet-events-handler";
import { MapHandle } from "./map-handle.interface";
import { GOnetMapHandle } from "./gonet.handle";
import { MapContext } from "../../contexts/map.context";

interface IProps {
    onMapReady: (handle: MapHandle) => void
}
export function GOnetWrapper({ onMapReady }: IProps) {

    const { mapHandle } = useContext(MapContext)
    const tokenRepository = getService(TokenRepository)
    const [token, setToken] = useState<Token | undefined>()
    const { onMapSelection } = useGOnetEventsHandler()

    useInit(async () => {
        const token = await tokenRepository.get()
        setToken(token)
    })

    const onReady = useCallback((gonetHandle: GOnetHandle, payload: MapReadyEventPayload) => {
        const handle = new GOnetMapHandle(gonetHandle, payload.layers)
        onMapReady(handle)
    }, [onMapReady])

    const onHandshake = useCallback((gonetHandle: GOnetHandle) => {
        if (!gonetHandle) return
        gonetHandle.addEventListener('mapReady', (payload) => onReady(gonetHandle, payload));
    }, [onReady])

    useEffect(() => {
        if (!mapHandle) return
        const subscription = mapHandle.events.onSelect.subscribe({ next: onMapSelection })
        return () => {
            subscription?.unsubscribe()
        }
    }, [mapHandle, onMapSelection])

    return (
        <>
            {token?.gde && !environment.OFFLINE && <GonetComponent code="00007" gde={token.gde} mapFullscreen hostname={environment.GONET_URL} onHandshake={onHandshake} />}
        </>
    )
}