import { Service, Inject } from "typedi";
import { RestService } from "api-client-shared";
import { EspeceParams, Espece, SearchEspeceParams } from "soverdi-api-models";
import { environment } from "soverdi-api-models";
import { SoverdiAPIUrl } from "../utils/soverdi-api-url";
import { SOVERDI_API_HOSTNAME_TOKEN } from "../tokens/soverdi-api-hostname.token";
import { IAutocompleteService, AutocompleteService } from "./composition/autocomplete.service";
import { BulkService, IBulkService } from "./composition/bulk.service";
import { ISearchService, SearchService } from "./composition/search-service";

@Service()
export class EspeceService extends RestService<Espece, EspeceParams, Partial<EspeceParams>> implements IAutocompleteService<Espece>, IBulkService<Espece, never>, ISearchService<Espece, SearchEspeceParams>{
    modelClazz = Espece
    @Inject()
    protected url!: SoverdiAPIUrl
    endpoint = environment.endpoints.espece.base

    readonly search = new SearchService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly autocomplete = new AutocompleteService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly bulk = new BulkService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
}