import { ReactNode, memo, useCallback } from "react";
import { createStandaloneToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { addTranslations } from "./_status.i18n"
import { StatusContext } from "./_status.context";

//this delay prevent the toast from disapearing if the route changes just before exposing the toast
const delay = 400
interface IProps {
    children: ReactNode
}
function StatusProviderComponent({ children }: IProps) {

    addTranslations()

    const { t } = useTranslation()
    const { ToastContainer, toast } = createStandaloneToast()

    const onSuccess = useCallback(async (identifier: string, type: "delete" | "save" | "get", subtext?: string) => {
        setTimeout(() =>
            toast({
                title: identifier + t("StatusProvider.success" + type),
                description: subtext,
                status: 'success',
                duration: 9000,
                isClosable: true,
            }),
            delay
        )
    }, [toast])

    const onError = useCallback(async (identifier: string, type: "delete" | "save" | "get", subtext?: string) => {
        setTimeout(() =>
            toast({
                title: identifier + t("StatusProvider.error" + type),
                description: subtext,
                status: 'error',
                duration: 9000,
                isClosable: true,
            }),
            delay
        )
    }, [toast])

    return (
        <StatusContext.Provider value={{ onSuccess, onError }}>
            {children}
            <ToastContainer />
        </StatusContext.Provider>
    )
}

export const StatusProvider = memo(StatusProviderComponent) as typeof StatusProviderComponent