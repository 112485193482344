import { ChildResources, ModelResources } from "./model.resources";
import { CommandeService, EvenementService } from "soverdi-api-client";
import { TabIdentifier } from "../hooks/tab-stack/tab-identifier.enum";
import { Container } from "typedi"
import { Commande, CreateCommandeParams, UpdateCommandeParams, Vegetal, environment } from "soverdi-api-models";
import { VegetalResources } from "./vegetal.resources";
import { AiFillTag } from "react-icons/ai";
import { instanceToInstance } from "class-transformer"
import { PlantAction } from "../components/menu-actions/actions/plant.action";
import { ShowVegetauxBulkAction } from "../components/menu-actions/bulk-actions/show-vegetaux.bulk-action";
import { DeleteBulkAction } from "../components/menu-actions/bulk-actions/delete.bulk-action";
import { CustomForm } from "../components/model-forms/form.component";
import { ExportVegetauxBulkAction } from "../components/menu-actions/bulk-actions/export-vegetaux.bulk-action";
import { ExportInterventionsBulkAction } from "../components/menu-actions/bulk-actions/export-interventions.bulk-action";
import { ChangeParentAction } from "../components/menu-actions/actions/change-parent.action";
import { SetTagsAction } from "../components/menu-actions/bulk-actions/set-tags.bulk-action";

class CommandeResourcesClass extends ModelResources<Commande> {
    form = CustomForm
    get service() { return Container.get(CommandeService) }
    get parentService() { return Container.get(EvenementService) }

    icon = AiFillTag
    bulkActions = [
        SetTagsAction,
        ShowVegetauxBulkAction,
        ExportVegetauxBulkAction,
        ExportInterventionsBulkAction,
        DeleteBulkAction]
    actions = [PlantAction, ChangeParentAction]
    tabIdentifier = TabIdentifier.commande
    lists = [
        {
            resources: VegetalResources,
            get: (me) => me?.vegetaux,
            set: (me, values) => {
                me.vegetaux = values
                return instanceToInstance(me)
            }
        } as ChildResources<Commande, Vegetal>
    ];
    roles = { rest: environment.endpoints.commande.rest };
    Model = Commande
    CreateParams = CreateCommandeParams
    UpdateParams = UpdateCommandeParams
}

export const CommandeResources = new CommandeResourcesClass()