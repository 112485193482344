import { DOMAINE_TYPE } from '../enums/domaine.enum';
import { METADATA_TAG } from '../enums/metadata-tag.enum';
import { MetadataStorage } from '../utils/metadata-storage';

export function IsDomaine(type: DOMAINE_TYPE): PropertyDecorator {
    return (target: Object, propertyKey: string | symbol): void => {
        MetadataStorage.setOnClassProperty(METADATA_TAG.DOMAINE_TAG, target.constructor as any, propertyKey as string, type)
        const _additionalDomaines = MetadataStorage.getByClass(METADATA_TAG.DOMAINES_TAG, target.constructor as any) ?? []
        MetadataStorage.setOnClass(METADATA_TAG.DOMAINES_TAG, target.constructor as any, [..._additionalDomaines, type])
    }
}
