import { AxiosError } from "axios";
import { ValidationError } from "./validation-error";
import { ApiError } from "./api-error";
import { DBError } from "./db-error";

export function GetErrors(e: AxiosError<any>) {
    const validation = typeof e.response?.data?.message === "string" && e.response?.data?.message?.includes("Invalid body") ? new ValidationError(e.response.data) : undefined
    const api = validation ? undefined : e.response?.data as ApiError
    const db = e.response?.data?.message?.detail ? new DBError(e.response.data) : undefined
    return { validation, api, db }
}