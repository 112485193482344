import { IsOptional, ValidateNested } from "class-validator"
import { SearchParams } from "./search.params"
import { Type } from "class-transformer"
import { BetweenDates } from "../between-dates.params"
import { Model } from "../../models/base/model"
import { ISearchParams } from "./search-params.type"
import { Intervention } from "../../models/intervention.model"

export class SearchInterventionParams extends SearchParams implements ISearchParams<Intervention, "dateprevue" | "datedepassage" | "idevenement" | "commentaires"> {

    @IsOptional()
    @ValidateNested()
    @Type(() => Model)
    typeintervention?: Model

    @IsOptional()
    @Type(() => BetweenDates)
    @ValidateNested()
    betweenDatesDatePassage?: BetweenDates

}