import { SearchParams } from "soverdi-api-models"
import { DateRangeFilter } from "./form-controls/date-range-filter.component"
import { YearFilter } from "./form-controls/year-filter.component"
import { TFunction } from "i18next"
import { SearchParameter } from "./query-forms/search-parameters.interface"
import { DomainesFilter } from "./form-controls/domaines-filter.component"
import { TagService } from "soverdi-api-client"
import { Container } from "typedi"

export function BaseForm(t: TFunction<"translation", undefined>): Array<SearchParameter<SearchParams>> {
    return [
        {
            key: "tags",
            i18n: t("vegetal.tags"),
            component: (key, i18n, setValue, onRemove) => <DomainesFilter data-testid="tags-input" key={key} text={i18n} domaineServiceType={Container.get(TagService)} onChange={(x) => setValue(prev => ({ ...prev, tags: x }))} onRemove={onRemove} />,
            invalid: () => undefined,
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, tags: undefined }))
        },
        {
            key: "year",
            i18n: t("filters.year"),
            component: (key, i18n, setValue, onRemove) => <YearFilter key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, betweenDates: x }))} onRemove={onRemove} />,
            invalid: () => undefined,
            onClear: ({ setValue, filters }) => { if (!filters.some(f => f.key === "betweenDates")) setValue(prev => ({ ...prev, betweenDates: undefined })) }
        },
        {
            key: "betweenDates",
            i18n: t("filters.between"),
            component: (key, i18n, setValue, onRemove) => <DateRangeFilter key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, betweenDates: x }))} onRemove={onRemove} />,
            invalid: ({ filters }) => {
                if (filters.some(f => (f.key as any) === "year")) return t("QueryForm.between")
            },
            onClear: ({ setValue, filters }) => { if (!filters.some(f => (f.key as any) === "year")) setValue(prev => ({ ...prev, betweenDates: undefined })) }
        }
    ] as Array<SearchParameter<SearchParams>>
}