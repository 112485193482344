import { memo, useMemo } from "react";
import { environment } from "../../config/environment";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { CustomInputProps } from "./input.props";

export const DateInput = memo(({ value, onChange, "data-testid": dataTestId }: CustomInputProps<Date, never>) => {
    const inputProps = useMemo(() => ({ inputProps: { "data-testid": dataTestId, } }), [dataTestId])
    return (
        <SingleDatepicker
            propsConfigs={inputProps as any}
            configs={environment.dateconfig}
            date={value}
            onDateChange={onChange}
        />
    )
})