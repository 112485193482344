import { Model, SearchableModel } from "soverdi-api-models";
import { InputGroup, Input, CloseButton, InputRightElement } from "@chakra-ui/react";
import { useRoutingDisclosure } from "react-shared";
import { memo, useMemo } from "react";
import { RestService } from "api-client-shared";
import { FromModelListModal } from "./components/from-model-list.component";
import { InputProps } from "@class-former/react";
import { GetModelService } from "soverdi-api-client";

export interface FromModelListInputProps<T extends Model & SearchableModel> extends InputProps<T> {
    model: new () => T
    'data-testid'?: string;
}
function FromModelListInputComponent<T extends Model & SearchableModel>({ value, onChange, model, 'data-testid': dataTestId }: FromModelListInputProps<T>) {

    const service = useMemo(() => GetModelService(model) as RestService<T, any, any>, [model])
    const { isOpen, onOpen, onClose } = useRoutingDisclosure(service.endpoint + "/selectfromlist")

    return (
        <>
            <InputGroup>
                <Input data-testid={dataTestId} onClick={onOpen} value={value?.searchBy || ""} onChange={() => null} readOnly />
                <InputRightElement>
                    <CloseButton data-testid="close-domaine-input-button" variant={"ghost"} onClick={() => onChange(undefined)} />
                </InputRightElement>
            </InputGroup>
            <FromModelListModal isOpen={isOpen} onClose={onClose} model={model} onChange={onChange} />
        </>
    )
}

export const FromModelListInput = memo(FromModelListInputComponent) as typeof FromModelListInputComponent