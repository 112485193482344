import { Badge, HStack, CloseButton, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

interface IProps {
    text: string
    onClose: () => void
    'data-testid'?: string
    children: ReactNode
}
export function FilterFormControl({ text, onClose, children, 'data-testid': dataTestId }: IProps) {


    return (
        <Badge maxWidth={"100%"} borderRadius={5} p={2} key={"badge-" + text} colorScheme='purple'>
            <HStack data-testid={dataTestId} justifyContent={"space-between"}>
                <Text>{text}:</Text>
                {children}
                <CloseButton data-testid="remove-query-filter-button" onClick={onClose} size={"sm"} />
            </HStack>
        </Badge>
    )
}