import 'reflect-metadata';
//import "./wdyr.js"
import ReactDOM from 'react-dom/client';
import './index.css';
import './router-transitions.scss'
import App from './app';
import reportWebVitals from './reportWebVitals';
import "./i18n"
import { BrowserRouter } from 'react-router-dom';
import { Configure } from 'soverdi-api-client';
import { environment } from './config/environment';
import { Container } from "typedi"
import { AuthService } from 'portail-api-client';
import { AuthServiceMock } from './testing/mocks/auth.mock';
import * as serviceWorkerRegistration from './service-worker-registration';

if (environment.OFFLINE) {
  Container.set(AuthService, new AuthServiceMock())
}

Configure({ baseUrl: environment.API_URL })
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
