import {
    Text,
    HStack,
    Icon,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spinner,
    useDisclosure,
} from "@chakra-ui/react";
import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import { ParseAddress } from "@goazimut/address-parser";
import { getService } from "api-client-shared";
import { useTranslation } from "react-i18next";
import { IoSearchOutline } from "react-icons/io5";
import { AddressCandidate, FindAddressCandidatesParams } from "soverdi-api-models";
import { GeocoderService } from "soverdi-api-client"
import { Address } from "@goazimut/address-parser/src/interfaces/address.interface";
import { BadgeInsideFakeInput } from "react-shared";
import { InputProps } from "@class-former/react";

function AddressInputComponent({ value, onChange }: InputProps<AddressCandidate>) {

    const { t } = useTranslation()
    const [input, setInput] = useState("")
    const [address, setAddress] = useState<Address | undefined>()
    const [candidates, setCandidates] = useState<AddressCandidate[]>([])
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loading, setLoading] = useState(false)
    const service = getService(GeocoderService)
    const [error, setError] = useState<string | undefined>()

    const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value)
        if (e.target.value.length < 2) {
            setError(undefined)
            return
        }
        try {
            const _address = ParseAddress(e.target.value, { zipcode: true, province: true })
            setAddress(_address)
            setError(undefined)
        }
        catch (e: any) {
            setError(t("AddressInput.errors." + e.type))
        }
    }, [setAddress, setError, t])

    const onMenuClick = useCallback(async () => {
        if (!address) return
        setLoading(true)
        const params = new FindAddressCandidatesParams()
        params.address = address.address
        params.city = address.city
        params.postal = address.postal
        const result = await service.findAddressCandidates(params)
        setCandidates(result)
        setLoading(false)
        onOpen()
    }, [setLoading, service, setCandidates, onOpen, address])

    const onBadgeRemove = useCallback(() => {
        onChange(undefined)
    }, [onChange])


    useEffect(() => {
        if (value && value.adresse) return
        setInput("")
        setAddress(undefined)
    }, [value, setInput, setAddress])

    return (
        <>
            <HStack>
                {value && value.adresse
                    ? <BadgeInsideFakeInput label={value.adresse.printableformat} onRemove={onBadgeRemove} />
                    : <Input data-testid="address-input" value={input} onChange={onInputChange} placeholder={t("AddressInput.placeholder")} />

                }
                <Menu isLazy isOpen={isOpen} onOpen={onMenuClick} onClose={onClose}>
                    <MenuButton isDisabled={!address} data-testid="address-search-button" as={IconButton}>
                        {loading ? <Spinner /> : <Icon as={IoSearchOutline} />}
                    </MenuButton>
                    <MenuList>
                        {candidates.length === 0 ?
                            <MenuItem>{t("AddressInput.nocandidates")}</MenuItem>
                            : <>
                                {candidates.map(c => (
                                    <MenuItem data-testid="address-candidate" key={c.adresse.printableformat} onClick={() => onChange(c)}>{c.adresse.printableformat}</MenuItem>
                                ))}
                            </>
                        }
                    </MenuList>
                </Menu>
            </HStack>
            {error && <Text fontSize={"xs"} color={"red"}>{error}</Text>}
        </>
    )
}

export const AddressInput = memo(AddressInputComponent) as typeof AddressInputComponent