import { IVegetauxService } from "soverdi-api-client";
import { BulkActionProps } from "./bulk-action.props";
import { memo, useCallback, useContext } from "react";
import { AppContext } from "../../../app.context";
import { VegetalExportConfig, VegetalExportParams, vegetauxDefaultRoles } from "soverdi-api-models";
import { ExportCSVBulkAction } from "./export-csv.component";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "@chakra-ui/react";
import { useRoutingDisclosure } from "react-shared";
import { BiExport } from "react-icons/bi";

function ExportVegetauxBulkActionComponent(props: BulkActionProps<any, IVegetauxService>) {

    const { token } = useContext(AppContext)
    const { t } = useTranslation()
    const { isOpen, onOpen, onClose } = useRoutingDisclosure("/exportvegetaux")
    const exportAsCSV = useCallback(async (params: VegetalExportParams) => {
        return props.service.vegetaux.exportVegetauxForEntityIds(params)
    }, [props.service])

    return (
        <>
            {
                vegetauxDefaultRoles.exportVegetauxForEntityIds.includes(token!.role) &&
                <>
                    <Button colorScheme="whatsapp" data-testid="export-vegetaux-button" onClick={onOpen} rightIcon={<Icon as={BiExport} />}>{t("export.exportVegetaux")}</Button>
                    <ExportCSVBulkAction {...props} i18ntype="vegetaux" isOpen={isOpen} onClose={onClose} exportAsCSV={exportAsCSV} config={VegetalExportConfig} />
                </>
            }
        </>
    )
}

export const ExportVegetauxBulkAction = memo(ExportVegetauxBulkActionComponent) as typeof ExportVegetauxBulkActionComponent