import { Icon, IconButton, Menu, MenuButton, MenuGroup, MenuItem, MenuList } from "@chakra-ui/react";
import { BsArrow90DegUp, BsListUl } from "react-icons/bs";
import { useTabStack } from "../../hooks/tab-stack/tab-stack.hook";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export function PopButton() {

    const { t } = useTranslation()
    const { tabStack, pop } = useTabStack()
    const navigable = useMemo(() => tabStack.slice(0, -1), [tabStack])

    return (
        <>
            {navigable.length > 0 &&
                <Menu isLazy>
                    <MenuButton as={IconButton} px={0} variant={"ghost"} icon={<Icon mb={1} fontSize={18} as={BsArrow90DegUp}></Icon>} />
                    <MenuList zIndex={2}>
                        <MenuGroup title={t("PopButton.navigateTo")}>
                            {navigable.map((x, i) => (
                                <MenuItem key={x.as + x.resources.tabIdentifier} onClick={() => pop(navigable.length - i)}><Icon mr={2} as={x.as === "ModelListView" ? BsListUl : x.resources.icon} /> {t("models." + ((x.as === "ModelListView") ? "asList." : "") + x.resources.tabIdentifier)}</MenuItem>
                            ))}
                        </MenuGroup>
                    </MenuList>
                </Menu>}
        </>
    )
}