import { InputGroup } from "@chakra-ui/input";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Model, SearchableModel } from "soverdi-api-models";
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteList,
    AutoCompleteItem,
    AutoCompleteRefMethods,
    AutoCompleteTag,
} from "@choc-ui/chakra-autocomplete";
import { useDebounce } from "react-shared";
import { useInputState } from "react-shared";
import { IAutocompleteService } from "soverdi-api-client";
import { environment } from "../../config/environment";
import { CustomInputProps } from "./input.props";
import { useToast, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { RestService } from "api-client-shared";

export interface AutocompleteMultipleInputIprops<T extends Model & SearchableModel, U extends IAutocompleteService<T>> extends CustomInputProps<T[], U> {
    inputMinLength?: number
}
function AutocompleteMultipleInputComponent<T extends Model & SearchableModel, U extends IAutocompleteService<T> & RestService<T, any, any>>({ value, service, onChange, placeholder, 'data-testid': dataTestId, inputMinLength = 3 }: AutocompleteMultipleInputIprops<T, U>) {

    const [loading, setLoading] = useState(false)
    const [autocompleteInput, [setAutocompleteInputFromInput, setAutocompleteInput]] = useInputState("")
    const debouncedInput = useDebounce(autocompleteInput, environment.debounceDelay)
    const autocompleteRef = useRef<AutoCompleteRefMethods>(null)
    const [data, setData] = useState<T[]>([])
    const toast = useToast()
    const [focusedAtLeastOnce, setFocusedAtLeastOnce] = useState(false)
    const { t } = useTranslation()

    const fetchSuggestions = useCallback(async (value: string) => {
        if (value.length < inputMinLength) {
            setData([])
            return
        }
        setLoading(true)
        try {
            const results = value ? await service.autocomplete.autocomplete(value) : await service.getAll()
            setData(results)
        }
        catch (e) {
            console.error(e)
            toast({ colorScheme: "red", title: t("common.errorTitle"), description: t("common.errorMessage") })
        }
        finally {
            setLoading(false)
        }
    }, [service, setData, inputMinLength, t, toast])

    const onSelect = useCallback((items: string[]) => {
        const results = items.map(x => data.find(d => d.searchBy === x)!)
        setAutocompleteInput("")
        onChange(results)
    }, [onChange, data, setAutocompleteInput])

    const onFocus = useCallback(async () => {
        fetchSuggestions(debouncedInput)
        setFocusedAtLeastOnce(true)
    }, [debouncedInput, fetchSuggestions, setFocusedAtLeastOnce])

    useEffect(() => {
        if (focusedAtLeastOnce)
            fetchSuggestions(debouncedInput)
    }, [focusedAtLeastOnce, debouncedInput, fetchSuggestions])


    return (
        <AutoComplete
            multiple
            openOnFocus
            values={value?.map(x => x.searchBy)}
            isLoading={loading}
            ref={autocompleteRef}
            onChange={onSelect}
            filter={() => true}
            emptyState={<Text mx={4}>{t("common.noResult")}</Text>}
        >
            <InputGroup>
                <AutoCompleteInput onFocus={onFocus} data-testid={dataTestId} p={2} type="text" value={autocompleteInput} onChange={setAutocompleteInputFromInput} placeholder={placeholder}>
                    {({ tags }) =>
                        tags.map((tag, tid) => (
                            <AutoCompleteTag
                                data-testid="autocomplete-tag"
                                key={tid}
                                label={tag.label}
                                onRemove={tag.onRemove}
                            />
                        ))
                    }
                </AutoCompleteInput>
            </InputGroup>
            <AutoCompleteList key={data.length}>
                {data.map((filter) => (
                    <AutoCompleteItem
                        data-testid="autocomplete-item"
                        key={`option-${filter.id}`}
                        value={filter.searchBy}
                    >
                        {filter.searchBy}
                    </AutoCompleteItem>
                ))}
            </AutoCompleteList>
        </AutoComplete>
    )
}

export const AutocompleteMultipleInput = memo(AutocompleteMultipleInputComponent) as typeof AutocompleteMultipleInputComponent