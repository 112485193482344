import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {
                translation: {
                    errors: {
                        api: {
                            VEGETAUX_GREATER_THAN_QUANTITE: "The number of vegetals in an order cannot exceeds its quantity"
                        },
                        validation: {
                            isNotEmpty: "cannot be empty",
                            isString: "is not a string",
                            isNumber: "is not a number",
                            isDate: "is not a date",
                            isPostalCode: "is not a zipcode"
                        }
                    },
                    common: {
                        cancel: "Cancel",
                        open: "Open",
                        save: "Save",
                        edit: "Edit",
                        delete: "Delete",
                        create: "Create",
                        submitting: "Submitting",
                        errorTitle: "Oops!",
                        errorMessage: "An error occured..",
                        search: "Search",
                        name: "Name",
                        createdOn: "Created on ",
                        position: "Position",
                        confirm: "Confirm",
                        with: "with",
                        uniqueconstraint: " does already exists",
                        upload: "Upload",
                        estimate: "Estimated processing time is",
                        noResult: "No results"
                    },
                    dashboard: {
                        searchPlaceholder: "Search project"
                    },
                    MapModal: {
                        queryresults: "Query results"
                    },
                    options: {
                        logout: "Logout"
                    },
                    filters: {
                        placeholder: "Parameters",
                        execute: "Execute",
                        queryType: "Query type",
                        between: "Between",
                        year: "Year",
                        month: "Month",
                        name: "Name",
                        type: "Type"
                    },
                    SearchActions: {
                        createProject: "Create a project",
                        catalog: "Inventory",
                        favorites: "Favorites"
                    },
                    layers: {
                        clearMap: "Clear map"
                    },
                    ProjetTab: {
                        searchPlaceholder: "Search a site name"
                    },
                    SiteTab: {
                        searchPlaceholder: "Search an event name",
                    },
                    EvenementTab: {
                        createIntervention: "Create intervention",
                        plantTree: "Planter"
                    },
                    DomaineInput: {
                        deleteRequest: "Do you want to delete the domaine "
                    },
                    Domaine: {
                        deleteConfirmTitle: "Delete domaine",
                        deleteConfirmBody: "Are you sure? You can't undo this action afterwards."
                    },
                    EmptyList: {
                        title: "Looks like this is empty.."
                    },
                    models: {
                        favorites: "Favorites",
                        projet: "Project",
                        site: "Site",
                        evenement: "Event",
                        intervention: "Intervention",
                        commande: "Order",
                        vegetal: "Vegetal",
                        produit: "Product",
                        espece: "Species",
                        "catalog": "Inventory",
                        asList: {
                            projet: "Projects",
                            site: "Sites",
                            evenement: "Events",
                            intervention: "Interventions",
                            commande: "Orders",
                            vegetal: "Vegetals",
                            produit: "Products",
                            espece: "Species",
                            users: "Users",
                            inventory: "Inventory"
                        },
                        ENTITE_ADMINISTRATIVE: "Administrative entity",
                        CALIBRE: "Caliber",
                        ETAT_SANTE: "Health state",
                        FORMAT_POT: "Pot format",
                        FOURNISSEUR: "Provider",
                        RAISON_MORTALITE: "Reason of death",
                        RESEAU: "Network",
                        TYPE_ESPECE: "Species type",
                        TYPE_EVENEMENT: "Event type",
                        TYPE_INTERVENTION: "Action",
                        INTERVENTION_PROGRESS: "Progress",
                        "TERRITOIRE": "Territory",
                        TAG: "Tag"
                    },
                    ListItem: {
                        favorite: "Add to favorites",
                        unassign: "Remove from favorites"
                    },
                    QueryForm: {
                        between: "Between cannot be used with year or month",
                        month: "Month cannot be used without year",
                        "empty": " cannot be empty"
                    },
                    SiteQueryForm: {
                        codepostal: "Zipcode cannot be empty",
                        reseau: "Network cannot be empty",
                        entiteadministrative: "Administrative entity cannot be empty",
                        adresse: "Address cannot be empty"
                    },
                    EspeceQueryForm: {
                        genre: "Genre cannot be empty",
                        nomespece: "Species name cannot be empty",
                        cultivar: "Cultivar cannot be empty",
                        code: "Code cannot be empty",
                        remplacementid: "Replacement cannot be empty"
                    },
                    VegetalQueryForm: {
                        etatsante: "Health state cannot be empty",
                        raisonmortalite: "Reason of death cannot be empty"
                    },
                    projet: {
                        nom: "Name",
                        _nom_normalized: "Name",
                        users: "Users",
                        tags: "Tags"
                    },
                    site: {
                        nom: "Name",
                        _nom_normalized: "Name",
                        importid: "identifiant (name)",
                        codepostal: "Zipcode",
                        reseau: "Network",
                        adresse: "Address",
                        zipcode: "Zipcode",
                        entiteadministrative: "Administrative entity",
                        users: "Users",
                        idprojet: "Project",
                        geometry: "Location",
                        territoire: "Territory",
                        tags: "Tags"
                    },
                    evenement: {
                        nom: "Name",
                        importid: "identifiant (name, scheduled date)",
                        noevenement: "Event number",
                        description: "Description",
                        dateprevue: "Scheduled date",
                        typeevenement: "Event type",
                        users: "Users",
                        idsite: "Site",
                        tags: "Tags"
                    },
                    intervention: {
                        datedepassage: "Passage date",
                        importid: "identifiant (planned date, action)",
                        dateprevue: "Planned date",
                        typeintervention: "Action",
                        commentaires: "Comments",
                        "passageyear": "Passage year",
                        "passagebetween": "Passage between",
                        users: "Users",
                        idsite: "Event",
                        tags: "Tags"
                    },
                    commande: {
                        quantite: "Quantity",
                        importid: "identifiant (product)",
                        planted: "Planted",
                        produit: "Product",
                        tags: "Tags"
                    },
                    vegetal: {
                        id: "id",
                        specimenid: "specimenid (branché)",
                        etatsante: "Health state",
                        importid: "identifiant (coordinates)",
                        raisonmortalite: "Cause of mortality",
                        remplacementid: "Replacement",
                        commentaires: "Comments",
                        photos: "Pictures",
                        idcommande: "Order",
                        geometry: "Location",
                        presence: "Presence",
                        dommages: "Damages",
                        cuvette: "Basin",
                        tags: "Tags"
                    },
                    produit: {
                        sku: "Sku",
                        dhs: "DHS",
                        hauteur: "Height",
                        fournisseur: "Provider",
                        formatpot: "Pot size",
                        calibre: "Caliber",
                        espece: "Species"
                    },
                    espece: {
                        genre: "Genre",
                        nomespece: "Species name",
                        cultivar: "Cultivar",
                        code: "Code",
                        typeespece: "Species type",
                        couleur: "Color (map layer Espèces)"
                    },
                    User: {
                        username: "Username"
                    },
                    Catalog: {
                        title: "Inventory"
                    },
                    DefaultBulkActions: {
                        showTrees: "Display trees",
                        show: "Display",
                        items: " items",
                        assignations: "The assignations"
                    },
                    BulkActions: {
                        "addtag": "Tag",
                        "addTagAlert": "Tags",
                        addTagEmpty: "Saving with no tags will remove the tags for the selected resources",
                        soustraitant: "Assign",
                        unassignAll: "Remove all users",
                        soustraitantAlert: "Assignation"
                    },
                    menuActions: {
                        change: "Your change ",
                        changeParentButton: "Change the",

                    },
                    ScanAction: {
                        button: "Scan barcode",
                        multiplecommandes: "There seems to be multiple order with the same product. This shouldn't happen.",
                        noCommandeResult: "There doesn't seem to be an order in this event with the product sku "
                    },
                    PopButton: {
                        navigateTo: "Navigate back to"
                    },
                    AddressInput: {
                        placeholder: "5333 Avenue Casgrain, Montréal",
                        nocandidates: "No results found",
                        errors: {
                            adresse: "The address and street is missing or invalid",
                            ville: "The city is missing or invalid"
                        }
                    },
                    CommandeActions: {
                        planter: "Plant",
                        choosePosition: "Please confirm its desired position"
                    },
                    markComplete: {
                        mark: "Mark complete"
                    },
                    VegetalForm: {
                        selectReplacement: "Choose a replacement",
                        selectReplacementSubtext: "Click on a vegetal to select"
                    },
                    FromModelList: {
                        title: "Select an item"
                    },
                    DeleteAction: {
                        "foreignkey": " prevents this resource from being deleted."
                    },
                    ShowVegetauxAction: {
                        novegetaux: "This resource doesn't seem to have any vegetals yet"
                    },
                    CustomFormErrors: {
                        isNotEmpty: "This field is required",
                        isPostalCode: "This postal code is invalid"
                    },
                    PhotosInput: {
                        prompt: "Do you want to upload these photos?",
                        photos: "The upload",
                        error: "An error occured in the server"
                    },
                    export: {
                        categoryOverhead: "This category will add",
                        vegetaux: {
                            title: "Export the vegetals as CSV"
                        },
                        interventions: {
                            title: "Export the interventions as CSV"
                        },
                        exportVegetaux: "Export vegetals",
                        exportInterventions: "Export interventions"
                    },
                    import: {
                        button: "Import",
                        isString: "string",
                        isNumber: "number",
                        isDate: "date",
                        inventory: "Import inventory",
                        vegetaux: "Import vegetals",
                        interventions: "Import interventions",
                        warning: "This import can take a few minutes. You can now close this popup and you will be notified upon completion.",
                        inventorySuccess: "Your CSV was successfully imported",
                        imported: "were imported",
                        skipped: "existed already",
                        csv: "CSV file with the columns",
                        totalEspeceImported: "species",
                        totalProduitImported: "product(s)",
                        totalProjetsImported: "project(s)",
                        totalSitesImported: "site(s)",
                        totalEvenementsImported: "event(s)",
                        totalCommandesImported: "order(s)",
                        totalVegetauxImported: "vegetal(s)",
                        errors: {
                            encoding: "The encoding is not UTF-8",
                            isPostalCode: "is not a valid postal code",
                            nodata: "This CSV seems to be empty",
                            missing: "Some columns are missing:",
                            validation: "Some columns are invalids at row #",
                            isNotEmpty: "cannot be empty",
                            isString: "is not a string",
                            isNumber: "is not a number",
                            isDate: "is not a date"
                        },
                        vegetalDescription: "If noevenement is missing, a new event will be created automatically",
                        legende: "Legend",
                        optional: "Optional",
                        required: "Required",
                        formats: "Formats",
                        date: "date: YYYY-MM-JJ",
                        latlon: "lat, lon: WGS84 (ex 34.056687222, -117.195731667)",
                        download: "Download template"
                    },
                    time: {
                        minutes: "minute(s)",
                        secondes: "second(s)"
                    }
                }
            },
            fr: {
                translation: {
                    errors: {
                        api: {
                            VEGETAUX_GREATER_THAN_QUANTITE: "Le nombre de vegetaux dans une commande ne peut pas dépasser sa quantité"
                        },
                        validation: {
                            isNotEmpty: "ne peut être vide",
                            isString: "n'est pas du texte",
                            isNumber: "n'est pas un nombre",
                            isDate: "n'est pas une date",
                            isPostalCode: "n'est pas un code postal"
                        }
                    },
                    "common": {
                        "cancel": "Annuler",
                        "open": "Ouvrir",
                        "save": "Enregistrer",
                        "edit": "Modifier",
                        "delete": "Supprimer",
                        "create": "Créer",
                        "submitting": "Soumission",
                        "errorTitle": "Oups!",
                        errorMessage: "Une erreur est survenue..",
                        "search": "Rechercher",
                        "name": "Nom",
                        "createdOn": "Créé le ",
                        "position": "Position",
                        "confirm": "Confirmer",
                        with: "avec",
                        uniqueconstraint: " existe déjà",
                        upload: "Téléverser",
                        estimate: "Le temps de traitement est estimé à",
                        noResult: "Pas de résultat"
                    },
                    "dashboard": {
                        "searchPlaceholder": "Rechercher un projet"
                    },
                    "MapModal": {
                        "queryresults": "Résultats de la requête"
                    },
                    "options": {
                        "logout": "Déconnexion"
                    },
                    "filters": {
                        "placeholder": "Paramètres",
                        "execute": "Exécuter",
                        "queryType": "Type de requête",
                        "between": "Entre dates",
                        "year": "Année",
                        "month": "Mois",
                        "name": "Nom",
                        type: "Type"
                    },
                    "SearchActions": {
                        "createProject": "Créer un projet",
                        "catalog": "Inventaire",
                        "favorites": "Favoris"
                    },
                    layers: {
                        clearMap: "Débarasser la carte"
                    },
                    "ProjetTab": {
                        "searchPlaceholder": "Rechercher un nom de site",
                    },
                    "SiteTab": {
                        "searchPlaceholder": "Rechercher un nom d'événement"
                    },
                    "EvenementTab": {
                        "plantTree": "Planter"
                    },
                    "DomaineInput": {
                        "deleteRequest": "Voulez-vous supprimer le domaine "
                    },
                    "Domaine": {
                        "deleteConfirmTitle": "Supprimer le domaine",
                        "deleteConfirmBody": "Êtes-vous sûr? Vous ne pourrez pas annuler cette action par la suite."
                    },
                    "EmptyList": {
                        "title": "Ceci semble vide.."
                    },
                    "models": {
                        "favorites": "Favoris",
                        "projet": "Projet",
                        "site": "Site",
                        "evenement": "Événement",
                        "intervention": "Intervention",
                        "commande": "Commande",
                        "vegetal": "Végétal",
                        "produit": "Produit",
                        "espece": "Espèce",
                        "catalog": "Inventaire",
                        "asList": {
                            "projet": "Projets",
                            "site": "Sites",
                            "evenement": "Événements",
                            "intervention": "Interventions",
                            "commande": "Commandes",
                            "vegetal": "Végétaux",
                            "produit": "Produits",
                            "espece": "Espèces",
                            "users": "Utilisateurs",
                            inventory: "Inventaire"
                        },
                        "ENTITE_ADMINISTRATIVE": "Entité administrative",
                        "CALIBRE": "Calibre",
                        "ETAT_SANTE": "État de santé",
                        "FORMAT_POT": "Format du pot",
                        "FOURNISSEUR": "Fournisseur",
                        "RAISON_MORTALITE": "Raison du décès",
                        "RESEAU": "Réseau",
                        "TYPE_ESPECE": "Type d'espèce",
                        "TYPE_EVENEMENT": "Type d'événement",
                        "TYPE_INTERVENTION": "Type d'intervention",
                        "INTERVENTION_PROGRESS": "Progression de l'intervention",
                        "TERRITOIRE": "Territoire",
                        TAG: "Tag"
                    },
                    "ListItem": {
                        "favorite": "Ajouter aux favoris",
                        "unassign": "Retirer des favoris"
                    },
                    "QueryForm": {
                        "name": "Le nom ne peut pas être vide",
                        "between": "Entre dates ne peut pas être utilisé avec année ou mois",
                        "month": "Le mois ne peut pas être utilisé sans année",
                        "type": "Type ne peut pas être vide",
                        "emptyYear": "L'année ne peut pas être vide"
                    },
                    "SiteQueryForm": {
                        "codepostal": "Le code postal ne peut pas être vide",
                        "reseau": "Le réseau ne peut pas être vide",
                        "entiteadministrative": "L'entité administrative ne peut pas être vide",
                        "adresse": "L'adresse ne peut pas être vide"
                    },
                    EspeceQueryForm: {
                        genre: "Genre ne peut pas être vide",
                        nomespece: "Nom espece ne peut pas être vide",
                        cultivar: "Cultivar ne peut pas être vide",
                        code: "Code ne peut pas être vide",
                        remplacementid: "Replacement ne peut pas être vide"
                    },
                    VegetalQueryForm: {
                        etatsante: "État de santé ne peut pas être vide",
                        raisonmortalite: "Raison mortalité ne peut pas être vide"
                    },
                    projet: {
                        nom: "Nom",
                        _nom_normalized: "Nom",
                        users: "Utilisateurs",
                        tags: "Tags"
                    },
                    "site": {
                        nom: "Nom",
                        importid: "identifiant (nom)",
                        _nom_normalized: "Nom",
                        users: "Utilisateurs",
                        "codepostal": "Code postal",
                        "reseau": "Réseau",
                        "adresse": "Adresse",
                        "zipcode": "Code postal",
                        "entiteadministrative": "Entité administrative",
                        idprojet: "Projet",
                        geometry: "Position",
                        territoire: "Territoire",
                        tags: "Tags"
                    },
                    "evenement": {
                        nom: "Nom",
                        importid: "identifiant (nom, date prévue)",
                        users: "Utilisateurs",
                        "noevenement": "Numéro événement",
                        "description": "Description",
                        "dateprevue": "Date prévue",
                        "typeevenement": "Type d'événement",
                        idsite: "Site",
                        tags: "Tags"
                    },
                    "intervention": {
                        users: "Utilisateurs",
                        importid: "identifiant (date prévue, type d'intervention)",
                        "datedepassage": "Date de passage",
                        "dateprevue": "Date prévue",
                        "typeintervention": "Type d'intervention",
                        "commentaires": "Commentaires",
                        "passageyear": "Année passage",
                        "passagebetween": "Passage entre",
                        idevenement: "Événement",
                        tags: "Tags"
                    },
                    "commande": {
                        "quantite": "Quantité",
                        importid: "identifiant (produit)",
                        "planted": "Planté",
                        idevenement: "Événement",
                        produit: "Produit",
                        tags: "Tags"
                    },
                    "vegetal": {
                        id: "id",
                        specimenid: "specimenid (branché)",
                        "etatsante": "État de santé",
                        importid: "identifiant (coordonées)",
                        "raisonmortalite": "Cause de mortalité",
                        "remplacementid": "Remplacement",
                        "commentaires": "Commentaires",
                        "photos": "Photos",
                        idcommande: "Commande",
                        geometry: "Position",
                        presence: "Présence",
                        dommages: "Dommages",
                        cuvette: "Cuvette",
                        tags: "Tags"
                    },
                    "produit": {
                        "sku": "SKU",
                        "dhs": "DHS",
                        "hauteur": "Hauteur",
                        "fournisseur": "Fournisseur",
                        "formatpot": "Taille du pot",
                        "calibre": "Calibre",
                        espece: "Espèce"
                    },
                    "espece": {
                        genre: "Genre",
                        "nomespece": "Nom espèce",
                        cultivar: "Cultivar",
                        "code": "Code",
                        typeespece: "Type d'espèce",
                        couleur: "Couleur (carte couche Espèces)"
                    },
                    "User": {
                        "username": "Nom d'utilisateur"
                    },
                    "Catalog": {
                        "title": "Inventaire"
                    },
                    "DefaultBulkActions": {
                        "showTrees": "Visualiser les arbres",
                        "show": "Visualiser",
                        "items": " articles",
                        "assignations": "Assignations"
                    },
                    "BulkActions": {
                        "addtag": "Tagger",
                        "addTagAlert": "Tags",
                        addTagEmpty: "Enregistrer sans tags enlevera les tags sur les resources selectionnées",
                        "soustraitant": "Assigner",
                        "unassignAll": "Retirer tous les utilisateurs",
                        "soustraitantAlert": "Assignation"
                    },
                    menuActions: {
                        change: "Votre changement ",
                        changeParentButton: "Changer le",
                    },
                    ScanAction: {
                        button: "Scaner un code barre",
                        multiplecommandes: "Il semble y avoir plus d'une commande avec le meme produit, ceci ne devrait pas arriver.",
                        noCommandeResult: "Il ne semble pas exister de commande dans cet événement avec le sku de produit "
                    },
                    "PopButton": {
                        "navigateTo": "Revenir à"
                    },
                    "AddressInput": {
                        "placeholder": "5333 Avenue Casgrain, Montréal",
                        "nocandidates": "Aucun résultat trouvé",
                        "errors": {
                            "adresse": "L'adresse et la rue sont manquants ou invalides",
                            "ville": "La ville est manquante ou invalide"
                        }
                    },
                    "CommandeActions": {
                        "planter": "Planter",
                        "choosePosition": "Veuillez confirmer sa position souhaitée"
                    },
                    "move": {
                        "confirm": "Êtes-vous satisfait de cette position?",
                        "confirmmessage": "Selectionner et glisser pour deplacer"
                    },
                    "markComplete": {
                        "mark": "Marquer comme accompli"
                    },
                    VegetalForm: {
                        selectReplacement: "Choisir un remplacement",
                        selectReplacementSubtext: "Cliquer sur un végétal pour selectionner"
                    },
                    FromModelList: {
                        title: "Choisir un item"
                    },
                    DeleteAction: {
                        "foreignkey": " empêche cette ressource d'être supprimée."
                    },
                    ShowVegetauxAction: {
                        novegetaux: "Cette ressource ne semble pas encore contenir de vegetaux."
                    },
                    CustomFormErrors: {
                        isNotEmpty: "Ce champ est obligatoire",
                        isPostalCode: "Ce code postal est invalide"
                    },
                    PhotosInput: {
                        prompt: "Voulez-vous téléverser ces photos?",
                        photos: "Le dépôt de fichiers",
                        error: "Une erreur s'est produite dans le serveur"
                    },
                    export: {
                        categoryOverhead: "Cette catégorie ajoute",
                        vegetaux: {
                            title: "Exporter les vegetaux en CSV"
                        },
                        interventions: {
                            title: "Exporter les interventions en CSV"
                        },
                        exportVegetaux: "Exporter vegetaux",
                        exportInterventions: "Exporter interventions"
                    },
                    import: {
                        button: "Importer",
                        isString: "texte",
                        isNumber: "nombre",
                        isDate: "date",
                        inventory: "Importer inventaire",
                        vegetaux: "Importer vegetaux",
                        interventions: "Importer interventions",
                        warning: "L'import peut prendre plusieurs minutes. Vous pouvez maintenant fermer cette fenêtre et vous serez notifiez lorsque ce sera terminé.",
                        inventorySuccess: "Votre CSV à été importé avec succès",
                        imported: "ont étés importés",
                        skipped: "existaient déjà",
                        error: "Votre CSV n'a pas pu être importé.",
                        csv: "Fichier CSV avec les colonnes",
                        totalEspeceImported: "espèce(s)",
                        totalProduitImported: "produit(s)",
                        totalProjetsImported: "projet(s)",
                        totalSitesImported: "site(s)",
                        totalEvenementsImported: "événement(s)",
                        totalCommandesImported: "commande(s)",
                        totalVegetauxImported: "végétaux",
                        errors: {
                            encoding: "L'encodage n'est pas UTF-8",
                            isPostalCode: "is not a valid postal code",
                            nodata: "Ce csv ne semble pas contenir de données",
                            missing: "Certaines colonnes sont manquantes:",
                            validation: "Certaines colonnes sont invalides à la rangée #",
                            isNotEmpty: "ne peut être vide",
                            isString: "n'est pas du texte",
                            isNumber: "n'est pas un nombre",
                            isDate: "n'est pas une date"
                        },
                        vegetalDescription: "Si noevenement est manquant, un nouvel evenement sera créé automatiquement",
                        legende: "Légende",
                        optional: "Optionel",
                        required: "Requis",
                        formats: "Formats",
                        date: "date: YYYY-MM-JJ",
                        latlon: "lat, lon: WGS84 (ex 34.056687222, -117.195731667)",
                        download: "Télécharger gabarit"
                    },
                    time: {
                        minutes: "minute(s)",
                        secondes: "seconde(s)"
                    }
                }

            }
        },
        lng: "fr", // if you're using a language detector, do not define the lng option
        fallbackLng: "fr",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });
