import { useCallback, useContext, useEffect, useMemo } from "react";
import { TabStackContext } from "../../contexts/tab-stack.context";
import { ViewInfo } from "./tab-stack-item.interface";
import { useLocation, useNavigate } from "react-router-dom";
import { TabIdentifier } from "./tab-identifier.enum";

export function useTabStack() {

    const { tabStack, setTabStack } = useContext(TabStackContext)
    const currentTab = useMemo(() => (tabStack && tabStack.length) > 0 ? tabStack[tabStack.length - 1] : undefined, [tabStack])
    const navigate = useNavigate()
    const location = useLocation()
    const pathPrefix = "/dashboard/selection"
    const getPath = useCallback((tabStackItem: ViewInfo) => pathPrefix + "/" + TabIdentifier[tabStackItem.resources.tabIdentifier] + (tabStackItem.as === "ModelListView" ? "list" : ""), [])

    const clear = useCallback(() => setTabStack([]), [setTabStack])

    const pop = useCallback((i: number = 1) => {
        setTabStack(prev => {
            if (prev.length <= i) return []
            const beforeLast = prev[prev.length - (i + 1)]
            navigate(getPath(beforeLast), { state: { dynamicLocation: true } })
            const cool = prev.slice(0, -i)
            return cool
        })
    }, [setTabStack, navigate, getPath])

    const push = useCallback((tab: ViewInfo) => {
        setTabStack(prev => {
            navigate(getPath(tab), { state: { dynamicLocation: true, from: prev.length > 0 ? getPath(prev[prev.length - 1]) : "/dashboard" } });
            return [...prev, tab]
        });
    }, [setTabStack, getPath, navigate]);

    const set = useCallback((tab: ViewInfo) => {
        navigate(getPath(tab), { state: { dynamicLocation: true, from: "/dashboard" } });
        setTabStack([tab]);
    }, [setTabStack, navigate, getPath]);

    const isPathInLocation = useCallback((path: string) => {
        if (location.pathname.includes(path + "/")) return true
        if (location.pathname.endsWith(path)) return true
        return false
    }, [location.pathname])

    useEffect(() => {
        const index = tabStack.findIndex(x => isPathInLocation(getPath(x)))
        if (index < tabStack.length - 1)
            setTabStack(tabStack.slice(0, index + 1))
    }, [tabStack, isPathInLocation, getPath, setTabStack])

    return { push, pop, set, tabStack, currentTab, clear };
}