import { InputGroup, InputRightElement, Icon } from "@chakra-ui/react";
import { AutoComplete, AutoCompleteInput, AutoCompleteList, AutoCompleteItem, AutoCompleteRefMethods } from "@choc-ui/chakra-autocomplete";
import { useRef, useCallback, ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";
import { SearchParameterIdentifier } from "./query-forms/search-parameters.interface";

interface IProps {
    selected: Array<SearchParameterIdentifier<any>>
    data: Array<SearchParameterIdentifier<any>>
    onChange: (x: Array<SearchParameterIdentifier<any>>) => void
}
export function ParameterAutocomplete({ selected, data, onChange }: IProps) {

    const { t } = useTranslation()
    const [autocompleteInput, _setAutocompleteInput] = useState("")
    const autocompleteRef = useRef<AutoCompleteRefMethods>(null)

    const resetAutocomplete = useCallback(() => {
        if (!autocompleteRef.current) return
        autocompleteRef.current.resetItems(true)
    }, [autocompleteRef])

    const onAddFilter = useCallback((filterKeys: string[]) => {
        const filterKey = filterKeys[0]
        if (!filterKey) return
        resetAutocomplete()
        if (selected.find(a => a.key === filterKey)) return
        const queryFilter = data.find(x => x.i18n === filterKey)
        if (!queryFilter) return
        onChange([...selected, queryFilter])
        _setAutocompleteInput("")
    }, [resetAutocomplete, onChange, selected, _setAutocompleteInput, data])

    const setAutocompleteInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        _setAutocompleteInput(e.target.value)
        resetAutocomplete()
    }, [resetAutocomplete, _setAutocompleteInput])

    return (
        <AutoComplete multiple={true} openOnFocus ref={autocompleteRef} onChange={onAddFilter}>
            {({ isOpen }) => (
                <>
                    <InputGroup>
                        <AutoCompleteInput data-testid="query-builder-parameter-input" type="text" value={autocompleteInput} onChange={setAutocompleteInput} variant="filled" placeholder={t("filters.placeholder")} />
                        <InputRightElement
                            children={
                                <Icon as={isOpen ? FiChevronRight : FiChevronDown} />
                            }
                        />
                    </InputGroup>
                    <AutoCompleteList>
                        {data.map((filter, cid) => (
                            <AutoCompleteItem
                                data-testid="parameter-autocomplete-item"
                                key={`option-${cid}`}
                                value={filter.i18n}
                            >
                                {filter.i18n}
                            </AutoCompleteItem>
                        ))}
                    </AutoCompleteList>
                </>
            )}
        </AutoComplete>
    )
}