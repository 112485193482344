import { Badge, HStack, IconButton, Icon, Text } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai"
import { FakeInput } from "./fake-input.component";

interface IProps {
    label: string
    onRemove: () => void
}
export function BadgeInsideFakeInput({ label, onRemove }: IProps) {
    return (
        <FakeInput>
            <Badge borderRadius={6} py={0} pl={4} pr={0} colorScheme={"green"}>
                <HStack spacing={0} alignItems={"center"} justifyContent={"center"}>
                    <Text whiteSpace={"normal"} data-testid="badge-fake-input">{label}</Text>
                    <IconButton
                        data-testid="remove-badge-button"
                        height={"30px"}
                        _hover={{}}
                        variant={"ghost"}
                        onClick={onRemove}
                        aria-label="close"
                        icon={<Icon as={AiOutlineClose} />} />
                </HStack>
            </Badge>
        </FakeInput>
    )
}