export function findLastIndex<T>(array: T[], predicate: (x: T, i?: number) => boolean) {
    if (!Array.isArray(array)) {
        throw new TypeError('The first argument must be an array');
    }

    if (typeof predicate !== 'function') {
        throw new TypeError('The second argument must be a function');
    }

    for (let i = array.length - 1; i >= 0; i--) {
        if (predicate(array[i], i)) {
            return i;
        }
    }

    return -1;
}
