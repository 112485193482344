import { useTranslation } from "react-i18next";
import { QueryForm } from "./query-form.component";
import { memo, useCallback, useMemo, useState } from "react";
import { SearchSiteParams } from "soverdi-api-models";
import { BaseForm } from "../base-form";
import { TextFilter } from "../form-controls/text-filter.component";
import { DomaineFilter } from "../form-controls/domaine-filter.component";
import { EntiteAdministrativeService, ReseauService, SiteService } from "soverdi-api-client";
import { getService } from "api-client-shared";
import { useTabStack } from "../../../../hooks/tab-stack/tab-stack.hook";
import { SiteResources } from "../../../../model-resources/sites.resources";
import { Container } from "typedi"
import { ModelListViewInfo } from "../../../../hooks/tab-stack/tab-stack-item.interface";
import { SearchParameter } from "./search-parameters.interface";

interface IProps {
    onClose: () => void
}
export const SiteQueryForm = memo(({ onClose }: IProps) => {
    const { t } = useTranslation()
    const [params, setParams] = useState(new SearchSiteParams())
    const service = getService(SiteService)
    const { set } = useTabStack()
    const parameters = useMemo(() => [
        {
            key: "name",
            i18n: t("filters.name"),
            component: (key, i18n, setValue, onRemove) => <TextFilter data-testid="nom-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, nom: x }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && value.nom === "") return t("common.name") + t("QueryForm.empty") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, nom: undefined }))
        },
        {
            key: "reseau",
            i18n: t("site.reseau"),
            component: (key, i18n, setValue, onRemove) => <DomaineFilter data-testid="reseau-input" key={key} text={i18n} domaineServiceType={Container.get(ReseauService)} onChange={(x) => setValue(prev => ({ ...prev, reseau: { id: x.id } }))} onRemove={onRemove} />,
            invalid: () => undefined,
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, reseau: undefined }))
        },
        {
            key: "entiteadministrative",
            i18n: t("site.entiteadministrative"),
            component: (key, i18n, setValue, onRemove) => <DomaineFilter data-testid="entiteadministrative-input" key={key} text={i18n} domaineServiceType={Container.get(EntiteAdministrativeService)} onChange={(x) => setValue(prev => ({ ...prev, entiteadministrative: { id: x.id } }))} onRemove={onRemove} />,
            invalid: () => undefined,
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, entiteadministrative: undefined }))
        },
        ...BaseForm(t)
    ] as Array<SearchParameter<SearchSiteParams>>, [t])

    const search = useCallback(async () => {
        const data = await service.search.searchForIds(params)
        set(new ModelListViewInfo(SiteResources, data, true))
    }, [params, set, service])

    return (
        <QueryForm onSearch={search} parameters={parameters} value={params} onChange={setParams} onClose={onClose} />
    )
})