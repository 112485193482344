import { Address } from "../interfaces/address.interface";
import { AddressRegexes } from "../config/_regexes"
import { AddressParseError } from "../errors/address-parse.error";

function ParsePart(toParse: string, regex: RegExp, type: "adresse" | "ville" | "province" | "zipcode", optional?: boolean) {
    const prefixRegex = /,\s+/
    const matches = toParse.match(regex)
    if (!matches || matches?.length === 0) {
        if (optional) return { result: undefined, toParse }
        throw new AddressParseError(type, type + " could not be parsed")
    }
    const raw = matches[0]
    const result = raw.replace(prefixRegex, "")
    toParse = toParse.replace(raw, "")
    return { result, toParse }
}

export function ParseAddress(stringAddress: string, optionals?: { zipcode?: boolean, province?: boolean }) {

    const { result: zipcodeResult, toParse: toParseAfterZipcode } = ParsePart(stringAddress, AddressRegexes.zipcode, "zipcode", optionals?.zipcode)
    const { result: addressResult, toParse: toParseAfterAddress } = ParsePart(toParseAfterZipcode, AddressRegexes.address, "adresse")
    const { result: villeResult, toParse: toParseAfterVille } = ParsePart(toParseAfterAddress, AddressRegexes.ville, "ville")
    const { result: provinceResult } = ParsePart(toParseAfterVille, AddressRegexes.province, "province", optionals?.province)

    const params = {} as Address
    params.address = addressResult!
    params.city = villeResult!
    params.postal = zipcodeResult
    params.region = provinceResult

    return params
}