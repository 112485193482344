import axios from "axios"
import { plainToInstance } from "class-transformer"
import { CompositionService } from "api-client-shared"
import { FilterParams, environment } from "soverdi-api-models"


export class SearchService<T, J> extends CompositionService<T>{

    async searchForEntities(body: J): Promise<T[]> {
        const res = await axios.post<T>(this.url.join([this.endpoint, environment.endpoints.generics.search.base]), body)
        const clazzed = plainToInstance(this.modelClazz, res.data) as Array<T>
        return clazzed
    }

    async searchForIds(body: J): Promise<number[]> {
        const res = await axios.post<Array<number>>(this.url.join([this.endpoint, environment.endpoints.generics.search.base, environment.endpoints.generics.search.searchForIds]), body)
        return res.data
    }

    async filter(params: FilterParams): Promise<number[]> {
        const res = await axios.post<Array<number>>(this.url.join([this.endpoint, environment.endpoints.generics.search.base, environment.endpoints.generics.search.filter]), params)
        return res.data
    }
}

export interface ISearchService<T, J> {
    readonly search: SearchService<T, J>
}
