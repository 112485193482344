import { Service, Inject } from "typedi";
import { RestService } from "api-client-shared";
import { Commande, CreateCommandeParams, SearchCommandeParams, SearchEspeceParams, UpdateCommandeParams } from "soverdi-api-models";
import { environment } from "soverdi-api-models";
import { SoverdiAPIUrl } from "../utils/soverdi-api-url";
import { SOVERDI_API_HOSTNAME_TOKEN } from "../tokens/soverdi-api-hostname.token";
import { IAutocompleteService, AutocompleteService } from "./composition/autocomplete.service";
import { IBulkService, BulkService } from "./composition/bulk.service";
import { IVegetauxService, VegetauxService } from "./composition/vegetaux.service";
import { ISearchService, SearchService } from "./composition/search-service";

@Service()
export class CommandeService extends RestService<Commande, CreateCommandeParams, UpdateCommandeParams> implements IBulkService<Commande, never>, IAutocompleteService<Commande>, IVegetauxService, ISearchService<Commande, SearchCommandeParams>{
    modelClazz = Commande
    @Inject()
    protected url!: SoverdiAPIUrl
    endpoint = environment.endpoints.commande.base

    readonly autocomplete = new AutocompleteService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly bulk = new BulkService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly vegetaux = new VegetauxService(this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly search = new SearchService<Commande, SearchCommandeParams>(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
}