import { CloseButton, InputGroup, InputRightElement, useToast, Text } from "@chakra-ui/react";
import { AutoCompleteInput, AutoCompleteList, AutoCompleteItem, AutoCompleteRefMethods, Item, AutoComplete } from "@choc-ui/chakra-autocomplete";
import { ChangeEvent, memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "react-shared";
import { Projet } from "soverdi-api-models";
import { environment } from "../../config/environment";
import { getService } from "api-client-shared";
import { ProjetService } from "soverdi-api-client";

interface IProps {
    result?: Projet,
    onChange: (value: Projet | undefined) => void
    inputMinLength?: number
}
export const SearchBarAutocomplete = memo(({ result, onChange, inputMinLength = 2 }: IProps) => {

    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [autocompleteInput, _setAutocompleteInput] = useState(result?.searchBy || "")
    const debouncedInput = useDebounce(autocompleteInput, environment.debounceDelay)
    const autocompleteRef = useRef<AutoCompleteRefMethods>(null)
    const [data, setData] = useState<Projet[]>([])
    const service = getService(ProjetService)
    const toast = useToast()

    const fetchSuggestions = useCallback(async (value: string) => {
        if (!value) return
        if (value.length < inputMinLength) {
            setData([])
            return
        }
        setLoading(true)
        try {
            autocompleteRef.current?.resetItems(false)
            const results = await service.autocomplete.autocomplete(value)
            setData(results)
        }
        catch (e) {
            console.error(e)
            toast({ colorScheme: "red", title: t("common.errorTitle"), description: t("common.errorMessage") })
        }
        setLoading(false)
    }, [service, setData, t, toast, inputMinLength])

    const setAutocompleteInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        _setAutocompleteInput(e.target.value)
        if (e.target.value !== result?.searchBy)
            onChange(undefined)
    }, [_setAutocompleteInput, onChange, result?.searchBy])

    const onSelect = useCallback((params: { item: Item }) => {
        const searchby = params.item.value
        const result = data.find(d => d.searchBy === searchby)
        onChange(result)
    }, [onChange, data])

    const clear = useCallback(() => _setAutocompleteInput(""), [_setAutocompleteInput])

    const onFocus = useCallback(() => {
        fetchSuggestions(debouncedInput)
    }, [fetchSuggestions, debouncedInput])

    useEffect(() => {
        fetchSuggestions(debouncedInput)
    }, [debouncedInput, fetchSuggestions])

    useEffect(() => result && _setAutocompleteInput(result?.searchBy), [result, _setAutocompleteInput])


    return (
        <AutoComplete
            filter={() => true}
            openOnFocus
            isLoading={loading}
            ref={autocompleteRef}
            onSelectOption={onSelect}
            emptyState={<Text mx={4}>{t("common.noResult")}</Text>}
        >
            <InputGroup>
                <AutoCompleteInput onFocus={onFocus} pl={"40px"} data-testid="search-bar-autocomplete" type="text" value={autocompleteInput} onChange={setAutocompleteInput} placeholder={t("dashboard.searchPlaceholder")} />
                <InputRightElement>
                    <CloseButton data-testid="clear-search-button" onClick={clear} />
                </InputRightElement>
            </InputGroup>
            {autocompleteInput.length >= inputMinLength &&
                <AutoCompleteList key={data.length}>
                    {data.map((filter) => {
                        return (
                            <AutoCompleteItem
                                data-testid="autocomplete-item"
                                key={`option-${filter.id}`}
                                value={filter.searchBy}
                            >
                                {filter.searchBy}
                            </AutoCompleteItem>
                        )
                    })}
                </AutoCompleteList>
            }
        </AutoComplete>
    )
})