import { IsNotEmpty, IsNumber, IsOptional, IsString } from "class-validator";
import { InterventionImportCSV } from "./intervention-import.csv"

export class InterventionExportCSV extends InterventionImportCSV {
    @IsString()
    @IsNotEmpty()
    projet!: string
    @IsString()
    @IsNotEmpty()
    site!: string
    @IsNumber()
    @IsNotEmpty()
    idvegetal!: number
    @IsNumber()
    @IsNotEmpty()
    annee!: number
    @IsString()
    @IsOptional()
    tags: string | undefined
}