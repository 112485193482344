import { Badge, Card, Checkbox, HStack, Icon, IconButton, Spacer, Text } from "@chakra-ui/react";
import { ChangeEvent, ReactNode, useCallback } from "react";
import { IoAddCircleOutline } from "react-icons/io5";
import { BrickMenu } from "react-shared";
import "./list-controls.component.css"

interface IProps {
    onSelectAll: () => void
    onDeselectAll: () => void
    numberOfSelected: number
    numberOfFiltered: number
    numberTotal: number
    onCreateClick?: () => void
    actions: Array<ReactNode>
}
export function ListControls({ onSelectAll, onDeselectAll, onCreateClick, numberOfSelected, numberOfFiltered, numberTotal, actions }: IProps) {

    const onCheckedChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked
        checked ? onSelectAll() : onDeselectAll()
    }, [onSelectAll, onDeselectAll])

    return (
        <HStack width={"full"}>
            <Card width={"full"}>
                <HStack pl={3}>
                    <HStack gap={0}>
                        <Checkbox mx={2} onChange={onCheckedChange} />
                        {numberOfSelected > 0 && <Badge data-testid="number-of-selected-items" className="number-selected" colorScheme="teal" fontWeight='bold'>
                            {numberOfSelected}
                        </Badge>}
                    </HStack>
                    <Text data-testid="number-of-filtered-items">
                        {`${numberOfFiltered}/${numberTotal}`}
                    </Text>

                    <Spacer />
                    <BrickMenu data-testid="list-controls-actions-button" isDisabled={numberOfSelected === 0} buttons={actions} />
                </HStack>
            </Card>
            {onCreateClick && <IconButton data-testid="create-child-button" onClick={onCreateClick} ml={1} aria-label="create" icon={<Icon fontSize={22} as={IoAddCircleOutline} />} />}
        </HStack>
    )
}