import { getMetadataStorage } from "class-validator"

type IsPrimitive<T> = T extends Date | string | number | boolean | symbol | null | undefined ? true : false;

function isPrimitive<T>(value: T): IsPrimitive<T> {
    return (
        typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean' ||
        typeof value === 'symbol' ||
        value === null ||
        value === undefined ||
        typeof (value as unknown as Date).getMonth === 'function'

    ) as IsPrimitive<T>;
}

/** Strip will remove any values not decorated by class-validator decorators */
export function Strip<T>(instance: T): T {
    if (isPrimitive(instance)) return instance
    const cleanObject: any = {};
    const okeys = Object.keys(instance as any)
    const proto = Object.getPrototypeOf(instance)
    const storage = getMetadataStorage()
    for (const key of okeys) {
        // Check if the property has any decorators' metadata
        const validatorsMetadata = storage.getTargetValidationMetadatas(proto.constructor, "", true, false)
        const isNotExtraenous = validatorsMetadata.find(v => v.propertyName === key)
        if (isNotExtraenous) {
            let property = (instance as any)[key]
            if (!property) continue
            if (property && !isPrimitive(property)) {
                if (Array.isArray(property))
                    property = property.map(p => Strip(p))
                else
                    property = Strip(property)
            }
            cleanObject[key] = property;
        }
    }

    return cleanObject;
}