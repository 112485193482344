import { Input } from "@chakra-ui/react";
import { ChangeEvent, useCallback, useState } from "react"
import { IFilterFormControlProps } from "./filter-form-control.props"
import { FilterFormControl } from "./filter-form-control.component"

export function TextFilter({ text, onChange: _onChange, onRemove, 'data-testid': dataTestId }: IFilterFormControlProps<string>) {

    const [value, setValue] = useState<string>("")
    const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setValue(value)
        e.target.style.width = (5 + value.length) + "ch";
        _onChange(e.target.value)
    }, [_onChange])


    return (
        <FilterFormControl onClose={onRemove} text={text}>
            <Input data-testid={dataTestId} type="text" textAlign={"center"} px={2} htmlSize={1} value={value} onChange={onChange}
                height="25px" width='5ch' variant={"filled"}></Input>
        </FilterFormControl>
    )
}