import { useCallback, useMemo, useState } from "react";
import { Clean, FormableModel, Model, Strip } from "soverdi-api-models";
import { plainToInstance } from "class-transformer"
import { TabIdentifier } from "../../../hooks/tab-stack/tab-identifier.enum";
import { useStatusToast } from "react-shared";
import { useErrorHandler } from "./error-handler";
import { RestService } from "api-client-shared";
import { useTranslation } from "react-i18next";

interface IParams<T extends Model, U> {
    FormSubmitType: new () => U
    tabIdentifier: TabIdentifier
    model: T,
    service: RestService<T, any, any>
    onChange: (value: T) => void
}
export function useSubmit<T extends Model, U>({ onChange, service, model, FormSubmitType, tabIdentifier }: IParams<T, U>) {

    const { t } = useTranslation()
    const { onSuccess } = useStatusToast()
    const handleError = useErrorHandler()
    const [loading, setLoading] = useState(false)
    const isUpdateMode = useMemo(() => model.id, [model])

    const save = useCallback(async (values: Model & FormableModel) => {
        setLoading(true)
        const result = isUpdateMode ? await service.update(model!.id, values) : await service.create(values)
        onChange(result)
        return result
    }, [service, onChange, model, isUpdateMode])

    const submit = useCallback(async (values: T) => {
        try {
            const formdataWithExtraenousvalues = plainToInstance(FormSubmitType, values);
            const stripped = Strip(formdataWithExtraenousvalues)
            const strippedParams = plainToInstance(FormSubmitType, stripped);
            const formdata = Clean(strippedParams)

            await save(formdata)
            onSuccess(t("models." + tabIdentifier), "save")
            return true
        }
        catch (e) {
            handleError(tabIdentifier, e)
            return false
        }
        finally {
            setLoading(false)
        }
    }, [onSuccess, setLoading, save, handleError, FormSubmitType, t, tabIdentifier])

    return { loading, submit, isUpdateMode }
}