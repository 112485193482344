import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from "@chakra-ui/react";
import { memo, useCallback, useContext } from "react";
import { environment, InterventionImportCSV, InventoryItemCSV, VegetalImportCSV } from "soverdi-api-models";
import { useRoutingDisclosure, } from "react-shared";
import { BiImport } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../app.context";
import { CatalogResources } from "../../model-resources/catalog/catalog.resources";
import { VegetalResources } from "../../model-resources/vegetal.resources";
import { CSVImport } from "./csv-import.component";
import { getService } from "api-client-shared";
import { ImportService } from "soverdi-api-client";
import { ModalHeader } from "../modal/modal-header.component";
import { InterventionResources } from "../../model-resources/intervention.resources";

export const ImportationActions = memo(() => {

    const { t } = useTranslation()
    const { token } = useContext(AppContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const inventoryDisclosure = useRoutingDisclosure("/importinventory", { openDelay: 200 })
    const vegetauxDisclosure = useRoutingDisclosure("/importvegetaux", { openDelay: 200 })
    const interventionsDisclosure = useRoutingDisclosure("/importinterventions", { openDelay: 200 })
    const service = getService(ImportService)

    const importInventory = useCallback((value: string) => service.inventory(value), [service])
    const importVegetaux = useCallback((value: string) => service.vegetaux(value), [service])
    const importInterventions = useCallback((value: string) => service.interventions(value), [service])

    return (
        <>
            {[...environment.endpoints.import.inventory.roles, ...environment.endpoints.import.vegetaux.roles].includes(token!.role) &&
                <>
                    <Menu isLazy isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
                        <MenuButton data-testid="import-menu-button" boxShadow={"lg"} as={IconButton} icon={<Icon fontSize="20px" as={BiImport}></Icon>} />
                        <MenuList>
                            {environment.endpoints.import.inventory.roles.includes(token!.role) && <MenuItem data-testid="import-inventory-button" onClick={inventoryDisclosure.onOpen} icon={<Icon fontSize="20px" as={CatalogResources.icon} />}>{t("import.inventory")}</MenuItem>}
                            {environment.endpoints.import.vegetaux.roles.includes(token!.role) && <MenuItem data-testid="import-vegetaux-button" onClick={vegetauxDisclosure.onOpen} icon={<Icon fontSize="20px" as={VegetalResources.icon} />}>{t("import.vegetaux")}</MenuItem>}
                            {environment.endpoints.import.interventions.roles.includes(token!.role) && <MenuItem data-testid="import-interventions-button" onClick={interventionsDisclosure.onOpen} icon={<Icon fontSize="20px" as={InterventionResources.icon} />}>{t("import.interventions")}</MenuItem>}
                        </MenuList>
                    </Menu>
                    {inventoryDisclosure.isOpen && <CSVImport
                        title={t("models.asList.inventory")}
                        {...inventoryDisclosure}
                        csvClass={InventoryItemCSV}
                        submit={importInventory}
                        header={<ModalHeader icon={CatalogResources.icon} title={t("import.inventory")} actions={[]} />}
                    />}
                    {vegetauxDisclosure.isOpen && <CSVImport
                        title={t("models.asList.vegetal")}
                        {...vegetauxDisclosure}
                        description={t("import.vegetalDescription")}
                        csvClass={VegetalImportCSV}
                        submit={importVegetaux}
                        header={<ModalHeader icon={VegetalResources.icon} title={t("import.vegetaux")} actions={[]} />}
                    />}
                    {interventionsDisclosure.isOpen && <CSVImport
                        title={t("models.asList.intervention")}
                        {...interventionsDisclosure}
                        csvClass={InterventionImportCSV}
                        submit={importInterventions}
                        header={<ModalHeader icon={InterventionResources.icon} title={t("import.interventions")} actions={[]} />}
                    />}
                </>
            }
        </>
    )
})