import { DomaineFilter, FilterParams } from "soverdi-api-models";
import { TypedDomainesState } from "../../selectors/domaines.selector"
import { useCallback } from "react";
import { ISearchService } from "soverdi-api-client"

export function useFilterData(service: ISearchService<any, any>) {
    return useCallback(async (data: number[], stringFilter: string, domaineFilters: TypedDomainesState[]) => {
        if (!data || data.length === 0) return data
        const params = new FilterParams()
        params.filter = stringFilter
        params.domaines = domaineFilters.map(d => d.filters.map(f => ({ id: f.id, type: d.type } as DomaineFilter))).flat()
        if (params.filter === "" && params.domaines.length === 0) return data
        params.in = data
        const result = await service.search.filter(params)
        return result
    }, [service])
}