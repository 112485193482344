import { Service } from "typedi";
import { CompositionService } from "api-client-shared";
import axios from "axios"
import { AssignParams, environment } from "soverdi-api-models";


@Service()
export class AssignationService<T> extends CompositionService<T>{

    async assignMe(body: AssignParams): Promise<void> {
        await axios.put<Array<number>>(this.url.join([this.endpoint, environment.endpoints.generics.assignation.base, environment.endpoints.generics.assignation.assignMe]), body)
    }

    async unassignMe(body: AssignParams): Promise<void> {
        await axios.put<T>(this.url.join([this.endpoint, environment.endpoints.generics.assignation.base, environment.endpoints.generics.assignation.unassignMe]), body)
    }

    async assign(iduser: number, body: AssignParams): Promise<void> {
        await axios.put<Array<number>>(this.url.join([this.endpoint, environment.endpoints.generics.assignation.base, environment.endpoints.generics.assignation.assign, iduser.toString()]), body)
    }

    async unassign(iduser: number, body: AssignParams): Promise<void> {
        await axios.put<T>(this.url.join([this.endpoint, environment.endpoints.generics.assignation.base, environment.endpoints.generics.assignation.unassign, iduser.toString()]), body)
    }

    async unassignAll(ids: Array<number>): Promise<void> {
        await axios.put<T>(this.url.join([this.endpoint, environment.endpoints.generics.assignation.base, environment.endpoints.generics.assignation.unassignAll], { ids }))
    }
}

export interface IAssignationService<T> {
    readonly assignation: AssignationService<T>
}
