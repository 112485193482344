import { IsOptional, IsString } from "class-validator"
import { SearchParams } from "./search.params"
import { ISearchParams } from "./search-params.type"
import { Projet } from "../../models/projet.model"

export class SearchProjetParams extends SearchParams implements ISearchParams<Projet> {
    
    @IsOptional()
    @IsString()
    nom?: string

}