import { IsNumber, ValidateNested } from "class-validator";
import { ImportResponse } from "./import-response";

class VegetauxImportResult {
    @IsNumber()
    intervention!: number
}

export class InterventionImportResponse extends ImportResponse {
    @ValidateNested()
    added = new VegetauxImportResult()
    @ValidateNested()
    skipped = new VegetauxImportResult()
}