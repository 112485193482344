import { useTranslation } from "react-i18next";
import { QueryForm } from "./query-form.component";
import { memo, useCallback, useMemo, useState } from "react";
import { SearchProjetParams } from "soverdi-api-models";
import { BaseForm } from "../base-form";
import { getService } from "api-client-shared";
import { ProjetService } from "soverdi-api-client";
import { useTabStack } from "../../../../hooks/tab-stack/tab-stack.hook";
import { ProjetResources } from "../../../../model-resources/projet.resources";
import { ModelListViewInfo } from "../../../../hooks/tab-stack/tab-stack-item.interface";
import { TextFilter } from "../form-controls/text-filter.component";
import { SearchParameter } from "./search-parameters.interface";

interface IProps {
    onClose: () => void
}
export const ProjetQueryForm = memo(({ onClose }: IProps) => {
    const { t } = useTranslation()
    const [params, setParams] = useState(new SearchProjetParams())
    const service = getService(ProjetService)
    const parameters = useMemo(() => [
        {
            key: "nom",
            i18n: t("filters.name"),
            component: (key, i18n, setValue, onRemove) => <TextFilter data-testid="nom-input" key={key} text={i18n} onChange={(x) => setValue(prev => ({ ...prev, nom: x }))} onRemove={onRemove} />,
            invalid: ({ value, dirty }) => { if (dirty && value.nom === "") return t("common.name") + t("QueryForm.empty") },
            onClear: ({ setValue }) => setValue(prev => ({ ...prev, nom: undefined }))
        },
        ...BaseForm(t)] as Array<SearchParameter<SearchProjetParams>>, [t])
    const { set } = useTabStack()

    const search = useCallback(async () => {
        const data = await service.search.searchForIds(params)
        set(new ModelListViewInfo(ProjetResources, data, true))
    }, [params, set, service])

    return (
        <QueryForm onSearch={search} parameters={parameters} value={params} onChange={setParams} onClose={onClose} />
    )
})