import { Service, Inject } from "typedi";
import { RestService } from "api-client-shared";
import { ProduitParams, Produit, SearchProduitParams } from "soverdi-api-models";
import { environment } from "soverdi-api-models";
import { SoverdiAPIUrl } from "../utils/soverdi-api-url";
import { SOVERDI_API_HOSTNAME_TOKEN } from "../tokens/soverdi-api-hostname.token";
import { IAutocompleteService, AutocompleteService } from "./composition/autocomplete.service";
import { BulkService, IBulkService } from "./composition/bulk.service";
import { ISearchService, SearchService } from "./composition/search-service";

@Service()
export class ProduitService extends RestService<Produit, ProduitParams, Partial<ProduitParams>> implements IAutocompleteService<Produit>, IBulkService<Produit, never>, ISearchService<Produit, SearchProduitParams>{
    modelClazz = Produit
    @Inject()
    protected url!: SoverdiAPIUrl
    endpoint = environment.endpoints.produit.base

    readonly search = new SearchService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly autocomplete = new AutocompleteService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
    readonly bulk = new BulkService(this.modelClazz, this.endpoint, SOVERDI_API_HOSTNAME_TOKEN)
}