import { Formik, FieldProps as FormikFieldProps, FormikProps, FastField, FormikState } from 'formik';
import { ForwardedRef, forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { plainToInstance } from 'class-transformer';
import { useFormConfig } from '../hooks/_form-config.hook';
import { validateSync } from 'class-validator';
import { ClassFormerProps } from '../props/class-former.props';
import { FieldProps } from '../props/field-props';
import { createInitialValues } from '../utils/_initial-values';
import { ClassFormerRef } from '../types/class-former-ref';

function ClassFormerComponent<T extends Object>({
  data,
  onSubmit: _onSubmit,
  config,
  fieldWrapper,
  children
}: ClassFormerProps<T>, ref?: ForwardedRef<ClassFormerRef<T>>) {

  const DataType: new () => T = useMemo(() => Object.getPrototypeOf(data).constructor, [data])

  const { initialValues, preservedValues: unusedValues } = useMemo(() => createInitialValues(data), [data])
  const formConfig = useFormConfig(initialValues, data, config);

  const onSubmit = useCallback(
    async (values: any) => {
      for (const key of Object.keys(unusedValues)) {
        if (values[key] === undefined)
          values[key] = (unusedValues as any)[key]
      }
      const params = plainToInstance(DataType, values);
      _onSubmit(params);
    },
    [_onSubmit, DataType]
  );

  const formikRef = useRef<FormikProps<T>>(null)

  const resetForm = useCallback((nextState?: Partial<FormikState<T>> | undefined) => {
    formikRef.current?.resetForm({ ...nextState, values: nextState?.values ? createInitialValues(nextState.values).initialValues : undefined })
  }, [formikRef])

  useImperativeHandle(ref, () => ({ resetForm }));

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => onSubmit(values)}
      validateOnMount
      innerRef={formikRef}
      validate={values => {
        const params = plainToInstance(DataType, values);
        let validation = validateSync(params);
        validation = validation.filter(v => formConfig.find(c => c.name === v.property))
        let errors = {};
        validation.forEach(e => {
          const constraints = e.constraints ? Object.keys(e.constraints) : [];
          if (constraints.length > 0) (errors as any)[e.property] = constraints[0];
        });
        return errors;
      }}
    >
      {(formProps: FormikProps<T>) => {
        const fields = formConfig.map(c => {
          const Component = c.component;
          return {
            meta: c.meta,
            input: (
              <FastField key={c.name} name={c.name}>
                {({ field, meta, form }: FormikFieldProps) => {
                  const value = c.pipein ? c.pipein(field.value) : field.value
                  const onChange = (v: any) => {
                    let pipedValue = v
                    if (c.pipeout)
                      c.pipeout({ value: v, setValues: formProps.setValues })
                    else
                      field.onChange(pipedValue?.target ? pipedValue : { target: { value: pipedValue, name: c.name } })
                  }
                  const props = {
                    ...field,
                    ...c.props,
                    onChange,
                    value
                  };
                  const input = <Component {...props} />;
                  return fieldWrapper({ name: field.name, meta, input, form });
                }}
              </FastField>
            )
          } as FieldProps<any>;
        });
        return children({ fields, formConfig, errors: formProps.errors, touched: formProps.touched, dirty: formProps.dirty, resetForm });
      }}
    </Formik>
  );
}

const ClassFormerComponentFunction = forwardRef(ClassFormerComponent)

export const ClassFormer = memo(ClassFormerComponentFunction) as typeof ClassFormerComponent;
