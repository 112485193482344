import axios from "axios"
import { CompositionService } from "api-client-shared"
import { DeleteParams, EditParams, Model, environment } from "soverdi-api-models";

export class BulkService<T extends Model, U extends EditParams> extends CompositionService<T> {

    async edit(params: U): Promise<void> {
        await axios.put(this.url.join([this.endpoint, environment.endpoints.generics.bulk.base, environment.endpoints.generics.bulk.edit]), params)
    }

    async delete(params: DeleteParams): Promise<void> {
        await axios.post(this.url.join([this.endpoint, environment.endpoints.generics.bulk.base, environment.endpoints.generics.bulk.delete]), params)
    }
}

export interface IBulkService<T extends Model, U extends EditParams> {
    readonly bulk: BulkService<T, U>
}
