import { ChildResources, ModelResources } from "./model.resources";
import { ProjetService, SiteService } from "soverdi-api-client";
import { TabIdentifier } from "../hooks/tab-stack/tab-identifier.enum";
import { CreateSiteParams, Evenement, Site, UpdateSiteParams, environment } from "soverdi-api-models";
import { Container } from "typedi"
import { EvenementResources } from "./evenement.resources";
import { BiSolidSchool } from "react-icons/bi";
import { instanceToInstance } from "class-transformer"
import { AssignMeBulkAction } from "../components/menu-actions/bulk-actions/assignme.bulk-action";
import { DeleteBulkAction } from "../components/menu-actions/bulk-actions/delete.bulk-action";
import { AssignBulkAction } from "../components/menu-actions/bulk-actions/assign.bulk-action";
import { ShowVegetauxBulkAction } from "../components/menu-actions/bulk-actions/show-vegetaux.bulk-action";
import { ShowBulkAction } from "../components/menu-actions/bulk-actions/show.bulk-action";
import { UnassignAllBulkAction } from "../components/menu-actions/bulk-actions/unassign-all.bulk-action";
import { CustomForm } from "../components/model-forms/form.component";
import { ExportVegetauxBulkAction } from "../components/menu-actions/bulk-actions/export-vegetaux.bulk-action";
import { ExportInterventionsBulkAction } from "../components/menu-actions/bulk-actions/export-interventions.bulk-action";
import { ChangeParentAction } from "../components/menu-actions/actions/change-parent.action";
import { SetTagsAction } from "../components/menu-actions/bulk-actions/set-tags.bulk-action";

class SiteResourcesClass extends ModelResources<Site> {
    form = CustomForm
    get service() { return Container.get(SiteService) }
    get parentService() { return Container.get(ProjetService) }

    icon = BiSolidSchool
    bulkActions = [
        SetTagsAction,
        AssignMeBulkAction,
        AssignBulkAction,
        UnassignAllBulkAction,
        ShowBulkAction,
        ShowVegetauxBulkAction,
        ExportVegetauxBulkAction,
        ExportInterventionsBulkAction,
        DeleteBulkAction
    ]
    actions = [ChangeParentAction]
    tabIdentifier = TabIdentifier.site
    lists = [
        {
            resources: EvenementResources,
            get: (me) => me?.evenements,
            set: (me, values) => {
                me.evenements = values
                return instanceToInstance(me)
            }
        } as ChildResources<Site, Evenement>
    ];
    roles = { rest: environment.endpoints.site.rest };
    Model = Site
    CreateParams = CreateSiteParams
    UpdateParams = UpdateSiteParams
}

export const SiteResources = new SiteResourcesClass()
