import { memo, useCallback, useContext, useState } from "react";
import { Model, SearchableModel, restDefaultRoles } from "soverdi-api-models";
import { BsFillTrashFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useDeleteAlert, useStatusToast } from "react-shared";
import { IBulkService } from "soverdi-api-client";
import { BulkActionProps } from "./bulk-action.props";
import { AppContext } from "../../../app.context";
import { MapContext } from "../../../contexts/map.context";
import { Button, Icon } from "@chakra-ui/react";
import { useTabStack } from "../../../hooks/tab-stack/tab-stack.hook";

function DeleteBulkActionComponent<T extends Model & SearchableModel>({ service, onChange, data }: BulkActionProps<T, IBulkService<T, never>>) {

    const { t } = useTranslation()
    const dalert = useDeleteAlert()
    const salert = useStatusToast()
    const { token } = useContext(AppContext)
    const { mapHandle } = useContext(MapContext)
    const [loading, setLoading] = useState(false)
    const { pop } = useTabStack()

    const onDelete = useCallback(async () => {
        const actionlabel = (data.length + t("DefaultBulkActions.items"))
        const confirm = await dalert(actionlabel)
        if (!confirm) return
        setLoading(true)
        const ids = data
        try {
            await service.bulk.delete({ ids })
            if (onChange) onChange(prev => prev.filter(d => !ids.includes(d)))
            await mapHandle?.clear()
            pop()
            setTimeout(() => salert.onSuccess(actionlabel, "delete"), 100)
        }
        catch (e: any) {
            console.error(e)
            const table = e.response.data.table
            salert.onError(actionlabel, "delete", `${t("models." + table).toLowerCase()}${t("DeleteAction.foreignkey")}`)
        }
        onChange && onChange(prev => prev.filter(p => !ids.includes(p)))
        setLoading(false)
    }, [data, dalert, salert, t, onChange, service.bulk, mapHandle, setLoading, pop])

    return (
        <>
            {restDefaultRoles.delete.includes(token!.role) &&
                <Button isLoading={loading} loadingText={t("common.delete")} key="delete-button" colorScheme="red" data-testid="delete-button" onClick={onDelete} rightIcon={<Icon as={BsFillTrashFill} />}>{t("common.delete")}</Button>
            }
        </>
    )
}

export const DeleteBulkAction = memo(DeleteBulkActionComponent) as typeof DeleteBulkActionComponent
