import { IsEnum, IsOptional, ValidateNested, IsNumber } from "class-validator";
import { Type } from "class-transformer";
import { PARENT_TYPE } from "../enums/parent-type.enum";

export class Parent {

    @IsNumber()
    id!: number

    @IsEnum(PARENT_TYPE)
    type!: PARENT_TYPE

    @IsOptional()
    @ValidateNested()
    @Type(() => Parent)
    parent?: Parent

}