import { Type } from "class-transformer";
import { IsNumber, IsOptional, ValidateNested } from "class-validator";
import { Parent } from "../parent.model";
import { FormConfig } from "@class-former/decorators";
import { Model } from "./model";

export class ModelWithParent extends Model {

    @FormConfig({ skip: true })
    @IsOptional()
    @ValidateNested()
    @Type(() => Parent)
    parent?: Parent
}