import { Service, Inject } from "typedi";
import { AuthService } from "./auth.service";
import axios from "axios";
import { GOnetMunicipalite, IGOnetMunicipalite } from "portail-api-models";
import { environment } from "../config/_environment";
import { plainToClass } from "class-transformer";
import { PortailAPIUrl } from "../utils/portail-api-url";

@Service()
export class MunicipaliteService {

    @Inject()
    private url!: PortailAPIUrl

    constructor(@Service() private auth: AuthService) { }

    async get(): Promise<Array<GOnetMunicipalite>> {
        await this.auth.beforeRequest()
        const res = await axios.get<Array<IGOnetMunicipalite>>(this.url.join([environment.API.ENDPOINTS.municipalites]))
        const clazzed = res.data.map(d => plainToClass(GOnetMunicipalite, d))
        return clazzed
    }

}