import { IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { Espece } from './espece.model'
import { Domaine } from "./domaine.model";
import { Type } from "class-transformer";
import { SearchableModel } from "./composition/searchable.model";
import { ModelWithMeta } from "./base/model-with-meta";
import { TypedDomaine } from "./typed-domaine.model";
import { DOMAINE_TYPE } from "../enums/domaine.enum";
import { IsDomaine } from "../decorators/_domaine.decorator";
import { FormableModel } from "./composition/formable.model";
import { AddDomaines } from "../decorators/_add-domaine.decorator";

@AddDomaines(DOMAINE_TYPE.TYPE_ESPECE, DOMAINE_TYPE.CALIBRE)
export class Produit extends ModelWithMeta implements SearchableModel, FormableModel {

    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Espece)
    espece!: Espece

    @IsString()
    sku!: string

    @IsDomaine(DOMAINE_TYPE.FOURNISSEUR)
    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Domaine)
    fournisseur!: Domaine

    @IsDomaine(DOMAINE_TYPE.CALIBRE)
    @IsNotEmpty()
    @ValidateNested()
    @Type(() => Domaine)
    calibre!: Domaine

    @IsDomaine(DOMAINE_TYPE.FORMAT_POT)
    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Domaine)
    formatpot!: Domaine

    @IsNumber()
    @IsOptional()
    dhs?: number

    @IsNumber()
    @IsNotEmpty()
    hauteur!: number

    public get searchBy() { return this.espece.searchBy + (this.sku ? ` (sku: ${this.sku})` : "") }
    public get subtext() { return [{ value: (this.dhs ? `DHS: ${this.dhs}mm, ` : "") + `H: ${this.hauteur}cm, ${this.calibre.tostring(", ")}${this.formatpot.tostring()}` }] }
    public get domaines() { return [...this.espece.domaines, new TypedDomaine(this.calibre, DOMAINE_TYPE.CALIBRE)] }
    get parentid() { return 0 }
    set parentid(id: number) { }
}