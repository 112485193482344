// @ts-ignore
import { defaultMetadataStorage as esm5storage } from 'class-transformer/esm5/storage';
// @ts-ignore
import { defaultMetadataStorage as cjsstorage } from 'class-transformer/cjs/storage';
// @ts-ignore
import { defaultMetadataStorage as esm2015storage } from 'class-transformer/esm2015/storage';

function populated(storage: any) {
    return [storage._typeMetadatas,
    storage._transformMetadatas,
    storage._exposeMetadatas,
    storage._excludeMetadatas,
    storage._ancestorsMap].filter(m => m.size > 0).length > 0
}

export function getClassTransformerStorage() {
    const storage = populated(esm5storage) ? esm5storage : populated(cjsstorage) ? cjsstorage : populated(esm2015storage) ? esm2015storage : undefined
    if (!storage)
        throw new Error("Class-transformer storage could not be found from esm5, cjs or esm2015 instances")
    return storage
}