import { HStack, Button } from "@chakra-ui/react"
import { t } from "i18next"
import { memo } from "react"

interface IProps {
    onCancel: () => void
    formInvalid: boolean
    createMode: boolean
    loading: boolean
}
export const FormButtons = memo(({ onCancel, formInvalid, createMode, loading }: IProps) => {

    return (
        <HStack justifyContent={"space-between"} my={10}>
            <Button data-testid="form-cancel-button" onClick={onCancel}>
                {t("common.cancel")}
            </Button>
            <Button data-testid="form-submit-button" isLoading={loading} loadingText={t("common.submitting")} isDisabled={formInvalid} colorScheme="teal" type="submit">
                {createMode ? t("common.create") : t("common.save")}
            </Button>
        </HStack>
    )
})