import { Service, Inject } from "typedi";
import { environment } from "soverdi-api-models";
import { SoverdiAPIUrl } from "../utils/soverdi-api-url";
import axios from "axios"
import { AddressCandidate, FindAddressCandidatesParams } from "soverdi-api-models"

@Service()
export class GeocoderService {
    @Inject()
    protected url!: SoverdiAPIUrl
    endpoint = environment.endpoints.geocoder.base

    public async findAddressCandidates(params: FindAddressCandidatesParams) {
        const result = await axios.get<AddressCandidate[]>(this.url.join([this.endpoint, environment.endpoints.geocoder.findAddressCandidates.route]), { params })
        return result.data
    }
}
