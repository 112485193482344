export const environment = {
    USER_REPORT: {
        SLACK_WEBHOOK_URL: "https://hooks.slack.com/services/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"
    },
    API: {
        ENDPOINTS: {
            some: "some",
            allIds: "allids"
        }
    }
}