import { IsEnum, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { DOMAINE_TYPE } from "../enums/domaine.enum";
import { Type } from "class-transformer";

export class DomaineFilter {
    @IsNumber()
    id!: number
    @IsEnum(DOMAINE_TYPE)
    type!: DOMAINE_TYPE
}

export class FilterParams {

    @IsString()
    filter?: string

    @ValidateNested({ each: true })
    @Type(() => DomaineFilter)
    domaines?: Array<DomaineFilter>

    @IsNumber(undefined, { each: true })
    in!: Array<number>

}