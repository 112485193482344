import { IsLowercase, IsString } from "class-validator";
import { Model } from "./base/model";

export class Domaine extends Model {
    @IsString()
    @IsLowercase()
    nom!: string

    public tostring(suffix?: string): string {
        if (this.nom === "MISSING") return ""
        return this.nom + (suffix || "")
    }
}