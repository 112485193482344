import {
    Popover,
    PopoverTrigger,
    Icon,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    VStack,
    IconButton,
    HStack,
    PopoverCloseButton,
    Box,
    Portal,
    useDisclosure,
} from "@chakra-ui/react";
import { IconType } from "react-icons/lib";
import { ReactNode, forwardRef, useImperativeHandle, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

interface IProps {
    buttons: Array<ReactNode>
    closeOnBlur?: boolean
    isDisabled?: boolean
    usePortal?: boolean
    icon?: IconType,
    "data-testid"?: string
}

export interface MenuRef {
    onClose: () => void
}
export const BrickMenu = forwardRef<MenuRef, IProps>(({ buttons, isDisabled, icon, usePortal, closeOnBlur, "data-testid": dataTestId }, ref) => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    useImperativeHandle(ref, () => ({
        onClose
    }))

    const popoverContent = useMemo(() => (
        <PopoverContent width={"auto"} maxWidth={["calc( 100vw - 48px )", "450px"]}>
            <PopoverArrow />
            {!closeOnBlur &&
                <>
                    < PopoverCloseButton />
                    <Box mb={6} />
                </>
            }
            <PopoverBody p={4}>
                <VStack alignItems={"stretch"} spacing={"2"}>
                    <HStack flexWrap={"wrap"} gap={2} overflowX={"hidden"} justifyContent={"end"}>
                        {buttons}
                    </HStack>
                </VStack>
            </PopoverBody>
        </PopoverContent>
    ), [closeOnBlur, buttons])

    return (
        <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur={closeOnBlur || false} isLazy={true}>
            <PopoverTrigger>
                {buttons.length > 0 ?
                    <IconButton isDisabled={isDisabled} data-testid={dataTestId} variant={"ghost"} icon={<Icon fontSize={icon ? "20px" : undefined} as={icon || BsThreeDotsVertical} />} aria-label="tabs"></IconButton>
                    : <></>
                }
            </PopoverTrigger>
            {usePortal ?
                <Portal>
                    {popoverContent}
                </Portal>
                : popoverContent
            }
        </Popover>
    )
})