import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { ParameterAutocomplete } from "../parameter-autocomplete.component";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, HStack, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SearchParams } from "soverdi-api-models";
import { SearchParameter, SearchParameterIdentifier } from "./search-parameters.interface";

interface IProps<T extends SearchParams> {
    value: T,
    parameters: Array<SearchParameter<T>>
    onChange: Dispatch<SetStateAction<T>>,
    onSearch: () => Promise<void>
    onClose: () => void
}
export function QueryForm<T extends SearchParams>({ value, parameters, onChange: _onChange, onSearch, onClose }: IProps<T>) {

    const { t } = useTranslation()

    const [dirty, setDirty] = useState(() => parameters.map(f => false))
    const parameterIds = useMemo(() => parameters.map(f => ({ key: f.key, i18n: f.i18n } as SearchParameterIdentifier<T>)), [parameters])
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState<Array<SearchParameterIdentifier<T>>>([])
    const allDirty = useMemo(() => parameters.map(p => filters.some(f => f.key === p.key)).every((active, i) => active ? dirty[i] : true), [dirty, parameters, filters])
    const error = useMemo(() => parameters.map((p, i) => {
        if (filters.some(f => f.key === p.key))
            return p.invalid({ value, filters, dirty: dirty[i] })
        return undefined
    }).filter(x => x).join(". "), [parameters, filters, value, dirty])
    const invalidForm = useMemo(() => !!error || !allDirty, [error, allDirty])

    const _onSearch = useCallback(async () => {
        setLoading(true)
        try {
            await onSearch()
        }
        catch (e) {
            console.error(e)
        }
        finally {
            setLoading(false)
        }
    }, [onSearch, setLoading])

    const removeFilter = useCallback((key: keyof T, i: number) => {
        setFilters(prev => prev.filter(p => p.key !== key))
        setDirty(prev => prev.map((p, j) => i === j ? false : p))
        parameters[i].onClear({ filters, setValue: _onChange })
    }, [setFilters, parameters, filters, _onChange])

    const onChange = useCallback((i: number, valueOrUpdater: SetStateAction<T>) => {
        setDirty(prev => prev.map((p, j) => i === j ? true : p));
        _onChange(valueOrUpdater);
    }, [setDirty, _onChange]);

    const components = useMemo(() => parameters.map((filter, i) =>
        filters.some(f => f.key === filter.key) ? filter.component(filter.key, filter.i18n, (valueOrUpdater) => onChange(i, valueOrUpdater), () => removeFilter(filter.key, i)) : undefined),
        [filters, parameters, onChange, removeFilter])

    return (
        <VStack alignItems={"start"}>
            {error && <Alert status="error">
                <VStack alignItems={"start"}>
                    <HStack>
                        <AlertIcon />
                        <AlertTitle>{t("common.errorTitle")}</AlertTitle>
                    </HStack>
                    <AlertDescription data-testid="query-alert">{error}</AlertDescription></VStack>
            </Alert>}
            {components}
            <ParameterAutocomplete selected={filters} data={parameterIds} onChange={setFilters} />
            <HStack width={"full"} justifyContent={"space-between"}>
                <Button data-testid="close-query-button" onClick={onClose} variant={"outline"}>{t("common.cancel")}</Button>
                <Button data-testid="execute-query-button" isLoading={loading} loadingText={t("common.submitting")} onClick={_onSearch} isDisabled={invalidForm} colorScheme="teal">{t("filters.execute")}</Button>
            </HStack>
        </VStack>
    )
}