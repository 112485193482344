import { Input } from "@chakra-ui/react";
import { ChangeEvent, useCallback, useState } from "react"
import { BetweenDates } from "soverdi-api-models/src/params/between-dates.params"
import { IFilterFormControlProps } from "./filter-form-control.props"
import { FilterFormControl } from "./filter-form-control.component"

export function YearFilter({ text, onChange, onRemove }: IFilterFormControlProps<BetweenDates>) {

    const [numberValue, setNumberValue] = useState<string>("")
    const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setNumberValue(value)
        e.target.style.width = (5 + value.length) + "ch";
        const year = Number.parseInt(e.target.value)
        if (isNaN(year)) return
        const betweenDates: BetweenDates = { after: new Date(year, 0), before: new Date(year + 1, 0) }
        onChange(betweenDates)
    }, [setNumberValue, onChange])

    return (
        <FilterFormControl onClose={onRemove} text={text}>
            <Input data-testid="year-filter-input" type="number" textAlign={"center"} px={2} htmlSize={1} value={numberValue} onChange={onInputChange}
                height="25px" width='5ch' variant={"filled"}></Input>
        </FilterFormControl>
    )
}