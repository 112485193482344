import { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ModalSheetBody, ModalSheetContext, useInit } from "react-shared";
import { ModalHeader } from "../modal/modal-header.component";
import { FavoritesResources } from "../../model-resources/favorites.resources";
import { useTranslation } from "react-i18next";
import { ModelList } from "../model-list/model-list.component";
import { UserWithRelations } from "soverdi-api-models";
import { ChildResources } from "../../model-resources/model.resources";
import { getService } from "api-client-shared";
import { EmptyList } from "../empty-list.component";
import { UserService } from "soverdi-api-client";
import { Box, Spinner, Stack } from "@chakra-ui/react";
import { ModalTabInfo } from "../../types/modal-tab-info";
import { ModalSubheader } from "../modal/modal-subheader.component";
import { UnassignMeBulkAction } from "../menu-actions/bulk-actions/unassignme.bulk-action";
import { AssignMeBulkAction } from "../menu-actions/bulk-actions/assignme.bulk-action";
import "./favorites.view.css"

function FavoritesViewComponent() {

    const { t } = useTranslation()
    const [favorites, setFavorites] = useState<UserWithRelations | undefined>()
    const [virtuosoScrollParent, setVirtuosoScrollParent] = useState<HTMLElement>()
    const service = getService(UserService)
    const { setHeader } = useContext(ModalSheetContext)

    const refresh = useCallback(async () => {
        const _favorites = await service.getMe()
        setFavorites(_favorites)
    }, [service, setFavorites])

    const isEmpty = useMemo(() => favorites ? (FavoritesResources.lists.map(l => l.get(favorites)?.length || 0).reduce((a, c) => a + c, 0) === 0) : true, [favorites])
    const lists = useMemo(() => FavoritesResources.lists.map(l => {
        const clone = l.resources.clone()
        clone.bulkActions = [UnassignMeBulkAction, ...clone.bulkActions.filter(x => x !== AssignMeBulkAction)]
        return {
            ...l,
            resources: clone
        } as ChildResources<any, any>
    }), [])

    useInit(async () => {
        refresh()
    })

    useEffect(() => {
        setHeader(<ModalHeader title={favorites ? t("models.favorites") : undefined} icon={FavoritesResources.icon} actions={[]} />)
    }, [setHeader, favorites, t])

    return (
        <Box className="favorites-container">
            <ModalSheetBody data-testid="favorites-view" ref={r => { r && setVirtuosoScrollParent(r) }}>
                {favorites ?
                    isEmpty ?
                        <EmptyList /> :
                        lists.filter(l => l.get(favorites)!.length > 0).map(f => {
                            const listResources = f as ChildResources<UserWithRelations, any>
                            const list = listResources.get(favorites) || []
                            const tabinfo = { icon: listResources.resources.icon, title: t("models.asList." + listResources.resources.tabIdentifier) } as ModalTabInfo
                            return (
                                <Box key={f.resources.tabIdentifier}>
                                    <Box mb={4}>
                                        <ModalSubheader item={tabinfo} />
                                    </Box>
                                    <ModelList
                                        marginbottom="sm"
                                        modelResources={listResources.resources}
                                        ids={list}
                                        virtuosoScrollParent={virtuosoScrollParent}
                                        onChange={refresh}
                                    />
                                </Box>
                            )
                        }) : <Stack width={"full"} mt={"100px"} justifyContent={"center"} alignItems={"center"}><Spinner size={"xl"} /></Stack>}
            </ModalSheetBody>
        </Box>
    )
}

export const FavoritesView = memo(FavoritesViewComponent)