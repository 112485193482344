import { IsNumber, IsArray, ValidateNested, IsOptional, IsString, IsNotEmpty } from "class-validator";
import { Photo } from "./photo.model";
import { IsGeoJSON } from "../validators/geojson.validator";
import { ModelWithMeta } from "./base/model-with-meta";
import { Domaine } from "./domaine.model";
import { Type } from "class-transformer";
import { CartographicModel } from "./composition/cartographic.model";
import { SearchableModel } from "./composition/searchable.model";
import { Point } from "./geojson/point.geojson";
import { TypedDomaine } from "./typed-domaine.model";
import { DOMAINE_TYPE } from "../enums/domaine.enum";
import { IsDomaine } from "../decorators/_domaine.decorator";
import { FormConfig } from "@class-former/decorators"
import { FormableModel } from "./composition/formable.model";
import { Espece } from "./espece.model";
import { environment } from "../config/environment";
import { IsParent } from "../decorators/_parent.decorator";
import { PARENT_TYPE } from "../enums/parent-type.enum";
import { Produit } from "./produit.model";

export class Vegetal extends ModelWithMeta implements CartographicModel, SearchableModel, FormableModel {

    @IsDomaine(DOMAINE_TYPE.ETAT_SANTE)
    @ValidateNested()
    @IsOptional()
    @Type(() => Domaine)
    etatsante?: Domaine

    @IsDomaine(DOMAINE_TYPE.RAISON_MORTALITE)
    @IsOptional()
    @ValidateNested()
    @Type(() => Domaine)
    raisonmortalite?: Domaine

    @IsString()
    commentaires: string = ""

    @FormConfig({ specialType: "remplacement" })
    @IsOptional()
    @IsNumber()
    remplacementid?: number

    @IsGeoJSON()
    @ValidateNested()
    @Type(() => Point)
    geometry!: Point

    @IsDomaine(DOMAINE_TYPE.TAG)
    @ValidateNested({ each: true })
    @Type(() => Domaine)
    tags: Domaine[] = []

    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => Photo)
    photos: Photo[] = new Array()

    @IsParent(PARENT_TYPE.COMMANDE)
    @FormConfig({ skip: true, preserve: true })
    @IsNumber()
    idcommande!: number

    @FormConfig({ skip: true })
    @ValidateNested()
    @Type(() => Produit)
    produit!: Produit

    @IsDomaine(DOMAINE_TYPE.PRESENCE)
    @FormConfig({ tags: [environment.formTags.UAPMQ] })
    @IsOptional()
    @ValidateNested()
    @Type(() => Domaine)
    presence?: Domaine

    @IsDomaine(DOMAINE_TYPE.CUVETTE)
    @FormConfig({ tags: [environment.formTags.UAPMQ] })
    @IsOptional()
    @ValidateNested()
    @Type(() => Domaine)
    cuvette?: Domaine

    @IsDomaine(DOMAINE_TYPE.DOMMAGE)
    @FormConfig({ tags: [environment.formTags.UAPMQ] })
    @IsOptional()
    @ValidateNested({ each: true })
    @Type(() => Domaine)
    dommages: Domaine[] = []

    public static layer = "Végétaux"
    get layer() { return Vegetal.layer }
    get searchBy() { return this.produit.searchBy }
    public get subtext() {
        return [
            { i18nprefix: "vegetal.id", value: this.id.toString() },
            { i18nprefix: "common.createdOn", value: this.meta_datecreation.toDateString() }
        ]
    }
    get domaines() {
        const _types = []
        if (this.etatsante) _types.push(new TypedDomaine(this.etatsante, DOMAINE_TYPE.ETAT_SANTE))
        if (this.raisonmortalite) _types.push(new TypedDomaine(this.raisonmortalite, DOMAINE_TYPE.RAISON_MORTALITE))
        this.tags.forEach(t => _types.push(new TypedDomaine(t, DOMAINE_TYPE.TAG)))
        return _types
    }
    get parentid() { return this.idcommande }
    set parentid(id: number) { this.idcommande = id }
}
