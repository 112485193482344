import { ExportConfig } from "./export.config"
import { InterventionExportCSV } from "./intervention-export.csv"

class _InterventionExportConfig extends ExportConfig<InterventionExportCSV> {
    keys: { [key: string]: Array<keyof InterventionExportCSV> } = {
        vegetal: ["idvegetal"],
        intervention: ["typeintervention", "annee", "dateprevue", "datedepassage", "commentaires", "tags"],
        evenement: ["noevenement"],
        site: ["site"],
        projet: ["projet"]
    }
    categories = [this.keys.vegetal, this.keys.intervention]
    categoryOverheads = [0.000012, 0.0004]
    config = {
        idvegetal: { readonly: true },
        projet: {},
        site: {},
        noevenement: {},
        typeintervention: {},
        annee: {},
        dateprevue: {},
        datedepassage: {},
        commentaires: {},
        tags: {}
    }
}

export const InterventionExportConfig = new _InterventionExportConfig()