import { Type } from "class-transformer";
import { IsOptional, ValidateNested, IsArray } from "class-validator";
import { EditParams } from "../edit.params";
import { Model } from "../../../models/base/model";

export class TaggableBulkEditBody {
    @IsOptional()
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => Model)
    tags?: Array<Model>
}

export class TaggableBulkEditParams extends EditParams {
    @Type(() => TaggableBulkEditBody)
    @ValidateNested()
    body: Partial<TaggableBulkEditBody> = {};
}