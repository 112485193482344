import { Model, SearchableModel } from "soverdi-api-models";
import {
    HStack,
    Box,
    Card,
    CardBody,
    Modal,
    ModalBody,
    ModalContent,
    Heading,
    Button,
    ModalOverlay,
} from "@chakra-ui/react";
import { DisclosuredProps, useAsyncEffect } from "react-shared";
import { memo, useCallback, useMemo, useState } from "react";
import { ListFilters } from "../../model-list/components/list-filters.component";
import { Virtuoso } from "react-virtuoso";
import { EmptyList } from "../../empty-list.component";
import { ListItemPlaceholder } from "../../model-list-item/list-item.placeholder";
import { ModelListLoading } from "../../model-list/model-list-loading.component";
import { TypedDomainesState } from "../../selectors/domaines.selector";
import { useFilterData } from "../../model-list/hooks/filter-data";
import { ListItemBody } from "../../model-list-item/list-item-body.component";
import { RestService } from "api-client-shared";
import { useTranslation } from "react-i18next";
import { LoadMore } from "../../model-list/components/load-more.component";
import { environment } from "../../../config/environment";
import { GetModelService, ISearchService } from "soverdi-api-client";
import { MetadataHelper } from "soverdi-api-models";
import { FixIosPopupScroll } from "../../../hooks/ios-popup-scroll.hook";

const virtuosoStyle = { width: "100%", marginBottom: "60px" }
interface IProps<T extends Model & SearchableModel> extends DisclosuredProps {
    onChange: (value: T | undefined) => void
    model: new () => T
}
function FromModelListModalComponent<T extends Model & SearchableModel>({ onChange, isOpen, onClose, model }: IProps<T>) {

    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(true)
    const domaineTypes = useMemo(() => MetadataHelper.getDomainesTypesFromModel(model), [model])
    const service = useMemo(() => GetModelService(model) as RestService<T, any, any>, [model])
    const [loadedData, setLoadedData] = useState(new Array<T>())
    const [data, setData] = useState(new Array<number>())
    const [filteredData, setFilteredData] = useState(data)
    const [stringFilter, setStringFilter] = useState("")
    const [domaineFilters, setDomaineFilters] = useState<TypedDomainesState[]>(domaineTypes.map(d => ({ type: d, filters: [] })))
    const [virtuosoScrollParent, setVirtuosoScrollParent] = useState<HTMLElement>()

    FixIosPopupScroll(isOpen, () => document.getElementsByClassName("chakra-modal__body")[0])

    useAsyncEffect(async () => {
        if (!isOpen) return
        const result = await service.getAllIds()
        setData(result)
        setInitialLoading(false)
    }, [isOpen, service, setData, setInitialLoading])

    const filterData = useFilterData(service as unknown as ISearchService<any, any>)
    useAsyncEffect(async () => {
        if (!data) return
        setLoading(true)
        try {
            const result = await filterData(data, stringFilter, domaineFilters)
            setFilteredData(result)
            if (!result || result.length === 0) {
                setLoadedData([])
                return
            }
            const loaded = await service.some(result.slice(0, Math.min(result.length, environment.loadChunk)))
            setLoadedData(loaded)
        }
        catch (e) {
            console.error(e)
        }
        finally {
            setLoading(false)
        }
    }, [data, stringFilter, domaineFilters])

    const loadMore = useCallback(async () => {
        if (!filteredData) return
        const startIndex = loadedData.length
        if (startIndex >= filteredData.length) return
        setLoading(true)
        const loaded = await service.some(filteredData.slice(startIndex, Math.min(filteredData.length, startIndex + environment.loadChunk)))
        setLoadedData([...loadedData, ...loaded])
        setLoading(false)
    }, [setLoadedData, service, setLoading, loadedData, filteredData])

    const onItemClick = useCallback((x: T) => {
        onChange(x)
        onClose()
    }, [onChange, onClose])

    const prerenderedItems = useMemo(() => {
        return loadedData?.map((x) => (
            <Box mb={4} key={x.id}>
                <Card width={"full"}>
                    <CardBody p={3}>
                        <HStack justifyContent={"space-between"}>
                            <ListItemBody onClick={() => onItemClick(x)} data={x} />
                        </HStack>
                    </CardBody>
                </Card>
            </Box>
        ))
    }, [loadedData, onItemClick])

    const getPrerenderedListItem = useCallback((i: number) => (prerenderedItems[i]), [prerenderedItems])

    useAsyncEffect(async () => {
        loadMore()
    }, [data])

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxHeight={["100vh", "100vh", "70vh"]} width={["100vw", "100vw", "70vw"]} maxWidth={"600px"}>
                <ModalBody ref={r => { r && setVirtuosoScrollParent(r) }} height={"full"} width={"full"} overflow={"auto"}>
                    <HStack mb={4} mt={2} justifyContent={"space-between"}>
                        <Heading size={["sm", "sm", "md"]}>
                            {t("FromModelList.title")}
                        </Heading>
                        <Button onClick={onClose} variant={"outline"}>{t("common.cancel")}</Button>
                    </HStack>
                    <Box mb={4}>
                        <ListFilters
                            domaineTypes={domaineTypes}
                            stringFilter={stringFilter}
                            onStringFilterChange={setStringFilter}
                            domainefilters={domaineFilters}
                            setDomaineFilters={setDomaineFilters}
                        />
                    </Box>
                    {initialLoading ?
                        <ModelListLoading scrollParent={virtuosoScrollParent} />
                        : data?.length === 0 ?
                            <Box mt={12}><EmptyList /></Box>
                            : <Virtuoso
                                context={{ loading, loadMore }}
                                style={virtuosoStyle}
                                customScrollParent={virtuosoScrollParent}
                                data={prerenderedItems}
                                itemContent={getPrerenderedListItem}
                                components={{ ScrollSeekPlaceholder: ListItemPlaceholder, Footer: LoadMore }}
                                scrollSeekConfiguration={{
                                    enter: (velocity) => Math.abs(velocity) > 500,
                                    exit: (velocity) => Math.abs(velocity) < 20
                                }}
                            />
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export const FromModelListModal = memo(FromModelListModalComponent) as typeof FromModelListModalComponent