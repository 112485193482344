import { ChangeEvent, ChangeEventHandler, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

export function useInputState(initialState: string | (() => string), options?: { validate?: (value: string) => boolean, noUpdateOnInitialStateChange?: boolean }): [string, [ChangeEventHandler<HTMLInputElement>, Dispatch<SetStateAction<string>>], () => void, boolean] {
    const [text, setText] = useState<string>(initialState);
    const invalid = useMemo(() => text.length === 0 || (options?.validate ? !options?.validate(text) : false), [text])
    const onHTMLValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation()
        setText(e.target.value as string)
    }, [setText])
    const reset = useCallback(() => setText(initialState), [initialState])
    useEffect(() => {
        if (options?.noUpdateOnInitialStateChange) return
        setText(initialState)
    }, [initialState])
    return [text, [onHTMLValueChange, setText], reset, invalid]
}

export function useSelectState(initialState: string | (() => string)): [string, ChangeEventHandler<HTMLSelectElement>, Dispatch<SetStateAction<string>>] {

    const [text, setText] = useState<string>(initialState);
    const onHTMLValueChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        setText(e.target.value as string)
    }, [setText])
    return [text, onHTMLValueChange, setText]
}

export function useCheckboxState(initialState: boolean | (() => boolean)): [boolean, ChangeEventHandler<HTMLInputElement>, Dispatch<SetStateAction<boolean>>] {
    const [value, setValue] = useState<boolean>(initialState);
    const onHTMLValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.checked)
    }, [setValue])
    return [value, onHTMLValueChange, setValue]
}