import { IVegetauxService } from "soverdi-api-client";
import { BulkActionProps } from "./bulk-action.props";
import { ChangeEvent, memo, useCallback, useMemo, useState } from "react";
import { Button } from "@chakra-ui/button";
import { Spinner } from "@chakra-ui/spinner";
import { useToast } from "@chakra-ui/toast";
import { useTranslation } from "react-i18next";
import { useDownload } from "../../../hooks/download";
import { DisclosuredProps, useAsyncEffect } from "react-shared";
import {
    Alert,
    AlertIcon,
    Badge,
    Checkbox,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from "@chakra-ui/react";
import { getBadgeColor } from "../../../utils/badge-color";
import { ExportConfig } from "soverdi-api-models";
import { ExportParams, environment } from "soverdi-api-models";
import { CSVOptions } from "soverdi-api-models";

interface IProps<Y> extends BulkActionProps<any, IVegetauxService>, DisclosuredProps {
    i18ntype: "vegetaux" | "interventions"
    config: ExportConfig<Y>
    exportAsCSV: (params: ExportParams<Y>) => Promise<string>
}
function ExportCSVBulkActionComponent<Y>({ i18ntype, service, config, data, exportAsCSV, isOpen, onClose }: IProps<Y>) {

    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(false)
    const [exportOptions, setExportOptions] = useState(() => config.init(true))
    const [nbOfItemsToExport, setNbOfItemsToExport] = useState<number | undefined>()
    const toast = useToast()
    const { t } = useTranslation()
    const download = useDownload()
    const estimate = useMemo(() => {
        if (nbOfItemsToExport === undefined) return 0
        let _estimate = 0
        const mostExpansiveIndex = [...config.categories].reverse().findIndex((category, i) => category.some(c => exportOptions[c]))
        config.categoryOverheads.slice(0, config.categoryOverheads.length - mostExpansiveIndex).forEach(overhead => { _estimate += overhead * nbOfItemsToExport })
        return Math.ceil(_estimate)
    }, [nbOfItemsToExport, exportOptions, config.categoryOverheads, config.categories])

    useAsyncEffect(async () => {
        if (!isOpen) return
        if (service.vegetaux.getEndpoint() === environment.endpoints.vegetal.base)
            setNbOfItemsToExport(data.length)
        else {
            try {
                setInitialLoading(true)
                const vegetauxIds = await service.vegetaux.vegetauxForEntityIds(data)
                setNbOfItemsToExport(vegetauxIds.length)
            }
            catch (e) {
                console.log(e)
                toast({ colorScheme: "red", title: t("common.errorTitle"), description: t("common.errorMessage") })
            }
            finally {
                setInitialLoading(false)
            }
        }
    }, [isOpen, setNbOfItemsToExport, setInitialLoading, toast, t, data, service])

    const onOptionChange = useCallback((checked: boolean, key: keyof Y) => {
        if (config.config[key].readonly) return
        setExportOptions(p => {
            const cool = { ...p } as CSVOptions<Y>
            cool[key] = checked
            return cool
        })
    }, [setExportOptions, config.config])

    const onCategoryOptionChange = useCallback((e: ChangeEvent<HTMLInputElement>, keys: Array<keyof Y>) => {
        keys.forEach(x => onOptionChange(e.target.checked, x))
    }, [onOptionChange])

    const onSubmit = useCallback(async () => {
        setLoading(true)
        try {
            const options = { ...exportOptions } as CSVOptions<Y>
            const csv = await exportAsCSV({ ids: data, options })
            const filename = i18ntype + "_" + Date.now() + ".csv";
            download(csv, filename)
        }
        catch (e) {
            console.log(e)
            toast({ colorScheme: "red", title: t("common.errorTitle"), description: t("common.errorMessage") })
        }
        finally {
            setLoading(false)
        }
    }, [data, t, toast, download, exportOptions, exportAsCSV, i18ntype])

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent width={["100vw", "100vw", "70vw"]} maxWidth={"600px"}>
                <ModalHeader>{t(`export.${i18ntype}.title`)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {(!initialLoading && nbOfItemsToExport !== undefined) ?
                        config.categories.map((category, i) => {
                            const color = getBadgeColor(i)
                            return (
                                <VStack key={category.join()} ml={(i * 10) + "px"} mt={4} alignItems="start">
                                    <Badge p={1} pr={2} variant='outline' colorScheme={color}>
                                        <HStack>
                                            <Checkbox data-testid="export-category-checkbox" onChange={(e) => onCategoryOptionChange(e, category)} borderColor={"gray.600"} isChecked={category.every(c => exportOptions[c])}></Checkbox>
                                            <Text>{`${t("export.categoryOverhead")} ${Math.ceil(config.categoryOverheads[i] * nbOfItemsToExport)} ${t("time.secondes")}`}</Text>
                                        </HStack>
                                    </Badge>
                                    <HStack flexWrap={"wrap"} gap={2} overflowX={"hidden"}>
                                        {category.map(c => {
                                            if (!config.config[c]) console.log(c)
                                            const isReadonly = config.config[c].readonly
                                            return (
                                                <Badge p={1} pl={isReadonly ? 2 : 1} pr={2} key={c as string} colorScheme={color}>
                                                    <HStack>
                                                        <Checkbox data-testid="export-category-item-checkbox" isDisabled={isReadonly} borderColor={"gray.600"} isChecked={exportOptions[c]} onChange={(e) => onOptionChange(e.target.checked, c)}></Checkbox>
                                                        <Text>{c as string}</Text>
                                                    </HStack>
                                                </Badge>
                                            )
                                        })}
                                    </HStack>
                                </VStack>
                            )
                        }) :
                        <HStack justifyContent={"center"} alignItems={"centerF"}><Spinner size={"xl"} /></HStack>
                    }
                    {estimate > 0 &&
                        <Alert mt={6} data-testid="csv-import-estimate" status='success'>
                            <AlertIcon />
                            {`${t("common.estimate")} ${estimate} ${t("time.secondes")}`}
                        </Alert>
                    }
                    <HStack justifyContent={"space-between"} mt={10} mb={4}>
                        <Button data-testid="form-cancel-button" onClick={onClose}>
                            {t("common.cancel")}
                        </Button>
                        <Button data-testid="export-vegetaux-submit-button" onClick={onSubmit} isLoading={loading} loadingText={t("common.submitting")} colorScheme="teal">
                            {t("common.confirm")}
                        </Button>
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export const ExportCSVBulkAction = memo(ExportCSVBulkActionComponent) as typeof ExportCSVBulkActionComponent