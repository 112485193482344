import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
export interface RoutingDisclosureForTabsOptions {
    animationDuration: number, tabPaths: Array<string>
}
import urljoin from "url-join"
import { useInitialLocation } from "./location/initial-location"
import { findLastIndex } from "../utils/_findlastindex"

export function useRoutingDisclosureForTabs({ animationDuration, tabPaths }: RoutingDisclosureForTabsOptions) {

    const [tabIndex, setTabIndex] = useState(0)
    const [inTransition, setInTransition] = useState(false)
    const pathPrefix = useInitialLocation()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const isTabPathInLocation = useCallback((path: string) => {
        const url = urljoin(pathPrefix, path)
        if (pathname.includes(url + "/")) return true
        if (pathname.endsWith(url)) return true
        return false
    }, [pathname])

    const setFutureTabIndex = useCallback((index: number) => {
        navigate(urljoin(pathPrefix, tabPaths[index]), { state: { dynamicLocation: true } })
    }, [navigate, tabPaths, pathPrefix])

    const futureTabIndex = useMemo(() => {
        const _tabIndex = Math.max(0, (findLastIndex(tabPaths, p => isTabPathInLocation(p))))
        return _tabIndex
    }, [isTabPathInLocation, tabPaths])

    useEffect(() => {
        if (futureTabIndex === tabIndex) return
        setTimeout(() => {
            setTabIndex(futureTabIndex)
            setInTransition(false)
        }, animationDuration)
    }, [futureTabIndex, tabIndex, setTabIndex, setInTransition, animationDuration])

    const onTabChange = useCallback((i: number) => {
        setFutureTabIndex(i)
        setInTransition(true)
    }, [setInTransition, setFutureTabIndex])

    return { tabIndex, futureTabIndex, inTransition, onTabChange }
}