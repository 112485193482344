import { ExportConfig } from "./export.config"
import { VegetalExportCSV } from "./vegetal-export.csv"

class _VegetalExportConfig extends ExportConfig<VegetalExportCSV> {
    keys: { [key: string]: Array<keyof VegetalExportCSV> } = {
        vegetal: ["idvegetal", "remplacementid", "commentaires", "lat", "lon", "tags", "etatsante", "dommages", "cuvette", "raisonmortalite", "photos", "dernieremodification"],
        commande: ["sku", "fournisseur", "calibre", "hauteur", "formatpot", "dhs"],
        evenementAndInventory: ["typeevenement", "annee", "dateprevue", "specimenid", "genre", "nomespece", "cultivar", "code", "typeespece"],
        site: ["site", "territoire", "codepostal", "numerodeporte", "typerue", "nomrue", "entiteadministrative", "ville", "mrc", "reseau"],
        projet: ["projet"]
    }
    categories = [this.keys.vegetal, this.keys.commande, this.keys.evenementAndInventory, this.keys.site, this.keys.projet]
    categoryOverheads = [0.00014, 0.00012, 0.0001, 0.0001, 0.0001]
    config = {
        idvegetal: { readonly: true },
        remplacementid: {},
        projet: {},
        site: {},
        numerodeporte: {},
        typerue: {},
        nomrue: {},
        entiteadministrative: {},
        ville: {},
        mrc: {},
        territoire: {},
        codepostal: {},
        reseau: {},
        noevenement: {},
        typeevenement: {},
        annee: {},
        dateprevue: {},
        genre: {},
        nomespece: {},
        cultivar: {},
        code: {},
        sku: {},
        typeespece: {},
        specimenid: {},
        dhs: {},
        hauteur: {},
        calibre: {},
        formatpot: {},
        etatsante: {},
        raisonmortalite: {},
        fournisseur: {},
        dommages: {},
        cuvette: {},
        presence: {},
        tags: {},
        lat: {},
        lon: {},
        commentaires: {},
        photos: {},
        dernieremodification: {}
    }
}

export const VegetalExportConfig = new _VegetalExportConfig()
