import { Card, CardBody, HStack, Checkbox, VStack } from "@chakra-ui/react";
import { ChangeEvent, Dispatch, FC, SetStateAction, memo, useCallback, useMemo } from "react";
import { BrickMenu } from "react-shared";
import { Model, SearchableModel } from "soverdi-api-models";
import { BulkActionProps } from "../menu-actions/bulk-actions/bulk-action.props";
import { ActionProps } from "../menu-actions/actions/action.props"
import { RestService } from "api-client-shared";
import { IBulkService } from "soverdi-api-client";
import { ListItemBody } from "./list-item-body.component";

export interface IListItemProps<T extends Model & SearchableModel> {
    data?: T,
    model: new () => T
    service: RestService<T, any, any> | IBulkService<T, any>
    actions: Array<FC<ActionProps<T, any>>>
    bulkActions: Array<FC<BulkActionProps<T, any>>>
    onChange: Dispatch<SetStateAction<Array<number>>>
    checked: boolean
    onCheckedChange: (value: boolean) => void
    onClick: (value: T) => void
}

function ListItemComponent<T extends Model & SearchableModel>({ model, service, onChange, onClick: _onClick, data, actions, bulkActions, checked, onCheckedChange }: IListItemProps<T>) {

    const renderedActioms = useMemo(() => data ? actions.map((Action, i) => (
        <Action key={"action_" + i} model={model} service={service} data={data.id} onChange={onChange}></Action>
    )) : [], [data, onChange, actions, model, service])
    const renderedBulkActions = useMemo(() => data ? bulkActions.map((Action, i) => (
        <Action key={"bulkaction_" + i} model={model} service={service} data={[data.id]} onChange={onChange}></Action>
    )) : [], [data, onChange, service, bulkActions, model])
    const combinedActions = useMemo(() => ([...renderedActioms, ...renderedBulkActions]), [renderedActioms, renderedBulkActions])

    const onClick = useCallback(() => {
        if (!data) return
        _onClick(data)
    }, [data, _onClick])

    const onCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        onCheckedChange(e.target.checked)
    }, [onCheckedChange])


    return (
        <Card width={"full"} data-testid="model-list-item">
            <CardBody p={3}>
                <HStack justifyContent={"space-between"}>
                    <VStack alignItems={"start"}>
                        <Checkbox data-testid="list-item-checkbox" mx={2} isChecked={checked} onChange={onCheckboxChange} />
                    </VStack>
                    <ListItemBody onClick={onClick} data={data} />
                    <BrickMenu buttons={combinedActions} />
                </HStack>
            </CardBody>
        </Card>
    )
}

export const ListItem = memo(ListItemComponent) as typeof ListItemComponent