import { Service, Inject } from "typedi";
import { AuthService } from "./auth.service";
import axios from "axios";
import { ISharedGeoprojet, SharedGeoprojet } from "portail-api-models";
import { environment } from "../config/_environment";
import { plainToClass } from "class-transformer"
import { PortailAPIUrl } from "../utils/portail-api-url";

@Service()
export class GeoprojetPartageService {

    @Inject()
    private url!: PortailAPIUrl

    constructor(@Service() private auth: AuthService) { }

    async get(shareId: string): Promise<SharedGeoprojet> {
        await this.auth.beforeRequest()
        const res = await axios.get<ISharedGeoprojet>(this.url.join([environment.API.ENDPOINTS.partageGeoprojets, shareId]))
        const clazzed = plainToClass(SharedGeoprojet, res.data)
        return clazzed
    }

}