import { Button, Icon } from "@chakra-ui/react";
import { ChangeParentResourceRoles, FormableModel, Model, SearchableModel, getParentModel } from "soverdi-api-models";
import { useTranslation } from "react-i18next"
import { memo, useCallback, useContext, useMemo, useState } from "react";
import { RestService } from "api-client-shared";
import { useRoutingDisclosure, useStatusToast } from "react-shared"
import { FromModelListModal } from "../../inputs/components/from-model-list.component";
import { ActionProps } from "./action.props";
import { MetadataHelper } from "soverdi-api-models";
import { useTabStack } from "../../../hooks/tab-stack/tab-stack.hook";
import { MdModeEdit } from "react-icons/md";
import { AppContext } from "../../../app.context";

function ChangeParentComponent<T extends Model & FormableModel & SearchableModel>({ service, model, data }: ActionProps<T, RestService<T, any, any>>) {

    const { t } = useTranslation()
    const parentType = useMemo(() => MetadataHelper.getParentTypeFromModel(model), [model])
    const parentModel = useMemo(() => getParentModel(parentType) as new () => Model & SearchableModel, [parentType])
    const { isOpen, onOpen, onClose } = useRoutingDisclosure(service.endpoint + "/parent")
    const { pop } = useTabStack()
    const { onSuccess, onError } = useStatusToast()
    const [loading, setLoading] = useState(false)
    const { token } = useContext(AppContext)

    const onItemClick = useCallback(async (x: Model | undefined) => {
        setLoading(true)
        try {
            const toUpdate = new model()
            for (const key in toUpdate) { if (toUpdate.hasOwnProperty(key)) toUpdate[key] = undefined as any }
            toUpdate.parentid = x?.id!
            await service.update(data, toUpdate)
            pop()
            onSuccess(t("menuActions.change"), "save")
        }
        catch (e) {
            console.error(e)
            onError(t("menuActions.change"), "save")
        }
        finally {
            setLoading(false)
        }
    }, [data, model, onError, onSuccess, pop, service, t])

    return (
        <>
            {ChangeParentResourceRoles.includes(token!.role) &&
                <>
                    <Button isLoading={loading} loadingText={`${t("menuActions.changeParentButton")} ${t("models." + parentType)}`} data-testid="change-parent-button" onClick={onOpen} rightIcon={<Icon as={MdModeEdit} />}>{`${t("menuActions.changeParentButton")} ${t("models." + parentType)}`}</Button>
                    <FromModelListModal
                        onChange={onItemClick}
                        isOpen={isOpen}
                        model={parentModel}
                        onClose={onClose}
                    />
                </>
            }
        </>
    )
}

export const ChangeParentAction = memo(ChangeParentComponent) as typeof ChangeParentComponent