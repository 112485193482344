import { Expose, Transform } from "class-transformer"
import { IsNotEmpty, IsNumber, IsOptional, IsString } from "class-validator"
import { CSVModel } from "./_csv-model.type"
import { Espece } from "../models/espece.model"
import { Produit } from "../models/produit.model"

export class InventoryItemCSV implements CSVModel<Espece, "couleur">, CSVModel<Produit, "espece"> {
    @IsString()
    @IsNotEmpty()
    genre!: string
    @IsString()
    @IsNotEmpty()
    nomespece!: string
    @IsString()
    @IsOptional()
    cultivar?: string
    @IsString()
    @IsNotEmpty()
    code!: string
    @IsString()
    typeespece!: string
    @IsString()
    @IsNotEmpty()
    sku!: string
    @IsString()
    @IsNotEmpty()
    fournisseur!: string
    @IsString()
    @IsNotEmpty()
    calibre!: string
    @IsNumber()
    @IsNotEmpty()
    @Transform(({ value }) => Number.parseInt(value), { toClassOnly: true })
    hauteur!: number
    @IsString()
    @IsNotEmpty()
    formatpot!: string
    @IsNumber()
    @IsOptional()
    @Transform(({ value }) => value ? Number.parseInt(value) : undefined, { toClassOnly: true })
    dhs?: number
}