import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { useEffect } from "react"

/**
 * Hack for ios not supporting scroll on popup elements
 */
export function FixIosPopupScroll(isOpen: boolean, getTarget: () => Element) {
    useEffect(() => {
        setTimeout(() => {
            const target = getTarget()
            isOpen ? disableBodyScroll(target, {}) : enableBodyScroll(target)
        })
    }, [isOpen, getTarget])
}