import { Transform } from "class-transformer"
import { IsDate, IsNotEmpty, IsOptional, IsString } from "class-validator";
import { CSVModel } from "./_csv-model.type";
import { Intervention } from "../models/intervention.model";

export class InterventionImportCSV implements CSVModel<Intervention, "users" | "idevenement" | "tags"> {
    @IsString()
    @IsNotEmpty()
    noevenement!: string
    @IsString()
    @IsNotEmpty()
    @Transform(({ value }) => value ? value.toLowerCase() : undefined, { toClassOnly: true })
    typeintervention!: string
    @IsDate()
    @Transform(({ value }) => value ? new Date(value) : undefined, { toClassOnly: true })
    dateprevue!: Date
    @IsDate()
    @IsOptional()
    @Transform(({ value }) => value ? new Date(value) : undefined, { toClassOnly: true })
    datedepassage?: Date
    @IsString()
    commentaires!: string
}