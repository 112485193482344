import { Box, Wrap, WrapItem } from "@chakra-ui/react";
import { ReactNode } from "react";

interface IProps {
    children?: ReactNode
}
export function FakeInput({ children }: IProps) {
    return (
        <Wrap width={"full"}>
            <WrapItem width={"full"}>
                <Box width={"full"}
                    borderWidth="1px"
                    borderColor="gray.200"
                    py={1}
                    px={1}
                    borderRadius={6}
                    minHeight={"40px"}
                >
                    {children}
                </Box>
            </WrapItem>
        </Wrap>
    )
}